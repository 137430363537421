import {useTheme} from "@mui/system";
import {Box, useMediaQuery} from "@mui/material";
import "./commonPage.css";
import React from "react";

const Desktop = ({Image}) => {
    return <div
        className={"common-page-container"}
        style={{
            backgroundImage: `url(${Image})`
        }}
    />
}

const Mobile = ({Image, title}) => {
    return <div
        className={"common-page-container"}
        style={{
            backgroundImage: "none"
        }}
    >
        <img src={Image} alt={title}/>
    </div>
}

const CommonPage = ({Component, ...props}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

    return <Box display="flex" justifyContent="center" alignItems="center" flex={1} position="relative">
        {Component ? <Component/> : <React.Fragment>
            {isMobile && <Mobile Image={props.MobileImage} title={props.title}/>}
            {!isMobile && <Desktop Image={props.DesktopImage} title={props.title}/>}
        </React.Fragment>}
    </Box>
}

export default CommonPage;