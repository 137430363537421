import {Divider, ListItemIcon, ListItemText} from "@mui/material";
import MuiMenu from "@mui/material/Menu";
import MuiMenuItem from "@mui/material/MenuItem";
import React from "react";

const Menu = React.memo(({ anchorEl, id, onClose, items = [], closeOnSelect, ...props }) => {
  return (
    <MuiMenu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={id}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
      {...props}
    >
      {items.map((item, index) => {
        if (item.isDivider) {
          return <Divider key={id + index} />;
        } else {
          return (
            <MuiMenuItem
              key={id + index}
              onClick={(e, p) => {
                if (item.onSelect) {
                  item.onSelect(e, p);
                }
                if (closeOnSelect || item.closeOnSelect) {
                  onClose(e, p);
                }
              }}
            >
              {item.Icon && (
                <ListItemIcon {...(item.props ? item.props : {})}>
                  {item.Icon}
                </ListItemIcon>
              )}
                <ListItemText>
                    {item.name}
                </ListItemText>
            </MuiMenuItem>
          );
        }
      })}
    </MuiMenu>
  );
});

export default Menu;
