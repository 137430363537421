import {useNavigate} from "react-router-dom";
import React from "react";
import {App} from "@capacitor/app";
import {Browser} from "@capacitor/browser";
import {Button, CircularProgress, Dialog, Paper, Stack, Typography, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useConfig} from "../providers/ConfigProvider";
import {UPORUtils} from "../../utils";
import {ScreenBrightness} from "@capacitor-community/screen-brightness";
import {Network} from "@capacitor/network";
import CardMedia from "@mui/material/CardMedia";
import {patternFormatter} from "react-number-format";
import {Capacitor} from "@capacitor/core";
import {AppUpdate, AppUpdateAvailability} from "@capawesome/capacitor-app-update";
import {CloudDownload} from "@mui/icons-material";

const NativeAppHandler = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {
        config,
        protectedResources,
        updateNetworkConnectionState,
        networkConnection,
        appIsRunning
    } = useConfig();

    const [userProps, setUserProps] = React.useState({
        qrCode: localStorage.getItem("uporAppQrCode"),
        mediumId: localStorage.getItem("uporAppMediumId"),
    });
    const [requiredUpdate, setRequiredUpdate] = React.useState(false);
    const [brightness, setBrightness] = React.useState(null);

    React.useEffect(() => {
        if (UPORUtils.isDevelopmentMode() || !Capacitor.isNativePlatform) {
            return;
        }

        ScreenBrightness
            .getBrightness()
            .then(screen => {
                setBrightness(screen.brightness);
            })
            .catch(e => e);
    }, []);

    React.useEffect(() => {
        if (UPORUtils.isDevelopmentMode() || !Capacitor.isNativePlatform) {
            return;
        }

        Network.addListener('networkStatusChange', status => {
            console.log("Changing network status : " + status.connected);
            console.log("Changing network type : " + status.connectionType);
            updateNetworkConnectionState(status.connected);
        });

        Network
            .getStatus()
            .then(status => {
                updateNetworkConnectionState(status.connected);
            })
            .catch(e => e);

        return () => {
            void Network.removeAllListeners();
        }
    }, []);

    React.useEffect(() => {
        if (brightness !== null && brightness !== undefined) {
            if (networkConnection === false) {
                void ScreenBrightness.setBrightness({brightness: 1.0});
            } else {
                void ScreenBrightness.setBrightness({brightness: brightness});
            }
        }
    }, [networkConnection, brightness]);

    React.useEffect(() => {
        if (protectedResources) {
            if (config && config['mediums']) {
                if (config['mediums'].length === 1) {
                    let card = config['mediums'][0];
                    localStorage.setItem("uporAppQrCode", card.qrCode);
                    localStorage.setItem("uporAppMediumId", card.mediumId);
                } else {
                    localStorage.removeItem("uporAppQrCode");
                    localStorage.removeItem("uporAppMediumId");
                }
            }
        } else {
            localStorage.removeItem("uporAppQrCode");
            localStorage.removeItem("uporAppMediumId");
        }

        setUserProps({
            qrCode: localStorage.getItem("uporAppQrCode"),
            mediumId: localStorage.getItem("uporAppMediumId")
        });
    }, [config, protectedResources]);

    React.useEffect(() => {
        if (appIsRunning) {
            let active = true;

            let timeout = setTimeout(() => {
                AppUpdate
                    .getAppUpdateInfo()
                    .then((appVersion) => {
                        if (active) {
                            if (appVersion.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
                                setRequiredUpdate(false);
                            }
                            let update = appVersion.availableVersion !== undefined && appVersion.currentVersion !== appVersion.availableVersion;
                            setRequiredUpdate(update);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }, 500);

            return () => {
                active = false;
                clearTimeout(timeout);
            };
        }
    }, [appIsRunning]);

    return <Dialog
        sx={{
            zIndex: 1399
        }}
        PaperProps={{
            sx: {
                color: theme.palette.mode === "light" ? "primary.contrastText" : "text.primary",
                backgroundColor: theme.palette.mode === "light" ? "primary.main" : "common.black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }
        }}
        open={networkConnection === false || requiredUpdate}
        fullScreen
    >
        {(networkConnection === true && requiredUpdate) &&
            <Stack spacing={5} justifyContent="center" alignItems="center" p={2}>
                <CloudDownload sx={{fontSize: "8rem"}}/>
                <Stack spacing={1} maxWidth={300}>
                    <Typography textAlign="center">
                        {t("components.appUpdateHandler.title")}
                    </Typography>
                    <Typography textAlign="center" variant="body2">
                        {t("components.appUpdateHandler.description")}
                    </Typography>
                </Stack>
                <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        AppUpdate
                            .openAppStore()
                            .catch(e => e);
                    }}
                >
                    {t("components.appUpdateHandler.button")}
                </Button>
            </Stack>}
        {networkConnection === false &&
            <Stack spacing={protectedResources && userProps.qrCode ? 4 : 10} justifyContent="center"
                   alignItems="center">
                {(protectedResources && userProps.qrCode) &&
                    <Stack component={Paper} bgcolor="common.white" alignItems="center" pt={2} pb={1} pl={4} pr={4}
                           spacing={1}>
                        <CardMedia
                            component="img"
                            sx={{
                                maxWidth: 140,
                                maxHeight: 140,
                                width: "100%"
                            }}
                            image={userProps.qrCode}
                        />
                        <Typography variant="caption" color="common.black">
                            {patternFormatter(userProps.mediumId, {format: "## ### ### ### ### ###"})}
                        </Typography>
                    </Stack>}
                <Stack spacing={1.5} justifyContent="center" alignItems="center">
                    <Typography>
                        {t("components.networkConnectionHandler.title")}
                    </Typography>
                    <CircularProgress color="secondary"/>
                </Stack>
                <Typography variant="caption" textAlign="justify" pr={3} pl={3}>
                    {t("components.networkConnectionHandler.description")}
                </Typography>
            </Stack>}
    </Dialog>
}

const NativeAppEventsListener = () => {
    const navigate = useNavigate();
    const {
        updateAppRunningState
    } = useConfig();

    React.useEffect(() => {
        if (UPORUtils.isDevelopmentMode() || !Capacitor.isNativePlatform) {
            return;
        }

        App.addListener('appUrlOpen', data => {
            try {
                if (data.url.includes("auth?token=")) {
                    const slug = data.url.split('auth?token=').pop();
                    if (slug) {
                        navigate("/auth?token=" + slug);
                    }
                } else if (data.url.includes("auth/password-recovery?token=")) {
                    const slug = data.url.split('auth/password-recovery?token=').pop();
                    if (slug) {
                        navigate("/auth/password-recovery?token=" + slug);
                    }
                } else if (data.url.includes("?purchaseId=")) {
                    const slug = data.url.split('?purchaseId=').pop();
                    if (slug) {
                        navigate("/?purchaseId=" + slug);
                    }
                }

                void Browser.close();
            } catch (e) {
                console.error(e);
            }
        });

        App.addListener('appStateChange', ({isActive}) => {
            updateAppRunningState(isActive === true);
        });


        return () => {
            void App.removeAllListeners();
        }
    }, []);

    return null;

}

export {
    NativeAppEventsListener,
    NativeAppHandler
}