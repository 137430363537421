import {getLanguage, useDataFetcher} from "@beesset/common-utils";
import {UPORClientRest} from "@beesset/upor-client-module-cmn";
import React from "react";
import {ChipsList} from "@beesset/ui-components";
import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import Article from "./Article";
import "@beesset/upor-server-module/public/css/TinymceTemplateStyles.css";

const Categories = ({selected, categories, toggleCategory}) => {
    return <ChipsList
        descriptors={categories.map(o => {
            return {
                name: o.name,
                color: selected[o.loid] ? "primary" : "default",
                onDelete: selected[o.loid]
                    ? () => toggleCategory(o)
                    : undefined,
                onClick: !selected[o.loid]
                    ? () => toggleCategory(o)
                    : undefined
            }
        })}
    />
}

const Articles = () => {
    const {t} = useTranslation();
    const [selected, setSelected] = React.useState(null);

    const {
        data
    } = useDataFetcher({
        fetch: UPORClientRest.getArticlesIntros,
        request: {
            language: getLanguage()
        }
    });

    React.useEffect(() => {
        if(data) {
            let result = {};
            data.categories.forEach(o => {
                result[o.loid] = true;
            });
            setSelected(result);
        }
    }, [data]);

    function toggleCategory(category) {
        setSelected(old => {
            let result = {...old};
            result[category.loid] = !result[category.loid];
            return result;
        })
    }

    function shouldShowArticle(article) {
        return article.categories.length === 0 || article.categories.find((o) => selected[o.loid]) !== undefined;
    }

    return selected && <Stack alignItems="center" flex={1} spacing={2}>
        <Typography variant="h4" color="primary" display={{xs: "none", sm: "block"}}>
            {t("componentsNames.articles")}
        </Typography>
        <Categories
            categories={data.categories}
            toggleCategory={toggleCategory}
            selected={selected}
        />
        <Stack maxWidth={1200} width="100%" spacing={5}>
            {data["intros"]
                .filter(shouldShowArticle)
                .map(o => <Article {...o}/>)
            }
        </Stack>
    </Stack>;
}

export {
    Articles
};