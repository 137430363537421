import React from "react";
import {useTranslation} from "react-i18next";
import {
    FormContainer,
    hideLoader,
    ResponsiveFieldsGroup,
    SelectField,
    showLoader,
    Snackbar,
    TextField
} from "@beesset/ui-components";
import {useFormContext} from "react-hook-form";
import {Box, Button, Collapse, Fade, List, MenuItem, Stack, Typography} from "@mui/material";
import {DateUtils, getLanguage, useDataFetcher} from "@beesset/common-utils";
import {APPLICATION_TYPE, RegulationCheck, UPORClientRest, useConfig} from "@beesset/upor-client-module-cmn";
import ChannelsField from "./ChannelsField";


const InvoiceApplicationFields = ({
                                      correction,
                                      transaction,
                                      transactionRequired = true,
                                      translations,
                                      onTransactionChange
                                  }) => {
    const {
        watch,
        reset,
        getValues
    } = useFormContext();

    const [bean, setBean] = React.useState(getValues());

    React.useEffect(() => {
        const subscription = watch((value) => {
            setBean(value);
        });
        return () => {
            subscription['unsubscribe']();
        };
    }, [watch]);

    React.useEffect(() => {
        if (bean.transaction || bean.transactionId || !transactionRequired) {
            onTransactionChange(true);
        } else {
            onTransactionChange(false);
        }
        if (!transaction && correction) {
            if (!bean.loid && bean.transaction && bean.transaction.invoice) {
                reset({
                    ...bean.transaction.invoice,
                    transactionId: undefined,
                    transaction: bean.transaction,
                    channel: bean.channel
                });
            }
        }
    }, [bean, onTransactionChange]);

    return <ResponsiveFieldsGroup isMainContainer minWidth="unset">
        {
            transactionRequired && <React.Fragment>
                {!transaction && <React.Fragment>
                    <ChannelsField
                        applicationType={correction?APPLICATION_TYPE.INVOICE_CORRECTION:APPLICATION_TYPE.INVOICE}
                    />
                    {bean["channel"] && <Fade in={true} timeout={500}>
                        <Box>
                            <SelectField
                                isStacked
                                key="select-transaction"
                                name="transaction"
                                label={translations.transaction}
                                required
                                disableClearButton
                                getOptionLabel={(option) => {
                                    return option.transactionId
                                }}
                                getOptionId={(option) => {
                                    return option.transactionId;
                                }}
                                renderOption={(props, option) => {
                                    return <MenuItem {...props}>
                                        <Stack width="100%">
                                            <Typography noWrap>
                                                {`${option.item.profileName} ${option.item.productName}`}
                                            </Typography>
                                            <Typography noWrap fontSize={13} color="text.secondary">
                                                {translations.fromToDate({
                                                    startDate: DateUtils.formatters.DDMMYYYY_HHMMSS(option.item.activationDate),
                                                    endDate: DateUtils.formatters.DDMMYYYY_HHMMSS(option.item.expirationDate)
                                                })}
                                            </Typography>
                                            <Typography noWrap fontSize={13} color="text.secondary">
                                                {DateUtils.formatters.DD_MONTH_YYYY_HHMMSS(option.transactionDate)}
                                            </Typography>
                                            <Typography noWrap fontSize={12} color="text.secondary">
                                                {option.transactionId}
                                            </Typography>
                                        </Stack>
                                    </MenuItem>;
                                }}
                                optionsProvider={{
                                    fetchOptions: UPORClientRest.getApplicationTransactions,
                                    fetchOptionsRequest: {
                                        language: getLanguage(),
                                        applicationType: correction?APPLICATION_TYPE.INVOICE_CORRECTION:APPLICATION_TYPE.INVOICE,
                                        channel: bean['channel']
                                    },
                                    convertOptions: response => response['transactions']
                                }}
                            />
                        </Box>
                    </Fade>}
                </React.Fragment>}
                {transaction && <TextField
                    isStacked
                    label={translations.transaction}
                    name="transactionId"
                    required
                    readOnly
                />}
            </React.Fragment>
        }
        {(bean.transaction || transaction || !transactionRequired) && <InvoiceApplicationOtherFields
            translations={translations}
            bean={bean}
        />}
    </ResponsiveFieldsGroup>
}

const InvoiceApplicationOtherFields = ({translations, bean}) => {
    return <React.Fragment>
        <SelectField
            isStacked
            name="type"
            label={translations.type}
            required
            getOptionLabel={(option) => {
                return translations.types(option);
            }}
            getOptionId={(option) => {
                return option;
            }}
            optionsProvider={{
                options: ["COMPANY", "PRIVATE_PERSON"]
            }}
            disableClearButton={true}
        />
        {bean['type'] === "PRIVATE_PERSON" &&
            <TextField required label={translations.firstAndLastName} name="nameAndSurname"/>}
        {bean['type'] === "COMPANY" && <TextField required label={translations.companyNip} name="companyNip"/>}
        {bean['type'] === "COMPANY" && <TextField required label={translations.companyName} name="companyName"/>}
        <TextField label={translations.street} name="street" required/>
        <TextField label={translations.houseNumber} name="houseNumber" required/>
        <TextField label={translations.apartmentNumber} name="apartmentNumber"/>
        <TextField label={translations.postalCode} name="postalCode" required/>
        <TextField label={translations.locality} name="city" required/>
        <TextField label={translations.email} name="email" required/>
    </React.Fragment>
}

const Application = ({innerRef, invoice = {}, ...props}) => {
    const {config} = useConfig();
    const formRef = React.useRef();

    innerRef.current = function (onSuccess) {
        formRef.current['handleSubmit']((bean) => {
            onSuccess({
                invoice: bean
            });
        }, () => {
            Snackbar.warning(props.translations.incorrectData);
        })();
    }

    return <FormContainer
        style={{marginTop: "unset"}}
        defaultValues={{
            email: config ? config.email : null,
            ...invoice,
            transactionId: props.transaction ? props.transaction.transactionId : null,
        }}
        ref={formRef}
    >
        <InvoiceApplicationFields {...props}/>
    </FormContainer>
}

const InvoiceApplicationPrv = ({
                                   visibility: {
                                       regulations = true,
                                       invoiceRegulations = true,
                                       button = true
                                   } = {},
                                   innerRef,
                                   onSuccess,
                                   ...props
                               }) => {

    const invoiceRef = React.useRef();
    const regulationRef = React.useRef();
    const invoiceRegulationRef = React.useRef();
    const [showAll, setShowAll] = React.useState(false);
    const {t} = useTranslation();

    const translations = React.useMemo(() => {
        return {
            title: props.correction ? t("components.application.invoiceCorrection.title") : t("components.application.invoice.title"),
            description: props.correction ? t("components.application.invoiceCorrection.description") : t("components.application.invoice.description"),
            invoiceRegulation: t("components.application.invoice.regulation"),
            regulation: t("components.application.regulation"),
            regulationWarning: t("components.application.regulationWarning"),
            invoiceRegulationWarning: t("components.application.invoice.regulationWarning"),
            send: t("button.send"),
            firstAndLastName: t("common.firstAndLastName"),
            companyName: t("components.application.invoice.companyName"),
            type: t("common.type"),
            companyNip: t("components.application.invoice.companyNip"),
            types: (type) => t(`components.application.invoice.types.${type}`),
            street: t("components.application.invoice.street"),
            houseNumber: t("components.application.invoice.houseNumber"),
            apartmentNumber: t("components.application.invoice.apartmentNumber"),
            postalCode: t("components.application.invoice.postalCode"),
            locality: t("components.application.invoice.locality"),
            email: t("components.application.invoice.email"),
            incorrectData: t("components.application.invoice.incorrectData"),
            transaction: t("components.application.invoice.transaction"),
            invoiceSuccess: t("components.application.invoice.success"),
            invoiceCorrectionSuccess: t("components.application.invoiceCorrection.success"),
            fromToDate: (props) => t("common.fromToDate", props)
        }
    }, [t]);

    function validate({onSuccess, send = false}) {
        function finalFn(bean) {
            let request = {
                type: props.correction ? "INVOICE_CORRECTION" : "INVOICE",
                data: JSON.stringify({
                    ...bean.invoice,
                    transaction: undefined,
                    transactionId: undefined
                })
            }
            if (props.transaction) {
                request['transactionId'] = props.transaction.transactionId;
            } else if (bean.invoice.transaction) {
                request['transactionId'] = bean.invoice.transaction.transactionId;
            }

            if (send) {
                const formData = new FormData();
                formData.append("request", JSON.stringify(request));

                showLoader();
                UPORClientRest.registerApplication({
                    handlers: {
                        success: () => {
                            onSuccess && onSuccess(request);
                            Snackbar.success(props.correction ? translations.invoiceCorrectionSuccess : translations.invoiceSuccess);
                        },
                        both: () => {
                            hideLoader();
                        }
                    },
                    formData
                });
            } else {
                onSuccess && onSuccess({
                    invoice: request
                });
            }
        }

        invoiceRef.current((bean) => {
            if (regulations) {
                regulationRef.current.check(() => {
                    if (invoiceRegulations) {
                        invoiceRegulationRef.current.check(() => {
                            finalFn(bean);
                        });
                    }
                });
            } else if (invoiceRegulations) {
                invoiceRegulationRef.current.check(() => {
                    finalFn(bean);
                });
            } else {
                finalFn(bean);
            }
        });
    }

    if (innerRef) {
        innerRef.current = validate;
    }

    function handleTransactionChange(value) {
        setShowAll(value);
    }

    return <Stack spacing={3}>
        <Stack spacing={1}>
            <Typography variant="body1" color="text.secondary" fontWeight={500}>
                {translations.title}
            </Typography>
            <Typography variant="body2">
                {translations.description}
            </Typography>
        </Stack>
        <Application
            {...props}
            innerRef={invoiceRef}
            translations={translations}
            onTransactionChange={handleTransactionChange}
        />
        {showAll && (regulations || invoiceRegulations) && <List>
            {regulations && <RegulationCheck
                ref={regulationRef}
                description={translations.regulation}
                warning={translations.regulationWarning}
                align="flex-start"
            />}
            {invoiceRegulations && <RegulationCheck
                ref={invoiceRegulationRef}
                description={translations.invoiceRegulation}
                warning={translations.invoiceRegulationWarning}
            />}
        </List>}
        {showAll && button &&
            <Button variant="contained" size="large" onClick={() => {
                validate({
                    onSuccess: onSuccess,
                    send: true
                });
            }}>{translations.send}</Button>}
    </Stack>
}

const InvoiceApplicationComponent = React.forwardRef((props, ref) => {
    return <InvoiceApplicationPrv {...props} innerRef={ref}/>;
});

export {
    InvoiceApplicationComponent
}

