

const CookiesHelper = {
    getCookie(name) {
        var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
        if (match) return match[2];
    },
    setCookie(name, value, cookieTimeout) {
        let cookie = name + "=" + escape(value) + "; path=/";
        if (cookieTimeout) {
            let expiry = new Date(new Date().getTime() + cookieTimeout);
            cookie = cookie + "; expires=" + expiry.toGMTString();
        }
        document.cookie = cookie;
    }
}

export default CookiesHelper;
