import {Dialog, List, Snackbar} from "@beesset/ui-components";
import {Box, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import {useTranslation} from "react-i18next";
import {Close} from "@mui/icons-material";

const RegulationCheckPrv = ({innerRef, description, align = "center", warning}) => {
    const [error, setError] = React.useState(false);
    const regulationDomRef = React.useRef();
    const [checked, setChecked] = React.useState(false);

    React.useEffect(() => {
        if (checked) {
            setError(false);
        }
    }, [checked]);

    innerRef.current = {
        check: (onSuccess) => {
            if (checked === false) {
                setError(true);
                regulationDomRef.current['scrollIntoView']({behavior: "smooth", block: "center", inline: "nearest"});
                Snackbar.warning(warning);
            } else {
                onSuccess();
            }
        },
        mark: () => {
            setChecked(true);
        }
    }

    return <ListItemButton
        role={undefined}
        onClick={(e) => {
            setChecked(old => !old);
        }}
        dense
        disableGutters
        alignItems={align}
    >
        <ListItemIcon>
            <Checkbox
                edge="start"
                checked={checked}
                tabIndex={-1}
                disableRipple
                inputProps={{'aria-label': warning}}
                sx={{color: error && !checked ? "error.main" : null, ml: "8px", p: 1, pl: 0}}
                ref={regulationDomRef}
            />
        </ListItemIcon>
        <ListItemText
            primaryTypographyProps={{
                variant: "caption",
                color: error && !checked ? "error" : null,
                whiteSpace: "pre-line"
            }}
            primary={description}
        />
    </ListItemButton>;
}

const RegulationCheck = React.forwardRef((props, ref) => {
    return <RegulationCheckPrv {...props} innerRef={ref}/>;
});

const RegulationCheckDialog = ({open = false, title, onClose, onAccept, children}) => {
    const {t} = useTranslation();

    return <Dialog
        title={title}
        open={open}
        disableMaxWidth
        actions={[{
            name: t("button.close"),
            color: "error",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }, {
            name: t("button.confirm"),
            color: "inherit",
            onClick: onAccept,
            fullScreenProps: {
                align: "right",
            },
        }]}
    >
        <Box overflow="auto" width="100%" maxWidth={{md: "800px", lg: "900px", xl: "1100px"}}>
            {children}
        </Box>
    </Dialog>
}

export {
    RegulationCheck,
    RegulationCheckDialog
};