import {
    AutocompleteField,
    Dialog,
    FormContainer,
    hideLoader,
    showLoader,
    Snackbar,
    useStepper
} from "@beesset/ui-components";
import React from "react";
import {CircularProgress, lighten, OutlinedInput, Slide, Stack, Typography, useTheme} from "@mui/material";
import {DateUtils} from "@beesset/common-utils";
import {useTariffs} from "../TariffsProvider";
import {CommonStep, DESCRIPTOR_TYPES} from "./CommonStep";
import {Controller, useFormContext} from "react-hook-form";
import {DateField, UPORClientRest, useConfig, UTILS} from "@beesset/upor-client-module-cmn";
import {styled} from "@mui/material/styles";
import {NumberInput} from "@mui/base/Unstable_NumberInput/NumberInput";
import {Add, Remove} from "@mui/icons-material";
import TicketControlImage from "./assets/ticket_control.svg";
import FreeDayImage from "./assets/free_day.svg";
import TicketControlContrastImage from "./assets/ticket_control_contrast.svg";
import FreeDayContrastImage from "./assets/free_day_contrast.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const DateSelection = ({translations}) => {
    const {getLanguage} = useConfig();
    const {watch, setValue, getValues} = useFormContext();
    const {PeriodTypes, findProduct} = useTariffs();
    const {getData} = useStepper();
    const [bean, setBean] = React.useState(getValues());

    const data = getData();
    const product = findProduct(data);
    const productTimeUnitType = data.productTimeUnitType;
    const productTimeUnitTypeValue = data.productTimeUnitTypeValue;
    const customActivationDateRequired = product.customActivationDateRequired;

    const language = getLanguage();

    const ticketPeriodDescription = React.useMemo(() => {
        let result;
        if (product && product.attributes) {
            let key = `TicketPeriodDescription_${language}`;
            if (!product.attributes[key]) {
                key = `TicketPeriodDescription_pl`;
            }
            result = product.attributes[key];
        }
        return result;
    }, [language, product]);

    React.useEffect(() => {
        const subscription = watch((value) => {
            setBean(value);
        });
        return () => {
            subscription['unsubscribe']();
        };
    }, [watch]);

    React.useEffect(() => {
        let timeUnitType;
        if (productTimeUnitType === PeriodTypes.DAYS
            || productTimeUnitType === PeriodTypes.N_DAYS
            || productTimeUnitType === PeriodTypes.DAYS_WEEKDAYS
        ) {
            timeUnitType = "day";
        } else if (productTimeUnitType === PeriodTypes.MONTHS
            || productTimeUnitType === PeriodTypes.N_MONTHS
            || productTimeUnitType === PeriodTypes.MONTHS_WEEKDAYS
        ) {
            timeUnitType = "month";
        } else if (productTimeUnitType === PeriodTypes.YEAR || productTimeUnitType === PeriodTypes.N_YEARS) {
            timeUnitType = "year";
        } else {
            console.error(`Unknown timeUnitType ${productTimeUnitType}`);
        }

        if (timeUnitType) {
            let moment = DateUtils.getDate(bean['startDate']);
            if (bean['startDate'] && moment.isValid()) {
                let endDate = UTILS.calculateEndDate(moment, timeUnitType, Number(productTimeUnitTypeValue));
                if (!bean['endDate'] || bean['endDate'] !== endDate) {
                    setValue('endDate', endDate);
                }
            } else {
                if (product['purchasePossibilityDateRange']) {
                    let from = product['purchasePossibilityDateRange']['dateFrom'];
                    let to = product['purchasePossibilityDateRange']['dateTo'];
                    if (from && to) {
                        let fromMoment = DateUtils.getDate(from).startOf(timeUnitType);
                        let toMoment = DateUtils.getDate(to).startOf(timeUnitType);
                        if (fromMoment.isSame(toMoment)) {
                            setValue("startDate", UTILS.calculateStartDate(fromMoment));
                            return;
                        }
                    }
                }

                if (bean['endDate']) {
                    setValue("endDate", null);
                }
            }
        }

    }, [bean, product]);

    return (
        <Stack direction="column" spacing={2} key="configuration-date-selection">
            <Stack
                direction={{
                    xs: "column",
                    md: "row",
                }}
                spacing={2}
                alignItems="flex-start"
            >
                <DateField
                    required
                    name="startDate"
                    label={translations.startDate}
                    sx={{
                        marginTop: "unset"
                    }}
                    truncate={true}
                    onlyYearAndMonthView={productTimeUnitType === PeriodTypes.MONTHS || productTimeUnitType === PeriodTypes.N_MONTHS || productTimeUnitType === PeriodTypes.MONTHS_WEEKDAYS}
                    minDate={
                        product['purchasePossibilityDateRange'] ?
                            DateUtils.getDate(product['purchasePossibilityDateRange']['dateFrom']) : null
                    }
                    maxDate={
                        product['purchasePossibilityDateRange'] ?
                            DateUtils.getDate(product['purchasePossibilityDateRange']['dateTo']) : null
                    }
                    withTime={true}
                    readOnly={!customActivationDateRequired}
                />
                {bean['endDate'] && <DateField
                    required
                    name="endDate"
                    onlyYearAndMonthView={productTimeUnitType === PeriodTypes.MONTHS}
                    label={translations.endDate}
                    readOnly={true}
                    withTime={true}
                    sx={{
                        marginTop: "unset"
                    }}
                />}
            </Stack>
            {ticketPeriodDescription && <Stack>
                {
                    ticketPeriodDescription.split('\\n')
                        .map((it, i) => {
                            return <Typography variant="caption"
                                               color="error"
                                               key={'x' + i}>{it}
                            </Typography>
                        })
                }
            </Stack>}
        </Stack>
    );
}

const VehicleSelection = ({translations, visibleDescription = false}) => {
    return <Stack spacing={1.5} width="100%">
        {visibleDescription && <Typography variant="caption" color="text.secondary" zIndex={3} mt={"-20px"}>
            {translations.configurationVehicleDescription}
        </Typography>}
        <AutocompleteField
            isStacked
            name="vehicleNumber"
            label={translations.vehicleNumber}
            required
            freeSolo
            disableClearable
            blurOnSelect={true}
            autocompleteSx={{
                width: "100%",
                maxWidth: 246
            }}
            isOptionEqualToValue={(option, value) => {
                return option === value;
            }}
            getOptionLabel={(option) => {
                return option;
            }}
            optionsProvider={{
                fetchOptions: UPORClientRest.getVehicles,
                convertOptions: response => response['vehicles']
            }}
        />
    </Stack>
}

const StyledInputRoot = styled('div')(
    () => `
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`,
);

const StyledInput = styled(OutlinedInput)(
    () => `
    width: 4rem;
    margin: 0 8px;
    .MuiOutlinedInput-input { 
      text-align: center;
    }
`,
);

const StyledButton = styled('button')(
    ({theme}) => `
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 0;
  border-radius: 999px;
  color: ${theme.palette.secondary.main};
  background: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === "dark" ? theme.palette.grey['800'] : lighten(theme.palette.secondary.main, 0.8)};
    cursor: pointer;
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`,
);

const QuantitySelection = ({max}) => {
    const {control} = useFormContext();
    return (
        <Controller
            name={"quantity"}
            control={control}
            defaultValue={1}
            render={({field: {onChange, value}}) => (
                <NumberInput
                    onChange={(e, value) => {
                        onChange(value);
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }}
                    required
                    value={value}
                    slots={{
                        root: StyledInputRoot,
                        input: StyledInput,
                        incrementButton: StyledButton,
                        decrementButton: StyledButton,
                    }}
                    slotProps={{
                        incrementButton: {
                            children: <Add/>,
                            className: 'increment',
                        },
                        decrementButton: {
                            children: <Remove/>,
                        },
                    }}
                    min={1}
                    max={max}
                />
            )}
        />
    );
}

const TicketControlException = ({id, onClose, translations}) => {
    const theme = useTheme();

    return <Dialog
        TransitionComponent={Transition}
        disableFullScreen
        open={id === 'TICKET_CONTROL_IN_PROGRESS'}
        actions={[{
            name: translations.buttonClose,
            onClick: onClose
        }]}
    >
        <Stack spacing={2} alignItems="center">
            <img src={theme.palette.mode === "light" ? TicketControlImage : TicketControlContrastImage} height={200}
                 alt="TicketControlInProgress"/>
            <Typography color="#c73f4a" textAlign="center" whiteSpace="pre-wrap" width={260}>
                {translations.mapException('TICKET_CONTROL_IN_PROGRESS')}&nbsp;:&nbsp;(
            </Typography>
        </Stack>
    </Dialog>
}

const MediumHasActiveTicketsException = ({id, onClose, onSuccess, translations}) => {
    return <Dialog
        TransitionComponent={Transition}
        disableFullScreen
        open={id === 'MEDIUM_HAS_ACTIVE_TICKETS'}
        actions={[{
            name: translations.buttonNo,
            color: "error",
            onClick: onClose
        }, {
            name: translations.buttonYes,
            onClick: onSuccess
        }]}
    >
        <Typography textAlign="justify">
            {translations.mapException('MEDIUM_HAS_ACTIVE_TICKETS')}
        </Typography>
    </Dialog>
}

const FreeDayException = ({id, onClose, translations}) => {
    const theme = useTheme();

    return <Dialog
        TransitionComponent={Transition}
        disableFullScreen
        open={id === 'FREE_DAY_IN_PROGRESS'}
        actions={[{
            name: translations.buttonClose,
            onClick: onClose
        }]}
    >
        <Stack spacing={2} alignItems="center">
            <img src={theme.palette.mode === "light" ? FreeDayImage : FreeDayContrastImage} height={200}
                 alt="FreeDayInProgress"/>
            <Typography color="#008351" textAlign="center" fontWeight={400} width={260}>
                {translations.mapException('FREE_DAY_IN_PROGRESS')}&nbsp;:&nbsp;)
            </Typography>
        </Stack>
    </Dialog>
}

const Step = ({translations, stepIndex}) => {
    const {getLanguage} = useConfig();
    const [exceptionDialogProps, setExceptionDialogProps] = React.useState({
        id: null
    });
    const ref = React.useRef(false);
    const {goNext, goBack, getData, updateData, activeStep} = useStepper();
    const {findProduct, createTransactionRequest, PeriodTypes} = useTariffs();

    const data = getData();
    const product = findProduct(data);
    const language = getLanguage();
    const descriptors = React.useMemo(() => {
        let result = [];
        if (!product) {
            return result;
        }
        if (product['customActivationDateRequired'] || (product.attributes && product.attributes[`TicketPeriodDescription_${language}`])) {
            result.push({
                type: DESCRIPTOR_TYPES.HEADER,
                name: translations.configurationPeriod
            }, {
                type: DESCRIPTOR_TYPES.COMPONENT,
                Component: <DateSelection translations={translations}/>
            });
        }
        if (product['maximumQuantityInCheckout'] > 1) {
            result.push({
                type: DESCRIPTOR_TYPES.HEADER,
                name: translations.configurationQuantity
            }, {
                type: DESCRIPTOR_TYPES.COMPONENT,
                Component: <QuantitySelection max={product['maximumQuantityInCheckout']}/>
            });
        }
        if (product['vehicleNumberRequired']) {
            result.push({
                type: DESCRIPTOR_TYPES.HEADER,
                name: translations.configurationVehicle
            }, {
                type: DESCRIPTOR_TYPES.COMPONENT,
                Component: <VehicleSelection
                    translations={translations}
                    visibleDescription={product.productTimeUnitType !== PeriodTypes.SINGLE_PASS}
                />
            });
        }
        return result;
    }, [product, translations, language]);

    const transactionInit = data['transactionInit'];
    React.useEffect(() => {
        if (stepIndex === activeStep) {
            if (descriptors.length === 0) {
                if (transactionInit) {
                    goBack();
                } else {
                    onSelect();
                }
            }
        }
    }, [transactionInit, descriptors, product, activeStep, stepIndex]);

    function onSelect(bean) {
        if (ref.current) {
            return;
        }
        ref.current = true;

        showLoader();
        UPORClientRest.initTransaction({
            handlers: {
                success: (response) => {
                    updateData({
                        ...bean,
                        transactionInit: response['transactionId']
                    });
                    goNext();
                },
                failure: (response) => {
                    let exceptionId = response.exceptionId;
                    if (["TICKET_CONTROL_IN_PROGRESS", "MEDIUM_HAS_ACTIVE_TICKETS", "FREE_DAY_IN_PROGRESS"]
                        .indexOf(exceptionId) >= 0
                    ) {
                        let props = {
                            id: exceptionId,
                            onClose: () => {
                                setExceptionDialogProps({
                                    id: null
                                })
                            }
                        }
                        if (exceptionId === "MEDIUM_HAS_ACTIVE_TICKETS") {
                            if (descriptors.length === 0) {
                                props.onClose = () => {
                                    goBack();
                                }
                            }
                            props.onSuccess = () => {
                                updateData({
                                    ...bean,
                                    transactionInit: response.params['transactionId']
                                });
                                goNext();
                            }
                        }
                        setExceptionDialogProps(props);
                    } else {
                        Snackbar.error(response);
                    }
                },
                both: () => {
                    ref.current = false;
                    hideLoader();
                }
            },
            request: createTransactionRequest({
                ...getData(),
                ...bean
            })
        });
    }

    return <React.Fragment>
        {descriptors.length > 0 && <React.Fragment>
            <FormContainer
                defaultValues={{
                    startDate: data.startDate,
                    endDate: data.endDate,
                    vehicleNumber: data.vehicleNumber,
                    quantity: data.quantity
                }}
                style={{
                    maxWidth: "unset",
                }}
                id="purchaseConfigurationFormId"
                onSuccess={onSelect}
            >
                <CommonStep
                    title={translations.configurationDesc}
                    descriptors={descriptors}
                />
            </FormContainer>
            <TicketControlException {...exceptionDialogProps} translations={translations}/>
            <FreeDayException {...exceptionDialogProps} translations={translations}/>
        </React.Fragment>}
        <MediumHasActiveTicketsException {...exceptionDialogProps} translations={translations}/>
        {descriptors.length === 0 &&
            <Stack flex={100} justifyContent="center" alignItems="center"><CircularProgress/></Stack>}
    </React.Fragment>
}


export default Step;