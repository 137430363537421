import {CookiesHelper, DateUtils} from "@beesset/common-utils";
import {invokeGET} from "@beesset/rest";
import {Dialog, hideLoader, showLoader} from "@beesset/ui-components";
import React from "react";
import {useTranslation} from "react-i18next";

function useSession({
                        showingWarningTime = 20000,
                        sessionExpiryCookie = "CloudCollectorSessionExpiry",
                        validateSession
                    }
) {
    const [, setVersion] = React.useState(0);

    const props = React.useRef({
        protectedResources: null,
        lastSessionRevalidationTime: null,
        sessionRevalidationRunning: false,
        sessionTimeoutWarning: null
    });

    function revalidateSession(force) {
        if (props.current.sessionRevalidationRunning) {
            return;
        }

        if (
            force === true
            || props.current.lastSessionRevalidationTime === null
            || DateUtils.getDiffMilis({
                fromDate: props.current.lastSessionRevalidationTime
            }) >= 10000
        ) {
            let request = {};
            if (props.current.protectedResources !== null) {
                request = {
                    protectedResources: props.current.protectedResources
                }
            }

            props.current.sessionRevalidationRunning = true;
            let functionProps = {
                handlers: {
                    success: (response) => {
                        props.current.lastSessionRevalidationTime = DateUtils.getNow();
                        if (response) {
                            props.current.protectedResources = response.protectedResources;
                        }
                    },
                    both: () => {
                        props.current.sessionRevalidationRunning = false;
                    }
                },
                showSnackbar: false,
                request
            }

            try {
                if (validateSession) {
                    validateSession(functionProps);
                } else {
                    invokeGET({
                        uri: "upm/api/validateSession",
                        ...functionProps
                    });
                }
            } catch (e) {
                props.current.sessionRevalidationRunning = false;
            }
        }
    }

    React.useEffect(() => {
        revalidateSession();
        let moveListener = () => {
            if (props.current.sessionTimeoutWarning == null) {
                revalidateSession(false);
            }
        };
        document.addEventListener("mousemove", moveListener);
        document.addEventListener("touchmove", moveListener);
        return () => {
            document.removeEventListener("mousemove", moveListener);
            document.removeEventListener("touchmove", moveListener);
        };
    }, []);

    React.useEffect(() => {
        let checkSessionInterval = setInterval(() => {
            if (props.current.protectedResources === true) {
                let sessionExpiry = CookiesHelper.getCookie(sessionExpiryCookie);
                if (sessionExpiry != null) {
                    let toSessionTimeout = DateUtils.getDiffMilis({
                        toDate: sessionExpiry.replace(/["]+/g, ''),
                    });

                    if (toSessionTimeout <= -2500) {
                        revalidateSession(true);
                        if (props.current.sessionTimeoutWarning > 0) {
                            props.current.sessionTimeoutWarning = toSessionTimeout;
                            setVersion((old) => old + 1);
                        }
                    } else if (toSessionTimeout > showingWarningTime + 1000) {
                        if (props.current.sessionTimeoutWarning !== null) {
                            props.current.sessionTimeoutWarning = null;
                            setVersion((old) => old + 1);
                        }
                    } else {
                        props.current.sessionTimeoutWarning = toSessionTimeout;
                        setVersion((old) => old + 1);
                    }
                }
            }
        }, 1000);

        return () => {
            clearInterval(checkSessionInterval);
        };
    }, []);

    return {
        sessionTimeoutWarning: props.current.sessionTimeoutWarning,
        revalidateSession: () => {
            revalidateSession(true);
        }
    };
}

const SessionValidator = (props) => {
    const visibleLoader = React.useRef(false);
    const {t} = useTranslation();
    const {sessionTimeoutWarning, revalidateSession} = useSession(props || {});

    React.useEffect(() => {
        if (sessionTimeoutWarning !== null && sessionTimeoutWarning <= 1000) {
            if (!visibleLoader.current) {
                showLoader(0);
                visibleLoader.current = true;
            }
        } else {
            if (visibleLoader.current) {
                hideLoader();
                visibleLoader.current = false;
            }
        }
    }, [sessionTimeoutWarning]);

    return <Dialog
        open={sessionTimeoutWarning !== null && sessionTimeoutWarning >= 1000}
        disableFullScreen={true}
        actions={[
            {
                name: t("commonComponents.sessionValidator.refreshSession"),
                color: "info",
                onClick: revalidateSession
            },
        ]}
        title={t("commonComponents.sessionValidator.warning")}
        description={t("commonComponents.sessionValidator.sessionTimeoutWarning", {
            timeout: sessionTimeoutWarning !== null && sessionTimeoutWarning >= 1000 ? parseInt(String(sessionTimeoutWarning / 1000), 10) : "",
        })}
    />
};

export default React.memo(SessionValidator);
