import {Box, CardActionArea, Stack, useTheme} from "@mui/material";
import {useConfig} from "@beesset/upor-client-module-cmn";
import logoPl from "./logo-eu--pl.svg";
import contrastLogoPl from "./contrast-logo-eu--pl.svg";
import logoDe from "./logo-eu--de.svg";
import contrastLogoDe from "./contrast-logo-eu--de.svg";
import logoEn from "./logo-eu--en.svg";
import contrastLogoEn from "./contrast-logo-eu--en.svg";
import logoUk from "./logo-eu--uk.svg";
import contrastLogoUk from "./contrast-logo-eu--uk.svg";
import React from "react";
import {useTranslation} from "react-i18next";

function getLogo({language, mode}) {
    switch (mode) {
        case "light": {
            switch (language) {
                case "pl": {
                    return logoPl;
                }
                case "en": {
                    return logoEn;
                }
                case "de": {
                    return logoDe;
                }
                case "uk": {
                    return logoUk;
                }
            }
        }
        case "dark": {
            switch (language) {
                case "pl": {
                    return contrastLogoPl;
                }
                case "en": {
                    return contrastLogoEn;
                }
                case "de": {
                    return contrastLogoDe;
                }
                case "uk": {
                    return contrastLogoUk;
                }
            }
        }
    }
}

const EuropeanFundsLogo = ({height = "50px", paddingLeft = 0}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {getLanguage} = useConfig();

    const language = getLanguage();
    const logo = React.useMemo(() => {
        return getLogo({
            language: language,
            mode: theme.palette.mode
        })
    }, [language, theme.palette.mode]);

    return <CardActionArea href="#/europeanFunds" sx={{display: "flex", justifyContent: "flex-start", p:1, pl: paddingLeft}}>
        <img src={logo} height={height} alt={t("components.europeanFunds.logoAltText")}/>
    </CardActionArea>
}

export {EuropeanFundsLogo};