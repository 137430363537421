import {generateId} from "@beesset/common-utils";
import {useTranslation} from "react-i18next";
import {Box, Button, CardActionArea, Input, Stack, Typography} from "@mui/material";
import {
    FormContainer,
    hideLoader,
    ResponsiveFieldsGroup,
    showLoader,
    Snackbar,
    TextField
} from "@beesset/ui-components";
import {APPLICATION_TYPE} from "./Applications";
import {PeselOrDateOfBirthField, PhoneNumberPattern, UPORClientRest} from "@beesset/upor-client-module-cmn";
import React from "react";
import {styled} from "@mui/material/styles";
import Card from "@mui/material/Card";
import {Controller, useFormContext} from "react-hook-form";
import {Image as ImageIcon} from "@mui/icons-material";
import {FormHelperText} from "@material-ui/core";

const StyledCard = styled(Card, {
    shouldForwardProp: propName => propName !== "invalid"
})(({theme, invalid}) => {
    return theme.unstable_sx({
        width: 150,
        height: 200,
        flexShrink: 0,
        borderColor: invalid ? "error.dark" : (theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)"),
        color: theme.palette.mode === "light" ? (invalid ? "error.dark" : "text.secondary") : "#ffff00"
    })
});

const ImageRequirements = {
    FORMAT: "format",
    RESOLUTION: "resolution",
    SIZE: "size",
    OTHER1: "other1",
    OTHER2: "other2"
};

const AllowedExtensions = [".jpg", ".jpeg", ".png"];

const ImageField = () => {
    const {t} = useTranslation();
    const [image, setImage] = React.useState(null);
    const {control, validationMessages, watch} = useFormContext();
    const file = watch("attachments");

    React.useEffect(() => {
        if (file) {
            let reader = new FileReader();
            reader.onload = function (e) {
                setImage(e.target.result);
            }
            reader.readAsDataURL(file);
        } else {
            setImage(null);
        }
    }, [file]);

    const rules = {
        validate: async (value) => {
            if (value) {
                function hasExtension(file) {
                    let fileName = file.name;
                    return (new RegExp('(' + AllowedExtensions.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
                }

                if (!hasExtension(value)) {
                    return ImageRequirements.FORMAT;
                }

                let size = value.size;
                if (size / 1024 / 1024 > 10) {
                    return ImageRequirements.SIZE;
                }

                let reader = new FileReader();
                reader.readAsDataURL(value);
                await new Promise(resolve => reader.onloadend = () => resolve());

                let image = new Image();
                image.src = reader.result;

                let result;
                await new Promise(resolve => image.onload = () => {
                    if (
                        (image.width / image.height).toFixed(2) != 0.67
                        || image.width < 200 || image.width > 800
                        || image.height < 300 || image.height > 1200
                    ) {
                        result = ImageRequirements.RESOLUTION;
                    }
                    resolve();
                });
                return result;
            }
        },
        required: validationMessages.required
    }

    return (
        <Controller
            control={control}
            name={"attachments"}
            rules={rules}
            render={({
                         field: {ref, onChange},
                         fieldState: {invalid, error},
                     }) => {
                return <Box>
                    <Stack direction={{xs: "column", md: "row"}} spacing={2}>
                        <Box>
                            <StyledCard
                                invalid={invalid}
                                variant="outlined"
                            >
                                <CardActionArea
                                    sx={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex"
                                    }}
                                    component={"label"}
                                    htmlFor="photo-uploader">
                                    <Input
                                        ref={ref}
                                        accept="image/*"
                                        id="photo-uploader"
                                        onChange={(e) => {
                                            if (e.target.files && e.target.files.length > 0) {
                                                let file = e.target.files[0];
                                                onChange(file);
                                            } else {
                                                onChange(null);
                                            }
                                        }}
                                        sx={{display: "none"}}
                                        type="file"
                                        required
                                    />
                                    {

                                        image
                                            ? <Box
                                                height="100%"
                                                width="100%"
                                                padding={0.5}
                                            >
                                                <img
                                                    height="100%"
                                                    width="100%"
                                                    src={image}
                                                />
                                            </Box>
                                            :
                                            <Stack margin={1} justifyContent="center" alignItems="center" flex={1}
                                                   spacing={0.5}>
                                                <Typography>{t("components.application.cityCardIssuance.image")}</Typography>
                                                <ImageIcon fontSize="large"/>
                                            </Stack>
                                    }
                                </CardActionArea>
                            </StyledCard>
                            {error && Object.values(ImageRequirements).indexOf(error.message) < 0 &&
                                <FormHelperText style={{marginLeft: "14px"}} error>
                                    {error.message}
                                </FormHelperText>}
                        </Box>
                        <Box>
                            <Box component="ul" sx={{m: 0, pl: "10px", color: "text.secondary"}}>
                                {Object.keys(ImageRequirements).map((key) => {
                                    return <Typography
                                        key={`image-requirements-${key}`}
                                        component="li"
                                        variant="caption"
                                        color={error && error.message === ImageRequirements[key] ? "error.light" : "inherit"}
                                    >
                                        {t(`components.application.cityCardIssuance.requirements.${ImageRequirements[key]}`)}
                                    </Typography>
                                })}
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            }}
        />
    );
}

const CityCardIssuanceApplication = ({onSuccess}) => {
    const formId = React.useRef(generateId(10));

    const {t} = useTranslation();

    const translations = React.useMemo(() => {
        return {
            title: t("components.application.cityCardIssuance.title"),
            description: t("components.application.cityCardIssuance.description"),
            attachment: t("components.application.other.attachment"),
            send: t("button.send"),
            email: t("common.email"),
            phoneNumber: t("common.phoneNumber"),
            firstName: t("common.firstName"),
            lastName: t("common.lastName"),
            street: t("components.application.invoice.street"),
            houseNumber: t("components.application.invoice.houseNumber"),
            apartmentNumber: t("components.application.invoice.apartmentNumber"),
            postalCode: t("components.application.invoice.postalCode"),
            locality: t("components.application.invoice.locality"),
            success: t("components.application.cityCardIssuance.success")
        }
    }, [t]);

    return <Stack spacing={3}>
        <Stack spacing={1}>
            <Typography variant="body1" color="text.secondary" fontWeight={500}>
                {translations.title}
            </Typography>
            <Typography variant="body2">
                {translations.description}
            </Typography>
        </Stack>
        <FormContainer
            id={formId.current}
            style={{marginTop: "unset"}}
            onSuccess={(bean) => {
                showLoader();

                const formData = new FormData();

                let address = bean.street + " " + bean.houseNumber;
                if (bean.apartmentNumber) {
                    address = address + "/" + bean.apartmentNumber;
                }
                let request = {
                    name: bean.name,
                    surname: bean.surname,
                    cityName: bean.locality,
                    postalCode: bean.postalCode,
                    phoneNumber: bean.phoneNumber,
                    email: bean.email,
                    pesel: bean.pesel,
                    dateOfBirth: bean.dateOfBirth,
                    address
                };

                formData.append("request", JSON.stringify({
                    type: APPLICATION_TYPE.CITY_CARD_ISSUANCE,
                    data: JSON.stringify(request),
                }));

                if (bean.attachments.length) {
                    Array.from(bean.attachments).forEach((attachment) => {
                        formData.append("attachments", attachment);
                    });
                } else {
                    formData.append("attachments", bean.attachments);
                }

                UPORClientRest.registerApplication({
                    handlers: {
                        success: () => {
                            Snackbar.success(translations.success);
                            onSuccess && onSuccess();
                        },
                        both: () => {
                            hideLoader();
                        }
                    },
                    formData
                });
            }}
        >
            <ResponsiveFieldsGroup isMainContainer minWidth="unset">
                <TextField
                    isStacked
                    required
                    label={translations.firstName}
                    name="name"
                />
                <TextField
                    required
                    label={translations.lastName}
                    name="surname"
                />
                <PeselOrDateOfBirthField/>
                <TextField label={translations.street} name="street" required/>
                <TextField label={translations.houseNumber} name="houseNumber" required/>
                <TextField label={translations.apartmentNumber} name="apartmentNumber"/>
                <TextField label={translations.postalCode} name="postalCode" required/>
                <TextField label={translations.locality} name="locality" required/>
                <TextField label={translations.email} name="email" required/>
                <TextField
                    label={translations.phoneNumber}
                    name={"phoneNumber"}
                    InputProps={{
                        inputComponent: PhoneNumberPattern
                    }}
                    inputProps={{
                        inputMode: "numeric"
                    }}
                />
                <ImageField/>
            </ResponsiveFieldsGroup>
        </FormContainer>
        <Button
            variant="contained"
            size="large"
            type="submit"
            form={formId.current}
        >
            {translations.send}
        </Button>
    </Stack>
}

export default CityCardIssuanceApplication;