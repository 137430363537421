import {Button, MenuItem, Stack, Typography} from "@mui/material";
import {
    FileUploadField,
    FormContainer,
    hideLoader,
    ResponsiveFieldsGroup,
    SelectField,
    showLoader,
    Snackbar,
    TextField
} from "@beesset/ui-components";
import {DateUtils, generateId} from "@beesset/common-utils";
import {UPORClientRest} from "../../rest";
import {APPLICATION_TYPE, DatePeriodField} from "../index";
import React from "react";
import {useTranslation} from "react-i18next";

const DiscountRegistrationApplication = ({onSuccess}) => {
    const formId = React.useRef(generateId(10));

    const {t} = useTranslation();

    const translations = React.useMemo(() => {
        return {
            title: t("components.application.discountRegistration.title"),
            description: t("components.application.discountRegistration.description"),
            attachment: t("components.application.other.attachment"),
            send: t("button.send"),
            firstAndLastName: t("common.firstAndLastName"),
            passenger: t("components.application.discountRegistration.passenger"),
            passengerDocument: t("components.application.discountRegistration.passengerDocument"),
            eventDescription: t("components.application.discountRegistration.eventDescription"),
            startDate: t("components.application.discountRegistration.startDate"),
            endDate: t("components.application.discountRegistration.endDate"),
            success: t("components.application.other.success")
        }
    }, [t]);

    return <Stack spacing={3}>
        <Stack spacing={1}>
            <Typography variant="body1" color="text.secondary" fontWeight={500}>
                {translations.title}
            </Typography>
            <Typography variant="body2">
                {translations.description}
            </Typography>
        </Stack>
        <FormContainer
            id={formId.current}
            style={{marginTop: "unset"}}
            onSuccess={(bean) => {
                showLoader();

                const formData = new FormData();
                formData.append("request", JSON.stringify({
                    type: APPLICATION_TYPE.DISCOUNT_REGISTRATION,
                    data: JSON.stringify({
                        ...bean.passenger,
                        startDate: bean.startDate,
                        endDate: bean.endDate,
                        description: bean.description,
                        passengerDocument: bean.passengerDocument
                    }),
                }));

                if (bean.attachments.length) {
                    Array.from(bean.attachments).forEach((attachment) => {
                        formData.append("attachments", attachment);
                    });
                } else {
                    formData.append("attachments", bean.attachments);
                }

                UPORClientRest.registerApplication({
                    handlers: {
                        success: () => {
                            Snackbar.success(translations.success);
                            onSuccess && onSuccess();
                        },
                        both: () => {
                            hideLoader();
                        }
                    },
                    formData
                });
            }}
        >
            <ResponsiveFieldsGroup isMainContainer minWidth="unset">
                <SelectField
                    isStacked
                    name="passenger"
                    label={translations.passenger}
                    required
                    getOptionLabel={(option) => {
                        return `${option.name} ${option.surname}`;
                    }}
                    getOptionId={(option) => {
                        return option.passengerId;
                    }}
                    renderOption={(props, option) => {
                        return <MenuItem {...props}>
                            <Stack width="100%">
                                <Typography noWrap>
                                    {`${option.name} ${option.surname}`}
                                </Typography>
                                {option.dateOfBirth && <Typography fontSize={13} color="text.secondary" noWrap>
                                    {DateUtils.formatters.DDMONTHYY(option.dateOfBirth)}
                                </Typography>}
                                {option.pesel && <Typography fontSize={13} color="text.secondary" noWrap>
                                    {option.pesel}
                                </Typography>}
                            </Stack>
                        </MenuItem>;
                    }}
                    optionsProvider={{
                        fetchOptions: UPORClientRest.getAccountPassengers,
                        convertOptions: response => response['passengers']
                    }}
                />
                <TextField
                    required
                    label={translations.passengerDocument}
                    name="passengerDocument"
                />
                <DatePeriodField
                    startDateName="startDate"
                    startDateLabel={translations.startDate}
                    endDateName="endDate"
                    endDateLabel={translations.endDate}
                    required
                />
                <TextField
                    label={translations.eventDescription}
                    name="description"
                    multiline
                    rows={9}/>
                <FileUploadField
                    label={translations.attachment}
                    name="attachments"
                    multiple
                    maxSize={10}
                    allowedExtensions={[
                        ".doc",
                        ".docx",
                        ".odt",
                        ".pdf",
                        ".tex",
                        ".txt",
                        ".rtf",
                        ".jpg",
                        ".jpeg",
                        ".png",
                        ".svg"
                    ]}
                    required
                />
            </ResponsiveFieldsGroup>
        </FormContainer>
        <Button
            variant="contained"
            size="large"
            type="submit"
            form={formId.current}
        >
            {translations.send}
        </Button>
    </Stack>
}

export default DiscountRegistrationApplication;