import {Box, IconButton, Stack, useMediaQuery} from "@mui/material";
import "./SingInUp.css"
import {Outlet} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import Carousel from 'react-material-ui-carousel'
import {NavigateBefore, NavigateNext} from "@mui/icons-material";
import {useTheme} from "@mui/system";
import {useDataFetcher} from "@beesset/common-utils";
import {UPORClientRest} from "@beesset/upor-client-module-cmn";

function Item({path, name}) {
    return <div className={`signInUp-background`} style={{backgroundImage: `url(${path})`}} aria-label={name}/>
}

const Container = ({children}) => {
    return <Box sx={{
        height: {
            xs: "unset",
            md: "100vh"
        },
        overflow: {
            xs: "unset",
            md: "hidden"
        },
    }}>
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            height: "100%"
        }}>
            <Box sx={{
                display: "grid",
                flexBasis: {
                    xs: "100%",
                    lg: "40%",
                    xl: "30%"
                },
                overflow: "auto",
                minHeight: {
                    xs: "calc(100vh - 56px)",
                    sm: "calc(100vh - 64px)",
                    md: "100vh"
                }
            }}>
                {children[0]}
            </Box>
            <Box sx={{
                flexBasis: {
                    xs: "0%",
                    lg: "60%",
                    xl: "70%"
                }
            }}>
                {children[1]}
            </Box>
        </Box>
    </Box>
}

const SliderImages = () => {
    const {t} = useTranslation();

    const [autoPlay, setAutoPlay] = React.useState(true);

    const {
        data: images
    } = useDataFetcher({
        fetch: UPORClientRest.getSliderImages,
        convert: response => response['images']
    });

    React.useEffect(() => {
        let visibilityListener = () => {
            setAutoPlay(document.visibilityState === "visible");
        };
        document.addEventListener("visibilitychange", visibilityListener);

        return () => {
            document.removeEventListener("visibilitychange", visibilityListener);
        };
    }, []);

    return images && <Carousel
        sx={{
            height: "100%"
        }}
        height="100%"
        animation="fade"
        duration={800}
        fullHeightHover={false}
        navButtonsAlwaysVisible={true}
        autoPlay={autoPlay}
        navButtonsProps={{
            "aria-hidden": true
        }}
        indicatorIconButtonProps={{
            'aria-label': t("components.signInUp.picture")
        }}
        NavButton={({onClick, className, style, next, prev}) => {
            return (
                <IconButton
                    sx={{
                        backgroundColor: "rgb(73, 73, 73)",
                        margin: "0px 10px",
                        color: "white",
                        top: "calc(50% - 20px) !important"
                    }}
                    onClick={onClick}
                    className={className}
                    style={style}
                    aria-label={next ? t("components.signInUp.nextPicture") : t("components.signInUp.prevPicture")}
                >
                    {next && <NavigateNext/>}
                    {prev && <NavigateBefore/>}
                </IconButton>
            )
        }}
        indicatorContainerProps={{
            style: {
                position: "absolute",
                bottom: "20px",
                zIndex: 10
            }
        }}
    >
        {images.map((image, index) => <Item {...image} key={`background-item-${index}`}/>)}
    </Carousel>
}

const SignInUp = () => {
    const theme = useTheme();
    const showSlider = useMediaQuery(theme.breakpoints.up("lg"));

    return <Container>
        <Box sx={{
            p: {
                xs: 2,
                sm: 4,
                md: 6,
            },
            pt: {
                xs: 5,
                md: 6,
            },
            pb: {
                xs: 5,
                md: 6,
            },
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
        }}>
            <Stack maxWidth="350px" width="100%" direction="column" spacing={2}>
                <Outlet/>
            </Stack>
        </Box>
        {showSlider && <SliderImages/>}
    </Container>
}

export {
    SignInUp
};