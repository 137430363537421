import {Box, Button, Stack, Typography} from "@mui/material";
import {List, Table} from "@beesset/ui-components";
import {
    Cancel,
    CheckCircle,
    DateRange,
    DirectionsBus,
    Key,
    PictureAsPdf,
    Receipt,
    Replay,
    Rule
} from "@mui/icons-material"
import {DateUtils} from "@beesset/common-utils";
import React from "react";
import {EnumActionType, PriceDescriptorCell, ProductDescriptorCell} from "../TransactionsHistory";

const WebTransactionDetails = ({
                                   transaction,
                                   translations,
                                   showInvoiceDialog,
                                   showRefundDialog,
                                   showComplaintDialog,
                                   downloadConfirmation
                               }) => {
    const ref = React.useRef();

    React.useEffect(() => {
        if(ref.current) {
            let timeout = setTimeout(() => {
                ref.current['scrollIntoView']({
                    block: "center",
                    behavior: 'smooth',
                });
            }, 500);

            return () => clearTimeout(timeout);
        }
    }, []);

    const descriptors = React.useMemo(() => {
        let result = [{
            name: translations.id,
            value: transaction.transactionId,
            Icon: <Key/>
        }];

        if (transaction.visibleVehicleNumber) {
            result.push({
                name: translations.vehicleNumber,
                value: transaction.vehicleNumber,
                Icon: <DirectionsBus/>
            });
        }

        if (transaction.item) {
            result.push({
                name: translations.period,
                TextProps: {
                    sx: {
                        whiteSpace: "pre-line"
                    }
                },
                value: [
                    <span key={"startDate-title"}>
                        {`${translations.startDate}: `}
                    </span >,
                    <span style={{fontWeight: 500}} key={"startDate-description"}>{DateUtils.formatters.FULLWithSeconds(transaction.item.activationDate)}</span>,
                    <br key={"period-breakLine"}/>,
                    <span key={"endDate-title"}>
                        {`${translations.endDate}: `}
                    </span >,
                    <span style={{fontWeight: 500}} key={"endDate-description"}>{DateUtils.formatters.FULLWithSeconds(transaction.item.expirationDate)}</span>,
                ],
                Icon: <DateRange/>
            });
        } else if (transaction.items) {
            const tabelConfig = {
                columns: [{
                    Header: translations.product,
                    accessor: props => `${props.profileName} ${props.productName}`,
                    Cell: ({row}) => {
                        return <ProductDescriptorCell {...row.original}/>
                    }
                }, {
                    Header: translations.price,
                    accessor: props => props.price.toFixed(2),
                    Cell: ({value}) => {
                        return <PriceDescriptorCell price={value} currencyCode={transaction.currencyCode}/>;
                    }
                }, {
                    Header: translations.startDate,
                    accessor: 'activationDate',
                    Cell: ({value}) => {
                        return <Typography sx={{fontSize: "0.85rem"}}>
                            {DateUtils.formatters.DD_MONTH_YYYY_HHMM(value)}
                        </Typography>
                    },
                    type: "datetime"
                }, {
                    Header: translations.endDate,
                    accessor: 'expirationDate',
                    Cell: ({value}) => {
                        return <Typography sx={{fontSize: "0.85rem"}}>
                            {DateUtils.formatters.DD_MONTH_YYYY_HHMM(value)}
                        </Typography>
                    },
                    type: "datetime"
                }],
                size: "small",
                pageSize: 5,
                pagination: true
            };

            result.push({
                Component: <Box
                    key={"all-transaction-products-list"}
                    sx={{
                        width: "100%",
                        maxWidth: "1200px"
                    }}>
                    <Table {...tabelConfig} data={transaction.items}/>
                </Box>
            })
        }

        if (transaction.paidOn != null) {
            result.push({
                name: translations.paidOn,
                value: DateUtils.formatters.FULLWithSeconds(transaction.paidOn),
                Icon: <CheckCircle/>
            });
        }

        if (transaction.cancelledOn != null) {
            result.push({
                name: translations.cancelledOn,
                value: DateUtils.formatters.FULLWithSeconds(transaction.cancelledOn),
                Icon: <Cancel/>
            });
        }

        if (transaction.refundedOn != null) {
            result.push({
                name: translations.refundedOn,
                value: DateUtils.formatters.FULLWithSeconds(transaction.refundedOn),
                Icon: <Replay/>
            });
        }

        if ((transaction['availableActions'] || []).length > 0) {
            result.push({
                Component: <Stack
                    key={"available-transaction-actions-stack"}
                    spacing={1}
                    mt={3}
                    direction="row"
                >
                    {(transaction['availableActions'] || []).indexOf(EnumActionType.INVOICE_REQUEST) >= 0 &&
                        <Button
                            endIcon={<Receipt/>}
                            size="small"
                            onClick={() => {
                                showInvoiceDialog(transaction);
                            }}
                            key={"invoice-button"}
                        >
                            {transaction.invoice ? translations.getInvoiceCorrection : translations.getInvoice}
                        </Button>
                    }
                    {(transaction['availableActions'] || []).indexOf(EnumActionType.COMPLAINT_REQUEST) >= 0 &&
                        <Button
                            endIcon={<Rule/>}
                            size="small"
                            onClick={() => {
                                showComplaintDialog(transaction);
                            }}
                            key={"complaint-button"}
                        >
                            {translations.getComplaint}
                        </Button>
                    }
                    {(transaction['availableActions'] || []).indexOf(EnumActionType.REFUND) >= 0 &&
                        <Button
                            endIcon={<Replay/>}
                            size="small"
                            onClick={() => {
                                showRefundDialog(transaction);
                            }}
                            key={"refund-button"}
                        >
                            {translations.refund}
                        </Button>}
                    {(transaction['availableActions'] || []).indexOf(EnumActionType.CONFIRMATION) >= 0 &&
                        <Button
                            endIcon={<PictureAsPdf/>}
                            size="small"
                            onClick={() => {
                                downloadConfirmation(transaction);
                            }}
                            key={"confirmation-download-button"}
                        >
                            {transaction.mediumType === 'PHONE_QR' ? translations.qrCodeConfirmation : translations.paymentCardConfirmation}
                        </Button>}
                </Stack>
            });
        }

        return result;
    }, [transaction, translations]);

    return <Box boxShadow="rgba(0, 0, 0, 0.15) 0px 5px 15px 0px" p={2} ref={ref}>
        <Box sx={{
            pr: {
                xs: 0,
                md: 7,
                xl: 10
            },
            pl: {
                xs: 0,
                md: 7,
                xl: 10
            }
        }}>
            <List
                descriptors={descriptors}
                sx={{
                    width: "100%"
                }}
            />
        </Box>
    </Box>
}

export default WebTransactionDetails;