import React from "react";
import {useFormContext} from "react-hook-form";
import {Box, Button, Fade, List, MenuItem, Stack, Typography} from "@mui/material";
import {
    FileUploadField,
    FormContainer,
    hideLoader,
    ResponsiveFieldsGroup,
    SelectField,
    showLoader,
    Snackbar,
    TextField
} from "@beesset/ui-components";
import {DateUtils, getLanguage} from "@beesset/common-utils";
import {
    APPLICATION_TYPE,
    PhoneNumberPattern,
    RegulationCheck,
    UPORClientRest,
    useConfig
} from "@beesset/upor-client-module-cmn";
import {useTranslation} from "react-i18next";
import ChannelsField from "./ChannelsField";

const Fields = ({
                    transaction,
                    translations,
                    onTransactionChange
                }) => {
    const {
        watch,
        getValues
    } = useFormContext();

    const [bean, setBean] = React.useState(getValues());

    React.useEffect(() => {
        const subscription = watch((value) => {
            setBean(value);
        });
        return () => {
            subscription['unsubscribe']();
        };
    }, [watch]);

    React.useEffect(() => {
        if (bean.transaction || bean.transactionId) {
            onTransactionChange(true);
        } else {
            onTransactionChange(false);
        }
    }, [bean]);

    return <ResponsiveFieldsGroup isMainContainer minWidth="unset">
        <React.Fragment>
            {!transaction && <React.Fragment>
                <ChannelsField
                    applicationType={APPLICATION_TYPE.COMPLAINT}
                />
                {bean["channel"] && <Fade in={true} timeout={500}>
                    <Box>
                        <SelectField
                            isStacked
                            key="select-transaction"
                            name="transaction"
                            label={translations.transaction}
                            required
                            getOptionLabel={(option) => {
                                return option.transactionId
                            }}
                            getOptionId={(option) => {
                                return option.transactionId;
                            }}
                            renderOption={(props, option) => {
                                return <MenuItem {...props}>
                                    <Stack width="100%">
                                        <Typography noWrap>
                                            {`${option.item.profileName} ${option.item.productName}`}
                                        </Typography>
                                        <Typography noWrap fontSize={13} color="text.secondary">
                                            {translations.fromToDate({
                                                startDate: DateUtils.formatters.DDMMYYYY_HHMMSS(option.item.activationDate),
                                                endDate: DateUtils.formatters.DDMMYYYY_HHMMSS(option.item.expirationDate)
                                            })}
                                        </Typography>
                                        <Typography noWrap fontSize={13} color="text.secondary">
                                            {DateUtils.formatters.DD_MONTH_YYYY_HHMMSS(option.transactionDate)}
                                        </Typography>
                                        <Typography noWrap fontSize={12} color="text.secondary">
                                            {option.transactionId}
                                        </Typography>
                                    </Stack>
                                </MenuItem>;
                            }}
                            optionsProvider={{
                                fetchOptions: UPORClientRest.getApplicationTransactions,
                                fetchOptionsRequest: {
                                    language: getLanguage(),
                                    applicationType: APPLICATION_TYPE.COMPLAINT,
                                    channel: bean['channel']
                                },
                                convertOptions: response => response['transactions']
                            }}
                        />
                    </Box>
                </Fade>}
            </React.Fragment>}
            {transaction && <TextField
                isStacked
                label={translations.transaction}
                name="transactionId"
                required
                readOnly
            />}
        </React.Fragment>
        {(bean.transaction || transaction) && <OtherFields
            translations={translations}
        />}
    </ResponsiveFieldsGroup>
}

const OtherFields = ({translations}) => {
    return <React.Fragment>
        <TextField isStacked required label={translations.firstAndLastName} name="nameAndSurname"/>
        <TextField
            label={translations.phoneNumber}
            name={"phoneNumber"}
            InputProps={{
                inputComponent: PhoneNumberPattern
            }}
            inputProps={{
                inputMode: "numeric"
            }}
        />
        <TextField label={translations.email} name="email" required/>
        <TextField
            label={translations.eventDescription}
            name={"description"}
            required
            multiline
            rows={9}
        />
        <FileUploadField
            label={translations.attachment}
            name="attachments"
            multiple
            maxSize={10}
            allowedExtensions={[
                ".doc",
                ".docx",
                ".odt",
                ".pdf",
                ".tex",
                ".txt",
                ".rtf",
                ".jpg",
                ".jpeg",
                ".png",
                ".svg"
            ]}
        />
    </React.Fragment>
}

const Application = ({innerRef, complaint = {}, ...props}) => {
    const {config} = useConfig();
    const formRef = React.useRef();

    innerRef.current = function (onSuccess) {
        formRef.current['handleSubmit']((bean) => {
            onSuccess(bean);
        }, () => {
            Snackbar.warning(props.translations.incorrectData);
        })();
    }

    return <FormContainer
        style={{marginTop: "unset"}}
        defaultValues={{
            email: config ? config.email : null,
            ...complaint,
            transactionId: props.transaction ? props.transaction.transactionId : null,
        }}
        ref={formRef}
    >
        <Fields {...props}/>
    </FormContainer>
}

const ComplaintApplicationComponentPrv = ({
                                              visibility: {
                                                  button = true,
                                                  regulations = true
                                              } = {},
                                              innerRef,
                                              onSuccess,
                                              ...props
                                          }) => {
    const regulationRef = React.useRef();
    const [showAll, setShowAll] = React.useState(false);
    const complaintRef = React.useRef();
    const {t} = useTranslation();

    const translations = React.useMemo(() => {
        return {
            title: t("components.application.complaint.title"),
            description: t("components.application.complaint.description"),
            eventDescription: t("components.application.complaint.eventDescription"),
            send: t("button.send"),
            firstAndLastName: t("common.firstAndLastName"),
            email: t("components.application.other.email"),
            phoneNumber: t("common.phoneNumber"),
            attachment: t("components.application.other.attachment"),
            incorrectData: t("components.application.complaint.incorrectData"),
            transaction: t("components.application.invoice.transaction"),
            complaintSuccess: t("components.application.complaint.success"),
            fromToDate: (props) => t("common.fromToDate", props),
            regulation: t("components.application.regulation"),
            regulationWarning: t("components.application.regulationWarning"),
        }
    }, [t]);

    function validate({onSuccess}) {
        function finalFn(bean) {
            let request = {
                type: APPLICATION_TYPE.COMPLAINT,
                data: JSON.stringify({
                    nameAndSurname: bean.nameAndSurname,
                    email: bean.email,
                    phoneNumber: bean.phoneNumber,
                    description: bean.description
                })
            }
            if (props.transaction) {
                request['transactionId'] = props.transaction.transactionId;
            } else if (bean.transaction) {
                request['transactionId'] = bean.transaction.transactionId;
            }

            const formData = new FormData();
            formData.append("request", JSON.stringify(request));

            if (bean.attachments) {
                if (bean.attachments.length) {
                    Array.from(bean.attachments).forEach((attachment) => {
                        formData.append("attachments", attachment);
                    });
                } else {
                    formData.append("attachments", bean.attachments);
                }
            }

            showLoader();
            UPORClientRest.registerApplication({
                handlers: {
                    success: () => {
                        onSuccess && onSuccess(request);
                        Snackbar.success(translations.complaintSuccess);
                    },
                    both: () => {
                        hideLoader();
                    }
                },
                formData
            });
        }

        complaintRef.current((bean) => {
            regulationRef.current.check(() => {
                finalFn(bean);
            });
        });
    }

    if (innerRef) {
        innerRef.current = validate;
    }

    function handleTransactionChange(value) {
        setShowAll(value);
    }

    return <Stack spacing={3}>
        <Stack spacing={1}>
            <Typography variant="body1" color="text.secondary" fontWeight={500}>
                {translations.title}
            </Typography>
            <Typography variant="body2">
                {translations.description}
            </Typography>
        </Stack>
        <Application
            innerRef={complaintRef}
            translations={translations}
            onTransactionChange={handleTransactionChange}
            {...props}
        />
        {showAll && <List>
            <RegulationCheck
                ref={regulationRef}
                description={translations.regulation}
                warning={translations.regulationWarning}
                align="flex-start"
            />
        </List>}
        {showAll && button &&
            <Button variant="contained" size="large" onClick={() => {
                validate({
                    onSuccess: onSuccess
                });
            }}>{translations.send}</Button>}
    </Stack>
}

const ComplaintApplicationComponent = React.forwardRef((props, ref) => {
    return <ComplaintApplicationComponentPrv {...props} innerRef={ref}/>;
});


export {
    ComplaintApplicationComponent
}
