import {Controller, useFormContext} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import React from "react";
import {DateUtils} from "@beesset/common-utils";

const DateField = (props) => {
    const {control, validationMessages} = useFormContext();

    const {
        name,
        label,
        required,
        readOnly,
        onlyYearAndMonthView,
        truncate = false,
        minDate,
        maxDate,
        withTime,
        sx = {
            marginTop: "10px"
        },
        isStacked,
        ...restProps
    } = props;

    let muiInputConfig = {
        label: label
    };

    let validation = {};
    if (required) {
        muiInputConfig["required"] = true;
        validation.required = validationMessages.required;
    }

    validation.validate = (value) => {
        if (value && value === "Invalid date") {
            return validationMessages.invalidDate;
        }
    };

    let format = (event) => {
        if (event.isValid()) {
            let date = event.clone();
            if (truncate) {
                date = UTILS.calculateStartDate(date, "day");
            }
            return date.format(withTime ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD");
        }
        return "Invalid date";
    };

    if (isStacked) {
        sx['width'] = "100%";
        sx['margin'] = "0px";
    }

    let {
        views,
        openTo,
        ...restProps2
    } = restProps;

    if (!openTo || !views) {
        openTo = "day";
        if (onlyYearAndMonthView) {
            views = ["year", "month"];
            openTo = "month";
            muiInputConfig['onKeyDown'] = (e) => e.preventDefault();
            format = (event) => {
                if (event.isValid()) {
                    let date = event.clone();
                    if (truncate) {
                        date = UTILS.calculateStartDate(date, "month");
                    }
                    return date.startOf("minute").format(withTime ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DD")
                }
                return "Invalid date";
            };
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={validation}
            defaultValue={null}
            render={({
                         field: {onChange, ref, value, ...fieldRest},
                         fieldState: {invalid, error},
                     }) => {
                return (
                    <DatePicker
                        value={value || null}
                        {...fieldRest}
                        onChange={(event) => {
                            onChange(event ? format(event) : null);
                        }}
                        inputRef={ref}
                        inputFormat={withTime ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY"}
                        readOnly={readOnly}
                        minDate={minDate}
                        maxDate={maxDate}
                        views={views}
                        clearable
                        openTo={openTo}
                        disableHighlightToday={true}
                        {...restProps2}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...{
                                        ...params,
                                        InputProps: {
                                            readOnly: true,
                                            ...params.InputProps,
                                            sx: {
                                                ...params.InputProps.sx,
                                                "& .MuiInputBase-input": {
                                                    caretColor: 'transparent'
                                                }
                                            }

                                        }
                                    }}
                                    sx={sx}
                                    {...muiInputConfig}
                                    error={invalid}
                                    helperText={error ? error.message : null}
                                />
                            );
                        }}
                    />
                );
            }}
        />
    );
};

const UTILS = {
    calculateStartDate: (momentDate, timeUnitType) => {
        if (momentDate.isAfter(DateUtils.getNow(), timeUnitType)) {
            return momentDate.startOf(timeUnitType);
        }
        return DateUtils.getNow().startOf("minute");

    },
    calculateEndDate: (startDate, timeUnitType, timeUnitTypeValue) => {
        return DateUtils.plus(Number(timeUnitTypeValue), timeUnitType, startDate.clone().startOf(timeUnitType))
            .subtract(1, "second")
            .format("YYYY-MM-DDTHH:mm:ss");
    }
}

export {
    UTILS,
    DateField
};