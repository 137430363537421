import {Typography} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import {NavLink} from "react-router-dom";
import {useLayoutNavigation} from "../LayoutNavigation";
import {styled} from "@mui/material/styles";

const StyledListItemButton = styled(ListItemButton)(({theme}) => {
    return {
        borderLeft: `5px solid transparent`,
        color: theme.palette.text.secondary,
        "&.Mui-selected": {
            borderLeft: `5px solid ${theme.palette.primary.main}`,
            "& .MuiListItemIcon-root": {
                color: theme.palette.primary.main
            },
            "& .MuiListItemText-root": {
                "& .MuiTypography-root": {
                    fontWeight: 600
                }
            }
        },
    }
});

const SidebarItem = React.memo((props) => {
    const {id, name, Icon, link, onClick} = props;
    const {selectedItem} = useLayoutNavigation();

    let selected = selectedItem && selectedItem.id === id;
    return (
        <React.Fragment>
            {link && <StyledListItemButton
                key={id}
                component={NavLink}
                to={link}
                selected={selected}
            >
                <ListItemIcon sx={{minWidth: "32px"}}>{Icon && <Icon fontSize="small"/>}</ListItemIcon>
                <ListItemText>
                    <Typography pt={0.5} pb={0.5} variant={"body2"} noWrap>{name}</Typography>
                </ListItemText>
            </StyledListItemButton>}
            {onClick && <StyledListItemButton
                key={id}
                onClick={onClick}
                selected={selected}
            >
                <ListItemIcon sx={{minWidth: "32px"}}>{Icon && <Icon/>}</ListItemIcon>
                <ListItemText>
                    <Typography pt={0.5} pb={0.5} variant={"body2"} noWrap>{name}</Typography>
                </ListItemText>
            </StyledListItemButton>}
        </React.Fragment>
    );
});

export default SidebarItem;
