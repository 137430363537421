import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {generateId} from "@beesset/common-utils";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import SyncIcon from "@mui/icons-material/Sync";
import {Box, Chip, Collapse, Stack, Typography,} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTableFooter from "@mui/material/TableFooter";
import MuiTableHead from "@mui/material/TableHead";
import MuiTablePagination from "@mui/material/TablePagination";
import MuiTableRow from "@mui/material/TableRow";
import MuiTableSortLabel from "@mui/material/TableSortLabel";
import update from "immutability-helper";
import PropTypes from "prop-types";
import React from "react";
import isEqual from "react-fast-compare";
import {useTranslation} from "react-i18next";
import {useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable} from "react-table";
import {CheckboxCell} from ".";
import BooleanColumnFilter from "./filter/BooleanFilter";
import DefaultColumnFilter from "./filter/DefaultColumnFilter";
import DateColumnFilter from "./filter/DateColumnFilter";
import GlobalFilter from "./filter/GlobalFilter";
import SelectColumnFilter from "./filter/SelectColumnFilter";
import {customFilterTypes} from "./filterTypes/FilterTypes";
import TableToolbar from "./toolbar/Toolbar";
import * as XLSX from "xlsx";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {Snackbar} from "../snackbar";
import NumberRangeColumnFilter from "./filter/NumerRangeColumnFilter";

const AttributesChipList = ({column, row}) => {
    let result = [];

    column.attributes.forEach((mapping) => {
        if (!mapping.show || mapping.show(row.original)) {
            result.push(
                <Chip
                    size="small"
                    key={row.id + "-" + mapping.accessor}
                    label={mapping.label}
                    variant="filled"
                    color={row.original[mapping.accessor] ? "info" : "default"}
                    sx={{
                        cursor: "pointer",
                    }}
                />
            );
        }
    });

    return (
        <Stack spacing={1} direction="row">
            {result}
        </Stack>
    );
};

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <React.Fragment>
                <Checkbox
                    ref={resolvedRef}
                    {...rest}
                    onClick={(e) => e.stopPropagation()}
                />
            </React.Fragment>
        );
    }
);

function createConfig({props, t, tableRef}) {
    const {
        columns,
        title,
        rowProps,
        cellProps,
        pagination = false,
        pageSize = 10,
        sorting = false,
        columnFiltering = false,
        globalFiltering = false,
        actions: {
            onAdd,
            onRefresh,
            onRowClicked,
            onRowSelected,
            onRowExpanded,
            onRowDragAndDrop,
            other = [],
            onDownloadXLSX
        } = {},
        autoResetPage = true,
        autoResetSortBy = true,
        autoResetFilters = true,
        autoResetExpanded = true,
        selectedRows,
        expandedRows,
        size = "medium",
        labelTitleOnHover,
        HeaderComponent,
        ...restProps
    } = props;

    columns.forEach((column) => {
        const {filterType, type, getOptionLabel} = column;

        if (!column.id && typeof column.accessor === "function") {
            column.id = generateId(5);
        }

        switch (type) {
            case "boolean":
            case "chip":
                column.Filter = BooleanColumnFilter;
                column.filter = "boolean";
                if (type === "boolean") {
                    column.Cell = ({cell}) => {
                        return <CheckboxCell value={cell.value} fontSize={size}/>;
                    };
                } else {
                    column.Cell = ({cell}) => {
                        return <Chip
                            size="small"
                            label={column.Header}
                            variant="filled"
                            color={cell.value ? "info" : "default"}
                        />;
                    };
                }
                break;
            case "date":
            case "datetime":
            case "time":
                column.sortType = "isodatetime";
                column.Filter = DateColumnFilter;
                if (type === "time") {
                    column.filter = "time";
                } else {
                    column.filter = "date";
                }
                break;
            case "multiAttributes":
                column.Cell = AttributesChipList;
                column.accessor = (props) => {
                    let result = column.attributes
                        .map((el) => {
                            if ((!el.show || el.show(props)) && props[el.accessor]) {
                                return "1";
                            }
                            return "0";
                        })
                        .join("");
                    return parseInt(result, 2);
                };

                const options = [];
                column.attributes.forEach((el) => {
                    options.push({
                        value: true,
                        label: el.label,
                        accessor: el.accessor,
                    });
                });
                column.attributes.forEach((el) => {
                    options.push({
                        value: false,
                        label: el.label,
                        accessor: el.accessor,
                    });
                });

                column.Filter = (props) => (
                    <SelectColumnFilter
                        {...props}
                        multiple={true}
                        mapOptions={(id, preFilteredRows) => {
                            let optionsByAttribute = {};

                            options.forEach((option) => {
                                let foundIndex = preFilteredRows.findIndex((row) => {
                                    return (
                                        (option.value && row.original[option.accessor]) ||
                                        (!option.value && !row.original[option.accessor])
                                    );
                                });
                                if (foundIndex >= 0) {
                                    if (!optionsByAttribute[option.label]) {
                                        optionsByAttribute[option.label] = [];
                                    }
                                    optionsByAttribute[option.label].push(option);
                                }
                            });

                            let filteredOptions = [];
                            Object.values(optionsByAttribute).forEach((o) => {
                                if (o.length > 1) {
                                    filteredOptions.push(...o);
                                }
                            });

                            return filteredOptions.sort((a, b) => b.value - a.value);
                        }}
                        isOptionEqualToValue={(option, value) => {
                            return (
                                option.label === value.label && option.value === value.value
                            );
                        }}
                        options={options}
                        maxVisible={3}
                        groupBy={(option) => t(`commonComponents.filter.attributes.${option.value}`)}
                    />
                );
                column.filter = "multiAttributesSelect";
                break;
            case "number":
                column.Filter = NumberRangeColumnFilter;
                column.filter = 'between';
                column.sortType = "basic";
                break;
            default:
                break;
        }

        switch (filterType) {
            case "select":
                column.Filter = (props) => (
                    <SelectColumnFilter
                        {...props}
                        multiple={false}
                        getOptionLabel={getOptionLabel}
                    />
                );
                column.filter = "select";
                break;
            case "multiSelect":
                column.Filter = (props) => (
                    <SelectColumnFilter
                        {...props}
                        multiple={true}
                        getOptionLabel={getOptionLabel}
                    />
                );
                column.filter = "multiSelect";
                break;
            default:
                break;
        }
    });

    let result = {
        title,
        rowProps,
        cellProps,
        pagination,
        sorting,
        globalFiltering,
        columnFiltering,
        onRowClicked,
        onRowSelected,
        onRowExpanded,
        onRowDragAndDrop,
        labelTitleOnHover: labelTitleOnHover,
        tablePlugins: [],
        tableOptions: {
            columns: columns,
            initialState: {pageSize: undefined, selectedRowIds: selectedRows || {}, expanded: expandedRows || {}},
            defaultColumn: {
                minWidth: 30,
                width: 150,
                maxWidth: 400,
            },
            filterTypes: undefined,
            sortTypes: undefined,
            autoResetPage: autoResetPage,
            autoResetSortBy: autoResetSortBy,
            autoResetFilters: autoResetFilters,
            autoResetExpanded: autoResetExpanded,
            paginateExpandedRows: onRowExpanded ? (pagination ? false : true) : true,
            size,
            ...restProps,
        },
        actionButtons: [],
        endActionButtons: [],
        translations: {
            add: t("button.add"),
            refresh: t("button.refresh"),
            pagination: {
                all: t("commonComponents.table.paginationAll"),
            },
            noDataToShow: t("commonComponents.table.noDataToShow"),
            noDataToDownload: t("commonComponents.table.noDataToDownload"),
            showDetails: t("commonComponents.table.showDetails"),
            hideDetails: t("commonComponents.table.hideDetails"),
            sortDesc: t("commonComponents.table.sortDesc"),
            sortAsc: t("commonComponents.table.sortAsc"),
            selectRow: t("commonComponents.table.selectRow"),
            unselectRow: t("commonComponents.table.unselectRow"),
            selectAllRows: t("commonComponents.table.selectAllRows"),
            unselectAllRows: t("commonComponents.table.unselectAllRows"),
            removeSort: t("commonComponents.table.removeSort"),
            downloadXLSX: t("commonComponents.table.downloadXLSX"),
            selectedRows: props => t("commonComponents.table.selectedRowsCount", props)
        },
        size: size === "small" ? "small" : "medium",
        HeaderComponent
    };

    if (globalFiltering === true) {
        result.tablePlugins.push(useGlobalFilter);
    } else if (columnFiltering === true) {
        result.tablePlugins.push(useFilters);
        result.tableOptions.defaultColumn.Filter = DefaultColumnFilter;
        result.tableOptions.defaultColumn.filter = "fuzzyText";
        result.tableOptions.filterTypes = customFilterTypes;
    }
    if (sorting === true) {
        result.tablePlugins.push(useSortBy);
        result.tableOptions.sortTypes = {
            isodatetime: (rowA, rowB, id) => {
                if (rowA.original[id] > rowB.original[id]) return 1;
                if (rowB.original[id] > rowA.original[id]) return -1;
                return 0;
            },
        };
    }

    if (onRowExpanded) {
        result.tablePlugins.push(useExpanded);
        result.tablePlugins.push((hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "expander",
                    headerStyle: {
                        width: 0
                    },
                    Cell: ({row}) => {
                        return (
                            <span
                                {...row.getToggleRowExpandedProps({
                                    style: {
                                        paddingLeft: `${row.depth * 2}rem`,
                                    },
                                    title: row.isExpanded ? result.translations.hideDetails : result.translations.showDetails,
                                    onClick: (e) => {
                                        row.toggleRowExpanded();
                                        e.stopPropagation();
                                        e.preventDefault();
                                        return false;
                                    }
                                })}
                            >
                                {row.isExpanded ? <ArrowDropDownIcon fontSize={size}/> :
                                    <ArrowRightIcon fontSize={size}/>}
                            </span>
                        );
                    },
                    SubCell: () => null,
                },
                ...columns,
            ]);
        });
    }

    if (onRowDragAndDrop) {
        result.tablePlugins.push((hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "dragAndDropHandler",
                    Cell: (props) => {
                        return <DragIndicatorIcon {...props.cell.getDragAndDropProps()} fontSize={size}/>;
                    },
                    headerStyle: {
                        width: 0
                    },
                },
                ...columns,
            ]);
        });
    }

    if (pagination === true) {
        result.tablePlugins.push(usePagination);
        result.tableOptions.initialState.pageSize = pageSize;
    }
    if (onRowSelected) {
        result.tablePlugins.push(useRowSelect);
        result.tablePlugins.push((hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                    id: "selection",
                    Header: ({getToggleAllRowsSelectedProps, isAllRowsSelected}) => {
                        return <div>
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps({
                                title: isAllRowsSelected ? result.translations.unselectAllRows : result.translations.selectAllRows,
                                size,
                                color: "secondary"
                            })} />
                        </div>
                    },
                    headerStyle: {
                        width: 0
                    },
                    Cell: ({row}) => {
                        return (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps({
                                    title: row.isSelected ? result.translations.unselectRow : result.translations.selectRow,
                                    size,
                                    color: "secondary"
                                })} />
                            </div>
                        );
                    },
                    accessor: "isSelected",
                    sortType: (a, b) => (a.isSelected === b.isSelected) ? 0 : a.isSelected ? -1 : 1,
                    Filter: false
                },
                ...columns,
            ]);
        });
    }

    if (onAdd) {
        result.actionButtons.unshift({
            name: result.translations.add,
            onClick: onAdd,
            Icon: <AddCircleIcon/>,
            isVisible: ({numSelected}) => numSelected === 0
        });
    }

    if (onRefresh) {
        result.actionButtons.unshift({
            name: result.translations.refresh,
            onClick: onRefresh,
            Icon: <SyncIcon/>,
            isVisible: ({numSelected}) => numSelected === 0
        });
    }

    if (onDownloadXLSX) {
        result.endActionButtons.unshift({
            name: result.translations.downloadXLSX,
            onClick: () => {
                let saved = false;
                if (tableRef && tableRef.current) {
                    let rows = tableRef.current.getCurrentRows();
                    let downloadConfig = onDownloadXLSX(rows);

                    if (downloadConfig && downloadConfig.sheets && downloadConfig.sheets.length > 0 && downloadConfig.fileName) {
                        let workbook = XLSX.utils.book_new();
                        downloadConfig.sheets.forEach(({name, data}) => {
                            if (data.length > 0) {
                                let worksheet = XLSX.utils.json_to_sheet(data);
                                const colLengths = Object.keys(data[0]).map((k) => k.toString().length)
                                for (const d of data) {
                                    Object.values(d).forEach((element, index) => {
                                        const length = element ? element.toString().length : 0;
                                        if (colLengths[index] < length) {
                                            colLengths[index] = length
                                        }
                                    })
                                }
                                worksheet["!cols"] = colLengths.map((l) => ({wch: l}));
                                XLSX.utils.book_append_sheet(workbook, worksheet, name);
                            }
                        });
                        if (workbook.SheetNames.length > 0) {
                            XLSX.writeFile(workbook, `${downloadConfig.fileName}.xlsx`, {compression: true});
                            saved = true;
                        }
                    }
                }
                if (!saved) {
                    Snackbar.warning(result.translations.noDataToDownload);
                }
            },
            Icon: <FileDownloadOutlinedIcon/>
        });
    }

    if (other) {
        other.forEach(({order, position = "start", ...props}) => {
            if (position === "start") {
                if (order != undefined) {
                    result.actionButtons.splice(order, 0, props);
                } else {
                    result.actionButtons.push(props);
                }
            }
            if (position === "end") {
                if (order != undefined) {
                    result.endActionButtons.splice(order, 0, props);
                } else {
                    result.endActionButtons.push(props);
                }
            }
        });
    }

    return result;
}

function createPageSizeOptions(translations, tableOptions, rows) {
    let pageSizeOptions = [...new Set([tableOptions.initialState.pageSize, 10, 25, 50])];
    if (rows.length >= 100) {
        pageSizeOptions.push(100);
    } else {
        pageSizeOptions.push({
            label: translations.pagination.all,
            value: rows.length,
        });
    }
    pageSizeOptions.sort((a, b) => a - b);
    return pageSizeOptions;
}

const Table = ({data, ...props}) => {
    if (!data) {
        return null;
    }

    const {t} = useTranslation();
    const selectedRows = React.useRef(null);
    const tableRef = React.useRef({
        rows: []
    });

    const dataRef = React.useRef(data);
    if (!isEqual(data, dataRef.current)) {
        dataRef.current = data || [];
    }

    const {
        tableOptions,
        tablePlugins,
        actionButtons,
        endActionButtons,
        pagination,
        sorting,
        globalFiltering,
        columnFiltering,
        title,
        onRowClicked,
        onRowSelected,
        onRowDragAndDrop,
        onRowExpanded,
        rowProps,
        cellProps,
        translations,
        size,
        labelTitleOnHover,
        HeaderComponent
    } = React.useMemo(() => {
        return createConfig({props, t, tableRef});
    }, [t, props.columns]);

    const {
        getVisibleRows,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        visibleColumns,
        preFilteredRows,
        state: {pageIndex, pageSize, globalFilter, filters, selectedRowIds},
    } = useTable({...tableOptions, data: dataRef.current}, ...tablePlugins);

    if (getVisibleRows) {
        getVisibleRows(rows)
    }

    React.useEffect(() => {
        if ((rows.length / pageSize) >> 0 < pageIndex) {
            gotoPage(0);
        }
    }, [rows.length, pageIndex, pageSize, gotoPage]);

    tableRef.current.getCurrentRows = () => {
        if (Object.keys(selectedRowIds).length > 0) {
            return (preFilteredRows || rows)
                .filter((row) => selectedRowIds[row.id])
                .map((row) => row.original)
        }
        return rows.map((row) => row.original);
    }

    if (
        (selectedRows.current &&
            !isEqual(selectedRows.current, selectedRowIds)) ||
        (!selectedRows.current &&
            Object.keys(selectedRowIds).length > 0 &&
            rows.length > 0)
    ) {
        selectedRows.current = selectedRowIds;
    }

    React.useEffect(() => {
        if (selectedRows.current) {
            onRowSelected(
                (preFilteredRows || rows)
                    .filter((row) => selectedRows.current[row.id])
                    .map((row) => row.original)
            );
        }
    }, [preFilteredRows, rows, selectedRows.current]);

    const {
        dragAndDropTableBodyProps,
        expandableTableBodyProps,
        commonTableProps,
    } = React.useMemo(() => {
        let commonTableProps = {
            rows: pagination ? page : rows,
            getFinalRowProps: (row) => {
                prepareRow(row);

                return row.getRowProps((internalProps) => {
                    let extraProps = rowProps ? rowProps(row) || {} : {};
                    if (onRowClicked) {
                        extraProps.hover = true;
                        if (!extraProps.sx) {
                            extraProps.sx = {};
                        }
                        extraProps.sx[":hover"] = {
                            cursor: "pointer",
                        };
                        extraProps.onClick = () => {
                            onRowClicked(row.original, row);
                        };
                    }
                    return {
                        ...internalProps,
                        ...extraProps,
                    };
                });
            },
            getFinalCellProps: (cell) => {
                return cell.getCellProps((internalProps) => {
                    let extraProps = cellProps ? cellProps(cell) || {} : {};
                    return {
                        ...internalProps,
                        ...extraProps,
                    };
                });
            },
        };

        if (onRowDragAndDrop) {
            return {
                dragAndDropTableBodyProps: {
                    ...commonTableProps,
                    dataRef,
                    onRowDragAndDrop,
                },
            };
        }

        if (onRowExpanded) {
            return {
                expandableTableBodyProps: {
                    ...commonTableProps,
                    onRowExpanded,
                    visibleColumns,
                },
            };
        }

        return {
            commonTableProps,
        };
    }, [pagination, page, rows, visibleColumns]);

    let pageSizeOptions = createPageSizeOptions(translations, tableOptions, rows);
    return (
        <MuiTableContainer sx={{height: "100%"}}>
            <MuiTable size={size} {...getTableProps({sx: {height: "1px"}})} stickyHeader>
                <MuiTableHead>
                    {HeaderComponent && <MuiTableRow>
                        <MuiTableCell colSpan={visibleColumns.length} sx={{pb: 0, borderBottom: "unset", top: "unset"}}>
                            <HeaderComponent rows={tableRef.current.getCurrentRows()}/>
                        </MuiTableCell>
                    </MuiTableRow>}
                    {(title || actionButtons.length > 0 || globalFiltering || endActionButtons.length > 0) &&
                        <MuiTableRow>
                            <MuiTableCell colSpan={visibleColumns.length} sx={{pb: 0, borderBottom: "unset", top: -1}}>
                                <TableToolbar
                                    title={title}
                                    globalFilter={
                                        globalFiltering ? (
                                            <GlobalFilter
                                                preGlobalFilteredRows={preGlobalFilteredRows}
                                                globalFilter={globalFilter}
                                                setGlobalFilter={setGlobalFilter}
                                            />
                                        ) : null
                                    }
                                    size={size}
                                    actionButtons={actionButtons}
                                    endActionButtons={endActionButtons}
                                    numSelected={selectedRows.current ? Object.keys(selectedRows.current).length : 0}
                                    translations={translations}
                                    rows={tableRef.current.getCurrentRows()}
                                />
                            </MuiTableCell>
                        </MuiTableRow>}
                    {headerGroups.map((headerGroup) => (
                        <MuiTableRow {...headerGroup.getHeaderGroupProps({sx: {height: "100%"}})}>
                            {headerGroup.headers.map((column) => {
                                let props = column.getHeaderProps();
                                props.sx = {
                                    ...props.sx,
                                    ...(labelTitleOnHover ? {
                                        maxWidth: column.width,
                                        height: "inherit",
                                        whiteSpace: "nowrap"
                                    } : {
                                        maxWidth: column.width,
                                        height: "inherit"
                                    }),
                                    maxWidth: 200,
                                    ...column['headerStyle'],
                                    top: (title || actionButtons.length > 0 || globalFiltering || endActionButtons.length > 0) ? (size === "small" ? 53 : 79) : 0
                                }

                                return (
                                    <MuiTableCell {...props}>
                                        <Box sx={{
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>
                                            <Box sx={{
                                                flex: "1 0 0"
                                            }}>
                                                <Box sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    height: "100%"
                                                }}>
                                                    {sorting ? (
                                                        <MuiTableSortLabel
                                                            active={column.isSorted}
                                                            hideSortIcon={false}
                                                            direction={column.isSortedDesc ? "desc" : "asc"}
                                                            {...column.getSortByToggleProps({
                                                                title: labelTitleOnHover ? column.Header : (column.isSortedDesc === undefined ? translations.sortAsc : (column.isSortedDesc ? translations.removeSort : translations.sortDesc))
                                                            })}
                                                        >
                                                            {column.render("Header")}
                                                        </MuiTableSortLabel>
                                                    ) : (
                                                        column.render("Header")
                                                    )}
                                                </Box>
                                            </Box>
                                            {columnFiltering && (
                                                <Box sx={{
                                                    width: "100%",
                                                    maxWidth: "250px",
                                                    marginTop: "5px"
                                                }}>
                                                    {column.canFilter ? column.render("Filter") : null}
                                                </Box>
                                            )}
                                        </Box>
                                    </MuiTableCell>
                                );
                            })}
                        </MuiTableRow>
                    ))}
                </MuiTableHead>
                <MuiTableBody {...getTableBodyProps()}>
                    {dragAndDropTableBodyProps && (
                        <DragAndDropTableBody {...dragAndDropTableBodyProps} />
                    )}
                    {expandableTableBodyProps && (
                        <ExpandableTableBody {...expandableTableBodyProps} />
                    )}
                    {commonTableProps && <CommonTableBody {...commonTableProps} />}
                </MuiTableBody>
                {pagination &&
                    dataRef.current.length > tableOptions.initialState.pageSize && (
                        <MuiTableFooter>
                            <MuiTableRow>
                                <MuiTablePagination
                                    rowsPerPageOptions={pageSizeOptions}
                                    colSpan={visibleColumns.length}
                                    count={rows.length}
                                    rowsPerPage={pageSize}
                                    page={(rows.length / pageSize) >> 0 < pageIndex ? 0 : pageIndex}
                                    onPageChange={(event, newPage) => {
                                        gotoPage(newPage);
                                    }}
                                    onRowsPerPageChange={(event) => {
                                        setPageSize(Number(event.target.value));
                                    }}
                                    sx={{borderBottom: "none"}}
                                />
                            </MuiTableRow>
                        </MuiTableFooter>
                    )}
                {rows.length === 0 && (
                    <MuiTableFooter>
                        <MuiTableRow>
                            <MuiTableCell
                                colSpan={visibleColumns.length}
                                sx={{
                                    borderBottom: "none"
                                }}
                            >
                                <Stack
                                    color={"grey.200"}
                                    spacing={1}
                                    width={"100%"}
                                    marginTop={6}
                                    marginBottom={6}
                                    direction={"column"}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    id={"not-data-info"}
                                >
                                    <NotificationImportantIcon fontSize={"large"}/>
                                    <Typography
                                        color={"grey.300"}
                                        variant={"body2"}
                                        sx={{textAlign: "center"}}
                                    >
                                        {translations.noDataToShow}
                                    </Typography>
                                </Stack>
                            </MuiTableCell>
                        </MuiTableRow>
                    </MuiTableFooter>
                )}
            </MuiTable>
        </MuiTableContainer>
    );
};

const CommonTableBody = ({rows, getFinalRowProps, getFinalCellProps}) => {
    return (
        <React.Fragment>
            {rows.map((row) => {
                return (
                    <MuiTableRow {...getFinalRowProps(row)}>
                        {row.cells.map((cell) => {
                            return (
                                <MuiTableCell {...getFinalCellProps(cell)}>
                                    {cell.render("Cell")}
                                </MuiTableCell>
                            );
                        })}
                    </MuiTableRow>
                );
            })}
        </React.Fragment>
    );
};

const ExpandableTableBody = ({
                                 onRowExpanded,
                                 rows,
                                 getFinalRowProps,
                                 getFinalCellProps,
                                 visibleColumns,
                             }) => {
    return (
        <React.Fragment>
            {rows.map((row, index) => {
                const props = getFinalRowProps(row);
                return (
                    <React.Fragment key={props.key}>
                        <MuiTableRow {...props} key={`${props.key}-expander`}>
                            {row.cells.map((cell) => {
                                return (
                                    <MuiTableCell {...getFinalCellProps(cell)}>
                                        {cell.render("Cell")}
                                    </MuiTableCell>
                                );
                            })}
                        </MuiTableRow>
                        <MuiTableRow key={`${props.key}-expanded`}>
                            <MuiTableCell
                                style={{
                                    paddingBottom: 0,
                                    paddingTop: 0,
                                    borderBottom: "none",
                                }}
                                colSpan={visibleColumns.length}
                            >
                                <Collapse in={row.isExpanded} timeout="auto" unmountOnExit>
                                    {row.isExpanded && (
                                        <Box sx={{margin: 3}}>{onRowExpanded({row})}</Box>
                                    )}
                                </Collapse>
                            </MuiTableCell>
                        </MuiTableRow>
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

const DragAndDropTableBody = ({
                                  onRowDragAndDrop,
                                  dataRef,
                                  rows,
                                  getFinalRowProps,
                              }) => {
    const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = dataRef.current[dragIndex];
        onRowDragAndDrop(
            update(dataRef.current, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            })
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            {rows.map((row, index) => {
                const props = getFinalRowProps(row);
                return (
                    <Row
                        id={row.id}
                        key={props.key}
                        index={index}
                        cells={row.cells}
                        moveRow={moveRow}
                        rowProps={props}
                    />
                );
            })}
        </DndProvider>
    );
};

const Row = ({rowProps, cells, index, moveRow, id}) => {
    const dropRef = React.useRef(null);
    const dragRef = React.useRef(null);

    const [, drop] = useDrop({
        accept: "row",
        hover(item, monitor) {
            if (!dropRef.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = dropRef.current.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveRow(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });

    const [{isDragging}, drag, preview] = useDrag({
        type: "row",
        item: () => ({id: id, index}),
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    if (!rowProps["sx"]) {
        rowProps["sx"] = {};
    }
    rowProps["sx"]["opacity"] = isDragging ? 0.1 : 1;

    preview(drop(dropRef));

    drag(dragRef);

    return (
        <MuiTableRow ref={dropRef} {...rowProps}>
            {cells.map((cell, index) => {
                const cellProps = cell.getCellProps();
                if (cell.column.id === "dragAndDropHandler") {
                    cell.getDragAndDropProps = () => {
                        return {
                            style: {
                                cursor: isDragging ? "grabbing" : "pointer",
                            },
                            ref: dragRef,
                        };
                    };
                }
                return (
                    <MuiTableCell
                        {...cellProps}
                        ref={cell.column.id === "dragAndDropHandler" ? dragRef : null}
                    >
                        {cell.render("Cell")}
                    </MuiTableCell>
                );
            })}
        </MuiTableRow>
    );
};

Table.propTypes = {
    data: PropTypes.array,
    title: PropTypes.string,
    dataProvider: PropTypes.shape({
        rowsData: PropTypes.array,
        fetchRowsData: PropTypes.func,
        converter: PropTypes.func,
    }),
    pagination: PropTypes.bool,
    sorting: PropTypes.bool,
    columnFiltering: PropTypes.bool,
    globalFiltering: PropTypes.bool,
    visibleFooter: PropTypes.bool,
};

export default React.memo(Table, isEqual);
