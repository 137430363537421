import CardsComponent from "./CardsComponent";
import {useTranslation} from "react-i18next";
import {DateUtils, generateId} from "@beesset/common-utils";
import {Dialog, FormContainer, hideLoader, ResponsiveFieldsGroup, showLoader, TextField} from "@beesset/ui-components";
import {Close, Save} from "@mui/icons-material";
import {
    DateField,
    MEDIUM_TYPE,
    PASSENGER_IDENTITY_TYPE,
    PeselOrDateOfBirthField,
    UPORClientRest,
    useConfig
} from "@beesset/upor-client-module-cmn";
import {Typography} from "@mui/material";
import React from "react";

const AddCityCardComponent = ({action, onClose}) => {
    const {refresh, isPassengerIdentityType, config} = useConfig();
    const {t} = useTranslation();

    React.useEffect(() => {
        onClose();
        hideLoader();
    }, [config]);

    return <Dialog
        open={action.type === "ADD"}
        title={t("components.settings.addCityCardTitle")}
        description={t("components.settings.addCityCardDesc")}
        actions={[{
            name: t("button.close"),
            color: "inherit",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }, {
            name: t("button.save"),
            type: "submit",
            form: "addCityCardForm",
            fullScreenProps: {
                Icon: <Save/>,
                align: "right",
            },
        }]}>
        <FormContainer
            id="addCityCardForm"
            defaultValues={action.object}
            onSuccess={(bean) => {
                showLoader();
                UPORClientRest.assignMedium({
                    handlers: {
                        success: () => {
                            refresh();
                        },
                        failure: () => {
                            hideLoader();
                        }
                    },
                    request: {
                        medium: {
                            ...bean,
                            mediumType: MEDIUM_TYPE.CITY_CARD
                        }
                    }
                });
            }
            }>
            <ResponsiveFieldsGroup isMainContainer minWidth="unset">
                <TextField label={t("common.cardNumber")} name="mediumId" required isStacked/>
                {isPassengerIdentityType(PASSENGER_IDENTITY_TYPE.DATE_OF_BIRTH) && <DateField
                    label={t("common.dateOfBirth")}
                    name="dateOfBirth"
                    required
                    openTo="year"
                    views={["year", "month", "day"]}
                    minDate={DateUtils.getNow().startOf("day").subtract(120, "year")}
                    maxDate={DateUtils.getNow().startOf("day")}
                    disableHighlightToday
                />}
                {isPassengerIdentityType(PASSENGER_IDENTITY_TYPE.PESEL) && <PeselOrDateOfBirthField/>}
                <TextField label={t("common.optionalName")} name="optionalName"/>
            </ResponsiveFieldsGroup>
        </FormContainer>
    </Dialog>
}


const CityCardsComponent = () => {
    const {t} = useTranslation();

    const config = React.useMemo(() => {
        return {
            mediumType: MEDIUM_TYPE.CITY_CARD,
            TableComponentConfig: {
                title: "components.settings.cityCards",
                firstColumn: {
                    name: t("common.token"),
                    value: (bean) => bean['mediumId'],
                    Header: t("common.cardNumber"),
                    accessor: "mediumId",
                    Cell: ({value}) => {
                        return <Typography maxWidth={200} noWrap fontSize="inherit">
                            {value}
                        </Typography>
                    },
                    headerStyle: {
                        width: 250,
                        maxWidth: 250
                    }
                }
            },
            DeleteComponentConfig: {
                title: "components.settings.deleteCityCardTitle",
                description: "components.settings.deleteCityCardDesc",
            },
            EditComponentConfig: {
                title: "components.settings.editCityCardTitle",
                description: "components.settings.editCityCardDesc"
            },
            AddComponent: AddCityCardComponent
        }
    }, []);

    return <CardsComponent {...config}/>
}

export {CityCardsComponent, AddCityCardComponent};