import * as React from 'react';

import Desktop_4 from "./backgrounds/4_desktop.svg";
import Mobile_4 from "./backgrounds/4_mobile.svg";
import Desktop_5 from "./backgrounds/5_desktop.svg";
import Mobile_5 from "./backgrounds/5_mobile.svg";
import Desktop_6 from "./backgrounds/6_desktop.svg";
import Mobile_6 from "./backgrounds/6_mobile.svg";
import Desktop_7 from "./backgrounds/7_desktop.svg";
import Mobile_7 from "./backgrounds/7_mobile.svg";
import Desktop_8 from "./backgrounds/8_desktop.svg";
import Mobile_8 from "./backgrounds/8_mobile.svg";
import Desktop_9 from "./backgrounds/9_desktop.svg";
import Mobile_9 from "./backgrounds/9_mobile.svg";
import Desktop_10 from "./backgrounds/10_desktop.svg";
import Mobile_10 from "./backgrounds/10_mobile.svg";
import Desktop_11 from "./backgrounds/11_desktop.svg";
import Mobile_11 from "./backgrounds/11_mobile.svg";
import Page1 from "./pages/1/Page1";
import Page2 from "./pages/2/Page2";
import Page3 from "./pages/3/Page3";

import "./help.css";
import CommonHelpComponent from "../common/CommonHelpComponent";

const HelpComponent = () => {
    const config = React.useMemo(() => {
        return {
            Pages: [{
                id: "1",
                Component: Page1
            }, {
                id: "2",
                onClick: Page2
            }, {
                id: "3",
                Component: Page3
            }, {
                id: "4",
                DesktopImage: Desktop_4,
                MobileImage: Mobile_4
            }, {
                id: "5",
                DesktopImage: Desktop_5,
                MobileImage: Mobile_5
            }, {
                id: "6",
                DesktopImage: Desktop_6,
                MobileImage: Mobile_6
            }, {
                id: "7",
                DesktopImage: Desktop_7,
                MobileImage: Mobile_7
            }, {
                id: "8",
                DesktopImage: Desktop_8,
                MobileImage: Mobile_8
            }, {
                id: "9",
                DesktopImage: Desktop_9,
                MobileImage: Mobile_9
            }, {
                id: "10",
                DesktopImage: Desktop_10,
                MobileImage: Mobile_10
            }, {
                id: "11",
                DesktopImage: Desktop_11,
                MobileImage: Mobile_11
            }],
            i18nPrefix: "components.help.help.names.",
            menuClassPrefix: "help-menu-item-"
        }
    }, []);

    return <CommonHelpComponent {...config}/>
}

export {
    HelpComponent
};