import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React from "react";
import {IconButton, lighten, Tooltip} from '@mui/material'
import DefaultIcon from "@mui/icons-material/FiberManualRecord";

const TableToolbar = (props) => {
    const {
        numSelected,
        globalFilter,
        actionButtons = [],
        endActionButtons = [],
        title,
        size,
        translations,
        rows
    } = props;

    let startButtons = actionButtons
        .filter(({isVisible}) => !isVisible || isVisible({rows, numSelected}));

    let endButtons = endActionButtons
        .filter(({isVisible}) => !isVisible || isVisible({rows, numSelected}));

    return (
        <Toolbar
            variant={size === "small" ? "dense" : "regular"}
            disableGutters={true}
            sx={(theme) => {
                let result = {};

                if (numSelected > 0) {
                    result.color = theme.palette.secondary.main;
                    result.backgroundColor = lighten(theme.palette.secondary.light, 0.9);
                    result.paddingLeft = theme.spacing(2);
                    result.paddingRight = theme.spacing(2);
                }

                return result;
            }}
        >
            {startButtons
                .map((props, index) => {
                    return <ToolbarButton {...props} key={`start-table-action-${index}`}/>
                })}
            {numSelected === 0 && title && <Typography
                variant="h6"
                id="tableTitle"
                key={"table-title"}
                ml={startButtons.length > 0 ? 2 : 0}
                mr={endButtons.length > 0 ? 2 : 0}
                noWrap
            >
                {title}
            </Typography>}
            {numSelected > 0 && <Typography
                variant="subtitle1"
                id="tableSelectedRows"
                key={"table-selected-rows"}
                ml={startButtons.length > 0 ? 2 : 0}
                mr={endButtons.length > 0 ? 2 : 0}
                fontWeight={300}
                noWrap
            >
                {translations.selectedRows({count: numSelected})}
            </Typography>}
            <div style={{flex: 1}}/>
            {globalFilter}
            {endButtons
                .map((props, index) => {
                    return <ToolbarButton
                        {...props}
                        onClick={(event) => props.onClick && props.onClick({event, rows, numSelected})}
                        key={`end-table-action-${index}`}
                    />
                })}
        </Toolbar>
    );
};

const ToolbarButton = ({name, onClick, color, Icon, disabled, size}) => {
    return <Tooltip title={name} TransitionProps={{timeout: 0}}>
                  <span>
                    <IconButton
                        aria-label={name}
                        onClick={(event) => onClick(event)}
                        color={color}
                        disabled={disabled}
                        size={size}
                    >
                      {Icon ? Icon : <DefaultIcon/>}
                    </IconButton>
                  </span>
    </Tooltip>;
}

TableToolbar.propTypes = {
    title: PropTypes.string,
    size: PropTypes.string,
    globalFilter: PropTypes.object,
    actionButtons: PropTypes.arrayOf(PropTypes.object),
    endActionButtons: PropTypes.arrayOf(PropTypes.object),
    selectedRowsCount: PropTypes.number
};

export default TableToolbar;
