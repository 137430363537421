import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import * as React from "react";
import {Navigate, NavLink, Outlet, Route, Routes, useLocation} from "react-router-dom";
import {useTheme} from "@mui/system";
import {Tab, Tabs, useMediaQuery} from "@mui/material";

const Offset = styled("div")(({theme}) => theme.mixins.toolbar);

const PageNotFound = () => {
    return <div>404 Page Not Found</div>;
};

const WrappedContent = ({withPaddings = true, children}) => {
    return <React.Fragment>
        {withPaddings && <Box sx={{
            flexGrow: 1,
            p: {
                xs: 2,
                sm: 3,
                md: 4,
            },
            width: "100%",
            flex: 1,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "inherit"
        }}>
            {children}
        </Box>}
        {!withPaddings && children}
    </React.Fragment>
}

const LayoutTabs = ({item}) => {
    const location = useLocation();

    function getTabValue() {
        if (location.pathname === item.link) {
            let firstTab = item.subRoutes[0];
            return `${item.link}${firstTab.link ? `/${firstTab.link}` : ""}`;
        }
        return location.pathname;
    }

    return <Box
        sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative"
        }}
    >
        <Box sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            position: "fixed",
            bgcolor: "background.main",
            zIndex: 1
        }}>
            <Tabs
                sx={{
                    p: {
                        xs: 2,
                        sm: 3,
                        md: 4,
                    },
                    pb: {
                        xs: 0,
                        sm: 0,
                        md: 0,
                    }
                }}
                value={getTabValue()}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                orientation="horizontal"
            >
                {item.subRoutes.map(tab => {
                    return <Tab
                        key={tab.id}
                        label={tab.name}
                        value={`${item.link}${tab.link ? `/${tab.link}` : ""}`}
                        component={NavLink}
                        to={tab.link || ""}
                        id={`tab-item-${tab.id}`}
                        aria-controls={`tab-panel-${tab.id}`}
                    />
                })}
            </Tabs>
        </Box>
        <Box height={{
            xs: 65,
            sm: 73,
            md: 81
        }}/>
        <Box
            sx={{
                flexGrow: 1,
                p: {
                    xs: 2,
                    sm: 3,
                    md: 4,
                },
                display: "flex"
            }}>
            <Outlet/>
        </Box>
    </Box>
}


export default function LayoutContent({moduleItems, StartupPathListenerComponent, drawerWidth}) {
    const theme = useTheme();
    const showOffset = useMediaQuery(theme.breakpoints.down("md"));

    const routes = (function mapAllRoutes(items) {
        return items.reduce((allItems, item) => {
            let result = [];

            if (item.link || item.path) {
                if (item.component) {
                    result = allItems.concat([
                        <Route
                            exact={item.exact !== undefined ? item.exact : true}
                            path={item.path || item.link}
                            element={<WrappedContent
                                withPaddings={item.withPaddings}
                            >
                                {<item.component {...item.componentProps}/>}
                            </WrappedContent>}
                            key={item.id}
                        >
                            {item.subRoutes && item.subRoutes
                                .map((subRoute) => {
                                    return <Route
                                        key={subRoute.id}
                                        index={!subRoute.link}
                                        path={subRoute.link}
                                        element={<subRoute.component {...subRoute.componentProps}/>
                                        }/>
                                })}
                        </Route>,
                    ]);
                } else if (item.isTabs) {
                    let firstTab = item.subRoutes[0];
                    result = allItems.concat([
                        <Route
                            key={item.id}
                            path={item.path || item.link}
                            element={<LayoutTabs item={item}/>}
                        >
                            {item.subRoutes.map((tab) => {
                                return <Route key={tab.id} path={tab.link} element={<tab.component role="tabpanel"
                                                                                                   id={`tab-panel-${tab.id}`}
                                                                                                   aria-labelledby={`tab-item-${tab.id}`}/>}/>
                            })}
                            <Route key={`${firstTab.id}-index`} index={true} element={<firstTab.component/>}/>
                        </Route>
                    ]);
                }
            } else {
                result = allItems;
            }

            if (item.onInitSelected) {
                if (!StartupPathListenerComponent) {
                    result.unshift(
                        <Route index key={"redirect"} element={<Navigate replace to={item.link}/>}/>
                    );
                } else {
                    result.unshift(
                        <Route index key={"startup-path-listener"}
                               element={<StartupPathListenerComponent initialPath={item.link}/>}/>
                    );
                }

                result.push(<Route key={"route-*"} path="*" element={<Navigate replace to={item.link}/>}/>);
            }

            if (item.items && item.items.length) {
                result = result.concat(mapAllRoutes(item.items));
            }
            return result;
        }, []);
    })(moduleItems);

    return (
        <React.Fragment>
            {showOffset && <Offset/>}
            <Box
                component="main"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    float: "right",    
                    width: {md: `calc(100% - ${drawerWidth}px)`, xs: "100%"},
                    height: "auto",
                    bgcolor: "background.main",
                    paddingTop: "env(safe-area-inset-top)",
                    paddingBottom: "env(safe-area-inset-bottom)",
                    minHeight: {
                        xs: "calc(100vh - 56px)",
                        sm: "calc(100vh - 64px)",
                        md: "100vh"
                    }
                }}
            >
                <Routes>
                    {routes}
                </Routes>
            </Box>
        </React.Fragment>
    );
}
