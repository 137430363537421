import {useTranslation} from "react-i18next";
import React from "react";
import {Card, CardActions, CardContent, Tooltip, Typography} from "@mui/material";
import {Close, Contactless, Save} from "@mui/icons-material";
import {Dialog, FormContainer, hideLoader, ResponsiveFieldsGroup, showLoader, TextField} from "@beesset/ui-components";
import {
    DateField,
    MEDIUM_TYPE,
    PASSENGER_IDENTITY_TYPE, PeselOrDateOfBirthField,
    UPORClientRest,
    useConfig
} from "@beesset/upor-client-module-cmn";
import {DateUtils, generateId} from "@beesset/common-utils";
import {useNavigate} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import CardsComponent from "./CardsComponent";

const EnumTokenizationRequestType = {
    SETTINGS: "SETTINGS",
    PURCHASE: "PURCHASE"
}

const AddStepOne = ({translations, request}) => {
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (!loading) {
            return;
        }

        let active = true;
        UPORClientRest.registerTokenization({
            handlers: {
                success: (response) => {
                    if (active) {
                        window.location.href = response.redirect;
                    }
                },
                failure: () => {
                    setLoading(false);
                }
            },
            request
        });

        return () => {
            active = false;
        };
    }, [loading]);

    return <Card raised sx={{overflow: "unset", mt: 3}}>
        <CardContent>
            <Typography gutterBottom variant="h6" component="div">
                {translations.tokenizationTitle}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {translations.tokenizationDesc}
            </Typography>
            <Typography variant="body2" color="error.light" mt={0.5}>
                {translations.tokenizationDesc2}
            </Typography>
            <CardActions sx={{pl: 0, pr: 0}}>
                <LoadingButton
                    sx={{mt: 1}}
                    type="submit"
                    variant="contained"
                    loading={loading}
                    loadingPosition="end"
                    fullWidth
                    endIcon={<Contactless/>}
                    onClick={() => {
                        setLoading(true);
                    }}
                >
                    {translations.addTitle}
                </LoadingButton>
            </CardActions>
        </CardContent>
    </Card>
}

const AddStepTwo = ({translations, formId, token, assignMediumRequestId}) => {
    const {refresh, isPassengerIdentityType} = useConfig();

    return <FormContainer
        id={formId}
        defaultValues={{
            mediumId: token
        }}
        onSuccess={(bean) => {
            showLoader();
            UPORClientRest.assignMedium({
                handlers: {
                    success: () => {
                        refresh();
                    },
                    failure: () => {
                        hideLoader();
                    }
                },
                request: {
                    assignMediumRequestId: assignMediumRequestId,
                    medium: {
                        ...bean,
                        mediumType: MEDIUM_TYPE.PAYMENT_CARD
                    }
                }
            });
        }
        }>
        <ResponsiveFieldsGroup isMainContainer minWidth="unset">
            <TextField label={translations.firstName} name="firstName" required isStacked/>
            <TextField label={translations.lastName} name="lastName" required/>
            {isPassengerIdentityType(PASSENGER_IDENTITY_TYPE.DATE_OF_BIRTH) && <DateField
                label={translations.dateOfBirth}
                name="dateOfBirth"
                required
                openTo="year"
                views={["year", "month", "day"]}
                minDate={DateUtils.getNow().startOf("day").subtract(120, "year")}
                maxDate={DateUtils.getNow().startOf("day")}
                disableHighlightToday
            />}
            {isPassengerIdentityType(PASSENGER_IDENTITY_TYPE.PESEL) && <PeselOrDateOfBirthField/>}
            <TextField label={translations.token} name="mediumId" required readOnly/>
            <TextField label={translations.optionalName} name="optionalName"/>
        </ResponsiveFieldsGroup>
    </FormContainer>
}

const AddPaymentCardComponent = ({action, onClose, title, description, closeButtonProps, saveButtonProps}) => {
    const {t} = useTranslation();
    const {config} = useConfig();
    const formId = React.useRef(generateId(10));

    React.useEffect(() => {
        onClose();
        hideLoader();
    }, [config]);

    const translations = React.useMemo(() => {
        return {
            dateOfBirth: t("common.dateOfBirth"),
            firstName: t("common.firstName"),
            lastName: t("common.lastName"),
            token: t("common.token"),
            optionalName: t("common.optionalName"),
            close: t("button.close"),
            save: t("button.save"),
            tokenizationTitle: t("components.settings.tokenizationTitle"),
            tokenizationDesc: t("components.settings.tokenizationDesc"),
            tokenizationDesc2: t("components.settings.tokenizationDesc2"),
            addTitle: t("components.settings.addPaymentCardTitle"),
            addDesc: t("components.settings.addPaymentCardDesc")
        }
    }, [t]);

    let actions = [{
        name: translations.close,
        color: "inherit",
        onClick: onClose,
        fullScreenProps: {
            Icon: <Close/>,
            align: "left",
        },
        ...closeButtonProps
    }];
    if (action.object && action.object.token) {
        actions.push({
            name: translations.save,
            type: "submit",
            form: formId.current,
            fullScreenProps: {
                Icon: <Save/>,
                align: "right",
            },
            ...saveButtonProps
        });
    }

    return <React.Fragment>
        {action.type === "ADD" && <Dialog
            open={true}
            title={title || translations.addTitle}
            description={description || translations.addDesc}
            actions={actions}>
            {action.object && action.object.type && <AddStepOne
                translations={translations}
                request={action.object}
            />}
            {action.object && action.object.token && <AddStepTwo
                translations={translations}
                token={action.object.token}
                assignMediumRequestId={action.assignMediumRequestId}
                formId={formId.current}
            />}
        </Dialog>}
    </React.Fragment>
}

const PaymentCardsComponent = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const config = React.useMemo(() => {
        return {
            locationListener: (location, setAction) => {
                if (location.state && location.state.addNewPaymentCard) {
                    setAction({
                        type: "ADD",
                        object: location.state.addNewPaymentCard
                    });
                    navigate(location.pathname, {});
                }
            },
            onAdd: () => {
                return {
                    type: EnumTokenizationRequestType.SETTINGS
                }
            },
            mediumType: MEDIUM_TYPE.PAYMENT_CARD,
            TableComponentConfig: {
                title: "components.settings.paymentCards",
                firstColumn: {
                    name: t("common.token"),
                    value: (bean) => bean['mediumId'],
                    Header: t("common.token"),
                    accessor: "mediumId",
                    Cell: ({value}) => {
                        return <Tooltip title={value}>
                            <Typography maxWidth={200} noWrap fontSize="inherit">
                                {value}
                            </Typography>
                        </Tooltip>
                    },
                    headerStyle: {
                        width: 250,
                        maxWidth: 250
                    }
                }
            },
            DeleteComponentConfig: {
                title: "components.settings.deletePaymentCardTitle",
                description: "components.settings.deletePaymentCardDesc",
            },
            EditComponentConfig: {
                title: "components.settings.editPaymentCardTitle",
                description: "components.settings.editPaymentCardDesc"
            },
            AddComponent: AddPaymentCardComponent
        }
    }, [t]);

    return <CardsComponent {...config}/>
}

export {EnumTokenizationRequestType, AddPaymentCardComponent, PaymentCardsComponent};