import {hideLoader, showLoader} from "@beesset/ui-components";
import React from "react";
import isEqual from 'react-fast-compare';

const useDataFetcher = ({
                            request = {},
                            callback,
                            fetch,
                            data,
                            convert,
                            validateRequest,
                            showLoading = true,
                            instantLoader = -1
                        }) => {
    const [newDataRequest, setNewDataRequest] = React.useState(null);
    const [dataState, setDataState] = React.useState(null);

    const refresh = React.useCallback(() => {
        setNewDataRequest((old) => {
            return {...old};
        });
    }, []);

    React.useEffect(() => {
        let active = true;

        if (active) {
            if (data && !fetch) {
                setDataState(data);
                return;
            }

            if (!newDataRequest || isEqual(newDataRequest, request) === false) {
                if (!validateRequest || validateRequest(request)) {
                    setNewDataRequest(request);
                } else {
                    setNewDataRequest(null);
                }
            }
        }

        return () => {
            active = false;
        };
    }, [request, data]);

    React.useEffect(() => {
        if (!newDataRequest) {
            setDataState(null);
            return;
        }

        let active = true;
        let activeLoader = true;

        showLoading && showLoader(instantLoader >= 0 && dataState == null ? instantLoader : undefined);
        fetch({
            handlers: {
                success: (response) => {
                    if (active) {
                        setDataState(convert ? convert(response) : response);
                    }
                    if (callback) {
                        callback(response);
                    }
                },
                both: () => {
                    if (active) {
                        showLoading && hideLoader();
                        activeLoader = false;
                    }
                },
            },
            request: newDataRequest
        });

        return () => {
            if (activeLoader) {
                showLoading && hideLoader();
            }
            active = false;
        };
    }, [newDataRequest, instantLoader]);

    return {
        refresh,
        data: dataState
    };
};

const useLoadingDataFetcher = ({request = {}, callback, fetch, data, convert, validateRequest}) => {
    const state = React.useRef({
        newDataRequest: null,
        loading: false,
        loadingTimer: null,
        data: null
    });
    const rerender = React.useReducer(() => ({}), {})[1];

    function createLoader(instant) {
        if(!state.current.loading) {
            if(instant) {
                state.current.loading = true;
                rerender();
            }
            else {
                state.current.loading = false;
                state.current.loadingTimer = setTimeout(() => {
                    state.current.loading = true;
                    if (state.current.data === null) {
                        state.current.data = [];
                    }
                    rerender();
                }, 200);
            }
        }
    }

    function clearLoader() {
        if(state.current.loadingTimer) {
            clearTimeout(state.current.loadingTimer);
            state.current.loadingTimer = null;
        }
        state.current.loading = false;
    }

    const refreshData = React.useCallback(() => {
        state.current.newDataRequest = {...state.current.newDataRequest};
        createLoader(true);
    }, []);

    React.useEffect(() => {
        if (!state.current.newDataRequest) {
            return;
        }

        createLoader();
        let active = true;
        fetch({
            handlers: {
                success: (response) => {
                    if (active) {
                        if (state.current.loading === true) {
                            setTimeout(() => {
                                state.current.data = convert ? convert(response) : response;
                                clearLoader();
                                rerender();
                            }, 300);
                        } else {
                            state.current.data = convert ? convert(response) : response;
                            clearLoader();
                            rerender();
                        }
                    }
                    if (callback) {
                        callback(response);
                    }
                },
                failure: () => {
                    if (active) {
                        clearLoader();
                        rerender();
                    }
                }
            },
            request: {
                ...state.current.newDataRequest
            }
        });

        return () => {
            active = false;
        };
    }, [state.current.newDataRequest]);

    React.useEffect(() => {
        let active = true;

        if (active) {
            if (data && !fetch) {
                state.current.data = data;
                return;
            }

            if (!state.current.newDataRequest || isEqual(state.current.newDataRequest, request) === false) {
                if (!validateRequest || validateRequest(request)) {
                    state.current.newDataRequest = {
                        ...request
                    };
                    rerender();
                } else {
                    if (state.current.newDataRequest) {
                        state.current.newDataRequest = null;
                        state.current.data = null;
                        rerender();
                    }
                }
            }
        }

        return () => {
            active = false;
        };
    }, [request, data]);

    return {
        refresh: refreshData,
        data: state.current.data,
        loading: state.current.loading
    };
};


export {
    useDataFetcher,
    useLoadingDataFetcher
};