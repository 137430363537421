import {Dialog, List} from "@beesset/ui-components";
import {useTranslation} from "react-i18next";
import {Close, Discount, Email, Event, Fingerprint, Person, Phone} from "@mui/icons-material";
import React from "react";
import {DateUtils} from "@beesset/common-utils";
import {useConfig} from "@beesset/upor-client-module-cmn";
import {Stack, Typography} from "@mui/material";

const VirtualCardDetails = ({open, onClose}) => {
    const {config} = useConfig();
    const {t} = useTranslation();


    let card = config['mediums'][0];

    return <Dialog
        open={open}
        title={t("common.details")}
        actions={[{
            name: t("button.close"),
            color: "inherit",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }]}
    >
        <List
            descriptors={[
                {
                    name: t("common.firstAndLastName"),
                    value: `${card.firstName} ${card.lastName}`,
                    Icon: <Person/>
                },
                card.pesel
                    ? {
                        name: t("common.pesel"),
                        value: card.pesel,
                        Icon: <Fingerprint/>
                    }
                    : {
                        name: t("common.dateOfBirth"),
                        value: DateUtils.formatters.DDMONTHYY(card.dateOfBirth),
                        Icon: <Event/>
                    },
                {
                    name: t("common.email"),
                    value: config.email,
                    Icon: <Email/>
                },
                {
                    name: t("common.phoneNumber"),
                    value: config.phoneNumber,
                    Icon: <Phone/>
                },
                {
                    Icon: <Discount/>,
                    name: t("common.profile"),
                    value: <Stack component="span" spacing={1}>
                        {card.profiles.map(({name, validFrom, validTo}, i) => {
                            return <Stack key={`profile-description-${i}`} component="span">
                                <Typography component="span" fontSize="0.85rem">
                                    {name}
                                </Typography>
                                <Typography component="span" fontSize="0.7rem" color="text.secondary" fontWeight={400}
                                            lineHeight={1.15}>
                                    {`${t("common.startDate")}: ${validFrom ? DateUtils.formatters.DD_MONTH_YYYY_HHMM(validFrom) : "–"}`}
                                </Typography>
                                <Typography component="span" fontSize="0.7rem" color="text.secondary" fontWeight={400}
                                            lineHeight={1.15}>
                                    {`${t("common.endDate")}: ${validTo ? DateUtils.formatters.DD_MONTH_YYYY_HHMM(validTo) : "–"}`}
                                </Typography>
                            </Stack>
                        })}
                    </Stack>
                }
            ]}
        />
    </Dialog>
}

export default VirtualCardDetails;