import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import * as React from "react";
import {Navigate, NavLink, Outlet, Route, Routes, useLocation} from "react-router-dom";
import {Stack, Tab, Tabs, Typography} from "@mui/material";
import {useLayout} from "../Layout";
import {useTranslation} from "react-i18next";

const Offset = styled("div")(({theme}) => theme.mixins.toolbar);
const Content = styled("div")(({theme}) => ({
    width: "100%",
    overflow: "auto",
    height: "100%",
    maxHeight: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    display: "inherit",
    flexDirection: "inherit"
}));

const PageNotFound = () => {
    const {t} = useTranslation();

    return <Box
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
    >
        <Stack maxWidth={500} justifyContent="center" alignItems="center" spacing={1} color="text.secondary"
               mt={"-10%"}>
            <Typography variant="h1" color="error.light">
                404
            </Typography>
            <Typography color="error.light">
                {t("commonException.PAGE_NOT_FOUND")}
            </Typography>
        </Stack>
    </Box>;
};

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open, drawerwidth}) => ({
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(2),
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        width: `100%`,
        [theme.breakpoints.up("sm")]: {
            marginLeft: `-${drawerwidth}px`,
            ...(open && {
                width: `calc(100% - ${drawerwidth}px)`,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            })
        },
    }),
);
const LayoutTabs = ({item}) => {
    const location = useLocation();

    function getTabValue() {
        return location.pathname;
    }

    return <Box
        sx={{
            height: "100%",
            width: "100%",
            flexGrow: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            position: "relative"
        }}
    >
        <Box sx={{borderBottom: 1, borderColor: "divider", width: "100%"}}>
            <Tabs
                value={getTabValue()}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                orientation="horizontal"
            >
                {item.tabs.map(tab => {
                    return <Tab
                        key={tab.id}
                        label={tab.name}
                        value={tab.link}
                        component={NavLink}
                        to={tab.link || ""}
                        id={`tab-item-${tab.id}`}
                        aria-controls={`tab-panel-${tab.id}`}
                    />
                })}
            </Tabs>
        </Box>
        <Box
            sx={{
                flexGrow: 1,
                position: "relative",
                overflow: "hidden"
            }}>
            <Outlet/>
        </Box>
    </Box>
}

export default function LayoutContent() {
    const {
        drawerWidth,
        desktopOpen,
        moduleItems
    } = useLayout();

    const routes = React.useMemo(() => {
        let onInitSelected;
        let mapRoutes = (items) => {
            return items.reduce((allItems, item) => {
                let result = [];

                if (item.link || item.path) {
                    if (item.component) {
                        result = allItems.concat([
                            <Route
                                exact
                                path={item.path || item.link}
                                element={<item.component/>}
                                key={item.id}
                            />,
                        ]);
                    } else if (item.tabs && item.tabs.length > 0) {
                        let firstTab = item.tabs[0];
                        result = allItems.concat([
                            <Route
                                key={item.id}
                                path={item.path || item.link}
                                element={<LayoutTabs item={item}/>}
                            >
                                {item.tabs.map((tab) => {
                                    if (tab.onInitSelected) {
                                        onInitSelected = tab;
                                    }
                                    return <Route
                                        key={tab.id}
                                        path={tab.link}
                                        element={<tab.component
                                            role="tabpanel"
                                            id={`tab-panel-${tab.id}`}
                                            aria-labelledby={`tab-item-${tab.id}`}
                                        />}
                                    />
                                })}
                                <Route key={`${firstTab.id}-index`} index={true} element={<firstTab.component/>}/>
                            </Route>
                        ]);
                    }
                } else {
                    result = allItems;
                }

                if (item.onInitSelected) {
                    onInitSelected = item;
                }

                if (item.items && item.items.length) {
                    result = result.concat(mapRoutes(item.items));
                }
                return result;
            }, []);
        }

        let result = mapRoutes(moduleItems);
        if (onInitSelected) {
            result.unshift(
                <Route index key={"redirect"} element={<Navigate replace to={onInitSelected.link}/>}/>
            );
        }
        result.push(<Route path={"*"} element={<PageNotFound/>} key={"not-found-route"}/>);
        return result;
    }, [moduleItems]);

    return (
        <Main
            open={desktopOpen}
            drawerwidth={drawerWidth}
        >
            <Offset/>
            <Content>
                <Routes>{routes}</Routes>
            </Content>
        </Main>
    );
};
