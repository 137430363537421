import React from "react";
import {useLayoutNavigation} from "../LayoutNavigation";
import Drawer from "@mui/material/Drawer";
import {useTranslation} from "react-i18next";
import {Box, Button, CardActions, CardMedia, IconButton, List, Stack, Tooltip, useTheme} from "@mui/material";
import {Accessible, Language} from "@mui/icons-material";
import SidebarItem from "./SidebarItem";
import SidebarHeader from "./SidebarHeader";

const MobileDrawer = React.memo(() => {
    const theme = useTheme();
    const {mobileOpen, toggleMobileMenu, drawerWidth, MenuFooter} = useLayoutNavigation();

    return (
        <Drawer
            variant="temporary"
            open={mobileOpen}
            onClick={() => {
                toggleMobileMenu();
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: {xs: "block", md: "none"},
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                },
            }}
        >
            <Box sx={{
                height: "env(safe-area-inset-top)",
                width: "100%",
                backgroundColor: theme.palette.mode === "light" ? "primary.main" : "transparent"
            }}/>
            <SidebarHeader/>
            <Menu/>
            {MenuFooter && <MenuFooter/>}
            <Box height="env(safe-area-inset-bottom)" width="100%" bgcolor='transparent'/>
        </Drawer>
    );
});

const DesktopDrawer = React.memo(() => {
    const {t} = useTranslation();

    const {drawerWidth, toggleSettingsMenu, MenuFooter} = useLayoutNavigation();

    return (
        <Drawer
            variant="permanent"
            sx={{
                display: {xs: "none", md: "block"},
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                },
            }}
            open
        >
            <SidebarHeader/>
            <Menu/>
            {MenuFooter && <MenuFooter/>}
            <Box display="flex" overflow="auto">
                <Stack alignSelf="flex-start" p={1} direction="row" spacing={1}>
                    <Button
                        size="small"
                        color="primary"
                        startIcon={<Language/>}
                        aria-label={t("common.languages")}
                        aria-controls="settings"
                        aria-haspopup={true}
                        onClick={toggleSettingsMenu}
                    >
                        {t("common.languages")}
                    </Button>
                    <Tooltip title={t("button.accessibility")}>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={toggleSettingsMenu}
                            aria-label={t("button.accessibility")}
                            aria-controls="settings"
                            aria-haspopup={true}
                        >
                            <Accessible/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Box>
        </Drawer>
    );
});

const Menu = React.memo(() => {
    const {moduleItems} = useLayoutNavigation();

    return (
        <React.Fragment>
            <List
                sx={{width: "100%", maxWidth: 360, flex: 1}}
                component="nav"
            >
                {moduleItems
                    .filter(item => !item["omitMenu"])
                    .map((item, index) => {
                        return <SidebarItem {...item} key={index}/>;
                    })}
            </List>
        </React.Fragment>
    );
});

export {MobileDrawer, DesktopDrawer};