import {useStepper} from "@beesset/ui-components";
import {AddCard, DomainAdd, PersonAddAlt1} from '@mui/icons-material';
import {CommonStep, DESCRIPTOR_TYPES} from "./CommonStep";
import {Typography} from "@mui/material";
import {
    AddCityCardComponent,
    AddPaymentCardComponent,
    AddQrProfileComponent,
    EnumTokenizationRequestType,
    MEDIUM_TYPE,
    useConfig,
} from "@beesset/upor-client-module-cmn"

import React from "react";
import {useTariffs} from "../TariffsProvider";

const Step3_MediumSelection = ({translations}) => {
    const {config, isMediumTypeAvailable} = useConfig();
    const {resolveMediumIcon} = useTariffs();

    const [addBlikAction, setAddBlikAction] = React.useState({type: "NONE"});
    const [addPaymentCardAction, setAddPaymentCardAction] = React.useState({type: "NONE"});
    const [addCityCardAction, setAddCityCardAction] = React.useState({type: "NONE"});

    const {goNext, getData, updateData} = useStepper();

    const data = getData();
    React.useEffect(() => {
        if (data.token) {
            setAddPaymentCardAction({
                type: "ADD",
                object: {
                    token: data.token
                }
            });
            updateData({
                token: null
            });
        }
    }, [data]);

    function onSelect(item) {
        updateData({
            medium: item.medium,
            transactionInit: null
        });
        goNext();
    }

    const descriptors = React.useMemo(() => {
        let result = [];
        let mediums = config['mediums'];

        if (isMediumTypeAvailable(MEDIUM_TYPE.PHONE_QR)) {
            let qrCodes = mediums.filter(o => o.mediumType === MEDIUM_TYPE.PHONE_QR).map((o) => {
                return {
                    id: o.id,
                    name: o.normalizedName,
                    type: MEDIUM_TYPE.PHONE_QR
                }
            });

            result.push({
                type: DESCRIPTOR_TYPES.SUBLIST,
                descriptors: [{
                    type: DESCRIPTOR_TYPES.HEADER,
                    name: translations.mediumQrCode,
                    disableGutters: true
                }, ...qrCodes
                    .map((qrCodePersonalData) => {
                        return {
                            name: qrCodePersonalData.name,
                            Icon: resolveMediumIcon(MEDIUM_TYPE.PHONE_QR),
                            value: translations.mediumTypes(MEDIUM_TYPE.PHONE_QR),
                            medium: qrCodePersonalData
                        }
                    }), {
                    Text: () => <Typography color="info.main" mt={1.5} mb={1.5} fontSize="0.92rem">
                        {translations.mediumQrCodeAdd}
                    </Typography>,
                    Icon: () => <PersonAddAlt1 color="info"/>,
                    onClick: () => {
                        setAddBlikAction({
                            type: "ADD"
                        });
                    }
                }]
            });
        }
        if (isMediumTypeAvailable(MEDIUM_TYPE.CITY_CARD)) {
            let cityCards = mediums.filter(o => o.mediumType === MEDIUM_TYPE.CITY_CARD).map((o) => {
                return {
                    id: o.id,
                    name: o.normalizedName,
                    type: MEDIUM_TYPE.CITY_CARD
                }
            });

            result.push({
                type: DESCRIPTOR_TYPES.SUBLIST,
                descriptors: [
                    {
                        type: DESCRIPTOR_TYPES.HEADER,
                        name: translations.mediumCityCard,
                        disableGutters: true
                    },
                    ...cityCards
                        .map((cityCard) => {
                            return {
                                name: cityCard.name,
                                Icon: resolveMediumIcon(MEDIUM_TYPE.CITY_CARD),
                                value: translations.mediumTypes(MEDIUM_TYPE.CITY_CARD),
                                medium: cityCard
                            }
                        }), {
                        Text: () => <Typography color="info.main" mt={1.5} mb={1.5} fontSize="0.92rem">
                            {translations.mediumCityCardAdd}
                        </Typography>,
                        Icon: () => <DomainAdd color="info"/>,
                        onClick: () => {
                            setAddCityCardAction({
                                type: "ADD"
                            });
                        }
                    }]
            });
        }
        if (isMediumTypeAvailable(MEDIUM_TYPE.PAYMENT_CARD)) {
            let paymentCards = mediums.filter(o => o.mediumType === MEDIUM_TYPE.PAYMENT_CARD).map((o) => {
                return {
                    id: o.id,
                    name: o.normalizedName,
                    type: MEDIUM_TYPE.PAYMENT_CARD
                }
            });

            result.push({
                type: DESCRIPTOR_TYPES.SUBLIST,
                descriptors: [
                    {
                        type: DESCRIPTOR_TYPES.HEADER,
                        name: translations.mediumPaymentCard,
                        disableGutters: true
                    },
                    ...paymentCards
                        .map((paymentCard) => {
                            return {
                                name: paymentCard.name,
                                Icon: resolveMediumIcon(MEDIUM_TYPE.PAYMENT_CARD),
                                value: translations.mediumTypes(MEDIUM_TYPE.PAYMENT_CARD),
                                medium: paymentCard
                            }
                        }), {
                        Text: () => <Typography color="info.main" mt={1.5} mb={1.5} fontSize="0.92rem">
                            {translations.mediumPaymentCardAdd}
                        </Typography>,
                        Icon: () => <AddCard color="info"/>,
                        onClick: () => {
                            setAddPaymentCardAction({
                                type: "ADD",
                                object: {
                                    type: EnumTokenizationRequestType.PURCHASE,
                                    data: JSON.stringify({
                                        productTimeUnitType: data.productTimeUnitType,
                                        productTimeUnitTypeValue: data.productTimeUnitTypeValue,
                                        profileLoid: data.profileLoid,
                                        productCategory: data.productCategory,
                                        productLoid: data.productLoid,
                                    })
                                }
                            });
                        }
                    }]
            });
        }

        return result
            .map((o, index) => (
                index < result.length - 1 ? [o, {
                    type: DESCRIPTOR_TYPES.DIVIDER
                }] : o
            )).reduce((a, b) => a.concat(b), []);
    }, [config, translations, resolveMediumIcon]);

    return <React.Fragment>
        <CommonStep
            title={translations.mediumSelectionDesc}
            onSelect={onSelect}
            descriptors={descriptors}
        />
        <AddPaymentCardComponent
            action={addPaymentCardAction}
            onClose={() => {
                setAddPaymentCardAction({
                    type: "NONE"
                });
            }}/>
        <AddCityCardComponent action={addCityCardAction} onClose={() => setAddCityCardAction({type: "NONE"})}/>
        <AddQrProfileComponent action={addBlikAction} onClose={() => setAddBlikAction({type: "NONE"})}/>
    </React.Fragment>
}

export default Step3_MediumSelection;