import {Box} from "@mui/material";
import {styled} from "@mui/system";

const Container = styled(Box)(({theme}) => ({
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: "2 1 100%",
    flexWrap: "wrap",
    width: "100%",
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
        flexWrap: "nowrap",
        height: "100%",
        minWidth: 750,
        paddingRight: 0,
        overflow: "hidden"
    },
    [theme.breakpoints.up("lg")]: {
        minWidth: 1000
    },
}));

const FirstChildContainer = styled(Box)(({theme}) => ({
    position: "relative",
    flexBasis: "100%",
    overflow: "auto",
    paddingRight: 0,
    [theme.breakpoints.up("md")]: {
        flexBasis: "49%",
        paddingRight: theme.spacing(2),
    }
}));


const SecondChildContainer = styled(Box)(({theme}) => ({
    position: "relative",
    flexBasis: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minHeight: 500,
    [theme.breakpoints.up("md")]: {
        flexBasis: "49%",
        marginTop: 0,
        marginBottom: 0
    }
}));

const DoubleContainer = ({children}) => {
    return <Container>
        <FirstChildContainer>
            {children[0]}
        </FirstChildContainer>
        <SecondChildContainer>
            {children[1]}
        </SecondChildContainer>
    </Container>
}

export default DoubleContainer;