import {useTranslation} from "react-i18next";
import {Close, Save} from "@mui/icons-material";
import {Dialog, FormContainer, hideLoader, ResponsiveFieldsGroup, showLoader, TextField} from "@beesset/ui-components";
import React from 'react';
import {DateUtils, generateId} from "@beesset/common-utils";
import {
    DateField, MEDIUM_TYPE,
    PASSENGER_IDENTITY_TYPE,
    PeselOrDateOfBirthField,
    UPORClientRest,
    useConfig
} from "@beesset/upor-client-module-cmn";
import CardsComponent from "./CardsComponent";

const AddQrProfileComponent = ({action, onClose}) => {
    const {refresh, isPassengerIdentityType, config} = useConfig();
    const {t} = useTranslation();

    React.useEffect(() => {
        onClose();
        hideLoader();
    }, [config]);

    return <Dialog
        open={action.type === "ADD"}
        title={t("components.settings.addQRCodeProfileTitle")}
        description={t("components.settings.addQRCodeProfileDesc")}
        actions={[{
            name: t("button.close"),
            color: "inherit",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }, {
            name: t("button.save"),
            type: "submit",
            form: "addQrCodeForm",
            fullScreenProps: {
                Icon: <Save/>,
                align: "right",
            },
        }]}>
        <FormContainer
            id="addQrCodeForm"
            defaultValues={action.object}
            onSuccess={(bean) => {
                showLoader();
                UPORClientRest.assignMedium({
                    handlers: {
                        success: () => {
                            refresh();
                        },
                        failure: () => {
                            hideLoader();
                        }
                    },
                    request: {
                        medium: {
                            ...bean,
                            mediumType: MEDIUM_TYPE.PHONE_QR
                        }
                    }
                });
            }
            }>
            <ResponsiveFieldsGroup isMainContainer minWidth="unset">
                <TextField label={t("common.firstName")} name="firstName" required isStacked/>
                <TextField label={t("common.lastName")} name="lastName" required/>
                {isPassengerIdentityType(PASSENGER_IDENTITY_TYPE.DATE_OF_BIRTH) && <DateField
                    label={t("common.dateOfBirth")}
                    name="dateOfBirth"
                    required
                    openTo="year"
                    views={["year", "month", "day"]}
                    minDate={DateUtils.getNow().startOf("day").subtract(120, "year")}
                    maxDate={DateUtils.getNow().startOf("day")}
                    disableHighlightToday
                />}
                {isPassengerIdentityType(PASSENGER_IDENTITY_TYPE.PESEL) && <PeselOrDateOfBirthField/>}
                <TextField label={t("common.optionalName")} name="optionalName"/>
            </ResponsiveFieldsGroup>
        </FormContainer>
    </Dialog>
}

const QRCodesProfilesComponent = () => {
    const config = React.useMemo(() => {
        return {
            mediumType: 'PHONE_QR',
            TableComponentConfig: {
                title: "components.settings.qrCodesProfiles"
            },
            DeleteComponentConfig: {
                title: "components.settings.deleteQRCodeProfileTitle",
                description: "components.settings.deleteQRCodeProfileDesc",
            },
            EditComponentConfig: {
                title: "components.settings.editQRCodeProfileTitle",
                description: "components.settings.editQRCodeProfileDesc"
            },
            AddComponent: AddQrProfileComponent
        }
    }, []);

    return <CardsComponent {...config}/>
}

export {AddQrProfileComponent, QRCodesProfilesComponent};