import {Stack} from "@mui/material";
import React from "react";

const ResponsiveFieldsGroup = ({children, direction = "row", isMainContainer, ...props}) => {
    const config = React.useMemo(() => {
        if(isMainContainer) {
            direction = "column";
        }
        let config = {
            direction: {
                xs: "column",
                sm: direction
            },
            spacing: {
                xs: 1.5,
                sm: 2
            }
        };

        if (isMainContainer) {
            config['marginTop'] = 3;
            config["minWidth"] = {
                xs: "unset",
                sm: 500
            };
        }

        return config;
    }, [direction, isMainContainer]);

    return <Stack {...config} {...props}>
        {children}
    </Stack>
}

export default ResponsiveFieldsGroup;