import React from 'react';
import {Editor as Tiny} from '@tinymce/tinymce-react';
import './OverrideStyles.css';
import {FormContainer, TextField} from "@beesset/ui-components";
import {Save} from "@mui/icons-material";
import {Fab} from "@mui/material";

const EDITOR_CSS = `
    body {
        font-family: Roboto;
        font-size: 12pt;
    }
    table {
        border-collapse: collapse;
        border: 1px solid #ccc;
    }
    table th,
    table td {
        border: 1px solid #ccc;
        padding: 5px;
    }
    
    figure figcaption {
        color: #999;
        margin-top: 5px;
        text-align: center;
    }
    
    hr {
        border-color: #ccc;
        border-style: solid;
        border-width: 1px 0 0 0;
    }
    
    .mce-content-body:not([dir=rtl]) blockquote {
        border-left: 2px solid #ccc;
        margin-left: 1.5rem;
        padding-left: 1rem;
    }
    
    .mce-content-body[dir=rtl] blockquote {
        border-right: 2px solid #ccc;
        margin-right: 1.5rem;
        padding-right: 1rem;
    }
`

const standaloneEditorContent = (content) => {
    return `<html lang="pl"><head><title></title><style>${EDITOR_CSS}</style></head><body>${content}</body></html>`
}

const DocumentEditorPrv
        = ({
               innerRef,
               title,
               content = "",
               tokens = [],
               imageUploadUrl = "",
               documentBaseUrl = "",
               onSave
           }) => {

        const editorRef = React.useRef({});
        const formRef = React.useRef({});

        const prepareRequest = () => {
            let result = {
                content: editorRef.current['getContent'](),
                standaloneContent: standaloneEditorContent(editorRef.current['getContent']())
            }
            if (title !== undefined) {
                result.title = formRef.current['getValues']()["title"];
            }
            return result;
        }

        if (innerRef) {
            innerRef.current = prepareRequest;
        }

        return <FormContainer
            ref={formRef}
            onSuccess={(bean) => {
                if (onSave) {
                    onSave(prepareRequest());
                }
            }}
            style={{maxWidth: "unset", height: "100%"}}
        >
            {title !== undefined && <TextField
                label="Nazwa dokumentu"
                name="title"
                sx={{mb: "10px", mt: "5px"}}
                value={title}
                required
            />}
            <Tiny
                initialValue={content}
                init={{
                    init_instance_callback: (editor) => {
                        editorRef.current = editor;
                    },
                    language: "pl",
                    selector: 'textarea',
                    paste_as_text: true,
                    plugins: `preview importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link table pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap`,
                    menubar: 'file edit view insert format tools table help',
                    toolbar: `undo redo | fullscreen preview code tokens | blocks fontsize | bold italic underline strikethrough forecolor backcolor removeformat | alignleft aligncenter alignright alignjustify | indent outdent |  numlist bullist image | pagebreak | charmap | ltr rtl`,
                    toolbar_sticky: true,
                    importcss_append: true,
                    image_advtab: true,
                    images_upload_url: imageUploadUrl,
                    // images_upload_base_path: editorProps.imageUploadBasePath,
                    document_base_url: documentBaseUrl,
                    resize: false,
                    height: "100%",
                    image_caption: true,
                    noneditable_class: 'mceNonEditable',
                    noneditable_regexp: /\$\{[^\}]+\}/g,
                    toolbar_mode: 'sliding',
                    setup: (editor) => {
                        editor.ui.registry.addIcon('token', '<svg enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="<a href="http://www.w3.org/2000/svg">http://www.w3.org/2000/svg</a>"><path d="m0 0h24v24h-24z" fill="none"/><path d="m21 12-4.37 6.16c-.37.52-.98.84-1.63.84h-3v-2h3l3.55-5-3.55-5h-10v3h-2v-3c0-1.1.9-2 2-2h10c.65 0 1.26.31 1.63.84zm-11 3h-3v-3h-2v3h-3v2h3v3h2v-3h3z"/></svg>');
                        editor.ui.registry.addMenuButton("tokens", {
                            icon: "token",
                            tooltip: "Token",
                            fetch: (callback) => {
                                callback(tokens.map(({name, value}) => {
                                    return {
                                        type: "menuitem",
                                        text: name,
                                        onAction: () => {
                                            editor.insertContent(`\${${value}}`);
                                        }
                                    }
                                }));
                            }
                        });
                    },
                    content_css: 'document',
                    content_style: EDITOR_CSS
                }}
            />
            {onSave && <Fab
                sx={{
                    position: 'absolute',
                    bottom: 80,
                    right: {
                        xs: 30,
                        sm: 50
                    },
                }}
                type="submit"
                color="secondary"
            >
                <Save/>
            </Fab>}
        </FormContainer>
    }
;

const DocumentEditor = React.forwardRef(
    ({
         title,
         content = "",
         tokens = [],
         imageUploadUrl = "",
         documentBaseUrl = "",
         onSave
     }, ref) => {
        return <DocumentEditorPrv
            title={title}
            content={content}
            tokens={tokens}
            imageUploadUrl={imageUploadUrl}
            documentBaseUrl={documentBaseUrl}
            onSave={onSave}
            innerRef={ref}
        />;
    }
);

export default DocumentEditor;
