import {Button, Input, InputAdornment, Menu, MenuItem, OutlinedInput, Stack, Typography} from "@mui/material";
import {useTranslation} from 'react-i18next';
import {DatePicker, DateTimePicker, TimePicker} from "@mui/x-date-pickers";
import Today from "@mui/icons-material/Today";
import React from "react";
import {PickersActionBar} from '@mui/x-date-pickers/PickersActionBar';
import {FocusTrap} from '@mui/base';
import isEqual from "react-fast-compare";
import {DateUtils} from "@beesset/common-utils";

const CustomActionBar = ({onComparisonTypeChange, description, comparisonType, options, ...props}) => {
    const value = options.find((option) => option.id === comparisonType)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Stack sx={{p: 2, pt: 0, fontSize: "typography.body1"}} direction="row" alignItems="center" spacing={0.5}>
                <Typography variant={"body2"} fontSize="inherit" color="text.secondary">
                    {description}
                </Typography>
                <Button
                    disableRipple
                    sx={{
                        fontSize: "inherit",
                        textTransform: "lowercase",
                        lineHeight: 1
                    }}
                    color={"inherit"}
                    size="small"
                    disableFocusRipple={true}
                    id={`picker-actions-xxx`}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                    {value.name}
                </Button>
            </Stack>
            <FocusTrap open={open}>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {options.map((option) => {
                        return <MenuItem
                            key={`ComparisonType-${option.id}`}
                            selected={value.id === option.id}
                            onClick={() => {
                                onComparisonTypeChange(option.id);
                                handleClose();
                            }}>{option.name}</MenuItem>
                    })}
                </Menu>
            </FocusTrap>
            <PickersActionBar {...props}/>
        </React.Fragment>
    );
}

const CommonFilter = ({
                          PickerComponent,
                          PickerComponentProps: {formatter, valueFormatter},
                          column: {filterValue, preFilteredRows, setFilter, type, Header},
                          size
                      }) => {
    const {t} = useTranslation();
    const {options, description} = React.useMemo(() => {
        return {
            options: [{
                id: "less"
            }, {
                id: "lessEqual"
            }, {
                id: "equal"
            }, {
                id: "moreEqual"
            }, {
                id: "more"
            }].map((option) => {
                return {
                    ...option,
                    name: t(`commonComponents.filter.dateComparison.${type}.values.${option.id}`)
                };
            }),
            description: t(`commonComponents.filter.dateComparison.${type}.desc`)
        }
    }, [t]);

    const [value, setValue] = React.useState(filterValue || {
        value: null,
        comparisonType: options[3].id
    });
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (open === false) {
            if (!isEqual(filterValue, value)) {
                setFilter(value.value ? {
                    ...value
                } : undefined);
            }
        }
    }, [open]);

    React.useEffect(() => {
        setValue(filterValue || {
            value: null,
            comparisonType: options[3].id
        });
    }, [filterValue]);

    function getStartAdornment() {
        if (filterValue && filterValue.value) {
            let sign;
            switch (filterValue.comparisonType) {
                case "less": {
                    sign = "<";
                    break;
                }
                case "lessEqual": {
                    sign = "<=";
                    break;
                }
                case "equal": {
                    sign = "=";
                    break;
                }
                case "moreEqual": {
                    sign = ">=";
                    break;
                }
                case "more": {
                    sign = ">";
                    break;
                }
            }
            return <InputAdornment position="start">{sign}</InputAdornment>;
        }
    }

    return (
        <PickerComponent
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            desktopModeMediaQuery={`@media screen and (min-width: 1400px)`}
            inputProps={{
                "aria-label": `${t("commonComponents.filter.select")}: ${Header}`
            }}
            value={value.value}
            onChange={(event) => {
                let date = event ? valueFormatter(event) : null;
                setValue((old) => {
                    return {
                        ...old,
                        value: date
                    }
                });
            }}
            onAccept={(event) => {
                let date = event ? valueFormatter(event) : null;
                setValue((old) => {
                    return {
                        ...old,
                        value: date
                    }
                });
            }}
            OpenPickerButtonProps={{
                size: "small"
            }}
            components={{
                OpenPickerIcon: () => <Today fontSize={"small"}/>,
                ActionBar: (props) => <CustomActionBar
                    description={description}
                    options={options}
                    comparisonType={value.comparisonType}
                    onComparisonTypeChange={(comparisonType) => {
                        setValue((old) => {
                            return {
                                ...old,
                                comparisonType
                            }
                        })
                    }
                    }
                    {...props}
                />
            }}
            componentsProps={{
                actionBar: {
                    actions: filterValue ? ["clear", "accept"] : ["accept"]
                }
            }}
            closeOnSelect={false}
            renderInput={({inputRef, inputProps, InputProps}) => (
                <OutlinedInput
                    ref={inputRef}
                    inputProps={{
                        ...inputProps,
                        placeholder: t("commonComponents.filter.select"),
                        sx: {
                            fontSize: "0.9rem"
                        }
                    }}
                    size="small"
                    value={filterValue && filterValue.value ? formatter(filterValue.value) : ''}
                    readOnly
                    startAdornment={getStartAdornment()}
                    endAdornment={InputProps.endAdornment}
                    onClick={(e) => setOpen(true)}
                    onKeyDown={(e) => e.preventDefault()}
                />
            )}
        />
    )
}

const DateColumnFilter = (props) => {
    const type = props.column.type;

    return <React.Fragment>
        {type === "date" && <CommonFilter
            PickerComponent={DatePicker}
            PickerComponentProps={{
                formatter: DateUtils.formatters.DDMMYYYY,
                valueFormatter: (event) => {
                    return event.format("YYYY-MM-DD");
                }
            }}
            {...props}
        />}
        {
            type === "datetime" && <CommonFilter
                PickerComponent={DateTimePicker}
                PickerComponentProps={{
                    formatter: DateUtils.formatters.DDMMYYYY_HHMMSS,
                    valueFormatter: (event) => {
                        return event.format("YYYY-MM-DDTHH:mm:ss");
                    }
                }}
                {...props}
            />
        }
        {
            type === "time" && <CommonFilter
                PickerComponent={TimePicker}
                PickerComponentProps={{
                    formatter: DateUtils.formatters.HH_MM_SS,
                    valueFormatter: (event) => {
                        return event.format("YYYY-MM-DDTHH:mm:ss");
                    }
                }}
                {...props}
            />
        }
    </React.Fragment>
}

export default DateColumnFilter;
