import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";
import {SnackbarProvider as NotiSnackbarProvider, useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

let useSnackbarRef;
let translateError;

function SnackbarCloseButton({keyValue}) {
    const {t} = useTranslation();

    return (
        <IconButton
            disableRipple
            aria-label={t("button.close")}
            size="small"
            onClick={() => useSnackbarRef.closeSnackbar(keyValue)}
        >
            <CloseIcon
                sx={{
                    color: "common.white",
                }}
            />
        </IconButton>
    );
}


const SnackbarProvider = ({children}) => {

    return (
        <NotiSnackbarProvider
            maxSnack={5}
            anchorOrigin={{horizontal: "right", vertical: "bottom"}}
            preventDuplicate={false}
            action={(key) => <SnackbarCloseButton keyValue={key}/>}
        >
            <SnackbarConfigurator>{children}</SnackbarConfigurator>
        </NotiSnackbarProvider>
    );
};

const SnackbarConfigurator = ({children}) => {
    const {t, i18n} = useTranslation();
    useSnackbarRef = useSnackbar();
    translateError = (error) => {
        if (error && error.exceptionId) {
            let params = {};
            if(error.params) {
                Object.keys(error.params).forEach((key) => {
                    if (typeof error.params[key] === 'string' || error.params[key] instanceof String) {
                        params[key] = t(error.params[key])
                    }
                });
            }
            if(i18n.exists(`commonException.${error.exceptionId}`)) {
                return t(`commonException.${error.exceptionId}`, params);
            }
            else if(i18n.exists(`exception.${error.exceptionId}`)) {
                return t(`exception.${error.exceptionId}`, params);
            }
            else {
                return t("commonException.UNEXPECTED");
            }
        }
        return error || t("commonException.UNEXPECTED");
    }
    return children;
};

export {useSnackbarRef, translateError};
export default SnackbarProvider;
