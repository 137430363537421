import languageEN from "./locate/en/translations.json";
import languagePL from "./locate/pl/translations.json";
import languageUK from "./locate/uk/translations.json";
import languageDE from "./locate/de/translations.json";

const i18nResources = {
    en: languageEN,
    pl: languagePL,
    uk: languageUK,
    de: languageDE
};

export {i18nResources};