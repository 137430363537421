import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

const MultiAutocompleteField = ({
                                    validation = {},
                                    parseError,
                                    name,
                                    value,
                                    isOptionEqualToValue,
                                    getOptionLabel,
                                    onSelected,
                                    onChange,
                                    groupBy = null,
                                    refreshMode = false,
                                    sx = {},
                                    optionsProvider: {options = [], fetchOptions, convertOptions},
                                    isStacked,
                                    ...rest
                                }) => {
    const {control, validationMessages} = useFormContext();

    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({
        options: fetchOptions ? [] : options,
        isLoaded: fetchOptions ? false : true,
    });
    const loading = open && data.isLoaded == false;

    if (!isOptionEqualToValue || !getOptionLabel) {
        alert("Autocomplete isOptionEqualToValue and getOptionLabel is required.");
        return;
    }

    if (rest.required) {
        validation.required = validationMessages.required;
    }
    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        console.log("fetch1");
        fetchOptions({
            handlers: {
                success: (response) => {
                    if (active) {
                        setData({
                            options: convertOptions ? convertOptions(response) : response,
                            isLoaded: true,
                        });
                    }
                },
                failure: () => {
                    if (active) {
                        setData({
                            options: [],
                            isLoaded: true,
                        });
                    }
                },
            },
        });

        return () => {
            active = false;
        };
    }, [loading]);


    React.useEffect(() => {
        let active = true;

        if (refreshMode) {
            console.log("fetch2 - refresh mode");
            setOpen(false);
            fetchOptions({
                handlers: {
                    success: (response) => {
                        if (active) {
                            setData({
                                options: convertOptions ? convertOptions(response) : response,
                                isLoaded: true,
                            });
                        }
                    },
                    failure: () => {
                        if (active) {
                            setData({
                                options: [],
                                isLoaded: true,
                            });
                        }
                    },
                },
            });
        }

        return () => {
            active = false;
        };
    }, [fetchOptions]);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
    const checkedIcon = <CheckBoxIcon fontSize="small"/>;

    if (isStacked) {
        sx['width'] = "100%";
        sx['margin'] = "0px";
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={validation}
            defaultValue={value || []}
            render={({
                         field: {onChange, ...fieldRest},
                         fieldState: {invalid, error},
                     }) => {
                return (
                    <Autocomplete
                        {...fieldRest}
                        multiple
                        groupBy={groupBy}
                        disableCloseOnSelect
                        sx={{marginTop: "10px", ...sx}}
                        onChange={(event, option) => {
                            onChange(option);
                            if (onSelected) {
                                onSelected(option);
                            }
                        }}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        isOptionEqualToValue={isOptionEqualToValue}
                        getOptionLabel={getOptionLabel}
                        options={data.options}
                        loading={loading}
                        renderOption={(props, option, {selected}) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                                {getOptionLabel(option)}
                            </li>
                        )}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    {...rest}
                                    error={invalid}
                                    helperText={
                                        error
                                            ? typeof parseError === "function"
                                                ? parseError(error)
                                                : error.message
                                            : rest.helperText
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? (
                                                    <CircularProgress color="inherit" size={20}/>
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            );
                        }}
                    />
                );
            }}
        />
    );
};

export default MultiAutocompleteField;
