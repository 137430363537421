import React from "react";

const AccessProviderContext = React.createContext({});

export default function AccessProvider({ rights = [], children }) {
  const values = React.useMemo(() => {
    return {
      hasAccess: (accessRights) => {
        if(!accessRights || accessRights.length == 0) {
          return true;
        }

        if (Array.isArray(accessRights)) {
          return (
            accessRights.findIndex(
              (name) => rights.findIndex((r) => r.name === name) >= 0
            ) >= 0
          );
        }
        return rights.findIndex((r) => r.name === accessRights) >= 0;
      },
    };
  }, [rights]);

  return (
    <AccessProviderContext.Provider value={values}>
      {children}
    </AccessProviderContext.Provider>
  );
}

export function useAccess() {
  let result = {
    hasAccess: () => {},
  };
  result = React.useContext(AccessProviderContext);
  return result;
}
