import {Backdrop, CircularProgress} from "@mui/material";
import React from "react";

const LoaderRef = React.createRef();
export default function RefinedLoader() {
    const [loading, setLoading] = React.useState(false);
    const loadingTimeoutRef = React.useRef(null);

    LoaderRef.current = {
        show: (timeout = 700) => {
            if (loadingTimeoutRef.current == null && loading === false) {
                loadingTimeoutRef.current = setTimeout(() => {
                    setLoading(true);
                }, timeout);
            }
        },
        hide: () => {
            if (loadingTimeoutRef.current != null) {
                clearTimeout(loadingTimeoutRef.current);
                loadingTimeoutRef.current = null;
            }
            setLoading(false);
        },
    };

    return (
        <Backdrop
            sx={{
                color: "common.white",
                zIndex: (theme) => theme.zIndex.snackbar - 1,
            }}
            open={loading}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
}

export function showLoader(timeout) {
    if (LoaderRef.current == null) {
        throw "Create loader in root container!";
    }
    LoaderRef.current.show(timeout);
}

export function hideLoader() {
    if (LoaderRef.current == null) {
        throw "Create loader in root container!";
    }
    LoaderRef.current.hide();
}
