import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import CssBaseline from "@mui/material/CssBaseline";
import {createTheme, responsiveFontSizes, ThemeProvider as MuiThemeProvider,} from "@mui/material/styles";
import {deDE as dateLocaleDeDE, LocalizationProvider, plPL as dateLocalePlPL} from "@mui/x-date-pickers";
import React from "react";
import {useTranslation} from "react-i18next";
import {useConfig} from "@beesset/upor-client-module-cmn";
import {deDE as coreLocaleDeDE, plPL as coreLocalePlPL, ukUA as coreLocaleUkUA} from '@mui/material/locale';


const ThemeProvider = ({children, ThemeProps}) => {
    const {config, getLanguage} = useConfig();

    const {t} = useTranslation();

    const theme = React.useMemo(() => {
        let localeLang = getLanguage();

        const scrollbarOptions = {
            track: "#e0e0e0",
            thumb: "#959595",
            active: "#b5b5b5",
        };

        let components = {
            MuiCssBaseline: {
                styleOverrides: {
                    html: {
                        fontSize: `${config.accessibility.fontSize}%`
                    },
                    body: {
                        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                            backgroundColor: scrollbarOptions.track,
                            width: 10,
                            height: 10,
                            borderRadius: 6,
                        },
                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                            borderRadius: 5,
                            backgroundColor: scrollbarOptions.thumb,
                            minHeight: 10,
                            border: `2px solid ${scrollbarOptions.track}`,
                        },
                        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                            backgroundColor: scrollbarOptions.active,
                        },
                        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                            backgroundColor: scrollbarOptions.active,
                        },
                        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: scrollbarOptions.active,
                        },
                    },
                    input: {
                        ":placeholder-shown": {
                            textOverflow: "ellipsis"
                        }
                    }
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: "0.8rem",
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        minWidth: 40
                    }
                }
            }
        };

        switch (localeLang) {
            case "pl":
                components = {
                    ...components,
                    ...coreLocalePlPL.components,
                    ...dateLocalePlPL.components
                }
                break;
            case "de":
                components = {
                    ...components,
                    ...coreLocaleDeDE.components,
                    ...dateLocaleDeDE.components,
                }
                break;
            case "uk":
                components = {
                    ...components,
                    ...coreLocaleUkUA.components,
                }
                break;
            default:
        }

        if (!components.MuiTablePagination) {
            components.MuiTablePagination = {
                defaultProps: {}
            };
        }

        if (components.MuiLocalizationProvider) {
            components.MuiLocalizationProvider.defaultProps.localeText.datePickerToolbarTitle = t("commonComponents.datePicker.datePickerToolbarTitle");
            components.MuiLocalizationProvider.defaultProps.localeText.dateTimePickerToolbarTitle = t("commonComponents.datePicker.dateTimePickerToolbarTitle");
            components.MuiLocalizationProvider.defaultProps.localeText.timePickerToolbarTitle = t("commonComponents.datePicker.timePickerToolbarTitle");
            components.MuiLocalizationProvider.defaultProps.localeText.dateRangePickerToolbarTitle = t("commonComponents.datePicker.dateRangePickerToolbarTitle");
            components.MuiDatePicker = {
                defaultProps: {
                    toolbarTitle: components.MuiLocalizationProvider.defaultProps.localeText.datePickerToolbarTitle
                }
            }
            components.MuiDateTimePicker = {
                defaultProps: {
                    toolbarTitle: components.MuiLocalizationProvider.defaultProps.localeText.dateTimePickerToolbarTitle
                }
            }
            components.MuiTimePicker = {
                defaultProps: {
                    toolbarTitle: components.MuiLocalizationProvider.defaultProps.localeText.timePickerToolbarTitle
                }
            }
        }

        components.MuiTablePagination.defaultProps.showFirstButton = true;
        components.MuiTablePagination.defaultProps.showLastButton = true;

        let palette =
            config.accessibility.highContrast
                ? {
                    mode: "dark",
                    background: {
                        main: "rgb(35,35,35)"
                    },
                    error: {
                        main: "#ffff00"
                    },
                    warning: {
                        main: "#ffff00"
                    },
                    info: {
                        main: "#BBE6F7"
                    },
                    success: {
                        main: "#BBE6F7"
                    },
                    text: {
                        primary: "rgb(255,255,255)",
                        secondary: "rgb(255,255,153)"
                    }
                } : {
                    mode: "light",
                    primary: {
                        main: ThemeProps.primary,
                    },
                    secondary: {
                        main: ThemeProps.secondary,
                    },
                    background: {
                        main: "whitesmoke"
                    }
                };

        let theme = createTheme({
            palette,
            components
        });

        theme = responsiveFontSizes(theme);
        return theme;
    }, [t, config.accessibility.highContrast, config.accessibility.fontSize]);

    return (
        <React.Fragment>
            <MuiThemeProvider theme={theme}>
                <CssBaseline enableColorScheme={true}/>
                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={getLanguage()}>
                    {children}
                </LocalizationProvider>
            </MuiThemeProvider>
        </React.Fragment>
    );
};

export default ThemeProvider;
