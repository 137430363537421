import {DatePeriodField, UPORClientRest, UPORUtils, useConfig} from "@beesset/upor-client-module-cmn";
import {useTranslation} from "react-i18next";
import React from "react";
import {Stack} from "@mui/material";
import {SelectField} from "@beesset/ui-components";

const FilterFields = () => {
    const {config, getLanguage} = useConfig();
    const {t} = useTranslation();

    return <Stack spacing={1}>
        {!UPORUtils.isNativePlatform() && <React.Fragment>
            <SelectField
                name="channel"
                label={t("components.history.channel")}
                getOptionLabel={(option) => {
                    return option.name;
                }}
                getOptionId={(option) => {
                    return option.loid;
                }}
                optionsProvider={{
                    fetchOptions: UPORClientRest.getSellChannels,
                    convertOptions: response => response['channels'],
                    fetchOptionsRequest: {
                        language: getLanguage()
                    }
                }}
                emptyOption={true}
            />
            <SelectField
                name="medium"
                label={t("components.history.medium")}
                getOptionLabel={(option) => {
                    return option['normalizedName']
                }}
                getOptionId={(option) => {
                    return option.id;
                }}
                optionsProvider={{
                    options: config['mediums']
                }}
                groupBy={(option) => {
                    return t(`common.mediumTypes.${option.mediumType}`)
                }
                }
                emptyOption={true}
            />
        </React.Fragment>}
        <DatePeriodField
            startDateName="startDate"
            startDateLabel={t("common.startDate")}
            endDateName="endDate"
            endDateLabel={t("common.endDate")}
        />
    </Stack>
}

export default FilterFields;