import { useSnackbarRef, translateError } from "./SnackbarProvider";

const Snackbar = {
  success(msg, props) {
    this.toast(msg, "success", props);
  },
  warning(msg, props) {
    this.toast(msg, "warning", props);
  },
  info(msg, props) {
    this.toast(msg, "info", props);
  },
  error(msg, props) {
    this.toast(translateError(msg), "error", props);
  },
  toast(msg, variant = "default", props) {
    useSnackbarRef.enqueueSnackbar(msg, { variant, ...props });
  },
};
export default Snackbar;
