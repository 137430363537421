import languageEN from "./en/translations.json";
import languagePL from "./pl/translations.json";
import languageUK from "./uk/translations.json";
import languageDE from "./de/translations.json";

const resources = {
    en: languageEN,
    pl: languagePL,
    uk: languageUK,
    de: languageDE
};

export  default resources;