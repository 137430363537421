import React from "react";
import {Stack} from "@mui/material";
import {styled} from "@mui/material/styles";
import {PaymentCardsComponent} from "./PaymentCards";
import {QRCodesProfilesComponent} from "./QRCodesProfiles";
import {ProfileInfoComponent} from "./ProfileInfo";
import {CityCardsComponent} from "./CityCards";
import {MEDIUM_TYPE, useConfig} from "@beesset/upor-client-module-cmn";

const SettingsViewWrapper = styled(Stack)(({theme}) => {
    return {
        '& .MuiTableContainer-root': {
            "height": "unset",
            backgroundColor: theme.palette.background.paper,
            '& #not-data-info': {
                display: "none"
            }
        }
    }
});
const SettingsComponent = () => {
    const {isMediumTypeAvailable} = useConfig();

    return <SettingsViewWrapper spacing={3}>
        <ProfileInfoComponent/>
        {isMediumTypeAvailable(MEDIUM_TYPE.PAYMENT_CARD) && <PaymentCardsComponent/>}
        {isMediumTypeAvailable(MEDIUM_TYPE.CITY_CARD) && <CityCardsComponent/>}
        {isMediumTypeAvailable(MEDIUM_TYPE.PHONE_QR) && <QRCodesProfilesComponent/>}
    </SettingsViewWrapper>
}

export {
    SettingsComponent
};