import {useTranslation} from "react-i18next";
import {Stack, Typography} from "@mui/material";


const Page1 = () => {
    const {t} = useTranslation();

    return <Stack direction="column" spacing={2} textAlign="center" maxWidth={{
        sm: 400,
        md: 500
    }}>
        <Typography variant="h6">
            {t("components.help.help.contact.title")}
        </Typography>
        <Typography variant="body1">
            {t("components.help.help.contact.description")}
        </Typography>
    </Stack>
}

export default Page1;