import {DateUtils, getLanguage} from "@beesset/common-utils";
import HttpClientProvider from "./HttpClientProvider";
import {UPORUtils} from "@beesset/upor-client-module-cmn";
import {Capacitor} from "@capacitor/core";

/*
*
* API
*
* */
function login({request, handlers}) {
    if (UPORUtils.isNativePlatform()) {
        request.platform = Capacitor.getPlatform();
    }

    HttpClientProvider.invokePost({
        uri: "unprotected/login",
        request,
        handlers,
        showSnackbar: false
    });
}

function register({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "unprotected/register",
        handlers,
        request
    });
}

function verifyAccount({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "unprotected/verifyAccount",
        handlers,
        request
    });
}

function recoverPassword({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "unprotected/recoverPassword",
        handlers,
        request
    });
}

function verifyPasswordRecoveryToken({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "unprotected/verifyPasswordRecoveryToken",
        handlers,
        request
    });
}

function changePasswordWithToken({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "unprotected/changePassword",
        handlers,
        request
    });
}

function declarationOfAccessibility({handlers, request}) {
    HttpClientProvider.invokeGet({
        uri: "unprotected/declarationOfAccessibility",
        handlers,
        request,
        showSnackbar: false
    });
}

function regulation({handlers, request}) {
    HttpClientProvider.invokeGet({
        uri: "unprotected/regulation",
        handlers,
        request,
        showSnackbar: false
    });
}

function privacyPolicy({handlers, request}) {
    HttpClientProvider.invokeGet({
        uri: "unprotected/privacyPolicy",
        handlers,
        request,
        showSnackbar: false
    });
}

function getTariff({handlers, request}) {
    HttpClientProvider.invokeGet({
        uri: "unprotected/getTariff",
        handlers,
        request
    });
}

function getVehicles({handlers}) {
    HttpClientProvider.invokeGet({
        uri: "protected/getVehicles",
        handlers
    });
}

function getConfig({handlers}) {
    HttpClientProvider.invokeGet({
        uri: "unprotected/getConfig",
        handlers,
        request: {
            clientTime: DateUtils.getNow().format("YYYY-MM-DD[T]HH:mm:ss"),
        },
        showSnackbar: false
    });
}

function getAccountConfig({handlers}) {
    HttpClientProvider.invokeGet({
        uri: "protected/getAccountConfig",
        handlers,
        request: {
            language: getLanguage(),
            clientTime: DateUtils.getNow().format("YYYY-MM-DD[T]HH:mm:ss"),
        },
        showSnackbar: false
    });
}

function assignMedium({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/assignMedium",
        handlers,
        request
    });
}

function registerTokenization({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/registerTokenization",
        handlers,
        request
    });
}

function confirmTokenization({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/confirmTokenization",
        handlers,
        request,
        showSnackbar: false
    });
}

function unassignMedium({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/unassignMedium",
        handlers,
        request
    });
}

function changePassword({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/changePassword",
        handlers,
        request
    });
}

function createTransaction({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/createTransaction",
        handlers,
        request,
        showSnackbar: false
    });
}

function initTransaction({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/initTransaction",
        handlers,
        request,
        showSnackbar: false
    });
}

function getTransactions({handlers, request, showSnackbar = true}) {
    HttpClientProvider.invokePost({
        uri: "protected/getTransactions",
        handlers,
        request,
        showSnackbar
    });
}

function createRefund({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/createRefund",
        handlers,
        request
    });
}

function getApplicationTransactions({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/getApplicationTransactions",
        handlers,
        request
    });
}

function getApplicationChannels({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/getApplicationChannels",
        handlers,
        request
    });
}

function getAccountPassengers({handlers}) {
    HttpClientProvider.invokeGet({
        uri: "protected/getAccountPassengers",
        handlers
    });
}

function registerApplication({handlers, formData}) {
    HttpClientProvider.invokePostFormData({
        uri: "unprotected/registerApplication",
        handlers,
        formData
    });
}

function removeNotification({handlers, request}) {
    HttpClientProvider.invokePost({
        uri: "protected/removeNotification",
        handlers,
        request
    });
}


function getTransactionConfirmation({request}) {
    HttpClientProvider.downloadFile({
        uri: "protected/getTransactionConfirmation",
        request
    });
}

function getSellChannels({handlers, request}) {
    HttpClientProvider.invokeGet({
        uri: "protected/getSellChannels",
        handlers,
        request
    });
}

function logout() {
    HttpClientProvider.invokeGet({
        uri: "protected/logout",
        showSnackbar: false
    });
}

function getArticlesIntros({handlers, request}) {
    HttpClientProvider.invokeGet({
        uri: "unprotected/getArticlesIntros",
        handlers,
        request
    });
}

function getArticle({handlers, request}) {
    HttpClientProvider.invokeGet({
        uri: "unprotected/getArticle",
        handlers,
        request
    });
}

function downloadAttachment({request}) {
    HttpClientProvider.downloadFile({
        uri: `unprotected/articles/attachments/${request.normalizedName}`
    });
}

function validateSession({handlers, request, timeout}) {
    HttpClientProvider.invokeGet({
        uri: "unprotected/validateSession",
        handlers,
        request,
        showSnackbar: false,
        timeout
    });
}

function getSliderImages({handlers, request}) {
    HttpClientProvider.invokeGet({
        uri: "unprotected/getSliderImages",
        handlers,
        request
    });
}

export default {
    login,
    register,
    recoverPassword,
    verifyAccount,
    verifyPasswordRecoveryToken,
    changePassword,
    changePasswordWithToken,
    declarationOfAccessibility,
    regulation,
    privacyPolicy,
    unassignMedium,
    assignMedium,
    registerTokenization,
    confirmTokenization,
    getConfig,
    getAccountConfig,
    getTariff,
    getVehicles,
    createTransaction,
    initTransaction,
    getTransactions,
    createRefund,
    logout,
    getApplicationChannels,
    getApplicationTransactions,
    getAccountPassengers,
    registerApplication,
    getTransactionConfirmation,
    removeNotification,
    getSellChannels,
    getArticlesIntros,
    getArticle,
    downloadAttachment,
    validateSession,
    getSliderImages
};
