import {useTranslation} from "react-i18next";
import {DateUtils, generateId} from "@beesset/common-utils";
import {Box, Divider, IconButton, Stack, Tooltip, Typography, useMediaQuery} from "@mui/material";
import {Add, Close, Delete, Edit, Numbers, Person, Save} from "@mui/icons-material";
import {
    Dialog,
    FormContainer,
    hideLoader,
    List,
    ResponsiveFieldsGroup,
    showLoader,
    Table,
    TextField
} from "@beesset/ui-components";
import {PASSENGER_IDENTITY_TYPE, UPORClientRest, useConfig} from "@beesset/upor-client-module-cmn";
import React from "react";
import {useLocation} from "react-router-dom";
import {useTheme} from "@mui/system";

const DeleteDialog = ({action, title, description, onClose}) => {
    const {t} = useTranslation();
    const {refresh, config} = useConfig();

    React.useEffect(() => {
        onClose();
        hideLoader();
    }, [config]);

    return <Dialog
        open={action.type === "DELETE"}
        title={t(title)}
        description={t(description)}
        disableFullScreen
        actions={[{
            name: t("button.close"),
            color: "inherit",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }, {
            name: t("button.delete"),
            color: "error",
            onClick: () => {
                showLoader();
                UPORClientRest.unassignMedium({
                    handlers: {
                        success: () => {
                            refresh();
                        },
                        failure: () => {
                            hideLoader();
                        }
                    },
                    request: {
                        mediumId: action.object.id
                    }
                });
            },
            fullScreenProps: {
                align: "right",
            }
        }]}>
    </Dialog>
}

const EditDialog = ({action, title, description, onClose, mediumType}) => {
    const {t} = useTranslation();
    const {refresh, config} = useConfig();

    React.useEffect(() => {
        onClose();
        hideLoader();
    }, [config]);

    return <Dialog
        open={action.type === "EDIT"}
        title={t(title)}
        description={t(description)}
        actions={[{
            name: t("button.close"),
            color: "inherit",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }, {
            name: t("button.save"),
            type: "submit",
            form: "editCardForm",
            fullScreenProps: {
                Icon: <Save/>,
                align: "right",
            },
        }]}>
        <FormContainer
            defaultValues={action.object}
            id="editCardForm"
            onSuccess={(bean) => {
                showLoader();
                UPORClientRest.assignMedium({
                    handlers: {
                        success: () => {
                            refresh();
                        },
                        failure: () => {
                            hideLoader();
                        }
                    },
                    request: {
                        medium: {
                            ...bean,
                            mediumType: mediumType
                        }
                    }
                });
            }
            }>
            <ResponsiveFieldsGroup isMainContainer minWidth="unset">
                <TextField label={t("common.optionalName")} name="optionalName" isStacked/>
            </ResponsiveFieldsGroup>
        </FormContainer>
    </Dialog>
}

const WebTable = ({translations, TableComponentConfig, setAction, data, onAdd, title}) => {
    const {isPassengerIdentityType} = useConfig();

    const tableConfig = React.useMemo(() => {
        let columns = [];
        if (TableComponentConfig.firstColumn) {
            columns.push(TableComponentConfig.firstColumn);
        }
        columns.push({
            Header: translations.personalData,
            accessor: props => `${props.firstName} ${props.lastName}`,
            headerStyle: {
                width: TableComponentConfig.firstColumn?200:450,
                maxWidth: 800
            },
            Cell: ({row, value}) => {
                return <Stack>
                    <Typography fontSize="inherit" fontWeight="inherit">
                        {value}
                    </Typography>
                    <Typography fontSize="0.8rem" fontWeight="inherit" color="text.secondary">
                        {row.original.pesel && isPassengerIdentityType(PASSENGER_IDENTITY_TYPE.PESEL)
                            ? `${translations.pesel}: ${row.original.pesel}`
                            : `${translations.dateOfBirth}: ${DateUtils.formatters.DDMONTHYY(row.original.dateOfBirth)}`}
                    </Typography>
                </Stack>
            }
        }, {
            Header: translations.optionalName,
            accessor: "optionalName",
            headerStyle: {
                width: 200
            },
        }, {
            Header: translations.savedOn,
            Cell: ({value}) => DateUtils.formatters.FULL(value),
            type: "datetime",
            accessor: "savedOn",
            headerStyle: {
                width: 200
            },
        }, {
            Header: () => {
                return <Stack direction="row" justifyContent="flex-end" width="100%">
                    <Tooltip title={translations.add}>
                        <IconButton
                            tabIndex={1}
                            size="small"
                            color="primary"
                            aria-label={translations.add}
                            onClick={(e) => {
                                setAction({
                                    type: "ADD",
                                    object: onAdd ? onAdd() : {}
                                });
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                        >
                            <Add/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            },
            headerStyle: {
                width: 100
            },
            id: "actions",
            Cell: ({row}) => {
                return <Stack direction="row" justifyContent="flex-end">
                    <Tooltip title={translations.edit}>
                        <IconButton
                            tabIndex={1}
                            size="small"
                            color="primary"
                            aria-label={translations.edit}
                            onClick={(e) => {
                                setAction({
                                    type: "EDIT",
                                    object: row.original
                                });
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                        >
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translations.delete}>
                        <IconButton
                            tabIndex={1}
                            size="small"
                            color="primary"
                            aria-label={translations.delete}
                            onClick={(e) => {
                                setAction({
                                    type: "DELETE",
                                    object: row.original
                                });
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                        >
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            }
        });


        return {
            columns: columns,
            pagination: true,
            pageSize: 5,
            columnFiltering: false,
            sorting: false
        }
    }, [translations, TableComponentConfig]);

    return <React.Fragment>
        <Box sx={{
            paddingLeft: {
                xs: 0,
                md: 3
            },
            paddingRight: {
                xs: 0,
                md: 3
            }
        }}>
            <Divider textAlign="left" component="div" role="presentation">{title}</Divider>
        </Box>
        <Box bgcolor={"background.paper"} pl={3} pr={3}>
            <Table {...tableConfig} data={data}/>
        </Box>
    </React.Fragment>
}

const NativeTableDescriptor = ({translations, bean, TableComponentConfig}) => {
    const _380 = useMediaQuery('(max-width:380px)');
    const _600 = useMediaQuery('(min-width:400px)');
    const _800 = useMediaQuery('(max-width:800px)');

    const {isPassengerIdentityType} = useConfig();

    const descriptors = React.useMemo(() => {
        let result = [];

        let props = {
            TextProps: {
                primaryTypographyProps: {
                    fontSize: "0.85rem",
                    noWrap: true
                },
                secondaryTypographyProps: {
                    fontSize: "0.75rem",
                    lineHeight: 1.2,
                    noWrap: true
                }
            }
        }
        if (TableComponentConfig.firstColumn) {
            result.push({
                Icon: <Numbers/>,
                name: TableComponentConfig.firstColumn.name,
                value: TableComponentConfig.firstColumn.value(bean),
                ...props
            });
        }

        result.push({
            name: translations.personalData,
            Icon: <Person/>,
            value: [
                `${bean.firstName} ${bean.lastName}`,
                <br/>,
                isPassengerIdentityType(PASSENGER_IDENTITY_TYPE.PESEL) && bean.pesel
                    ? bean.pesel
                    : DateUtils.formatters.DDMONTHYY(bean.dateOfBirth)
            ],
            ...props,
            sx: {
                whiteSpace: "pre-line"
            }
        });

        result.push({
            name: translations.optionalName,
            value: bean.optionalName,
            ...props,
            sx: {
                ml: "40px"
            }
        });

        return result;
    }, [bean]);

    function sx() {
        if(_600) {
            return {
                maxWidth: 250
            }
        }
        if(_380 || _800) {
            return {
                maxWidth: 180
            }
        }
    }

    return <List
        sx={sx()}
        descriptors={descriptors}
    />
}

const NativeTable = ({translations, TableComponentConfig, setAction, data, onAdd, title}) => {
    const tableConfig = React.useMemo(() => {
        let columns = [];

        columns.push({
            Header: title,
            id: "data",
            Cell: ({row}) => {
                let bean = row.original;

                return <NativeTableDescriptor
                    translations={translations}
                    bean={bean}
                    TableComponentConfig={TableComponentConfig}
                />
            }
        })

        columns.push({
            Header: () => {
                return <Stack direction="row" justifyContent="flex-end" width="100%">
                    <Tooltip title={translations.add}>
                        <IconButton
                            tabIndex={1}
                            size="small"
                            color="primary"
                            aria-label={translations.add}
                            onClick={(e) => {
                                setAction({
                                    type: "ADD",
                                    object: onAdd ? onAdd() : {}
                                });
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                        >
                            <Add/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            },
            id: "actions",
            Cell: ({row}) => {
                return <Stack direction="row" justifyContent="flex-end">
                    <Tooltip title={translations.edit}>
                        <IconButton
                            tabIndex={1}
                            size="small"
                            color="primary"
                            aria-label={translations.edit}
                            onClick={(e) => {
                                setAction({
                                    type: "EDIT",
                                    object: row.original
                                });
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                        >
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={translations.delete}>
                        <IconButton
                            tabIndex={1}
                            size="small"
                            color="primary"
                            aria-label={translations.delete}
                            onClick={(e) => {
                                setAction({
                                    type: "DELETE",
                                    object: row.original
                                });
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                        >
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                </Stack>
            }
        });


        return {
            columns: columns,
            pagination: true,
            pageSize: 5,
            columnFiltering: false,
            sorting: false
        }
    }, [translations, title, TableComponentConfig]);

    return <Box bgcolor={"background.paper"} pl={1} pr={1}>
        <Table {...tableConfig} data={data}/>
    </Box>
}

const CardsComponent = ({
                            locationListener = (location, setAction) => {
                            },
                            onAdd = () => {
                            },
                            mediumType = "mediumType",
                            TableComponentConfig = {
                                title: "Table component title",
                                firstColumn: {},
                            },
                            DeleteComponentConfig = {
                                title: "Delete dialog component title",
                                description: "Delete dialog component description",
                            },
                            EditComponentConfig = {
                                title: "Edit dialog component title",
                                description: "Edit dialog component description",
                            },
                            AddComponent
                        }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const location = useLocation();
    const {config} = useConfig();
    const {t} = useTranslation();
    const [action, setAction] = React.useState({
        type: "NONE"
    });

    React.useEffect(() => {
        locationListener && locationListener(location, setAction);
    }, [location.state]);

    const translations = React.useMemo(() => {
        return {
            paymentCards: t("components.settings.paymentCards"),
            dateOfBirth: t("common.dateOfBirth"),
            optionalName: t("common.optionalName"),
            pesel: t("common.pesel"),
            personalData: t("common.personalData"),
            savedOn: t("common.savedOn"),
            edit: t("button.edit"),
            add: t("button.add"),
            close: t("button.close"),
            delete: t("button.delete"),
            save: t("button.save"),
        };
    }, [t])

    const commonDialogConfig = {
        onClose: () => {
            setAction({
                type: "NONE"
            });
        },
        action
    };

    const tableConfig = {
        translations: translations,
        setAction: setAction,
        TableComponentConfig: TableComponentConfig,
        data: config['mediums'].filter(o => o.mediumType === mediumType),
        onAdd,
        title: t(TableComponentConfig.title)
    };

    return <React.Fragment>
        {
            isMobile
                ? <NativeTable {...tableConfig}/>
                : <WebTable {...tableConfig}/>
        }
        <DeleteDialog
            {...commonDialogConfig}
            {...DeleteComponentConfig}
        />
        <EditDialog
            {...commonDialogConfig}
            {...EditComponentConfig}
            mediumType={mediumType}
        />
        <AddComponent {...commonDialogConfig}/>
    </React.Fragment>
}

export default CardsComponent;
