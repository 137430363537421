import {Close} from "@mui/icons-material";
import {ComplaintApplicationComponent} from "@beesset/upor-client-module-cmn";
import {Dialog} from "@beesset/ui-components";
import React from "react";

const ComplaintDialog = ({translations, applicationProps, onClose, onSuccess}) => {
    const validateApplicationHandler = React.useRef();

    return applicationProps && <Dialog
        title={translations.complaint}
        open={true}
        actions={[{
            name: translations.close,
            color: "inherit",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }, {
            name: translations.send,
            onClick: () => {
                validateApplicationHandler.current({
                    onSuccess: onSuccess
                });
            },
            fullScreenProps: {
                align: "right",
            },
        }]}
    >
        <ComplaintApplicationComponent
            {...applicationProps}
            ref={validateApplicationHandler}
            visibility={{
                button: false
            }}
        />
    </Dialog>
}

export default ComplaintDialog;