import {UPMRest} from "@beesset/rest";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import RuleIcon from "@mui/icons-material/Rule";
import SellIcon from "@mui/icons-material/Sell";
import DevicesIcon from '@mui/icons-material/Devices';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import React from "react";
import {AccessProvider} from "@beesset/common-utils";
import StoreIcon from '@mui/icons-material/Store';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
var moment = require("moment");

const AppConfigContext = React.createContext();

export function AppConfigProvider({children, moduleId, standaloneModuleProps}) {
    const [initialized, setInitialized] = React.useState(false);
    const [config, setConfig] = React.useState({
        moduleId: moduleId,
        userInfo: null,
        standaloneModuleProps,
        modulesIcons: {
            tra: DirectionsBusIcon,
            upm: ManageAccountsIcon,
            ctr: HowToRegIcon,
            qua: RuleIcon,
            sll: SellIcon,
            upor: DevicesIcon,
            cmr: SupportAgentIcon,
            prp: AccountBalanceWalletIcon,
            dbt: RecentActorsIcon,
            pos: StoreIcon,
            mtm: DepartureBoardIcon
        },
    });

    React.useEffect(() => {
        let fetch = (standaloneModuleProps && standaloneModuleProps.fetchConfig)
            ? standaloneModuleProps.fetchConfig
            : UPMRest.fetchConfig;

        fetch({
            handlers: {
                success: (response) => {
                    const offset = response.timeOffset;
                    moment.now = () => {
                        return new Date() - offset;
                    };
                    setTimeout(() => {
                        setConfig((c) => {
                            return {
                                ...c,
                                userInfo: response.user,
                            };
                        });
                        setInitialized(true);
                    }, 500);
                },
            },
        });
    }, [standaloneModuleProps]);

    return (
        <AppConfigContext.Provider
            value={{
                initialized,
                config
            }}
        >
            <AccessProvider rights={config.userInfo ? config.userInfo.userRights : []}>
                {children}
            </AccessProvider>
        </AppConfigContext.Provider>
    );
}

export function useAppConfig() {
    let result = {
        initialized: false,
        config: {
            moduleId: null,
            userInfo: null,
            modulesIcons: {},
            standaloneModuleProps: {
                fetchConfig: () => {
                },
                validateSession: () => {
                },
                sessionExpiryCookie: "",
                logout: () => {
                }
            }
        }
    };
    result = React.useContext(AppConfigContext);
    return result;
}
