import * as React from 'react';

import "./validators.css";
import CommonHelpComponent from "../common/CommonHelpComponent";
import Page2 from "./pages/2/Page2";
import Page1 from "./pages/1/Page1";
import Page3 from "./pages/3/Page3";
import Page4 from "./pages/4/Page4";

const ValidatorsComponent = () => {
    const config = React.useMemo(() => {
        return {
            Pages: [{
                id: "1",
                onClick: Page1
            }, {
                id: "2",
                Component: Page2
            }, {
                id: "3",
                Component: Page3
            }, {
                id: "4",
                Pages: [{
                    id: "5",
                    Component: Page4
                }, {
                    id: "6",
                    onClick: () => {
                        window.location.href = "http://34.116.198.194/index.php";
                    }
                }]
            }],
            i18nPrefix: "components.help.validators.names.",
            menuClassPrefix: "validators-menu-item-"
        }
    }, []);

    return <CommonHelpComponent {...config}/>
}

export {
    ValidatorsComponent
};