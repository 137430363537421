import {useConfig} from "@beesset/upor-client-module-cmn";
import {useLayoutNavigation} from "../LayoutNavigation";
import {useTranslation} from "react-i18next";
import Drawer from "@mui/material/Drawer";
import {
    Box,
    Checkbox,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Stack,
    Switch,
    Typography,
    useTheme
} from "@mui/material";
import {Accessible, Close, Language, TextDecrease, TextIncrease} from "@mui/icons-material";
import React from "react";


const SettingsDrawer = () => {
    const {config, setLanguage, getLanguage, toggleHighContrast, changeFontSize} = useConfig();
    const {drawerWidth, toggleSettingsMenu, settingsOpen} = useLayoutNavigation();
    const {t} = useTranslation();
    const theme = useTheme();

    return <Drawer
        id={"settings"}
        variant="temporary"
        open={settingsOpen}
        anchor="right"
        onClose={toggleSettingsMenu}
        ModalProps={{
            keepMounted: true,
        }}
        sx={{
            "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: 300
            },
        }}
    >
        <Box sx={{
            height: "env(safe-area-inset-top)",
            width: "100%",
            backgroundColor: theme.palette.mode === "light" ? "primary.main" : "transparent"
        }}/>
        <Stack p={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography pl={1} fontSize="1.2rem" color="text.secondary">{t("common.settings")}</Typography>
                <IconButton
                    size="small"
                    aria-label={t("button.accessibility")}
                    onClick={toggleSettingsMenu}
                >
                    <Close fontSize="small"/>
                </IconButton>
            </Stack>
            <List
                dense
                sx={{width: "100%", maxWidth: drawerWidth, backgroundColor: "background.paper", flex: 1}}
                subheader={<ListSubheader disableSticky>
                    <Stack direction="row" alignItems="center">
                        <Language fontSize="small" sx={{mr: 1}}/>
                        {t("common.language")}
                    </Stack>
                </ListSubheader>}
            >
                {(config.languages || []).map((lang) => {
                    return <ListItem disablePadding key={`settings-language-${lang.id}`}>
                        <ListItemButton onClick={() => setLanguage(lang.id)}>
                            <ListItemText
                                primaryTypographyProps={{
                                    noWrap: true
                                }}
                                primary={lang.description}
                            />
                            <ListItemIcon sx={{minWidth: "unset"}}>
                                <Checkbox inputProps={{ "aria-label": lang.description}} checked={getLanguage() === lang.id} disableRipple/>
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                })}
            </List>
            <List
                dense
                sx={{width: "100%", maxWidth: drawerWidth, backgroundColor: "background.paper", flex: 1}}
                subheader={<ListSubheader disableSticky>
                    <Stack direction="row" alignItems="center">
                        <Accessible fontSize="small" sx={{mr: 1}}/>
                        {t("common.accessibility")}
                    </Stack>
                </ListSubheader>}
            >
                <ListItem disablePadding key={"switch-high-contrast"}>
                    <ListItemButton onClick={toggleHighContrast}>
                        <ListItemText
                            primaryTypographyProps={{
                                noWrap: true
                            }}
                            primary={t("common.highContrast")}
                        />
                        <Switch
                            color="primary"
                            disableRipple
                            edge="end"
                            inputProps={{
                                "aria-label": t("common.highContrast")
                            }}
                            onClick={(e) => {
                                toggleHighContrast();
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }
                            }
                            checked={config.accessibility.highContrast}
                        />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding key={"increase-font-size"}>
                    <ListItemButton onClick={() => {
                        changeFontSize("increase");
                    }
                    }>
                        <ListItemText
                            primaryTypographyProps={{
                                noWrap: true
                            }}
                            primary={t("common.increaseFontSize")}
                        />
                        <ListItemIcon
                            sx={{minWidth: "unset", p: 1}}
                        >
                            <TextIncrease color="primary"/>
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding key={"decrease-font-size"}>
                    <ListItemButton onClick={() => {
                        changeFontSize("decrease");
                    }
                    }>
                        <ListItemText
                            primaryTypographyProps={{
                                noWrap: true
                            }}
                            primary={t("common.decreaseFontSize")}/>
                        <ListItemIcon sx={{minWidth: "unset", p: 1}}>
                            <TextDecrease color="primary"/>
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
            </List>
        </Stack>
        <Box height="env(safe-area-inset-bottom)" width="100%" bgcolor="transparent"/>
    </Drawer>;
}

export default SettingsDrawer;