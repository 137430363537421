import React from "react";
import {Box, Collapse, FormControl, MenuItem, Select, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {InvoiceApplicationComponent as InvoiceApplication,} from "@beesset/upor-client-module-cmn";
import {OtherApplicationComponent as OtherApplication} from "./OtherApplication"
import CityCardIssuanceApplication from "./CityCardIssuanceApplication";
import DiscountRegistrationApplication from "./DiscountRegistrationApplication";
import {ComplaintApplicationComponent as ComplaintApplication} from "./ComplaintApplication";

const APPLICATION_TYPE = {
    INVOICE: "INVOICE",
    INVOICE_CORRECTION: "INVOICE_CORRECTION",
    CITY_CARD_ISSUANCE: "CITY_CARD_ISSUANCE",
    DISCOUNT_REGISTRATION: "DISCOUNT_REGISTRATION",
    COMPLAINT: "COMPLAINT",
    OTHER: "OTHER"
}

const ApplicationsComponent = ({
                                   availableTypes = [],
                                   InvoiceApplicationComponent,
                                   InvoiceCorrectionApplicationComponent,
                                   OtherApplicationComponent,
                                   onSuccess,
                                   footer = "components.application.footer"
                               }) => {
    const {t} = useTranslation();

    const [selectedType, setSelectedType] = React.useState(() => {
        if (availableTypes.length === 1) {
            return availableTypes[0];
        }
        return null;
    });

    const translations = React.useMemo(() => {
        return {
            title: t("components.application.one"),
            description: t("components.application.description"),
            type: t("components.application.type"),
            [APPLICATION_TYPE.INVOICE]: t(`components.application.types.${APPLICATION_TYPE.INVOICE}`),
            [APPLICATION_TYPE.INVOICE_CORRECTION]: t(`components.application.types.${APPLICATION_TYPE.INVOICE_CORRECTION}`),
            [APPLICATION_TYPE.OTHER]: t(`components.application.types.${APPLICATION_TYPE.OTHER}`),
            [APPLICATION_TYPE.CITY_CARD_ISSUANCE]: t(`components.application.types.${APPLICATION_TYPE.CITY_CARD_ISSUANCE}`),
            [APPLICATION_TYPE.DISCOUNT_REGISTRATION]: t(`components.application.types.${APPLICATION_TYPE.DISCOUNT_REGISTRATION}`),
            [APPLICATION_TYPE.COMPLAINT]: t(`components.application.types.${APPLICATION_TYPE.COMPLAINT}`),
            footer: t(footer),
        }
    }, [t]);

    function handleRegister() {
        setSelectedType(null);
        onSuccess && onSuccess();
    }

    React.useEffect(() => {
        if (selectedType == null && availableTypes.length === 1) {
            setSelectedType(availableTypes[0]);
        }
    }, [selectedType, availableTypes]);

    return <Stack direction="row" justifyContent="center">
        <Box maxWidth={600} width={"100%"} bgcolor="background.paper" p={3} borderRadius={3}>
            <Stack spacing={3}>
                <Stack spacing={1}>
                    <Typography variant="h6">
                        {translations.title}
                    </Typography>
                    {availableTypes.length > 1 && <Typography
                        variant="subtitle1"
                        component="label"
                        id={"application-type-form-field-label"}
                        htmlFor={"application-type-form-field"}
                        color="text.secondary"
                    >
                        {translations.description}
                    </Typography>}
                </Stack>
                {availableTypes.length > 1 && <FormControl fullWidth>
                    <Select
                        value={selectedType || ''}
                        onChange={(event) => {
                            setSelectedType(event.target.value);
                        }
                        }
                        displayEmpty
                        renderValue={(selected) => {
                            if (!selected) {
                                return <em>{translations.type}</em>;
                            }
                            return translations[selected];
                        }}
                        labelId={"application-type-form-field-label"}
                        inputProps={{
                            id: `application-type-form-field`
                        }}
                    >
                        <MenuItem disabled value={null}>
                            <em>{translations.type}</em>
                        </MenuItem>
                        {availableTypes.map((type) => {
                            return <MenuItem
                                value={type}
                                key={`menu-item-${type}`}
                            >
                                <Typography noWrap>
                                    {translations[type]}
                                </Typography>
                            </MenuItem>
                        })}
                    </Select>
                </FormControl>}
                <Collapse in={selectedType != null}>
                    {selectedType === APPLICATION_TYPE.INVOICE &&
                        (InvoiceApplicationComponent ? <InvoiceApplicationComponent onSuccess={handleRegister}/> :
                            <InvoiceApplication onSuccess={handleRegister}/>)
                    }
                    {selectedType === APPLICATION_TYPE.INVOICE_CORRECTION &&
                        (InvoiceCorrectionApplicationComponent ?
                            <InvoiceCorrectionApplicationComponent onSuccess={handleRegister}/> :
                            <InvoiceApplication onSuccess={handleRegister} correction={true}/>)
                    }
                    {selectedType === APPLICATION_TYPE.CITY_CARD_ISSUANCE &&
                        <CityCardIssuanceApplication onSuccess={handleRegister}/>}
                    {selectedType === APPLICATION_TYPE.DISCOUNT_REGISTRATION &&
                        <DiscountRegistrationApplication onSuccess={handleRegister}/>}
                    {selectedType === APPLICATION_TYPE.COMPLAINT &&
                        <ComplaintApplication onSuccess={handleRegister}/>}
                    {selectedType === APPLICATION_TYPE.OTHER &&
                        (OtherApplicationComponent ? <OtherApplicationComponent onSuccess={handleRegister}/> :
                            <OtherApplication onSuccess={handleRegister}/>)
                    }
                </Collapse>
                <Typography
                    whiteSpace="pre-line"
                    variant="caption"
                    color="text.secondary"
                >
                    {translations.footer}
                </Typography>
            </Stack>
        </Box>
    </Stack>
}

export {APPLICATION_TYPE, ApplicationsComponent}