import {createStepper} from "@beesset/ui-components";
import Step3_MediumSelection from "./steps/Step3_MediumSelection";
import Step1_ProfileSelection from "./steps/Step1_ProfileSelection";
import React from "react";
import TariffsProvider from "./TariffsProvider";
import Step2_CategorySelection from "./steps/Step2_CategorySelection";
import Step5_SummaryAndPayment from "./steps/Step5_SummaryAndPayment";
import Step4_Configuration from "./steps/Step4_Configuration";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {MEDIUM_TYPE, UPORUtils, useConfig} from "@beesset/upor-client-module-cmn";

const PurchaseSteps = {
    PROFILE_SELECTION: "PROFILE_SELECTION",
    CATEGORY_SELECTION: "CATEGORY_SELECTION",
    MEDIUM_SELECTION: "MEDIUM_SELECTION",
    CONFIGURATION: "CONFIGURATION",
    SUMMARY_AND_PAYMENT: "SUMMARY_AND_PAYMENT"
}

const PurchaseComponentPrv = ({steps = [], data = null}) => {
    const dataRef = React.useRef({
        data: data,
        used: false
    });
    const {config} = useConfig();
    const {t} = useTranslation();

    const purchaseConfig = React.useMemo(() => {
        const translations = {
            profileSelection: t("components.purchase.profileSelection"),
            profileSelectionDesc: t("components.purchase.profileSelectionDesc"),
            categorySelection: t("components.purchase.categorySelection"),
            categorySelectionDesc: t("components.purchase.categorySelectionDesc"),
            mediumSelection: t("components.purchase.mediumSelection"),
            mediumSelectionDesc: t("components.purchase.mediumSelectionDesc"),
            mediumQrCode: t("components.purchase.mediumQrCode"),
            mediumQrCodeAdd: t("components.purchase.mediumQrCodeAdd"),
            mediumPaymentCard: t("components.purchase.mediumPaymentCard"),
            mediumPaymentCardAdd: t("components.purchase.mediumPaymentCardAdd"),
            mediumCityCard: t("components.purchase.mediumCityCard"),
            mediumCityCardAdd: t("components.purchase.mediumCityCardAdd"),
            mediumType: t("components.purchase.mediumPaymentCardAdd"),
            configuration: t("components.purchase.configuration"),
            configurationDesc: t("components.purchase.configurationDesc"),
            configurationPeriod: t("components.purchase.configurationPeriod"),
            configurationVehicle: t("components.purchase.configurationVehicle"),
            configurationVehicleDescription: t("components.purchase.configurationVehicleDescription"),
            configurationQuantity: t("components.purchase.configurationQuantity"),
            vehicleNumber: t("components.application.other.vehicleNumber"),
            startDate: t("common.startDate"),
            endDate: t("common.endDate"),
            price: t("common.price"),
            ticket: t("common.ticket"),
            fromToDate: (o) => t("common.fromToDate", o),
            payment: t("components.purchase.payment"),
            paymentDesc: t("components.purchase.paymentDesc"),
            paymentSummary: t("components.purchase.paymentSummary"),
            paymentRegulation: (mediumType) => t(`components.purchase.paymentRegulation.${mediumType}`),
            paymentRegulationCheck: t("components.purchase.paymentRegulationCheck"),
            paymentConfirm: t("components.purchase.paymentConfirm"),
            paymentHiddenInvoiceDesc: t("components.purchase.paymentHiddenInvoiceDesc"),
            regulation: t("components.application.regulation"),
            regulationWarning: t("components.application.regulationWarning"),
            invoiceRequest: t("components.application.invoice.request"),
            invoiceRequestNo: t("components.application.invoice.requestNo"),
            invoiceRequestYes: t("components.application.invoice.requestYes"),
            mediumTypes: (type) => t(`common.mediumTypes.${type}`),
            mapException: exceptionId => t(`exception.${exceptionId}`),
            buttonClose: t("button.close"),
            buttonGoNext: t("button.goNext"),
            buttonYes: t("button.yes"),
            buttonNo: t("button.no")
        };

        let finalSteps = steps
            .filter((step) => {
                return step !== PurchaseSteps.MEDIUM_SELECTION || !UPORUtils.isNativePlatform()
            });

        let mediumStep = steps
            .findIndex((step) => {
                return step === PurchaseSteps.MEDIUM_SELECTION
            });

        let buttonProps = {
            props: {
                size: "large"
            }
        };

        let finalData = {
            medium: UPORUtils.isNativePlatform() ? {
                id: config['mediums'][0].id,
                name: config['mediums'][0].normalizedName,
                type: MEDIUM_TYPE.VIRTUAL_CITY_CARD
            } : undefined
        };

        if (dataRef.current.used === false) {
            finalData = {
                ...finalData,
                ...dataRef.current.data
            }
            dataRef.current.used = true;
        }

        return {
            verticalVariant: "mobile",
            alternativeLabel: true,
            orientation: "auto",
            keepMounted: false,
            data: finalData,
            steps: finalSteps
                .map((step, index) => {
                    let nextStepOnStartup = false;

                    if (dataRef.current.data && mediumStep >= 0 && index < mediumStep) {
                        nextStepOnStartup = true;
                    }

                    switch (step) {
                        case PurchaseSteps.PROFILE_SELECTION:
                            return {
                                label: translations.profileSelection,
                                Content: () => <Step1_ProfileSelection
                                    translations={translations}
                                    nextStepOnStartup={nextStepOnStartup}
                                />,
                                BackButton: index > 0 ? buttonProps : false
                            };
                        case PurchaseSteps.CATEGORY_SELECTION:
                            return {
                                label: translations.categorySelection,
                                Content: () => <Step2_CategorySelection
                                    translations={translations}
                                    nextStepOnStartup={nextStepOnStartup}
                                />,
                                BackButton: index > 0 ? buttonProps : false
                            };
                        case PurchaseSteps.MEDIUM_SELECTION:
                            return {
                                label: translations.mediumSelection,
                                Content: () => <Step3_MediumSelection translations={translations}/>,
                                BackButton: index > 0 ? buttonProps : false
                            };
                        case PurchaseSteps.CONFIGURATION:
                            return {
                                label: translations.configuration,
                                Content: () => <Step4_Configuration
                                    translations={translations}
                                    stepIndex={index}
                                />,
                                NextButton: {
                                    props: {
                                        size: "large",
                                        type: "submit",
                                        form: "purchaseConfigurationFormId"
                                    }
                                },
                                BackButton: buttonProps
                            };
                        case PurchaseSteps.SUMMARY_AND_PAYMENT:
                            return {
                                label: translations.payment,
                                Content: () => <Step5_SummaryAndPayment translations={translations}/>,
                                BackButton: buttonProps
                            };
                    }
                })
        }
    }, [t, config]);

    const {Stepper} = createStepper(purchaseConfig);

    return <TariffsProvider>
        {Stepper}
    </TariffsProvider>;
}

const PurchaseComponent = ({steps}) => {
    const [ready, setReady] = React.useState({
        is: false,
        data: null
    });
    const {config} = useConfig();
    const navigate = useNavigate();
    const location = useLocation();

    React.useEffect(() => {
        if (UPORUtils.isNativePlatform()) {
            if (config['mediums'].length === 0) {
                navigate("/virtualCityCard");
            } else {
                setReady({
                    is: true
                });
            }
        } else {
            if (location.state && location.state.addNewPaymentCard) {
                setReady({
                    is: true,
                    data: {
                        token: location.state.addNewPaymentCard.token,
                        ...JSON.parse(location.state.addNewPaymentCard.data)
                    }
                })
                navigate(location.pathname, {});
            } else {
                setReady({
                    is: true
                });
            }
        }
    }, []);

    return ready.is && <PurchaseComponentPrv steps={steps} data={ready.data}/>
}

export {
    PurchaseComponent,
    PurchaseSteps
};