import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";
import isEqual from 'react-fast-compare';
import {Button, Divider, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Add} from "@mui/icons-material";

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}


const AutocompletePaper = ({children, onAdd}) => {
    const {t} = useTranslation();
    return <Paper>
        {onAdd && <React.Fragment>
            <Button
                color="primary"
                fullWidth
                sx={{justifyContent: "flex-start", p: 1.5, mt: 1}}
                onMouseDown={() => {
                    onAdd();
                }}
                startIcon={<Add fontSize="small"/>}
            >
                {t("button.add")}
            </Button>
            <Divider sx={{mt: 1}}/>
        </React.Fragment>}
        {children}
    </Paper>
}

const AutocompleteField = ({
                               validation = {},
                               parseError,
                               name,
                               value,
                               isOptionEqualToValue,
                               getOptionLabel,
                               getOptionDisabled,
                               renderOption,
                               freeSolo,
                               fetchOnRender,
                               autoSelect,
                               hideIfOneOption = false,
                               disableClearable = false,
                               blurOnSelect,
                               readOnly = false,
                               disabled = false,
                               groupBy = null,
                               autocompleteSx,
                               endAdornment,
                               optionsProvider: {options = [], fetchOptions, convertOptions, fetchOptionsRequest = {}},
                               onAdd,
                               isStacked = false,
                               ...rest
                           }) => {
    const {control, validationMessages, setValue, getValues, watch} = useFormContext();

    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState({
        version: 1,
        options: fetchOptions ? [] : options,
        request: fetchOptionsRequest,
        isLoaded: fetchOptions ? false : true
    });
    const loading = (open || data.version > 1 || hideIfOneOption || fetchOnRender) && data.isLoaded == false;
    const currentValue = watch(name);

    if (!isOptionEqualToValue || !getOptionLabel) {
        alert("Autocomplete isOptionEqualToValue and getOptionLabel is required.");
        return;
    }

    if (isStacked) {
        if (!autocompleteSx) {
            autocompleteSx = {};
        }
        autocompleteSx['width'] = "100%";
        autocompleteSx['margin'] = "0px";
    }

    if (rest.required) {
        validation.required = validationMessages.required;
    }

    React.useEffect(() => {
        if (!freeSolo && currentValue && data.options) {
            if (data.options.findIndex((el) => {
                return isOptionEqualToValue(el, currentValue) && (typeof getOptionDisabled !== 'function' || !getOptionDisabled(el))
            }) < 0) {
                setData({
                    ...data,
                    version: data.version + 1,
                    request: fetchOptionsRequest,
                    isLoaded: false,
                });
            }
        }
    }, [freeSolo, currentValue, data.options]);

    React.useEffect(() => {
        if (!isEqual(fetchOptionsRequest, data.request)) {
            setData({
                ...data,
                version: data.version + 1,
                request: fetchOptionsRequest,
                isLoaded: false,
            });
        }
    }, [fetchOptionsRequest]);

    React.useEffect(() => {
        if (data.request && data.isLoaded) {
            let value = getValues()[name];

            if (hideIfOneOption && data.options.length === 1) {
                setValue(name, data.options[0]);
            } else if (value && !freeSolo) {
                if (data.options.findIndex((el) => {
                    return isOptionEqualToValue(el, value) && (typeof getOptionDisabled !== 'function' || !getOptionDisabled(el))
                }) < 0) {
                    setValue(name, null);
                }
            }
        }
    }, [data, freeSolo]);

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        fetchOptions({
            handlers: {
                success: (response) => {
                    if (active) {
                        setData({
                            options: convertOptions ? convertOptions(response) : response,
                            isLoaded: true,
                            request: data.request,
                            version: data.version + 1
                        });
                    }
                },
                failure: () => {
                    if (active) {
                        setData({
                            options: [],
                            isLoaded: true,
                            request: data.request,
                            version: data.version + 1
                        });
                    }
                },
            },
            request: data.request
        });

        return () => {
            active = false;
        };
    }, [loading]);

    return (
        <Controller
            control={control}
            name={name}
            rules={validation}
            defaultValue={value || null}
            render={({
                         field: {onChange, ...fieldRest},
                         fieldState: {invalid, error},
                     }) => {
                return (
                    <Autocomplete
                        {...fieldRest}
                        hidden={hideIfOneOption && (data.version == 1 || data.options.length === 1)}
                        disableClearable={disableClearable}
                        freeSolo={freeSolo}
                        autoSelect={autoSelect || freeSolo}
                        readOnly={readOnly}
                        disabled={disabled}
                        blurOnSelect={blurOnSelect}
                        sx={{marginTop: "10px", ...autocompleteSx}}
                        onChange={(event, option) => {
                            onChange(option);
                            if(rest && rest.onChange){
                                rest.onChange(option);
                            }
                        }}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        getOptionDisabled={getOptionDisabled}
                        isOptionEqualToValue={isOptionEqualToValue}
                        getOptionLabel={getOptionLabel}
                        renderOption={renderOption}
                        options={data.options}
                        loading={loading}
                        groupBy={groupBy}
                        PaperComponent={(props) => <AutocompletePaper onAdd={onAdd} {...props}/>}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    {...rest}
                                    error={invalid}
                                    helperText={
                                        error
                                            ? typeof parseError === "function"
                                                ? parseError(error)
                                                : error.message
                                            : rest.helperText
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                <div style={{background: "inherit"}}>
                                                    {loading ? (
                                                        <CircularProgress color="inherit" size={20}/>
                                                    ) : null}
                                                    {params.InputProps.endAdornment}
                                                </div>
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            );
                        }}
                    />
                );
            }}
        />
    );
};

export default AutocompleteField;
