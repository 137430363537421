import {Snackbar} from "@beesset/ui-components";

function getRootUrl() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
        return "http://localhost:8080/";
    }
    if (window.location.port == 3000) {
        return location.origin.replace("3000", "8080") + "/";
    } else {
        return location.origin + "/";
    }
}

function parseResponse(response) {
    if (response.status == 205) {
        window.location.reload();
        return;
    }
    if (response.redirected) {
        window.location.href = response.url;
        return;
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        if (!response.ok) {
            return response.json().then((exception) => {
                let error = new Error("Application error occured.");
                error.exception = exception;
                throw error;
            });
        }
        return response.json();
    } else if (contentType && contentType.indexOf("application/zip") !== -1) {
        return response.blob();
    } else {
        if (!response.ok) {
            throw new Error("Application error occured.");
        }

        return response.text().then((text) => {
            return text;
        });
    }
}

function invoke({
                    uri,
                    timeout = 20000,
                    options,
                    handlers: {success, failure, both} = {},
                    showSnackbar = true,
                    parse
                }) {
    if (!options) {
        options = {};
    }

    const controller = new AbortController();
    options.signal = controller.signal;
    options.credentials = "include";

    let createdTimeout = setTimeout(() => controller.abort(), timeout || 20000);

    fetch(getRootUrl() + uri, options)
        .then(parse || parseResponse)
        .then((response) => {
            if (success) {
                try {
                    success(response);
                } catch (error) {
                    console.error(error);
                }
            }
        })
        .catch((error) => {
            let exception = error.exception;
            if (showSnackbar) {
                Snackbar.error(exception);
            }

            if (failure) {
                try {
                    failure(exception);
                } catch (error) {
                    console.error(error);
                }
            }
        })
        .finally(() => {
            if (both) {
                try {
                    both();
                } catch (error) {
                    console.error(error);
                }
            }
            clearTimeout(createdTimeout);
        });
}

function invokeFormDataPost({
                                uri,
                                request,
                                timeout,
                                handlers: {success, failure, both} = {},
                                showSnackbar = true
                            }) {
    invoke({
        uri: uri,
        options: {
            method: "POST",
            body: request
        },
        timeout,
        handlers: {
            success,
            failure,
            both,
        },
        showSnackbar
    });
}

function invokeGET({
                       uri,
                       request,
                       timeout,
                       handlers: {success, failure, both} = {},
                       showSnackbar = true,
                   }) {
    if (request && Object.keys(request).length > 0) {
        uri = uri + "?" + new URLSearchParams(request);
    }
    invoke({
        uri,
        timeout,
        handlers: {
            success,
            failure,
            both,
        },
        showSnackbar
    });
}

function invokePOST({
                        uri,
                        request,
                        timeout,
                        handlers: {success, failure, both} = {},
                        showSnackbar = true,
                    }) {
    invoke({
        uri,
        timeout,
        options: {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: request
                ? JSON.stringify(request).replace(/"\s+|\s+"/g, '"')
                : undefined,
        },
        handlers: {
            success,
            failure,
            both,
        },
        showSnackbar,
    });
}

function invokeDELETE({
                          uri,
                          request,
                          timeout,
                          handlers = {
                              success,
                              failure,
                              both,
                          },
                          showSnackbar = true
                      }) {
    if (request && Object.keys(request).length > 0) {
        uri = uri + "?" + new URLSearchParams(request);
    }
    invoke({
        uri,
        timeout,
        options: {
            method: "DELETE",
        },
        handlers,
        showSnackbar
    });
}

export {invoke, invokeGET, invokePOST, invokeDELETE, getRootUrl, parseResponse, invokeFormDataPost};
