import {DateUtils} from "@beesset/common-utils";
import {getRootUrl, invokeGET, invokePOST} from "../common/CommonRest";

function fetchConfig({handlers}) {
    invokePOST({
        uri: "upm/api/protected/config",
        handlers,
        request: {
            clientTime: DateUtils.getNow().format("YYYY-MM-DD[T]HH:mm:ss"),
        },
        showSnackbar: false,
    });
}

function fetchUserInfo({handlers}) {
    invokeGET({
        uri: "upm/api/protected/userInfo",
        handlers,
    });
}

function fetchEventTraces({handlers}) {
    invokeGET({
        uri: "upm/api/protected/event-traces",
        handlers,
    });
}

function fetchEventTracesAll({handlers}) {
    invokeGET({
        uri: "upm/api/protected/event-traces-all",
        handlers,
    });
}

function updateUserPassword({handlers, request}) {
    invokePOST({
        uri: "upm/api/protected/changePassword",
        request,
        handlers,
        showSnackbar: false,
    });
}

function logoutUser() {
    invokeGET({
        uri: "upm/api/protected/logout",
        showSnackbar: false,
        handlers: {
            failure: () => {
                window.location.reload();
            },
        },
    });
}

function getGeoLocation({success, failure}) {
    const controller = new AbortController();

    let options = {};
    options.signal = controller.signal;

    let createdTimeout = setTimeout(() => controller.abort(), 3000);

    fetch("https://geolocation-db.com/json/", options)
        .then(response => response.json())
        .then(response => success(response))
        .catch(() => {
            failure();
        })
        .finally(() => {
            clearTimeout(createdTimeout);
        });
}

function registerSessionInfo({handlers, request}) {
    invokePOST({
        uri: "upm/api/protected/registerSessionInfo",
        request,
        handlers,
        showSnackbar: false,
    });
}

function invalidateSession({handlers, request}) {
    invokePOST({
        uri: "upm/api/protected/invalidateSession",
        request,
        handlers
    });
}

function getSubscribeSessionUrl() {
    return getRootUrl() + "upm/api/session/subscribe";
}

function unsubscribeSession() {
    invokeGET({
        uri: "upm/api/session/unsubscribe",
        showSnackbar: false,
    });
}


export default {
    fetchConfig,
    fetchUserInfo,
    updateUserPassword,
    logoutUser,
    getGeoLocation,
    registerSessionInfo,
    invalidateSession,
    getSubscribeSessionUrl,
    unsubscribeSession,
    fetchEventTraces,
    fetchEventTracesAll
};
