import {AddPaymentCardComponent, UPORClientRest, useConfig} from "@beesset/upor-client-module-cmn";
import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import {DateUtils} from "@beesset/common-utils";
import {hideLoader, showLoader} from "@beesset/ui-components";

const NotificationsHandlerComponent = () => {
    return <React.Fragment>
        <AssignMediumNotification/>
    </React.Fragment>
}

const AssignMediumNotification = () => {
    const {t} = useTranslation();

    const {config, refresh} = useConfig();

    const [action, setAction] = React.useState({
        action: "NONE"
    });

    React.useEffect(() => {
        if (config.notification && config.notification.type === "ASSIGN_MEDIUM") {
            setAction({
                type: "ADD",
                object: {
                    token: config.notification.mediumId,
                },
                assignMediumRequestId: config.notification.id
            });
        } else {
            setAction({
                type: "NONE"
            });
        }
    }, [config.notification]);

    return <AddPaymentCardComponent
        action={action}
        title={t("components.notifications.assignMedium.title")}
        description={
            config.notification ? <div>
                {config.notification['vehicleName'] && <Typography>{t("components.notifications.assignMedium.place", {
                    vehicleName: config.notification['vehicleName']
                })}</Typography>}
                {config.notification['description'] &&
                    <Typography>{`${t("components.application.other.eventDescription")}: ${config.notification['description']}`}</Typography>}
                <Typography>{t("components.notifications.assignMedium.date", {
                    date: DateUtils.formatters.FULL(config.notification['requestedOn'])
                })}</Typography>
            </div> : ""
        }
        onClose={() => {
            setAction({
                type: "NONE"
            });
        }}
        saveButtonProps={{
            name: t("button.confirm")
        }}
        closeButtonProps={{
            name: t("button.refuse"),
            onClick: () => {
                showLoader();
                UPORClientRest.removeNotification({
                    handlers: {
                        success: () => {
                            refresh();
                        },
                        failure: () => {
                            hideLoader();
                        }
                    },
                    request: {
                        ...config.notification
                    },
                });
            }
        }}
    />
}

export {
    NotificationsHandlerComponent
};