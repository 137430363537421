import {DatePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";

const DateField = ({
                       parseError,
                       name,
                       value = null,
                       validation = {},
                       helperText,
                       label,
                       required,
                       readOnly,
                       disabled,
                       onlyYearView,
                       onlyYearAndMonthView,
                       DatePickerProps = {},
                       sx,
                       minDate,
                       maxDate,
                       onChangeDate,
                       isStacked,
                        size
                   }) => {
    const {control, validationMessages} = useFormContext();


    const {finalInputConfig, finalValidation, finalSx} = React.useMemo(() => {
        let config = {
            finalInputConfig: {
                label: label
            },
            finalValidation: {
                validate: (value) => {
                    if (value && value === "Invalid date") {
                        return validationMessages.invalidDate;
                    }
                },
                ...validation
            },
            finalSx: {
                marginTop: "10px",
                width: "100%",
                ...sx
            }
        }

        if (required) {
            config.finalInputConfig["required"] = true;
            config.finalValidation.required = validationMessages.required;
        }

        if (isStacked) {
            config.finalSx['width'] = "100%";
            config.finalSx['margin'] = "0px";
        }

        if(size === "small") {
            config.finalInputConfig.size = "small";
        }

        return config;
    }, [label, required, validationMessages, isStacked, validation, sx, size]);

    let inputFormat = "YYYY/MM/DD";
    let mask = "____/__/__";
    let views;
    if (onlyYearView) {
        inputFormat = "YYYY";
        mask = "____";
        views = ["year"];
    }
    if(onlyYearAndMonthView){
        inputFormat = "MMMM YYYY";
        mask = "___ ____";
        views = ["month","year"];
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={finalValidation}
            defaultValue={value}
            render={({
                         field: {onChange, ref, value, ...fieldRest},
                         fieldState: {invalid, error},
                     }) => {
                return (
                    <DatePicker
                        {...fieldRest}
                        value={value || null}
                        onChange={(event) => {
                            let date = event ? event.format("YYYY-MM-DD") : null;
                            onChange(date);
                            if (onChangeDate) {
                                onChangeDate(date);
                            }
                        }}
                        inputRef={ref}
                        inputFormat={inputFormat}
                        readOnly={readOnly}
                        disabled={disabled}
                        minDate={minDate}
                        maxDate={maxDate}
                        views={views}
                        mask={mask}
                        clearable
                        {...DatePickerProps}
                        disableMaskedInput={false}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    {...finalInputConfig}
                                    error={invalid}
                                    sx={finalSx}
                                    helperText={
                                        error
                                            ? typeof parseError === "function"
                                                ? parseError(error)
                                                : error.message
                                            : helperText
                                    }
                                />
                            );
                        }}
                    />
                );
            }}
        />
    );
};

export default DateField;
