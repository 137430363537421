import {
    AppBar,
    Box,
    Button,
    Dialog as MuiDialog,
    DialogActions as MuiDialogActions,
    DialogContent as MuiDialogContent,
    DialogContentText as MuiDialogContentText,
    DialogTitle as MuiDialogTitle,
    IconButton,
    Stack,
    Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material";
import {useTheme} from "@mui/system";
import React from "react";

const Action = ({config: {name, fullScreen, Icon, ...buttonProps}}) => {
    if (!name) {
        return;
    }

    if (Icon && fullScreen) {
        return (
            <IconButton {...buttonProps} edge="start" aria-label={name}>
                {Icon}
            </IconButton>
        );
    }

    return <Button {...buttonProps}>{name}</Button>;
};

const Dialog = ({
                    onlyFullScreen,
                    disableFullScreen,
                    open,
                    onClose,
                    children,
                    title,
                    description,
                    actions,
                    disableMaxWidth,
                    TransitionComponent
                }) => {
    let fullScreen = onlyFullScreen;
    if (!onlyFullScreen) {
        const theme = useTheme();
        fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    }

    if (disableFullScreen) {
        fullScreen = false;
    }

    let finalActions = [];

    if (actions) {
        if (Array.isArray(actions)) {
            let leftButtons = [];
            let rightButtons = [];

            actions.forEach(({fullScreenProps = {}, Icon, onlyFullScreen, show = true, key,...action}, index) => {
                let config,
                    tableToAdd = finalActions;

                if (show) {
                    if (fullScreen) {
                        if (fullScreenProps.align && fullScreenProps.align === "right") {
                            tableToAdd = rightButtons;
                        } else {
                            tableToAdd = leftButtons;
                        }
                        config = {
                            ...action,
                            color: "inherit",
                            variant: "outlined",
                            ...fullScreenProps,
                            fullScreen: true,
                        };
                    } else if (!onlyFullScreen) {
                        config = {
                            ...action,
                        };
                    }
                }

                config && tableToAdd.push(
                    <Action config={config} key={key || `dialog-action-button-${index}-${action.name}`}/>
                );
            });

            if (fullScreen) {
                finalActions.unshift(
                    <Stack direction="row" spacing={1} key="dialog-toolbar-left-buttons">
                        {leftButtons}
                    </Stack>
                );

                if (title) {
                    finalActions.push(
                        <Typography
                            noWrap
                            sx={{ml: 2, mr: 2, flex: "1 1 auto"}}
                            variant="h6"
                            component="div"
                            key="dialog-toolbar-title"
                        >
                            {title}
                        </Typography>
                    );
                }

                finalActions.push(
                    <Box sx={{flexGrow: 1}} key="dialog-toolbar-spacing"/>
                );

                finalActions.push(
                    <Stack direction="row" spacing={1} key="dialog-toolbar-right-buttons">
                        {rightButtons}
                    </Stack>
                );
            }
        }
    }

    return (
        <MuiDialog
            TransitionComponent={TransitionComponent}
            fullScreen={fullScreen}
            open={open}
            onClose={onClose}
            maxWidth={disableMaxWidth ? false : "sm"}
            PaperProps={{
                sx: {minWidth: 250},
            }}
        >
            {fullScreen && (
                <AppBar sx={{position: "relative"}}>
                    <Box height="env(safe-area-inset-top)" width="100%" bgcolor="transparent"/>
                    <Toolbar>{finalActions}</Toolbar>
                </AppBar>
            )}
            {!fullScreen && title && <MuiDialogTitle>{title}</MuiDialogTitle>}
            <MuiDialogContent sx={{display: "flex", flexDirection: "column"}}>
                {description && (
                    <MuiDialogContentText>{description}</MuiDialogContentText>
                )}
                {children}
            </MuiDialogContent>
            {!fullScreen && finalActions.length > 0 && (
                <MuiDialogActions>{finalActions}</MuiDialogActions>
            )}
        </MuiDialog>
    );
};

export default Dialog;
