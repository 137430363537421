import {
    APPLICATION_TYPE,
    ApplicationsComponent,
    DateField,
    RegulationCheck,
    UPORClientRest
} from "@beesset/upor-client-module-cmn";
import {Controller, useFormContext} from "react-hook-form";
import {Box, Button, InputAdornment, List, Stack, TextField as MuiTextFiled, Typography} from "@mui/material";
import {FormContainer, hideLoader, SelectField, showLoader, Snackbar, TextField} from "@beesset/ui-components";
import {useTranslation} from "react-i18next";
import React from "react";
import {TimePicker} from "@mui/x-date-pickers";
import {DateUtils} from "@beesset/common-utils";

const TimeField = (props) => {
    const {control, validationMessages} = useFormContext();

    const {
        name,
        label,
        required,
        sx = {}
    } = props;

    let muiInputConfig = {
        label: label
    };

    let validation = {};
    if (required) {
        muiInputConfig["required"] = true;
        validation.required = validationMessages.required;
    }

    let format = (event) => {
        if (event.isValid()) {
            return event.clone();
        }
        return "Invalid date";
    };

    validation.validate = (value) => {
        if (value && value === "Invalid date") {
            return validationMessages.invalidDate;
        }
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={validation}
            defaultValue={null}
            render={({
                         field: {onChange, ref, value, ...fieldRest},
                         fieldState: {invalid, error},
                     }) => {
                return (
                    <TimePicker
                        inputRef={ref}
                        {...fieldRest}
                        value={value || null}
                        onChange={(event) => {
                            onChange(event ? format(event) : null);
                        }}
                        clearable
                        renderInput={(params) => {
                            return (
                                <MuiTextFiled
                                    {...{
                                        ...params,
                                        InputProps: {
                                            readOnly: true,
                                            ...params.InputProps,
                                            sx: {
                                                ...params.InputProps.sx,
                                                "& .MuiInputBase-input": {
                                                    caretColor: 'transparent'
                                                }
                                            }

                                        }
                                    }}
                                    sx={sx}
                                    {...muiInputConfig}
                                    error={invalid}
                                    helperText={error ? error.message : null}
                                />
                            );
                        }}
                    />
                );
            }}
        />
    );
};

const InvoiceApplicationFields = ({translations, onChange}) => {
    const {
        watch,
        getValues
    } = useFormContext();

    const [bean, setBean] = React.useState(getValues());

    React.useEffect(() => {
        onChange(bean);
    }, [bean]);

    React.useEffect(() => {
        const subscription = watch((value) => {
            setBean(value);
        });
        return () => {
            subscription['unsubscribe']();
        };
    }, [watch]);

    return <Stack spacing={2}>
        <Stack direction={{xs: "column", sm: "row"}} marginTop="10px" spacing={2} alignItems="baseline">
            <DateField label={translations.date} name="date" required sx={{marginTop: "unset", width: "100%"}}/>
            <TimeField label={translations.time} name="time" sx={{marginTop: "unset", width: "100%"}}/>
        </Stack>
        <TextField label={translations.vehicleNumber} name="vehicleNumber" required/>
        <TextField label={translations.lineNumber} name="lineNumber" required/>
        <TextField
            InputProps={{
                startAdornment: <InputAdornment position="start">
                    <Box display="flex" justifyContent="center" alignItems="center">
                        PLN
                    </Box>
                </InputAdornment>
            }}
            type="number"
            label={translations.price}
            name="price"
            required
        />
        <TextField
            label={translations.additionalInformation}
            name={"additionalInformation"}
            placeholder={translations.additionalInformationPlaceholder}
            required
            multiline
            minRows={3}
        />
        <SelectField
            name="type"
            label={translations.type}
            required
            getOptionLabel={(option) => {
                return translations.types(option);
            }}
            getOptionId={(option) => {
                return option;
            }}
            optionsProvider={{
                options: ["COMPANY", "PRIVATE_PERSON"]
            }}
            disableClearButton={true}
        />
        {bean['type'] === "PRIVATE_PERSON" &&
            <TextField required label={translations.firstAndLastName} name="nameAndSurname"/>}
        {bean['type'] === "COMPANY" && <TextField required label={translations.companyNip} name="companyNip"/>}
        {bean['type'] === "COMPANY" && <TextField required label={translations.companyName} name="companyName"/>}
        <TextField label={translations.street} name="street" required/>
        <TextField label={translations.houseNumber} name="houseNumber" required/>
        <TextField label={translations.apartmentNumber} name="apartmentNumber"/>
        <TextField label={translations.postalCode} name="postalCode" required/>
        <TextField label={translations.locality} name="city" required/>
        <TextField label={translations.email} name="email" required/>
    </Stack>
}

const InvoiceApplicationPrv = ({innerRef, invoice = {}, defaultValues, ...props}) => {
    const formRef = React.useRef();

    innerRef.current = function (onSuccess) {
        formRef.current['handleSubmit']((bean) => {
            onSuccess({
                invoice: bean
            });
        }, () => {
            Snackbar.warning(props.translations.incorrectData);
        })();
    }

    return <FormContainer
        style={{marginTop: "15px"}}
        defaultValues={{
            ...defaultValues
        }}
        ref={formRef}
    >
        <InvoiceApplicationFields {...props}/>
    </FormContainer>
}

const InvoiceApplication = ({
                                onSuccess,
                                ...props
                            }) => {

    const invoiceRef = React.useRef();
    const regulationRef = React.useRef();
    const invoiceRegulationRef = React.useRef();
    const {t} = useTranslation();

    const translations = React.useMemo(() => {
        return {
            title: props.correction ? t("components.application.invoiceCorrection.title") : t("components.application.invoice.title"),
            description: props.correction ? t("components.application.invoiceCorrection.description") : t("components.application.invoice.description"),
            invoiceRegulation: t("components.application.invoice.regulation"),
            regulation: t("components.application.regulation"),
            regulationWarning: t("components.application.regulationWarning"),
            invoiceRegulationWarning: t("components.application.invoice.regulationWarning"),
            send: t("button.send"),
            firstAndLastName: t("common.firstAndLastName"),
            companyName: t("components.application.invoice.companyName"),
            type: t("common.type"),
            companyNip: t("components.application.invoice.companyNip"),
            types: (type) => t(`components.application.invoice.types.${type}`),
            street: t("components.application.invoice.street"),
            houseNumber: t("components.application.invoice.houseNumber"),
            apartmentNumber: t("components.application.invoice.apartmentNumber"),
            postalCode: t("components.application.invoice.postalCode"),
            locality: t("components.application.invoice.locality"),
            email: t("components.application.invoice.email"),
            incorrectData: t("components.application.invoice.incorrectData"),
            invoiceSuccess: t("components.application.invoice.success"),
            invoiceCorrectionSuccess: t("components.application.invoiceCorrection.success"),
            fromToDate: (props) => t("common.fromToDate", props),
            date: t("components.history.transactionDate"),
            time: t("components.application.invoice.transactionTime"),
            vehicleNumber: t("components.application.other.vehicleNumber"),
            lineNumber: t("components.application.invoice.lineNumber"),
            price: t("components.application.invoice.fullPrice"),
            additionalInformation: t("components.help.validators.5.additionalInformation"),
            additionalInformationPlaceholder: t("components.help.validators.5.additionalInformationPlaceholder")
        }
    }, [t]);

    function validate({onSuccess}) {
        function finalFn(bean) {
            let invoice = {...bean.invoice};

            if (invoice.time) {
                invoice.timeInMinutes = DateUtils.getDiffMinutes({
                    fromDate: invoice.time.clone().startOf('day'),
                    toDate: invoice.time
                });
                delete invoice.time;
            }

            let request = {
                type: props.correction ? "EXTERNAL_TRANSACTION_INVOICE_CORRECTION" : "EXTERNAL_TRANSACTION_INVOICE",
                data: JSON.stringify(invoice)
            }

            const formData = new FormData();
            formData.append("request", JSON.stringify(request));

            showLoader();
            UPORClientRest.registerApplication({
                handlers: {
                    success: () => {
                        onSuccess && onSuccess(request);
                        Snackbar.success(props.correction ? translations.invoiceCorrectionSuccess : translations.invoiceSuccess);
                    },
                    both: () => {
                        hideLoader();
                    }
                },
                formData
            });
        }

        invoiceRef.current((bean) => {
            regulationRef.current.check(() => {
                invoiceRegulationRef.current.check(() => {
                    finalFn(bean);
                });
            });
        });
    }

    return <Stack spacing={3}>
        <Stack spacing={1}>
            <Typography variant="body1" color="text.secondary" fontWeight={500}>
                {translations.title}
            </Typography>
            <Typography variant="body2">
                {translations.description}
            </Typography>
        </Stack>
        <InvoiceApplicationPrv
            {...props}
            innerRef={invoiceRef}
            translations={translations}
        />
        <List>
            <RegulationCheck
                ref={regulationRef}
                description={translations.regulation}
                warning={translations.regulationWarning}
                align="flex-start"
            />
            <RegulationCheck
                ref={invoiceRegulationRef}
                description={translations.invoiceRegulation}
                warning={translations.invoiceRegulationWarning}
            />
        </List>
        <Button variant="contained" size="large" onClick={() => {
            validate({
                onSuccess: onSuccess
            });
        }}>{translations.send}</Button>
    </Stack>
}

const Page4 = () => {
    const bean = React.useRef({});

    function handleChange(props) {
        bean.current = props;
    }

    return <Box alignSelf="flex-start">
        <ApplicationsComponent
            availableTypes={[
                APPLICATION_TYPE.INVOICE,
                APPLICATION_TYPE.INVOICE_CORRECTION
            ]}
            InvoiceApplicationComponent={(props) => <InvoiceApplication
                {...props}
                correction={false}
                defaultValues={bean.current}
                onChange={handleChange}
            />}
            InvoiceCorrectionApplicationComponent={(props) => <InvoiceApplication
                {...props}
                correction={true}
                defaultValues={bean.current}
                onChange={handleChange}
            />}
            onSuccess={() => handleChange({})}
        />
    </Box>
}

export default Page4;