import {Capacitor} from "@capacitor/core";

const UPORUtils = {
    isNativePlatform: () => {
        return Capacitor.isNativePlatform();
    },
    isDevelopmentMode: () => {
        if (window.location.port === "3000" || window.location.port === "3001") {
            return Capacitor.getPlatform();
        }
        return false;
    }
}

const MEDIUM_TYPE = {
    PAYMENT_CARD: "PAYMENT_CARD",
    PHONE_QR: "PHONE_QR",
    CITY_CARD: "CITY_CARD",
    VIRTUAL_CITY_CARD: "VIRTUAL_CITY_CARD"
}

const PASSENGER_IDENTITY_TYPE = {
    PESEL: "PESEL",
    DATE_OF_BIRTH: "DATE_OF_BIRTH"
}

export {UPORUtils, MEDIUM_TYPE, PASSENGER_IDENTITY_TYPE};

