import { invokePOST, invokeGET, invoke } from "../common/CommonRest";
import { Snackbar } from "@beesset/ui-components";
import { element } from "prop-types";

//infrastructure

function getComponents({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/getComponents.qua",
        handlers,
        request
    });
};

function getComponent({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/getComponent.qua",
        request,
        handlers
    });
};

function getComponentAttributeValueFiles({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/getComponentAttributeValueFiles.qua",
        request,
        handlers
    });
};

function saveComponent({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveComponent.qua",
        request,
        handlers
    });
};

function saveComponents({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveComponents.qua",
        request,
        handlers
    });
};

function saveComponentAttributeValues({handlers, request}) {
    let formData = new FormData();
    if(request.values) {
        request.values.forEach((el)=>{
            if(el.attribute.type === "FILE" && el.value) {
                for (let i = 0; i < el.value.length; i++) {
                    let file = el.value[i];
                    formData.append(""+el.attribute.loid, file, file.name);
                }
                let fileParam = {
                    loid: el.loid,
                    attribute: el.attribute,
                    files: [{loid: null}]
                }
                formData.append("values", JSON.stringify(fileParam));
            } else {
                if(el.attribute.type === "FILE") {
                    el.files = null;
                }
                formData.append("values", JSON.stringify(el));
            }
        });
    }
    formData.append("component", JSON.stringify(request.component));
    invoke({
      uri: "qua/qua_controller/saveComponentAttributeValues.qua",
      options: {
        method: "POST",
        body: formData,
      },
      handlers
    });
};

function removeComponent({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/removeComponent.qua",
        request,
        handlers
    });
};

function removeComponentAttributeValue({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/removeComponentAttributeValue.qua",
        request,
        handlers
    });
};

function getComponentTypes({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getComponentTypes.qua",
        handlers
    });
};

function saveComponentType({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveComponentType.qua",
        request: {componentType:request},
        handlers
    });
};

function getComponentParams({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/getComponentParams.qua",
        handlers,
        request: request ? request : {
            combo: null
        }
    });
};

function getComponentAttributeListElements({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getComponentAttributeListElements.qua",
        handlers
    });
};

function saveComponentAttributeListElement({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveComponentAttributeListElement.qua",
        handlers,
        request
    });
};

function saveComponentParam({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveComponentParam.qua",
        request: {componentAttribute:request},
        handlers
    });
};

function getAttributeValueTypesList({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getAttributeValueTypesList.qua",
        handlers
    })
}

//application

function getTicketTypes({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getTicketTypes.qua",
        handlers
    })
}

function saveTicketType({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveTicketType.qua",
        handlers,
        request
    })
}

function saveTicketCategory({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveTicketCategory.qua",
        handlers,
        request
    })
}

function getControlTypes({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getControlTypes.qua",
        handlers
    })
}

function changeTicketTypesSortingIndex({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/changeTicketTypesSortingIndex.qua",
        handlers,
        request
    })
}

function getTicketCategories({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getTicketCategories.qua",
        handlers
    })
}

function changeTicketCategoriesSortingIndex({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/changeTicketCategoriesSortingIndex.qua",
        handlers,
        request
    })
}

function getTicketStatuses({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getTicketStatuses.qua",
        handlers
    })
}

function getSheltersCleans({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getSheltersClean.qua",
        handlers
    })
}

function getShelterCleanItems({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/getShelterCleanItems.qua",
        handlers,
        request
    })
}

function getShelterCleanIssues({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getShelterCleanIssues.qua",
        handlers
    })
}

function getShelterCleanItemHistory({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/getShelterCleanItemHistory.qua",
        handlers,
        request
    })
}

function getShelterCleanItemPhotos({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/getShelterCleanItemPhotos.qua",
        handlers,
        request
    })
}

function saveShelterClean({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveShelterClean.qua",
        handlers,
        request
    })
}

function saveShelterCleanItem({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveShelterCleanItem.qua",
        handlers,
        request
    })
}

function getShelterStatuses({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getShelterStatuses.qua",
        handlers
    })
}

function getTickets({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/getTickets.qua",
        handlers,
        request
    })
}

function getTicketHistory({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/getTicketHistory.qua",
        handlers,
        request
    })
}

function saveTicket({handlers, request}) {
    let formData = new FormData();
    if(request.ticket.photos) {
        for (let i = 0; i < request.ticket.photos.length; i++) {
            let file = request.ticket.photos.item(i);
            formData.append("photos", file, file.name);
        }
    }
    delete request.ticket.photos;
    formData.append("request", JSON.stringify(request.ticket));
    invoke({
      uri: "qua/qua_controller/saveTicket.qua",
      options: {
        method: "POST",
        body: formData,
      },
      handlers
    });
}

function getTicketTemplateAttributes({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/getTicketTemplateAttributes.qua",
        handlers,
        request
    })
}

function saveTicketTemplate({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveTicketTemplate.qua",
        handlers,
        request
    })
}

function getControllersInfo({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getControllersInfo.qua",
        handlers
    })
}

function saveControllerInfo({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/saveControllerInfo.qua",
        handlers,
        request
    })
}

function createUserFromUpm({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/createUserFromUpm.qua",
        handlers,
        request
    })
}

function getUpmUsers({handlers}) {
    invokeGET({
        uri: "qua/qua_controller/getUpmUsers.qua",
        handlers
    })
}

function synchronizeQuaUserWithUpm({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/synchronizeQuaUserWithUpm.qua",
        handlers,
        request
    })
}

function changePassword({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/changePassword.qua",
        handlers,
        request
    })
}

function changeUserActive({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/changeUserActive.qua",
        handlers,
        request
    })
}

function removeUser({handlers, request}) {
    invokePOST({
        uri: "qua/qua_controller/removeUser.qua",
        handlers,
        request
    })
}

function getOperators({handlers}) {
    invokeGET({
        uri: "qua/qua_controller/getOperators.qua",
        handlers
    })
}

function getManagers({handlers}) {
    invokeGET({
        uri: "qua/qua_controller/getManagers.qua",
        handlers
    })
}

function getQualityControlApkInfo({handlers}) {
    invokePOST({
        uri: "qua/qua_controller/getQualityControlApkInfo.qua",
        handlers
    })
}
getQualityControlApkInfo

export default {
    getComponents,
    getComponent,
    saveComponent,
    saveComponents,
    saveComponentAttributeValues,
    getComponentAttributeValueFiles,
    removeComponent,
    removeComponentAttributeValue,
    getComponentTypes,
    saveComponentType,
    getComponentParams,
    saveComponentParam,
    saveComponentAttributeListElement,
    getComponentAttributeListElements,
    getAttributeValueTypesList,
    getTicketTypes,
    saveTicketType,
    changeTicketTypesSortingIndex,
    saveTicketCategory,
    getControlTypes,
    getTicketCategories,
    changeTicketCategoriesSortingIndex,
    getTicketStatuses,
    getSheltersCleans,
    getShelterCleanItems,
    getShelterCleanIssues,
    getShelterCleanItemHistory,
    getShelterCleanItemPhotos,
    saveShelterClean,
    saveShelterCleanItem,
    getShelterStatuses,
    getTickets,
    getTicketHistory,
    saveTicket,
    getTicketTemplateAttributes,
    saveTicketTemplate,
    saveControllerInfo,
    createUserFromUpm,
    getUpmUsers,
    synchronizeQuaUserWithUpm,
    getControllersInfo,
    changePassword,
    changeUserActive,
    removeUser,
    getOperators,
    getManagers,
    getQualityControlApkInfo
}
