import * as React from 'react';
import {styled} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ChipsList} from "@beesset/ui-components";
import {Box, Button, Fade, Link, Stack} from "@mui/material";
import {DateUtils, getLanguage, useDataFetcher} from "@beesset/common-utils";
import {UPORClientRest} from "@beesset/upor-client-module-cmn";
import HtmlReactParser from "html-react-parser";
import {CloudDownload} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <ExpandMoreIcon {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const ArticleContent = ({id, expanded}) => {
    const {t} = useTranslation();

    const {
        data
    } = useDataFetcher({
        fetch: UPORClientRest.getArticle,
        request: {
            id,
            language: getLanguage()
        },
        validateRequest: () => expanded
    });

    return <Fade
        in={data !== null && expanded}
        timeout={150}
        easing={"ease-in"}
        exit={false}
        unmountOnExit
        className={"beesset_template"}
    >
        <Box>
            {data && <React.Fragment>
                {HtmlReactParser(data.htmlContent)}
                {data.attachments && data.attachments.length > 0 && <CardActions>
                    <Stack direction="column">
                        <Stack direction="row" spacing={1}>
                            <Typography fontSize="0.85rem" color="primary">
                                {t("components.articles.attachments")}
                            </Typography>
                            <CloudDownload color="primary"/>
                        </Stack>
                        <ul style={{margin: 0}}>
                            {data.attachments && data.attachments.map((attachment) => {
                                return <li>
                                    <Link
                                        component="button"
                                        variant="caption"
                                        textAlign="left"
                                        onClick={(e) => {
                                            UPORClientRest.downloadAttachment({
                                                request: attachment
                                            });
                                            e.preventDefault();
                                            e.stopPropagation();
                                            return false;
                                        }}
                                    >
                                        {`${attachment.name} (${attachment.sizeInKBytes < 1024 ? `${attachment.sizeInKBytes}KB` : `${(attachment.sizeInKBytes / 1024).toFixed(1)}MB`})`}
                                    </Link>
                                </li>
                            })}
                        </ul>
                    </Stack>
                </CardActions>}
            </React.Fragment>}
        </Box>
    </Fade>
}

const ArticleIntro = ({intro, image, expanded}) => {
    return <Fade in={!expanded} unmountOnExit timeout={expanded == null ? 0 : 200} exit={false} easing={"ease-out"}>
        <Box>
            {image && <CardMedia
                component="img"
                sx={{
                    width: {
                        xs: "100%",
                        sm: 300
                    }, height: {
                        xs: "unset",
                        sm: 180
                    },
                    float: "left",
                    mr: 2,
                    mb: 2,
                    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.3)"
                }}
                image={image}
            />}
            <Typography variant="body2">
                {intro}
            </Typography>
        </Box>
    </Fade>
}

const ArticleHeader = ({title, categories, validFrom, validUntil, expanded}) => {
    const {t} = useTranslation();
    const ref = React.useRef();

    React.useEffect(() => {
        if (ref.current && expanded !== null) {
            setTimeout(() => {
                ref.current['scrollIntoView']({
                    block: "start",
                    behavior: 'smooth',
                });
            }, 250);
        }
    }, [expanded]);

    return <CardHeader
        ref={ref}
        disableTypography
        title={<Typography fontSize="1.1rem">{title}</Typography>}
        subheader={<Stack>
            {(validFrom || validUntil) && <Typography color="text.secondary" fontSize="0.85rem">
                {(validFrom && validUntil)
                    ? t("components.articles.period", {
                        validFrom: DateUtils.formatters.DDMONTHYY(validFrom),
                        validUntil: DateUtils.formatters.DDMONTHYY(validUntil)
                    })
                    : (validFrom
                        ? t("components.articles.validFrom", {
                            date: DateUtils.formatters.DDMONTHYY(validFrom)
                        })
                        : t("components.articles.validUntil", {
                            date: DateUtils.formatters.DDMONTHYY(validFrom)
                        }))
                }
            </Typography>}
            <ChipsList
                descriptors={categories.map((o) => {
                    return {
                        name: o.name,
                        size: "small"
                    }
                })}
            />
        </Stack>}
    >
    </CardHeader>
}

export default function Article(props) {
    const {t} = useTranslation();

    const [expanded, setExpanded] = React.useState(null);

    const handleExpandClick = () => {
        setExpanded(old => !old ? true : false);
    };

    return (
        <Card sx={{width: "100%", backgroundImage: "unset"}}>
            <ArticleHeader {...props} expanded={expanded}/>
            <Stack sx={{position: "relative"}}>
                <CardContent sx={{pt: 0}}>
                    <Stack sx={{position: "relative"}}>
                        <ArticleIntro expanded={expanded} {...props}/>
                        <ArticleContent {...props} expanded={expanded}/>
                    </Stack>
                </CardContent>
                <CardActions disableSpacing sx={{justifyContent: "flex-end"}}>
                    <Button
                        size="small"
                        disableRipple
                        color="secondary"
                        onClick={handleExpandClick}
                        endIcon={<ExpandMore expand={expanded}/>}
                    >
                        {expanded ? t("button.hide") : t("button.open")}
                    </Button>

                </CardActions>
            </Stack>
        </Card>
    );
}