import {CheckboxCell} from "@beesset/ui-components";
import {Popper, Tooltip, Typography} from "@mui/material";
import Autocomplete, {autocompleteClasses} from "@mui/material/Autocomplete";
import ListSubheader from "@mui/material/ListSubheader";
import {styled, useTheme} from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import {useTranslation} from "react-i18next";
import {VariableSizeList} from "react-window";
import {matchSorter} from 'match-sorter';

const LISTBOX_PADDING = 6; // px

function RenderRow(props) {
    const [hoverStatus, setHover] = React.useState(false);
    const textElementRef = React.useRef();

    const compareSize = () => {
        const compare = textElementRef.current ?
            textElementRef.current.scrollWidth > textElementRef.current.clientWidth : false;
        setHover(compare);
    };

    React.useEffect(() => {
        compareSize();
        window.addEventListener("resize", compareSize);
    }, []);

    React.useEffect(
        () => () => {
            window.removeEventListener("resize", compareSize);
        },
        []
    );

    const {data, index, style} = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: style.top + LISTBOX_PADDING
    };

    if (dataSet.hasOwnProperty("group")) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Tooltip
            title={dataSet[1]}
            disableHoverListener={!hoverStatus}
            arrow
            followCursor
            placement="top"
            PopperProps={{
                sx: {
                    whiteSpace: "pre-line"
                }
            }}
        >
            <li {...dataSet[0]} style={inlineStyle}>
                {dataSet[2] && (
                    <CheckboxCell
                        value={dataSet[0]["aria-selected"]}
                        style={{marginRight: "8px"}}
                        fontSize="small"
                    />
                )}
                <Typography ref={textElementRef} noWrap fontSize="0.75rem">
                    {dataSet[1]}
                </Typography>
            </li>
        </Tooltip>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
) {
    const {children, ...other} = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
        noSsr: true,
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty("group")) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {RenderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.paper}`]: {
        width: "100%",
        minWidth: "200px",
    },
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0
        },
    },
});

export default function SelectColumnFilter({
                                               column,
                                               multiple = false,
                                               mapOptions,
                                               maxVisible = 1,
                                               groupBy,
                                               isOptionEqualToValue,
                                               getOptionLabel,
                                               size
                                           }) {
    let {filterValue, setFilter, preFilteredRows, id, Header} = column;

    const [open, setOpen] = React.useState(false);
    const {t} = useTranslation();

    const options = React.useMemo(() => {
        if (mapOptions) {
            return mapOptions(id, preFilteredRows);
        } else {
            const options = new Set();
            preFilteredRows.forEach((row) => {
                let option = row.values[id];
                options.add(option);
            });
            return [...options.values()]
                .sort((a, b) =>
                    String(a).localeCompare(String(b), undefined, {numeric: true})
                )
                .map((o) => {
                    return {
                        label: o,
                    };
                });
        }
    }, [id, preFilteredRows]);

    if (!getOptionLabel) {
        getOptionLabel = (option) =>
            option.label ? "" + option.label : t("commonComponents.filter.boolean.none");
    }

    if (multiple && !filterValue) {
        filterValue = [];
    }

    const filterOptions = !mapOptions ? (options, {inputValue}) => matchSorter(options, inputValue, {keys: ['label']}) : undefined;

    return (
        <Autocomplete
            value={filterValue || null}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            multiple={multiple}
            disableCloseOnSelect={multiple}
            fullWidth
            onChange={(event, options) => {
                if (!options || (multiple && options.length === 0)) {
                    setFilter(undefined);
                } else {
                    setFilter(options);
                }
            }}
            isOptionEqualToValue={(option, value) => {
                if (isOptionEqualToValue) {
                    return isOptionEqualToValue(option, value);
                }
                return option.label == value.label;
            }}
            disableListWrap
            limitTags={1}
            size="small"
            getOptionLabel={getOptionLabel}
            options={options}
            renderOption={(props, option) => [
                props,
                getOptionLabel(option),
                multiple,
            ]}
            renderTags={() => {
            }}
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            sx={{minWidth: 120}}
            groupBy={groupBy}
            filterOptions={filterOptions}
            renderGroup={(params) => params}
            renderInput={(params) => {
                let value = params.inputProps.value;
                if (!open) {
                    if (multiple) {
                        if (filterValue && filterValue.length <= maxVisible) {
                            value = filterValue.map((o) => getOptionLabel(o)).join(", ");
                        } else {
                            value = t("commonComponents.filter.selected", {
                                count: (filterValue || []).length,
                            });
                        }
                    }
                }
                params.inputProps.value = value;
                params.inputProps['aria-label'] = `${t("commonComponents.filter.select")}: ${Header}`;
                if(!params.inputProps['sx']) {
                    params.inputProps['sx'] = {}
                }
                params.inputProps['sx']['fontSize'] = "0.9rem";

                return (
                    <TextField
                        {...params}
                        placeholder={t("commonComponents.filter.select")}
                        variant="outlined"
                    />
                );
            }}
        />
    );
}
