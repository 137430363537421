import {Chip, Stack, Typography} from "@mui/material";
import {DateUtils} from "@beesset/common-utils";
import {mapStatusColor, PriceDescriptorCell, ProductDescriptorCell} from "../TransactionsHistory";
import {Table} from "@beesset/ui-components";
import React from "react";
import WebTransactionDetails from "./WebTransactionDetails";
import {useLocation, useNavigate} from "react-router-dom";

const WebTransactionsTable = ({
                                  translations,
                                  transactions,
                                  refresh,
                                  FiltersButton,
                                  downloadConfirmation,
                                  showAfterPurchaseMessage,
                                  showInvoiceDialog,
                                  showRefundDialog,
                                  showComplaintDialog
                              }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const purchaseIdRef = React.useRef((() => {
        if (location.state && location.state.purchaseId) {
            let found = transactions.find((el) => {
                return el.transactionId === location.state.purchaseId;
            });
            if (found) {
                return found.transactionId;
            }
        }
        return null;
    })());

    React.useEffect(() => {
        if (purchaseIdRef.current) {
            setTimeout(() => {
                showAfterPurchaseMessage(purchaseIdRef.current);
                purchaseIdRef.current = null;
            }, 500);

            navigate(location.pathname, {
                replace: true
            });
        }
    }, [location]);

    const tableConfig = React.useMemo(() => {
        let expandedRows = null;

        if (purchaseIdRef.current) {
            expandedRows = {
                [purchaseIdRef.current]: true
            }
        }

        return {
            columns: [{
                Header: translations.medium,
                accessor: props => `${translations.mapMediumType(props.mediumType)} ${props.mediumName}`,
                Cell: ({row}) => {
                    return (<Stack>
                        <Typography sx={{fontSize: "0.875rem"}}>
                            {row.original.mediumName}
                        </Typography>
                        <Typography sx={{color: "text.secondary", fontSize: "0.75rem"}}>
                            {translations.mapMediumType(row.original.mediumType)}
                        </Typography>
                    </Stack>);
                }
            }, {
                Header: translations.channel,
                accessor: "channel"
            }, {
                Header: translations.product,
                accessor: props => {
                    let items = [];
                    if(props.item) {
                        if(props.item.validityStatus){
                            items.push(translations.mapValidityStatus(props.item.validityStatus));
                        }
                        items.push(`${props.item.profileName} ${props.item.productName}`);
                    }
                    else {
                        items.push(translations.manyProducts);
                    }
                    return items.join(" ");
                },
                filterType: "select",
                Cell: ({row, value}) => {
                    if (row.original.item) {
                        return <ProductDescriptorCell {...row.original.item} translations={translations}/>
                    }
                    return <Typography sx={{fontSize: "0.875rem"}}>
                        {value}
                    </Typography>
                }
            }, {
                Header: translations.price,
                accessor: props => props.price.toFixed(2),
                Cell: ({value, row}) => {
                    return <PriceDescriptorCell price={value} currencyCode={row.original.currencyCode}/>;
                }
            }, {
                Header: translations.transactionDate,
                type: "datetime",
                accessor: "transactionDate",
                Cell: ({row}) => {
                    return (<Stack>
                        <Typography sx={{fontSize: "0.875rem"}}>
                            {DateUtils.formatters.HH_MM_SS(row.original.transactionDate)}
                        </Typography>
                        <Typography sx={{color: "text.secondary", fontSize: "0.75rem"}}>
                            {DateUtils.formatters.DDMONTHYY(row.original.transactionDate)}
                        </Typography>
                    </Stack>);
                }
            }, {
                Header: translations.status,
                accessor: props => translations.mapStatusLabel(props.status),
                Cell: ({value, row}) => {
                    return <Chip
                        size="small"
                        label={value}
                        variant="outlined"
                        color={mapStatusColor(row.original.status)}
                    />
                }
            }],
            actions: {
                onRefresh: refresh,
                other: [FiltersButton],
                onRowExpanded: ({row}) => {
                    return <WebTransactionDetails
                        transaction={row.original}
                        translations={translations}
                        downloadConfirmation={downloadConfirmation}
                        showInvoiceDialog={showInvoiceDialog}
                        showRefundDialog={showRefundDialog}
                        showComplaintDialog={showComplaintDialog}
                    />;
                },
                onRowClicked: (bean, row) => {
                    row.toggleRowExpanded();
                }
            },
            pagination: true,
            pageSize: 15,
            globalFiltering: true,
            sorting: true,
            getRowId: row => row.transactionId,
            expandedRows: expandedRows
        }
    }, [translations]);

    return <Table {...tableConfig} data={transactions}/>;
}

export default WebTransactionsTable;