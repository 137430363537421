import '@beesset/upor-server-module/public/css/TinymceTemplateStyles.css';
import React from 'react';
import {getLanguage, useDataFetcher} from "@beesset/common-utils";
import {Tabs} from "@beesset/ui-components";
import HtmlReactParser from 'html-react-parser';
import {Box, Skeleton, Fade} from "@mui/material";
import {useLocation} from "react-router-dom";

const SingleHtml = ({content}) => {
    return <div className="beesset_template" style={{width: "100%"}}>
        {HtmlReactParser(content)}
    </div>
}

const MultipleHtml = ({contents}) => {
    const location = useLocation();

    let foundIndex = contents.findIndex((content) => location.state && location.state['selectedTabId'] && content.id === location.state['selectedTabId']);

    return <Tabs
        selectedTab={foundIndex >= 0 ? foundIndex : undefined}
        items={contents.map((o) => {
            return {
                label: o.title,
                content: <SingleHtml {...o}/>
            }
        })}/>
}

const LoadingHtml = () => {
    return <Fade in={true}>
        <Box  flexDirection="column" width={"100%"} display="flex" flex={1}>
            <Skeleton sx={{flex: "1", transform: "scale(1, 0.90)"}}/>
            <Skeleton sx={{flex: "2", transform: "scale(1, 0.90)"}}/>
            <Skeleton sx={{flex: "3", transform: "scale(1, 0.90)"}}/>
            <Skeleton sx={{flex: "1", transform: "scale(1, 0.90)"}}/>
        </Box>
    </Fade>
}

const TemplateContainer = ({fetch}) => {
    const [loading, setLoading] = React.useState(false);
    const loadingTimeoutRef = React.useRef(null);

    const {data} = useDataFetcher({
        fetch: (props) => {
            if (loading === false && loadingTimeoutRef.current == null) {
                loadingTimeoutRef.current = setTimeout(() => {
                    setLoading(true);
                }, 500);
            }
            fetch(props);
        },
        request: {
            language: getLanguage()
        },
        showLoading: false,
        convert: (response) => response['contents'],
        callback: () => {
            if (loadingTimeoutRef.current != null) {
                clearTimeout(loadingTimeoutRef.current);
                loadingTimeoutRef.current = null;
            }
            setTimeout(() => {
                setLoading(false);
            }, 300);
        }
    });

    return <React.Fragment>
        {loading && <LoadingHtml/>}
        {!loading && data && data.length === 1 && <SingleHtml {...data[0]}/>}
        {!loading && data && data.length > 1 && <MultipleHtml contents={data}/>}
    </React.Fragment>
}

export {
    TemplateContainer
};

