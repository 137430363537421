import {generateId} from "@beesset/common-utils";
import {Dialog, FormContainer} from "@beesset/ui-components";
import {Close, FilterList} from "@mui/icons-material";
import {Badge, Box, Button, IconButton, Popover, Stack, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import isEqual from "react-fast-compare";
import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import {useTheme} from "@mui/system";

export default function useExtendedTableFilters({defaultValues = {}, FilterFieldsComponent, Icon}) {
    const theme = useTheme();
    let fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const defaultValuesRef = React.useRef(defaultValues);

    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const filterButtonRef = React.useRef(null);
    const [filterState, setFilterState] = React.useState({
        filterValue: defaultValuesRef.current,
        changesCount: 0
    });

    function handleFilterChange(object) {
        let changes = 0;
        Object.keys(object).forEach((key) => {
            if (!isEqual(object[key], defaultValuesRef.current[key])) {
                changes++;
            }
        });
        filterButtonRef.current.updateChangesCount(changes);
        setFilterState({
            filterValue: object,
            changesCount: changes
        });
        handleClose();
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return {
        FiltersPopover: <React.Fragment>
            {!fullScreen && <PopoverView
                {...filterState}
                FilterFieldsComponent={FilterFieldsComponent}
                onFilter={handleFilterChange}
                onClose={handleClose}
                defaultValues={defaultValuesRef.current}
                anchorEl={anchorEl}
            />}
            {fullScreen && <FullscreenView
                {...filterState}
                FilterFieldsComponent={FilterFieldsComponent}
                onFilter={handleFilterChange}
                onClose={handleClose}
                defaultValues={defaultValuesRef.current}
                anchorEl={anchorEl}
            />}
        </React.Fragment>,
        FiltersButton: {
            name: t("button.filter"),
            onClick: ({event, currentTarget}) => {
                setAnchorEl(currentTarget || event.currentTarget);
            },
            Icon: <FilterButton innerRef={filterButtonRef}>
                {Icon ? <Icon/> : <FilterList/>}
            </FilterButton>,
        },
        ...filterState
    };
}

const PopoverView = ({onClose, anchorEl, ...props}) => {
    const {t} = useTranslation();

    return <Popover
        id="extended-filter-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
    >
        <Box m={3}>
            <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography>
                        {t("commonComponents.filter.many")}
                    </Typography>
                    <IconButton color="primary" aria-label={t("button.close")} onClick={onClose}>
                        <Close/>
                    </IconButton>
                </Stack>
                <FilterForm {...props}/>
            </Stack>
        </Box>
    </Popover>
}

const FullscreenView = ({onClose, anchorEl, ...props}) => {
    const {t} = useTranslation();

    return <Dialog
        open={Boolean(anchorEl)}
        title={t("commonComponents.filter.many")}
        actions={[{
            name: t("button.close"),
            color: "inherit",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }]}
    >
        <Stack spacing={2}>
            <FilterForm {...props}/>
        </Stack>
    </Dialog>
}

const InnerFilterForm = ({FilterFieldsComponent, formId, changesCount, defaultValues}) => {
    const {t} = useTranslation();
    const {watch, reset, setValue, getValues} = useFormContext();
    const [bean, setBean] = React.useState(getValues());

    React.useEffect(() => {
        const subscription = watch((value) => {
            setBean(value);
        });
        return () => subscription['unsubscribe']();
    }, [watch]);

    return <React.Fragment>
        <FilterFieldsComponent bean={bean} setValue={setValue}/>
        <Stack
            direction={{
                xs: "column",
                sm: "row",
            }}
            spacing={1}
            alignItems="baseline"
            marginTop="10px"
            width={"100%"}
        >
            {changesCount > 0 && (
                <Button
                    sx={{marginTop: 1}}
                    size="large"
                    variant="text"
                    color="error"
                    onClick={(event) => {
                        reset({...defaultValues});
                    }}
                    fullWidth
                >
                    {t("button.reset")}
                </Button>
            )}
            <Button
                sx={{marginTop: 1}}
                size="large"
                type="submit"
                variant="text"
                form={formId}
                fullWidth
            >
                {t("button.filter")}
            </Button>
        </Stack>
    </React.Fragment>
}

const FilterForm = ({filterValue, onFilter, ...props}) => {
    const formId = React.useRef(generateId(10));

    return (
        <FormContainer
            id={formId.current}
            defaultValues={filterValue}
            onSuccess={onFilter}
        >
            <InnerFilterForm {...props} formId={formId.current}/>
        </FormContainer>
    );
};

const FilterButton = ({innerRef, children}) => {
    const [changesCount, setChangesCount] = React.useState(0);

    innerRef.current = {
        updateChangesCount: setChangesCount,
    };

    return (
        <Badge color="info" badgeContent={changesCount}>
            {children}
        </Badge>
    );
};
