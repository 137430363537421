import {Box} from "@mui/material";
import {styled} from "@mui/system";

const BoxWithMargin = styled(Box)(({theme, maxWidth}) => ({
    margin: 0,
    marginTop: theme.spacing(2),
    maxWidth: "unset",
    [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(2),
        marginTop: theme.spacing(3),
        maxWidth
    },
    [theme.breakpoints.up("md")]: {
        margin: theme.spacing(3)
    }
}));

export default BoxWithMargin;