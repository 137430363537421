import {useAccess, useDataFetcher} from "@beesset/common-utils";
import {hideLoader, showLoader, Table} from "@beesset/ui-components";
import {DeleteRounded, Edit} from "@mui/icons-material";
import {Chip, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import DeletionDialog from "./DeletionDialog";
import Details from "./Details";
import useFilters from "./Filters";
import PreviewDialog from "./PreviewDialog";
import {useLocation, useNavigate} from "react-router-dom";

const EnumActionType = {
    DELETION: "DELETION",
    EDITION: "EDITION",
    CREATION: "CREATION",
    PREVIEW: "PREVIEW",
};

function createDialogConfig({
                                detailsConfig,
                                selectedObject,
                                setSelectedObject,
                                refresh,
                            }) {
    if (!selectedObject) {
        return;
    }

    let result = {
        title: detailsConfig.title,
        object: selectedObject.object,
        onCancel: () => {
            setSelectedObject(null);
        },
    };

    if (selectedObject.actionType === EnumActionType.DELETION) {
        if (detailsConfig.deletion) {
            return {
                deletionDialogConfig: {
                    ...result,
                    ...detailsConfig.deletion,
                    onDelete: ({reason}) => {
                        if (detailsConfig.deletion.onDelete) {
                            showLoader();
                            detailsConfig.deletion.onDelete({
                                request: {
                                    loid: selectedObject.object.loid,
                                    reason: reason,
                                },
                                handlers: {
                                    success: () => {
                                        refresh();
                                    },
                                    failure: () => {
                                        hideLoader();
                                    },
                                },
                            });
                        }
                    },
                },
            };
        }
        return;
    }

    if (selectedObject.actionType === EnumActionType.PREVIEW) {
        if (detailsConfig.preview) {
            return {
                previewDialogConfig: {
                    ...result,
                    ...detailsConfig.preview,
                },
            };
        }
        return;
    }

    let action;
    if (selectedObject.actionType === EnumActionType.CREATION) {
        action = detailsConfig.addition;
    } else if (selectedObject.actionType === EnumActionType.EDITION) {
        action = detailsConfig.edition;
    }

    if (action) {
        return {
            editableDialogConfig: {
                ...result,
                ...action,
                onSave: (object) => {
                    if (action.onSave) {
                        showLoader();
                        action.onSave({
                            request: action.convertRequest
                                ? action.convertRequest(object)
                                : object,
                            handlers: {
                                success: () => {
                                    refresh();
                                },
                                failure: () => {
                                    hideLoader();
                                },
                            },
                        });
                    }
                },
            },
        };
    }
}

function createTableConfig({
                               setSelectedObject,
                               refresh,
                               detailsConfig,
                               tableConfig,
                               FiltersButton,
                               t,
                           }) {
    let actions = {
        onRefresh: refresh,
        onAdd: undefined,
        onRowClicked: undefined,
        other: [FiltersButton],
    };

    if (detailsConfig.addition) {
        actions.onAdd = () => {
            setSelectedObject({
                actionType: EnumActionType.CREATION,
                object: {},
            });
        };
    }

    if (detailsConfig.preview) {
        let fetchData = detailsConfig.fetchData;
        if (detailsConfig.preview && detailsConfig.preview.fetchData) {
            fetchData = detailsConfig.preview.fetchData;
        }

        actions.onRowClicked = (row) => {
            if (fetchData) {
                fetchData({
                    handlers: {
                        success: (response) => {
                            setSelectedObject({
                                actionType: EnumActionType.PREVIEW,
                                object: response,
                            });
                        },
                    },
                    request: {
                        loid: row.loid,
                    },
                });
            } else {
                setSelectedObject({
                    actionType: EnumActionType.PREVIEW,
                    object: row,
                });
            }
        };
    }

    let editionEnabled = false,
        validateEdition,
        fetchDataForEdition;
    if (detailsConfig.edition) {
        editionEnabled = true;
        validateEdition = detailsConfig.edition.validate || detailsConfig.validate;
        fetchDataForEdition =
            detailsConfig.edition.fetchData || detailsConfig.fetchData;
    }

    let deletionEnabled = detailsConfig.deletion != undefined;

    const {columns, hiddenIdColumn, ...restConfig} = tableConfig;

    let DELETED = t("historicalView.objectStatus.DELETED");
    let PAST = t("historicalView.objectStatus.PAST");
    let PRESENT = t("historicalView.objectStatus.PRESENT");
    let FUTURE = t("historicalView.objectStatus.FUTURE");

    const STATUSES = {
        DELETED: DELETED,
        PAST: PAST,
        PRESENT: PRESENT,
        FUTURE: FUTURE,
    };

    const STATUSES_CHIPS = {
        [DELETED]: (
            <Chip label={DELETED} color="error" variant="outlined" size="small"/>
        ),
        [PAST]: (
            <Chip label={PAST} color="default" variant="outlined" size="small"/>
        ),
        [PRESENT]: (
            <Chip label={PRESENT} color="primary" variant="outlined" size="small"/>
        ),
        [FUTURE]: (
            <Chip label={FUTURE} color="info" variant="outlined" size="small"/>
        ),
    };

    let tableColumns = [];
    if (!hiddenIdColumn) {
        tableColumns.push({
            Header: t("id"),
            accessor: "tailId",
            filterType: "multiSelect",
        });
    }
    tableColumns.push(...columns);
    tableColumns.push(
        {
            Header: t("startDateShort"),
            accessor: "startDate",
            Cell: ({value}) => {
              return <Typography fontSize="inherit" noWrap>
                  {value}
              </Typography>
            },
            type: "date",
        },
        {
            Header: t("endDateShort"),
            accessor: "endDate",
            Cell: ({value}) => {
                return <Typography fontSize="inherit" noWrap>
                    {value}
                </Typography>
            },
            type: "date",
        },
        {
            Header: t("historicalView.dataStatus"),
            accessor: (props) => STATUSES[props.historicalObjectStatus],
            filterType: "select",
            Cell: ({value}) => STATUSES_CHIPS[value],
        },
        {
            Header: "",
            accessor: "delete",
            Filter: false,
            Cell: (cell) => {
                let row = cell.row;

                return (
                    <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
                        {tableConfig.additionalLastColumnButtons && tableConfig.additionalLastColumnButtons.map((el, index) => {
                            if(el) {
                                return <Tooltip title={el.name} key={`table-action-button=${2 + index}`}>
                                    <IconButton
                                        tabIndex={1}
                                        size="small"
                                        color={el.color}
                                        onClick={(e) => {
                                            el.onClick(cell);
                                            e.preventDefault();
                                            e.stopPropagation();
                                            return false;
                                        }
                                        }
                                    >
                                        {el.Icon}
                                    </IconButton>
                                </Tooltip>
                            }
                        })}
                        {editionEnabled &&
                            (row.original.historicalObjectStatus === "PRESENT" ||
                                row.original.historicalObjectStatus === "FUTURE") && (
                                <Tooltip title={t("button.edit") } key={`table-action-button=${0}`}>
                                    <IconButton
                                        tabIndex={1}
                                        size="small"
                                        color="primary"
                                        onClick={(e) => {
                                            if (!validateEdition || validateEdition(row.original)) {
                                                if (fetchDataForEdition) {
                                                    fetchDataForEdition({
                                                        handlers: {
                                                            success: (response) => {
                                                                setSelectedObject({
                                                                    actionType: EnumActionType.EDITION,
                                                                    object: response,
                                                                });
                                                            },
                                                        },
                                                        request: {
                                                            loid: row.original.loid,
                                                        },
                                                    });
                                                } else {
                                                    setSelectedObject({
                                                        actionType: EnumActionType.EDITION,
                                                        object: row.original,
                                                    });
                                                }
                                            }

                                            e.preventDefault();
                                            e.stopPropagation();
                                            return false;
                                        }}
                                    >
                                        <Edit/>
                                    </IconButton>
                                </Tooltip>
                            )}
                        {deletionEnabled &&
                            row.original.historicalObjectStatus === "FUTURE" && (
                                <Tooltip title={t("button.delete")} key={`table-action-button=${1}`}>
                                    <IconButton
                                        tabIndex={1}
                                        size="small"
                                        color="primary"
                                        onClick={(e) => {
                                            setSelectedObject({
                                                actionType: EnumActionType.DELETION,
                                                object: row.original,
                                            });

                                            e.preventDefault();
                                            e.stopPropagation();
                                            return false;
                                        }}
                                    >
                                        <DeleteRounded/>
                                    </IconButton>
                                </Tooltip>
                            )}
                    </Stack>
                );
            },
        }
    );
    return {
        ...restConfig,
        actions,
        columns: tableColumns,
        autoResetSortBy: false,
        autoResetFilters: false,
    };
}

const HistoricalTableWithDetailsPrv = ({tableConfig, detailsConfig}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedObject, setSelectedObject] = React.useState(null);
    const [tmpSelectedObject, setTmpSelectedObject] = React.useState(null);

    const {FiltersPopover, FiltersButton, filterValue, setFilterValue} =
        useFilters();

    const {data, refresh} = useDataFetcher({
        fetch: props => {
            showLoader();
            tableConfig.dataProvider.fetchData(props);
        },
        convert: tableConfig.dataProvider.convertData,
        request: filterValue,
        callback: () => {
            if(!tmpSelectedObject) {
                hideLoader();
            }
            setSelectedObject(null)
        },
        showLoading: false
    });

    React.useEffect(() => {
        if (location.state && location.state['selectedObject']) {
            setTmpSelectedObject({
                actionType: EnumActionType.EDITION,
                loid: location.state['selectedObject'],
            });
            navigate(location.pathname, {
                replace: true
            });
        }
    }, [location.state]);

    React.useEffect(() => {
        if(tmpSelectedObject && data) {
            if(selectedObject) {
                setSelectedObject(null);
            }
            else {
                setTmpSelectedObject(null);

                let found = data && data.find((o) => o.loid === tmpSelectedObject.loid);

                if(found) {
                    showLoader(0);
                    let fetchDataForEdition =
                        detailsConfig.edition.fetchData || detailsConfig.fetchData;
                    if (fetchDataForEdition) {
                        fetchDataForEdition({
                            handlers: {
                                success: (response) => {
                                    setTimeout(() => {
                                        hideLoader();
                                        setSelectedObject({
                                            actionType: tmpSelectedObject.actionType,
                                            object: response,
                                        });
                                    }, 300);
                                }
                            },
                            request: {
                                loid: found.loid,
                            },
                        });
                    } else {
                        setTimeout(() => {
                            hideLoader();
                            setSelectedObject({
                                actionType: tmpSelectedObject.actionType,
                                object: found
                            });
                        }, 300);
                    }
                }
            }
        }
    }, [tmpSelectedObject, data, selectedObject]);

    const finalTableConfig = React.useMemo(() => {
        return createTableConfig({
            setSelectedObject,
            detailsConfig,
            tableConfig,
            refresh,
            FiltersButton,
            t,
        });
    }, [tableConfig, detailsConfig, t]);

    const {editableDialogConfig, previewDialogConfig, deletionDialogConfig} =
    React.useMemo(() => {
        return createDialogConfig({
            detailsConfig,
            selectedObject,
            setSelectedObject,
            refresh,
        });
    }, [detailsConfig, selectedObject]) || {};

    return (
        <React.Fragment>
            <Table {...finalTableConfig} data={data}/>
            {FiltersPopover}
            {editableDialogConfig && <Details {...editableDialogConfig} />}
            {previewDialogConfig && <PreviewDialog {...previewDialogConfig} />}
            {deletionDialogConfig && <DeletionDialog {...deletionDialogConfig} />}
        </React.Fragment>
    );
};

const HistoricalTableWithDetails = React.memo(
    ({tableConfig, detailsConfig}) => {
        const {hasAccess} = useAccess();

        const detailsConfigAfterAccessCheck = React.useMemo(() => {
            let result = {...detailsConfig};
            {
                let accessRights =
                    detailsConfig.edition && detailsConfig.edition.accessRights
                        ? detailsConfig.edition.accessRights
                        : detailsConfig.accessRights;
                if (!hasAccess(accessRights)) {
                    result.edition = undefined;
                }
            }
            {
                let accessRights =
                    detailsConfig.addition && detailsConfig.addition.accessRights
                        ? detailsConfig.addition.accessRights
                        : detailsConfig.accessRights;
                if (!hasAccess(accessRights)) {
                    result.addition = undefined;
                }
            }
            {
                let accessRights =
                    detailsConfig.deletion && detailsConfig.deletion.accessRights
                        ? detailsConfig.deletion.accessRights
                        : detailsConfig.accessRights;
                if (!hasAccess(accessRights)) {
                    result.deletion = undefined;
                }
            }
            return result;
        }, [hasAccess, detailsConfig]);

        return (
            <HistoricalTableWithDetailsPrv
                tableConfig={tableConfig}
                detailsConfig={detailsConfigAfterAccessCheck}
            />
        );
    }
);

export default HistoricalTableWithDetails;
