import {SnackbarProvider} from "@beesset/ui-components";
import {Layout} from "./layout/Layout";
import ThemeProvider from "./providers/ThemeProvider";
import ConfigProvider from "./providers/ConfigProvider";
import StartupLoader from "./layout/loader/StartupLoader";
import React from "react";
import "./index.css";
import {NativeAppHandler} from "./handlers/NativeAppHandler";
import {UPORUtils} from "../utils";

class ModuleConfig {
    HttpClientProviderProps = {
        portalApiDomain: ""
    }
    ThemeProps = {
        primary: "#3b4063",
        secondary: "#d5a665"
    }
    ConfigProviderProps = {
        LogoComponent: () => <React.Fragment></React.Fragment>,
        protectedResources: false
    }
    LayoutProps = {
        components: [],
        componentDefaultProps: {},
        EuropeanFundsComponent: {
            content: <React.Fragment></React.Fragment>
        }
    }
}

const ClientModuleInitializer = ({ThemeProps, ConfigProviderProps, HttpClientProviderProps, LayoutProps}) => {
    return <ConfigProvider ConfigProviderProps={ConfigProviderProps} HttpClientProviderProps={HttpClientProviderProps}>
        <ThemeProvider ThemeProps={ThemeProps}>
            <SnackbarProvider>
                {UPORUtils.isNativePlatform() && <NativeAppHandler/>}
                <StartupLoader/>
                <Layout LayoutProps={LayoutProps}/>
            </SnackbarProvider>
        </ThemeProvider>
    </ConfigProvider>
}

export {
    ClientModuleInitializer,
    ModuleConfig
};