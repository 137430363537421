import {useTranslation} from "react-i18next";
import React from "react";
import {FormContainer, hideLoader, showLoader, Snackbar, TextField} from "@beesset/ui-components";
import {Fade, IconButton, InputAdornment, Stack, Typography} from "@mui/material";
import {Login, Visibility, VisibilityOff} from "@mui/icons-material";
import {UPORClientRest, UPORUtils} from "@beesset/upor-client-module-cmn";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {LoadingButton} from "@mui/lab";

const SignInForm = ({Header, LogoComponent}) => {
    const [bean, setBean] = React.useState(null);

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const {t, i18n} = useTranslation();
    const [showPassword, setShowPassword] = React.useState(false);

    React.useEffect(() => {
        let timeout = undefined;

        if (bean) {
            timeout = setTimeout(() => {
                try {
                    UPORClientRest.login({
                        request: {
                            ...bean,
                            j_username: bean['j_username'].toLowerCase()
                        },
                        handlers: {
                            failure: (error) => {
                                setBean(null);
                                Snackbar.error(i18n.exists(
                                    `components.signIn.${error.exceptionId}`)
                                    ? t(`components.signIn.${error.exceptionId}`)
                                    : null
                                );
                            }
                        }
                    });
                } catch (e) {
                    setBean(null);
                }
            }, 800);
        }

        return () => {
            if (timeout !== undefined) {
                clearTimeout(timeout);
            }
        }
    }, [bean]);

    React.useEffect(() => {
        if (token != null) {
            showLoader();
            UPORClientRest.verifyAccount({
                handlers: {
                    success: () => {
                        Snackbar.info(t("components.signIn.verifyAccountSuccess"));
                    },
                    failure: () => {
                    },
                    both: () => {
                        navigate("/auth", {replace: true});
                        hideLoader();
                    }
                },
                request: {
                    token
                }
            });
        }
    }, [token]);

    return (

        <FormContainer
            onSuccess={(bean) => {
                setBean({
                    j_username: bean.field1,
                    j_password: bean.field2
                });
            }} style={{width: "100%"}}>
            <Fade in={true}>
                <Stack spacing={5}>
                    {Header && <Header/>}
                    {LogoComponent && <LogoComponent/>}
                    <Stack spacing={1}>
                        <Typography variant="h4" color="primary" fontWeight="bold">
                            {t("components.signIn.name")}
                        </Typography>
                        <Typography variant="h6">
                            {!UPORUtils.isNativePlatform()
                                ? t("components.signIn.description")
                                : t("components.signIn.nativeDescription")}
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <TextField
                            autoComplete="off"
                            sx={{marginTop: "unset"}}
                            name="field1"
                            label={t("common.email")}
                            type="email"
                            required
                            placeholder={t("common.emailPlaceholder")}
                        />
                        <TextField
                            name="field2"
                            sx={{marginTop: "unset"}}
                            label={t("common.password")}
                            required
                            type={showPassword ? "text" : "password"}
                            placeholder={t("common.passwordPlaceholder")}
                            autoComplete="new-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={t(showPassword ? "button.hidePassword" : "button.showPassword")}
                                            onClick={() => setShowPassword((old) => !old)}
                                            onMouseDown={(event) => event.preventDefault()}
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="body2" alignSelf="flex-end" color="text.secondary" component={NavLink}
                                    to={"/auth/password-recovery"}>
                            {t("components.signIn.recoverPassword")}
                        </Typography>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={bean !== null}
                            loadingPosition="end"
                            fullWidth={true}
                            endIcon={<Login/>}
                        >
                            {t("components.signIn.enter")}
                        </LoadingButton>
                    </Stack>
                    <Stack spacing={2} alignItems="center">
                        <div style={{display: "inline-block", textAlign: "center"}}>
                            <Typography variant="body2">
                                {t("components.signIn.signUpQuestion")}
                            </Typography>
                            <Typography variant="body2" component={NavLink} to={"/auth/sign-up"}>
                                {t("components.signIn.signUp")}
                            </Typography>
                        </div>
                        <Typography variant="body2" component={NavLink} to={"/regulations/privacyPolicy"}>
                            {t("components.privacyPolicy.name")}
                        </Typography>
                        <Typography variant="body2" component={NavLink} to={"/declaration-of-accessibility"}>
                            {t("components.declarationOfAccessibility.name")}
                        </Typography>
                    </Stack>
                </Stack>
            </Fade>
        </FormContainer>
    );
}

export {
    SignInForm
};