import {UPORClientRest} from "@beesset/upor-client-module-cmn";
import {SelectField} from "@beesset/ui-components";
import {useTranslation} from "react-i18next";
import React from "react";
import {useDataFetcher} from "@beesset/common-utils";
import {useFormContext} from "react-hook-form";
import {Box, Fade} from "@mui/material";

const ChannelsField = ({applicationType}) => {
    const [visible, setVisible] = React.useState(false);
    const {setValue} = useFormContext();
    const {
        data: channels
    } = useDataFetcher({
        fetch: UPORClientRest.getApplicationChannels,
        convert: response => response["channels"],
        request: {
            applicationType
        }
    })
    const {t} = useTranslation();

    React.useEffect(() => {
        if (channels) {
            if (channels.length === 1) {
                setValue("channel", channels[0]);
            } else {
                setVisible(true);
            }
        }
    }, [channels]);

    return visible && <Fade in={visible} timeout={500}>
        <Box>
            <SelectField
                isStacked
                name="channel"
                label={t("components.history.channel")}
                required
                disableClearButton
                getOptionLabel={(option) => {
                    return option.name
                }}
                getOptionId={(option) => {
                    return option.loid;
                }}
                optionsProvider={{
                    options: channels
                }}
            />
        </Box>
    </Fade>
}

export default ChannelsField;