import {useStepper} from "@beesset/ui-components";
import {useTariffs} from "../TariffsProvider";
import {CommonStep, DESCRIPTOR_TYPES} from "./CommonStep";
import React from "react";

const Step2_CategorySelection = ({translations, nextStepOnStartup}) => {
    const {getCategories} = useTariffs();
    const {goNext, getData, updateData} = useStepper();

    React.useEffect(() => {
        if (nextStepOnStartup) {
            goNext();
        }
    }, []);


    function onSelect(item) {
        updateData({
            productTimeUnitType: item.productTimeUnitType,
            productTimeUnitTypeValue: item.productTimeUnitTypeValue,
            productCategory: item.productCategory,
            productLoid: item.productLoid,
            startDate: null,
            endDate: null,
            quantity: 1,
            vehicleNumber: null,
            transactionInit: null
        });
        goNext();
    }

    const data = getData();
    const descriptors = React.useMemo(() => {
        let categories = getCategories(data.profileLoid)
            .map((group) => ({
                type: DESCRIPTOR_TYPES.SUBLIST,
                descriptors: [
                    {
                        type: DESCRIPTOR_TYPES.HEADER,
                        name: group.productGroupName,
                    },
                    ...group.productsConfigurations.map((product) => {
                        return {
                            name: product.productConfigurationName,
                            value: product.productConfigurationDescription,
                            ...product
                        }
                    })
                ]
            }));

        return categories
            .map((o, index) => (
                index < categories.length - 1 ? [o, {
                    type: DESCRIPTOR_TYPES.DIVIDER
                }] : o
            )).reduce((a, b) => a.concat(b), []);
    }, [data.profileLoid]);

    return <CommonStep
        title={translations.categorySelectionDesc}
        onSelect={onSelect}
        descriptors={descriptors}
    />
}

export default Step2_CategorySelection;