import {useTranslation} from "react-i18next";
import {Stack} from "@mui/system";
import {TextField} from "@beesset/ui-components";
import {DateField} from "./DateField";
import {FormControlLabel, Switch, Typography} from "@mui/material";
import {useFormContext} from "react-hook-form";
import React from "react";
import {DateUtils} from "@beesset/common-utils";
import {validatePolish} from "validate-polish";


const PeselOrDateOfBirthField = () => {
    const {
        formState: {errors}
    } = useFormContext();

    const [foreigner, setForeigner] = React.useState(false);
    const {t} = useTranslation();
    const {setValue, getValues} = useFormContext();

    React.useEffect(() => {
        if (foreigner === true) {
            setValue("pesel", null);
        } else {
            setValue("dateOfBirth", null);
        }
    }, [foreigner, setValue, getValues]);

    return <Stack direction="row" spacing={1}
                  alignItems={((foreigner && errors.dateOfBirth) || (!foreigner && errors.pesel)) ? "baseline" : "center"}>
        {!foreigner && <TextField
            name={"pesel"}
            label={t("common.pesel")}
            required
            isStacked
            maxLength={11}
            inputProps={{
                inputMode: "numeric"
            }}
            validation={{
                validate: (value) => {
                    if(!validatePolish.pesel(value)) {
                        return t("validation.wrongPesel");
                    }
                }
            }}
        />}
        {foreigner && <DateField
            name="dateOfBirth"
            label={t("common.dateOfBirth")}
            required
            isStacked
            openTo="year"
            views={["year", "month", "day"]}
            minDate={DateUtils.getNow().startOf("day").subtract(120, "year")}
            maxDate={DateUtils.getNow().startOf("day")}
            disableHighlightToday
        />}
        <FormControlLabel
            value="bottom"
            control={<Switch color="primary" size="small" onChange={() => setForeigner(old => !old)}/>}
            label={<Typography sx={{
                userSelect: "none",
                textAlign: "center",
                fontSize: {xs: "0.75rem", md: "0.95rem"},
                lineHeight: 1.15
            }} variant="caption">{t("common.foreigner")}</Typography>}
        />
    </Stack>
}
export {PeselOrDateOfBirthField};
