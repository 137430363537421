import {Chip, Stack, Typography} from "@mui/material";
import {Table} from "@beesset/ui-components";
import React from "react";
import {DateUtils} from "@beesset/common-utils";
import {mapStatusColor, mapValidityStatusColor, PriceDescriptorCell} from "../TransactionsHistory";
import NativeTransactionDetails from "./NativeTransactionDetails";
import {UPORUtils} from "@beesset/upor-client-module-cmn";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

const NativeTransactionsTable = ({
                                     transactions,
                                     refresh,
                                     FiltersButton,
                                     translations,
                                     downloadConfirmation,
                                     showAfterPurchaseMessage,
                                     showInvoiceDialog,
                                     showRefundDialog,
                                     showComplaintDialog
                                 }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [transaction, setTransaction] = React.useState(null);

    React.useEffect(() => {
        if (location.state && location.state.purchaseId) {
            let found = transactions.find((el) => {
                return el.transactionId === location.state.purchaseId;
            });
            if (found) {
                setTimeout(() => {
                    showAfterPurchaseMessage(location.state.purchaseId);
                }, 500);
            }
            navigate(
                `${location.pathname}${found ? `?transactionId=${found.transactionId}` : ""}`, {
                    replace: true
                }
            );
        }
    }, [location]);

    React.useEffect(() => {
        let transactionId = searchParams.get("transactionId");
        if (searchParams.get("transactionId")) {
            let found = transactions.find((o) => o.transactionId == transactionId);
            setTransaction(found);
        } else {
            setTransaction(null);
        }
    }, [searchParams, transactions]);

    React.useEffect(() => {
        if (transaction) {
            let found = transactions.find((o) => o.loid == transaction.loid);
            if (found) {
                setTransaction(found)
            } else {
                setTransaction(null);
            }
        }
    }, [transactions]);

    const tableConfig = React.useMemo(() => {

        return {
            columns: [{
                Header: translations.transaction,
                id: "overview",
                Cell: ({row}) => {
                    let bean = row.original;
                    return <React.Fragment>
                        {!UPORUtils.isNativePlatform() &&
                            <Typography fontSize="0.75rem" lineHeight={1} color="text.secondary">
                                {bean.channel}
                            </Typography>}
                        <Typography fontSize="0.85rem">
                            {DateUtils.formatters.DDMMYYYY_HHMMSS(bean.transactionDate)}
                        </Typography>
                        {bean.item.validityStatus &&
                            <Typography fontSize="0.7rem" color={mapValidityStatusColor(bean.item.validityStatus)}>
                                {translations.mapValidityStatus(bean.item.validityStatus)}
                            </Typography>
                        }
                        <Chip
                            sx={{mt: 0.5}}
                            size="small"
                            label={translations.mapStatusLabel(bean.status)}
                            variant="outlined"
                            color={mapStatusColor(row.original.status)}
                        />
                    </React.Fragment>
                }
            }, {
                Header: translations.price,
                accessor: props => props.price.toFixed(2),
                Cell: ({value, row}) => {
                    return <PriceDescriptorCell price={value} currencyCode={row.original.currencyCode}/>;
                }
            }],
            actions: {
                onRefresh: refresh,
                other: [FiltersButton],
                onRowClicked: (bean) => {
                    setSearchParams({
                        transactionId: bean.transactionId
                    });
                }
            },
            pagination: true,
            pageSize: 10
        }

    }, [translations]);


    return <React.Fragment>
        <Table {...tableConfig} data={transactions}/>
        {transaction && <NativeTransactionDetails
            translations={translations}
            transaction={transaction}
            onClose={() => setSearchParams({})}
            downloadConfirmation={downloadConfirmation}
            showInvoiceDialog={showInvoiceDialog}
            showRefundDialog={showRefundDialog}
            showComplaintDialog={showComplaintDialog}
        />}
    </React.Fragment>
}


export default NativeTransactionsTable;