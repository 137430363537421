import * as React from "react";
import {useLocation} from "react-router-dom";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import {useTheme} from "@mui/system";
import {useMediaQuery} from "@mui/material";

const LayoutNavigationContext = React.createContext(null);

export default function LayoutNavigation({moduleItems, drawerWidth, MenuFooter}) {
    const theme = useTheme();
    const showHeader = useMediaQuery(theme.breakpoints.down("md"));
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);

    const location = useLocation();

    function toggleMobileMenu() {
        setMobileOpen((old) => {
            return !old;
        });
    }

    function toggleSettingsMenu() {
        setSettingsOpen((old) => {
            return !old;
        });
    }

    const moduleItemsByLink = React.useMemo(() => {
        let result = {};
        (function mapItems(items) {
            if (items) {
                items.forEach((item) => {
                    let config = {
                        link: item.link,
                        id: item.id,
                        name: item.name,
                        onClick: item.onClick
                    };

                    if (item.link || item.onClick) {
                        result[item.link] = config;
                    }
                });
            }
        })(moduleItems);
        return result;
    }, [moduleItems]);

    React.useEffect(() => {
        let pathElements = location.pathname.split("/").filter((o) => o && o !== "");


        let path = "/";
        if (pathElements.length > 0) {
            path += pathElements[0];
        }
        setSelectedItem(moduleItemsByLink[path]);
    }, [location, moduleItemsByLink]);

    return (
        <LayoutNavigationContext.Provider
            value={{
                mobileOpen,
                toggleMobileMenu,
                settingsOpen,
                toggleSettingsMenu,
                selectedItem,
                drawerWidth,
                moduleItems,
                MenuFooter
            }}
        >
            {showHeader && <Header/>}
            <Sidebar/>
        </LayoutNavigationContext.Provider>
    );
};

export function useLayoutNavigation() {
    return React.useContext(LayoutNavigationContext);
}