import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import "./StartupLoader.css";
import {styled} from "@mui/material/styles";
import {useConfig} from "@beesset/upor-client-module-cmn";

const Dot = styled("div")(({theme}) => {
    return {
        backgroundColor: theme.palette.primary.main
    }
});

export default function StartupLoader() {
    const {
        LogoComponent
    } = useConfig();

    const {initialized} = useConfig();

    const [exist, setExist] = React.useState(true);
    const [visible, setVisible] = React.useState(true);

    React.useEffect(() => {
        if (initialized === true) {
            setTimeout(() => {
                setVisible(false);
            }, 200);
        } else {
            setExist(true);
            setVisible(true);
        }
    }, [initialized]);

    React.useEffect(() => {
        if (visible === false) {
            setTimeout(() => {
                setExist(false);
            }, 500);
        }
    }, [visible]);

    return (
        exist && (
            <Box
                className={`loader-container ${visible ? "visible" : ""}`}
                bgcolor={"background.paper"}
            >
                {LogoComponent && <Stack justifyContent="center" alignItems="center" width="100%" pr={2.5} pl={2.5}>
                    <LogoComponent/>
                </Stack>}
                <div className={"loading"}>
                    <Dot className={"dot dot1"}/>
                    <Dot className={"dot dot2"}/>
                    <Dot className={"dot dot3"}/>
                    <Dot className={"dot dot4"}/>
                    <Dot className={"dot dot5"}/>
                    <Dot className={"dot dot6"}/>
                </div>
                <Typography variant="caption" mt={5}>
                    powered by{" "}
                    <a
                        href="module/layout/loader/StartupLoader"
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        Beesset
                    </a>
                </Typography>
            </Box>
        )
    );
}
