import {useTranslation} from "react-i18next";
import React from "react";
import {DoublePassword, UPORClientRest} from "@beesset/upor-client-module-cmn";
import {FormContainer, hideLoader, showLoader, Snackbar, TextField} from "@beesset/ui-components";
import {Button, Fade, Link, Stack, Typography} from "@mui/material";
import {NavLink, useNavigate, useSearchParams} from "react-router-dom";
import {getLanguage} from "@beesset/common-utils";

const StepOne = ({translations}) => {
    const navigate = useNavigate();

    const onPasswordRecovery = (formData) => {
        showLoader();
        UPORClientRest.recoverPassword({
            handlers: {
                success: (response) => {
                    Snackbar.info(translations.stepOne.success);
                    navigate("/auth", {replace: true});
                },
                both: () => {
                    hideLoader();
                }
            },
            request: {
                ...formData,
                language: getLanguage()
            }
        });
    };

    return <React.Fragment>
        <Link component={NavLink} to={"/auth"}>
            {translations.buttonBack}
        </Link>
        <FormContainer onSuccess={onPasswordRecovery} style={{width: "100%"}}>
            <Fade in={true}>
                <Stack spacing={5}>
                    <Stack spacing={1}>
                        <Typography variant="h6">
                            {translations.stepOne.title}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {translations.stepOne.description}
                        </Typography>
                    </Stack>
                    <TextField
                        sx={{marginTop: "unset"}}
                        name="email"
                        label={translations.stepOne.email}
                        required
                        placeholder={translations.stepOne.emailPlaceholder}
                        autoComplete="off"
                    />
                    <Button
                        type="submit"
                        variant="contained"
                    >
                        {translations.stepOne.enter}
                    </Button>
                </Stack>
            </Fade>
        </FormContainer>
    </React.Fragment>
}

const StepTwo = ({translations, token}) => {
    const [verified, setVerified] = React.useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (token != null) {
            showLoader(0);
            UPORClientRest.verifyPasswordRecoveryToken({
                handlers: {
                    success: (response) => {
                        setVerified(true);
                    },
                    failure: () => {
                        navigate("/auth", {replace: true});
                    },
                    both: () => {
                        hideLoader();
                    }
                },
                request: {
                    token
                }
            });
        }
    }, [token]);


    const onPasswordChange = (formData) => {
        UPORClientRest.changePasswordWithToken({
            handlers: {
                success: (response) => {
                    Snackbar.info(translations.stepTwo.success);
                    navigate("/auth", {replace: true});
                },
            },
            request: {
                ...formData,
                token
            }
        });
    };

    return verified && <React.Fragment>
        <Link component={NavLink} to={"/auth"}>
            {translations.buttonBack}
        </Link>
        <FormContainer onSuccess={onPasswordChange} style={{width: "100%"}}>
            <Fade in={true}>
                <Stack spacing={5}>
                    <Stack spacing={1}>
                        <Typography variant="h6">
                            {translations.stepTwo.title}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {translations.stepTwo.description}
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <DoublePassword/>
                    </Stack>
                    <Button
                        type="submit"
                        variant="contained"
                    >
                        {translations.stepTwo.enter}
                    </Button>
                </Stack>
            </Fade>
        </FormContainer>
    </React.Fragment>
}


const PasswordRecovery = () => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    const translations = React.useMemo(() => {
        return {
            buttonBack: t("button.back"),
            stepOne: {
                title: t("components.passwordRecovery.name"),
                description: t("components.passwordRecovery.description"),
                enter: t("components.passwordRecovery.enter"),
                emailPlaceholder: t("common.emailPlaceholder"),
                email: t("common.email"),
                success: t("components.passwordRecovery.success"),
            },
            stepTwo: {
                title: t("common.changePasswordTitle"),
                description: t("common.changePasswordDesc"),
                success: t("common.changePasswordSuccess"),
                enter: t("button.changePassword")
            }
        }
    }, [t]);

    return !token ? <StepOne translations={translations}/> : <StepTwo token={token} translations={translations}/>
}

export {
    PasswordRecovery
};