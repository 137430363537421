import React from "react";
import {changeLanguage, getLanguage} from "@beesset/common-utils";
import {setPortalApiDomain, UPORClientRest} from "@beesset/upor-client-module-cmn";
import {useTranslation} from "react-i18next";

const moment = require("moment");

const ConfigContext = React.createContext({});

const ConfigProviderPrv = ({
                               ready = false,
                               children,
                               ConfigProviderProps
                           }) => {
    const {t} = useTranslation();
    const {clientConfig, refreshClientConfig, stopRefreshClientConfig} = useClientConfig({
        protectedResources: ConfigProviderProps.protectedResources,
        ready: ready,
    });
    const [appIsRunning, setIsRunning] = React.useState(true);
    const [networkConnection, setNetworkConnection] = React.useState(true);

    const [config, setConfig] = React.useState(() => {
        let accessibility = localStorage.getItem("CloudCollector-UPOR-accessibility");
        if (accessibility) {
            accessibility = JSON.parse(accessibility);
        } else {
            accessibility = {
                highContrast: false,
                fontSize: 100
            }
        }
        return {
            initialized: false,
            config: {
                accessibility: accessibility
            }
        }
    });

    React.useEffect(() => {
        if (appIsRunning && networkConnection) {
            refreshClientConfig();
        } else {
            stopRefreshClientConfig();
        }
    }, [t, appIsRunning, networkConnection]);

    React.useEffect(() => {
        if (!clientConfig) {
            return;
        }

        let defaultConfig = {
            accessibility: config.config.accessibility
        };

        if (clientConfig['timeOffset']) {
            const offset = clientConfig['timeOffset'];
            moment.now = () => {
                return new Date() - offset;
            };
        }
        setConfig({
            config: {
                ...defaultConfig,
                ...clientConfig,
            },
            initialized: true,
        });
    }, [clientConfig]);

    return (
        <ConfigContext.Provider
            value={{
                ...config,
                networkConnection: networkConnection,
                appIsRunning: appIsRunning,
                updateAppRunningState: (value) => {
                    setIsRunning(value);
                },
                updateNetworkConnectionState: (value) => {
                    setNetworkConnection(value);
                },
                isMediumTypeAvailable: (type) => {
                    return config.config['availableMediumTypes'].indexOf(type) >= 0;
                },
                isPassengerIdentityType: (type) => {
                    return config.config['passengerIdentityType'] === type;
                },
                refresh: () => {
                    refreshClientConfig();
                },
                setLanguage: (language) => {
                    changeLanguage(language)
                },
                getLanguage: () => {
                    return getLanguage();
                },
                toggleHighContrast: () => {
                    setConfig((old) => {
                        let newConfig = {...old};
                        newConfig.config.accessibility.highContrast = !old.config.accessibility.highContrast;
                        localStorage.setItem("CloudCollector-UPOR-accessibility", JSON.stringify(newConfig.config.accessibility));
                        return newConfig;
                    });
                },
                changeFontSize: (operation) => {
                    setConfig((old) => {
                        let newConfig = {...old};
                        let fontSize = old.config.accessibility.fontSize || 100;

                        if (operation === "increase") {
                            if (fontSize <= 120) {
                                fontSize += 10;
                            }
                        } else if (operation === "decrease") {
                            if (fontSize >= 100) {
                                fontSize -= 10;
                            }
                        }
                        newConfig.config.accessibility.fontSize = fontSize;
                        localStorage.setItem("CloudCollector-UPOR-accessibility", JSON.stringify(newConfig.config.accessibility));
                        return newConfig;
                    });
                },
                LogoComponent: ConfigProviderProps.LogoComponent,
                protectedResources: ConfigProviderProps.protectedResources,
                ...ConfigProviderProps.config
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
}

export function useConfig() {
    let result = {
        initialized: false,
        appIsRunning: true,
        updateAppRunningState: () => {
        },
        networkConnection: true,
        updateNetworkConnectionState: () => {
        },
        config: {
            languages: [],
            accessibility: {
                highContrast: false
            },
            notification: []
        },
        setLanguage: () => {
        },
        getLanguage: () => {
        },
        toggleHighContrast: () => {
        },
        changeFontSize: () => {
        },
        refresh: (callback) => {
        },
        LogoComponent: <React.Fragment></React.Fragment>,
        isMediumTypeAvailable: (type) => {
        },
        isPassengerIdentityType: (type) => {
        }
    };
    result = React.useContext(ConfigContext);
    return result;
}

const useClientConfig = ({protectedResources, ready}) => {
    const [state, setState] = React.useState(0);
    const [clientConfig, setClientConfig] = React.useState(null);

    React.useEffect(() => {
        if (!ready || state === 0) {
            return;
        }

        let active = true;
        let timeout = null;

        function run() {
            try {
                let getConfig = protectedResources ? UPORClientRest.getAccountConfig : UPORClientRest.getConfig;
                getConfig({
                    handlers: {
                        success: (response) => {
                            if (active) {
                                setClientConfig(response);
                            }
                        },
                        failure: () => {
                            if (active) {
                                timeout = setTimeout(() => {
                                    run();
                                }, 1000);
                            }
                        }
                    },
                    timeout: 5000
                });
            } catch (e) {
                if (active) {
                    timeout = setTimeout(() => {
                        run();
                    }, 1000);
                }
            }
        }

        run();

        return () => {
            active = false;
            clearInterval(timeout);
        }
    }, [state, ready, protectedResources]);

    return {
        clientConfig: clientConfig,
        refreshClientConfig: () => {
            setState(old => old + 1);
        },
        stopRefreshClientConfig: () => {
            setState(0);
        }
    };
}

const ClientConfigProvider = ({ConfigProviderProps, HttpClientProviderProps, ...props}) => {
    const [ready, setReady] = React.useState(false);

    React.useEffect(() => {
        setPortalApiDomain(HttpClientProviderProps.portalApiDomain);

        let timeout = null;

        function run() {
            try {
                UPORClientRest.validateSession({
                    handlers: {
                        success: () => {
                            setTimeout(() => {
                                setReady(true);
                            }, 100);
                        },
                        failure: () => {
                            timeout = setTimeout(() => {
                                run();
                            }, 1000);
                        }
                    },
                    request: {
                        protectedResources: ConfigProviderProps.protectedResources
                    },
                    timeout: 5000
                });
            } catch (e) {
                timeout = setTimeout(() => {
                    run();
                }, 1000);
            }
        }

        run();

        return () => clearTimeout(timeout);
    }, []);

    return <ConfigProviderPrv
        ConfigProviderProps={ConfigProviderProps}
        {...props}
        ready={ready}
    />;
}

export default ClientConfigProvider;

