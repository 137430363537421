import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {EnumTokenizationRequestType, UPORClientRest} from "@beesset/upor-client-module-cmn";
import React from 'react';

const StartupPathListenerComponent = ({initialPath}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    React.useEffect(() => {
        let active = true;

        const token = searchParams.get("token");
        const sessionId = searchParams.get("sessionId");
        const purchaseId = searchParams.get("purchaseId")

        if (sessionId && token) {
            UPORClientRest.confirmTokenization({
                handlers: {
                    success: (response) => {
                        if (active) {
                            let redirect;
                            if (response.type === EnumTokenizationRequestType.SETTINGS) {
                                redirect = "/settings";
                            } else if (response.type === EnumTokenizationRequestType.PURCHASE) {
                                redirect = "/purchase";
                            }
                            if (redirect) {
                                navigate(redirect, {
                                    state: {
                                        addNewPaymentCard: {
                                            token: token,
                                            data: response.data
                                        }
                                    },
                                    replace: true
                                });
                            }
                        }
                    },
                    failure: () => {
                        navigate(location.pathname, {
                            replace: true
                        });
                    }
                },
                request: {
                    sessionId: sessionId
                }
            });
        } else if (purchaseId) {
            navigate("/purchase-history", {
                state: {
                    purchaseId: purchaseId
                },
                replace: true
            });
        } else {
            navigate(initialPath, {replace: true});
        }

        return () => {
            active = false;
        };
    }, [searchParams]);

    return null;
}

export {
    StartupPathListenerComponent
};