import {Navigate, NavLink, Outlet, Route, Routes, useLocation} from "react-router-dom";
import * as React from "react";
import Stack from "@mui/material/Stack";
import Menu from "./menu/Menu";
import CommonPage from "./page/CommonPage";
import {useTranslation} from "react-i18next";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {Link} from "@mui/material";
import Typography from "@mui/material/Typography";

const SelectedPath = ({i18nPrefix}) => {
    const {t} = useTranslation();

    const location = useLocation();

    let pathElements = location.pathname.split("/").filter((o) => o && o !== "");

    return <Breadcrumbs separator="›" aria-label="breadcrumb">
        {pathElements.map((element, index) => {
            let name = t(`${i18nPrefix}${element}`);
            return index < pathElements.length - 1
                ? <Link
                    component={NavLink}
                    key="1" color="text.secondary" to={`/${pathElements.slice(0, index + 1).join("/")}`}
                    underline="hover"
                >
                    {name}
                </Link>
                : <Typography key={`breadcrumb-${index}`} color="text.primary">
                    {name}
                </Typography>
        })}
        {pathElements}
    </Breadcrumbs>
}

const CommonHelpComponent = ({Pages, i18nPrefix, menuClassPrefix}) => {
    const location = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return <Stack
        flex={1}
        spacing={2}
    >
        <SelectedPath i18nPrefix={i18nPrefix}/>
        <Routes>
            <Route key={"help-item-menu"} path={"/"} index
                   element={<Menu key={"help-item-menu-images"} items={Pages} i18nPrefix={i18nPrefix} menuClassPrefix={menuClassPrefix}/>}/>
            {Pages
                .filter(page => !page.onClick)
                .map((page) => {
                    if (page.Pages) {
                        return <React.Fragment>
                            <Route
                                path={`/${page.id}`}
                                key={`help-item-${page.id}`}
                                element={<Menu key={`help-item-menu-images-${page.id}`} parentId={page.id} items={page.Pages} i18nPrefix={i18nPrefix}
                                               menuClassPrefix={menuClassPrefix}/>}
                            />
                            {page.Pages
                                .filter(page => !page.onClick)
                                .map((page1) => {
                                    return <Route
                                        path={`/${page.id}/${page1.id}`}
                                        key={`help-item-${page.id}-${page1.id}`}
                                        element={<CommonPage {...page1}/>}
                                    />
                                })
                            }
                        </React.Fragment>
                    }
                    return <Route
                        path={`/${page.id}`}
                        key={`help-item-${page.id}`}
                        element={<CommonPage {...page}/>}
                    />
                })}
            <Route
                path={`/*`}
                key={`help-item-*`}
                element={<Navigate replace to={"/"}/>}
            />
        </Routes>
        <Outlet/>
    </Stack>
}

export default CommonHelpComponent;