import {Box, Chip} from "@mui/material";
import {styled} from "@mui/material/styles";
import React from "react";

const ListItem = styled("li")(({theme}) => ({
    margin: theme.spacing(0.5),
    marginLeft: 0,
    marginRight: theme.spacing(1),
}));

export default function ChipsList({descriptors, sx, size}) {
    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0,
                m: 0,
                ...sx
            }}
            component="ul"
        >
            {descriptors
                .filter(
                    ({show}) => show === undefined || show === null || show === true
                )
                .map(({name, value, color, ...props}, index) => {
                    return (
                        <ListItem key={name}>
                            <Chip
                                key={`${name}-${index}`}
                                label={name}
                                color={color ? color : (value ? "info" : "default")}
                                {...props}
                                size={size}
                            />
                        </ListItem>
                    );
                })}
        </Box>
    );
}
