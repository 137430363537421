import {useTranslation} from "react-i18next";
import {Button, Stack, Typography} from "@mui/material";
import {
    FileUploadField,
    FormContainer,
    hideLoader,
    ResponsiveFieldsGroup,
    showLoader,
    Snackbar,
    TextField
} from "@beesset/ui-components";
import {DateUtils, generateId} from "@beesset/common-utils";
import React from "react";
import {DateTimeField, PhoneNumberPattern, UPORClientRest, useConfig} from "@beesset/upor-client-module-cmn";
import {PatternFormat} from "react-number-format";

const AccountNumberPattern = React.forwardRef((props, ref) => {
    const {onChange, ...other} = props;

    return <PatternFormat
        {...other}
        format="## #### #### #### #### #### #### #### #"
        allowEmptyFormatting={false}
        getInputRef={ref}
        onValueChange={(values) => {
            onChange({
                target: {
                    value: values.formattedValue
                }
            });
        }}
    />
});


const OtherApplicationComponent = ({onSuccess}) => {
    const {config} = useConfig();
    const formId = React.useRef(generateId(10));

    const {t} = useTranslation();

    const translations = React.useMemo(() => {
        return {
            title: t("components.application.other.title"),
            description: t("components.application.other.description"),
            attachment: t("components.application.other.attachment"),
            send: t("button.send"),
            firstAndLastName: t("common.firstAndLastName"),
            email: t("components.application.other.email"),
            phone: t("components.application.other.phone"),
            date: t("components.application.other.date"),
            vehicleNumber: t("components.application.other.vehicleNumber"),
            eventDescription: t("components.application.other.eventDescription"),
            eventDescriptionPlaceholder: t("components.application.other.eventDescriptionPlaceholder"),
            account: t("components.application.other.account"),
            accountPlaceholder: t("components.application.other.accountPlaceholder"),
            success: t("components.application.other.success")
        }
    }, [t]);

    return <Stack spacing={3}>
        <Stack spacing={1}>
            <Typography variant="body1" color="text.secondary" fontWeight={500}>
                {translations.title}
            </Typography>
            <Typography variant="body2">
                {translations.description}
            </Typography>
        </Stack>
        <FormContainer
            id={formId.current}
            style={{marginTop: "unset"}}
            defaultValues={{
                email: config ? config.email : null
            }}
            onSuccess={(bean) => {
                showLoader();

                const formData = new FormData();
                formData.append("request", JSON.stringify({
                    type: "OTHER",
                    data: JSON.stringify({
                        nameAndSurname: bean.nameAndSurname,
                        email: bean.email,
                        phone: bean.phone,
                        date: bean.date,
                        description: bean.description,
                        account: bean.account,
                    }),
                }));

                if (bean.attachments) {
                    if (bean.attachments.length) {
                        Array.from(bean.attachments).forEach((attachment) => {
                            formData.append("attachments", attachment);
                        });
                    } else {
                        formData.append("attachments", bean.attachments);
                    }
                }

                UPORClientRest.registerApplication({
                    handlers: {
                        success: () => {
                            Snackbar.success(translations.success);
                            onSuccess && onSuccess();
                        },
                        both: () => {
                            hideLoader();
                        }
                    },
                    formData
                });
            }
            }
        >
            <ResponsiveFieldsGroup isMainContainer minWidth="unset">
                <TextField isStacked label={translations.firstAndLastName} name={"nameAndSurname"} required/>
                <TextField label={translations.email} name={"email"} required/>
                <TextField
                    label={translations.phone}
                    name={"phone"}
                    InputProps={{
                        inputComponent: PhoneNumberPattern
                    }}
                    inputProps={{
                        inputMode: "numeric"
                    }}
                />
                <DateTimeField
                    label={translations.date}
                    name={"date"}
                    required
                    maxDateTime={DateUtils.getNow()}
                />
                <TextField
                    label={translations.eventDescription}
                    name={"description"}
                    required
                    multiline
                    rows={10}
                    placeholder={translations.eventDescriptionPlaceholder}/>
                <TextField
                    label={translations.account}
                    name={"account"}
                    placeholder={translations.accountPlaceholder}
                    InputProps={{
                        inputComponent: AccountNumberPattern
                    }}
                    inputProps={{
                        inputMode: "numeric"
                    }}
                />
                <FileUploadField
                    label={translations.attachment}
                    name="attachments"
                    multiple
                    maxSize={10}
                    allowedExtensions={[
                        ".doc",
                        ".docx",
                        ".odt",
                        ".pdf",
                        ".tex",
                        ".txt",
                        ".rtf",
                        ".jpg",
                        ".jpeg",
                        ".png",
                        ".svg"
                    ]}
                />
            </ResponsiveFieldsGroup>
        </FormContainer>
        <Button
            variant="contained"
            size="large"
            type="submit"
            form={formId.current}
        >
            {translations.send}
        </Button>
    </Stack>
}

export {
    OtherApplicationComponent
};