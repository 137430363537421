import CardContent from "@mui/material/CardContent";
import {Button, CardActions, Divider, IconButton, InputAdornment, Stack, Typography} from "@mui/material";
import {Close, PersonRounded, Save, Visibility, VisibilityOff} from "@mui/icons-material";
import Card from "@mui/material/Card";
import React from "react";
import {useTranslation} from "react-i18next";
import {DoublePassword, UPORClientRest, useConfig} from "@beesset/upor-client-module-cmn";
import {DateUtils, generateId} from "@beesset/common-utils";
import {Dialog, FormContainer, ResponsiveFieldsGroup, Snackbar, TextField} from "@beesset/ui-components";

const ChangePassword = ({open, onClose, translations}) => {
    const {t} = useTranslation();

    const [showPassword, setShowPassword] = React.useState(false);

    const formId = React.useRef(generateId(10));

    return <Dialog
        open={open}
        title={translations.changePasswordTitle}
        description={translations.changePasswordDesc}
        actions={[{
            name: translations.close,
            color: "inherit",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }, {
            name: translations.changePassword,
            type: "submit",
            form: formId.current,
            fullScreenProps: {
                Icon: <Save/>,
                align: "right",
            },
        }]}
    >
        <FormContainer
            id={formId.current}
            onSuccess={(bean) => {
                UPORClientRest.changePassword({
                    handlers: {
                        success: () => {
                            Snackbar.success(translations.changePasswordSuccess);
                            onClose();
                        }
                    },
                    request: bean
                })
            }
            }
        >
            <ResponsiveFieldsGroup isMainContainer>
                <DoublePassword/>
                <TextField
                    name="oldPassword"
                    label={t("components.settings.oldPassword")}
                    required
                    type={showPassword ? "text" : "password"}
                    placeholder={t("components.settings.oldPasswordPlaceholder")}
                    autoComplete="new-password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={t(showPassword ? "button.hidePassword" : "button.showPassword")}
                                    onClick={() => setShowPassword((old) => !old)}
                                    onMouseDown={(event) => event.preventDefault()}
                                >
                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </ResponsiveFieldsGroup>
        </FormContainer>
    </Dialog>
}

const ProfileInfoComponent = () => {
    const [showChangePassword, setShowChangePassword] = React.useState(false);
    const {config} = useConfig();
    const {t} = useTranslation();

    const translations = React.useMemo(() => {
        return {
            changePassword: t("button.changePassword"),
            close: t("button.close"),
            changePasswordTitle: t("common.changePasswordTitle"),
            changePasswordDesc: t("common.changePasswordDesc"),
            changePasswordSuccess: t("common.changePasswordSuccess"),
        }
    }, [t]);

    return <React.Fragment>
        <Card variant="outlined" sx={{maxWidth: 300, flexShrink: 0}}>
            <CardContent>
                <Stack textAlign="center" direction="column" spacing={2} justifyContent="center" alignItems="center"
                       color="text.secondary">
                    <PersonRounded fontSize="large" sx={{fontSize: "4.5rem"}}/>
                    <Divider flexItem/>
                    <Typography variant="body2" color="text.secondary" noWrap width={"100%"}>
                        {config['email']}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {DateUtils.formatters.FULL(config['createdOn'])}
                    </Typography>
                </Stack>
            </CardContent>
            <CardActions>
                <Button fullWidth variant="contained" onClick={() => setShowChangePassword(true)}>
                    {translations.changePassword}
                </Button>
            </CardActions>
        </Card>
        <ChangePassword open={showChangePassword} onClose={() => setShowChangePassword(false)}
                        translations={translations}/>
    </React.Fragment>
}

export {
    ProfileInfoComponent
};