import {
    Box,
    Button,
    Fade,
    MobileStepper as MuiMobileStepper,
    Stack,
    Step as MuiStep,
    StepContent as MuiStepContent,
    StepLabel as MuiStepLabel,
    Stepper as MuiStepper,
    Typography,
    useMediaQuery
} from "@mui/material";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";

const StepContent = React.memo(
    ({hidden = true, keepMounted = true, Content, NextButton, BackButton, ResetButton, disableButtons = false}) => {
        const {t} = useTranslation();

        const contentRef = React.useRef();

        let RenderedContent;
        if (!keepMounted) {
            if (hidden === false) {
                RenderedContent = <Content/>;
            }
        } else if (keepMounted) {
            if (contentRef.current) {
                RenderedContent = contentRef.current;
            } else if (hidden === false) {
                RenderedContent = <Content/>;
                contentRef.current = RenderedContent;
            }
        }

        return (
            RenderedContent ? <Fade in={true} timeout={300}>
                <Box
                    sx={{
                        flex: "1 1 auto",
                        display: hidden === false ? "flex" : "none",
                        flexDirection: "column",
                        overflow: "auto",
                    }}
                >
                    {RenderedContent}
                    <Box sx={{flex: "1 1 auto"}}/>
                    {disableButtons === false && (BackButton || NextButton || ResetButton) &&
                        <Box sx={{display: "flex", flexDirection: "row", pt: 2}}>
                            {BackButton && (
                                <Button color="inherit" sx={{mr: 1}} {...BackButton.props}>
                                    {BackButton.props.name ? BackButton.props.name : t("button.back")}
                                </Button>
                            )}
                            <Box sx={{flex: "1 1 auto"}}/>
                            {ResetButton && (
                                <Button {...ResetButton.props}>
                                    {ResetButton.props.name ? ResetButton.props.name : "Reset"}
                                </Button>
                            )}
                            {NextButton && (
                                <Button {...NextButton.props}>
                                    {NextButton.props.name ? NextButton.props.name : t("button.next")}
                                </Button>
                            )}
                        </Box>}
                </Box>
            </Fade> : null
        );
    }
);

const REDUCER_ACTION = {
    INCREMENT: "INCREMENT",
    DECREMENT: "DECREMENT",
    CHANGE: "CHANGE",
};

function reducer(activeStep, {type, step, totalSteps}) {
    if (step < 0 || totalSteps - 1 < step) {
        return activeStep;
    }

    switch (type) {
        case REDUCER_ACTION.INCREMENT:
            return activeStep + 1;
        case REDUCER_ACTION.DECREMENT:
            return activeStep - 1;
        case REDUCER_ACTION.CHANGE:
            if (activeStep > step) {
                return step;
            } else {
                return activeStep;
            }
        default:
            return activeStep;
    }
}

export function createStepper({
                                  steps,
                                  keepMounted,
                                  alternativeLabel = false,
                                  orientation = "horizontal",
                                  data = {},
                                  verticalVariant = "default"
                              }) {
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down("md"));
    const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
    const [, setVersion] = React.useState(1);
    const [activeStep, dispatch] = React.useReducer(reducer, 0);
    const dataRef = React.useRef({...data});

    const stepsConfigs = React.useMemo(() => {
        return steps.map((s) => {
            let step = {...s};
            if (step.NextButton) {
                step.NextButton = {
                    props: {
                        ...step.NextButton.props
                    }
                }

                if (!step.NextButton.props.onClick && !step.NextButton.props.form) {
                    step.NextButton.props.onClick = () => {
                        dispatch({
                            type: REDUCER_ACTION.INCREMENT,
                            totalSteps: steps.length,
                        });
                    };
                }
            }
            if (step.BackButton) {
                step.BackButton = {
                    props: {
                        ...step.BackButton.props
                    }
                }

                if (!step.BackButton.props.onClick && !step.BackButton.props.form) {
                    step.BackButton.props.onClick = () => {
                        dispatch({
                            type: REDUCER_ACTION.DECREMENT,
                            totalSteps: steps.length,
                        });
                    };
                }
            }
            if (step.ResetButton) {
                step.ResetButton = {
                    props: {
                        ...step.ResetButton.props
                    }
                }

                if (!step.ResetButton.props.onClick && !step.ResetButton.props.form) {
                    step.ResetButton.props.onClick = () => {
                        dispatch({
                            type: REDUCER_ACTION.CHANGE,
                            step: 0,
                            totalSteps: steps.length,
                        });
                    };
                }
            }

            if (keepMounted === true || keepMounted === false) {
                if (step.keepMounted === undefined || keepMounted === null) {
                    step.keepMounted = keepMounted;
                }
            }

            return step;
        });
    }, [steps, keepMounted]);

    const contextValue = React.useMemo(() => {
        return {
            getData: () => {
                return dataRef.current;
            },
            updateData: (data, refresh) => {
                dataRef.current = {
                    ...dataRef.current,
                    ...data,
                };
                if(refresh) {
                    setVersion(old => old + 1);
                }
            },
            goNext: () =>
                dispatch({
                    type: REDUCER_ACTION.INCREMENT,
                    totalSteps: steps.length,
                }),
            goBack: () =>
                dispatch({
                    type: REDUCER_ACTION.DECREMENT,
                    totalSteps: steps.length,
                }),
            goTo: (step) => {
                dispatch({
                    type: REDUCER_ACTION.CHANGE,
                    step: step,
                    totalSteps: stepsConfigs.length,
                })
            }
        };
    }, []);

    const hasSteps = stepsConfigs.length > 1;

    return {
        activeStep: activeStep,
        isLast: activeStep === steps.length - 1,
        isFirst: activeStep === 0,
        ...contextValue,
        Stepper: (
            hasSteps && <StepperContext.Provider
                value={{
                    activeStep: activeStep,
                    ...contextValue,
                }}
            >
                {((orientation === "vertical") || (orientation === "auto" && smallDevice)) &&
                    <React.Fragment>
                        {(verticalVariant === "default" || !mobileDevice)
                            ? <VerticalStepper
                                stepsConfigs={stepsConfigs}
                                activeStep={activeStep}
                                dispatch={dispatch}
                            />
                            : <MobileStepper
                                stepsConfigs={stepsConfigs}
                                activeStep={activeStep}
                                dispatch={dispatch}
                            />
                        }
                    </React.Fragment>
                }
                {((orientation === "horizontal") || (orientation === "auto" && !smallDevice)) &&
                    <HorizontalStepper
                        alternativeLabel={alternativeLabel}
                        activeStep={activeStep}
                        stepsConfigs={stepsConfigs}
                        dispatch={dispatch}/>
                }
            </StepperContext.Provider>
        ),
    };
}

const HorizontalStepper = ({stepsConfigs, alternativeLabel, activeStep, dispatch}) => {
    return <Box
        sx={{
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            p: 3,
            flex: 1,
        }}
    >
        <MuiStepper alternativeLabel={alternativeLabel} activeStep={activeStep} sx={{pb: 2}}>
            {stepsConfigs.map(({label}, index) => {
                return (
                    <MuiStep
                        key={`stepper-label-${label}`}
                        sx={{cursor: "pointer"}}
                        onClick={() =>
                            dispatch({
                                type: REDUCER_ACTION.CHANGE,
                                step: index,
                                totalSteps: stepsConfigs.length,
                            })
                        }
                    >
                        <MuiStepLabel>{label}</MuiStepLabel>
                    </MuiStep>
                );
            })}
        </MuiStepper>
        {stepsConfigs.map((step, index) => {
            return (
                <StepContent
                    {...step}
                    hidden={activeStep !== index}
                    key={`stepper-content-${step.label}`}
                />
            );
        })}
    </Box>
}

const VerticalStepper = ({activeStep, dispatch, stepsConfigs}) => {
    return <Box
        sx={{
            width: "100%",
            // display: "flex",
            // flexDirection: "column",
            // p: hasSteps?3:0,
            // pt: 3,
            flex: 1,
        }}
    >
        <MuiStepper activeStep={activeStep} sx={{pb: 2}} orientation="vertical">
            {stepsConfigs.map((step, index) => {
                return (
                    <MuiStep
                        key={`stepper-label-${step.label}`}
                    >
                        <MuiStepLabel
                            sx={{cursor: "pointer"}}
                            onClick={() =>
                                dispatch({
                                    type: REDUCER_ACTION.CHANGE,
                                    step: index,
                                    totalSteps: stepsConfigs.length,
                                })
                            }
                        >{step.label}</MuiStepLabel>
                        <MuiStepContent>
                            <StepContent
                                {...step}
                                hidden={false}
                                key={`stepper-content-${step.label}`}
                            />
                        </MuiStepContent>
                    </MuiStep>
                );
            })}
        </MuiStepper>
    </Box>;
}


const MobileStepper = ({activeStep, stepsConfigs}) => {
    const {t} = useTranslation();
    const stepConfig = stepsConfigs[activeStep];

    return <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%"
        }}
    >
        <Stack direction="row" alignItems="center" spacing={1.5}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "primary.contrastText",
                    bgcolor: "primary.main",
                    borderRadius: "50%",
                    height: 25,
                    width: 25,
                    fontWeight: 400,
                    fontSize: "0.8rem"
                }}
                component="span"
            >
                {activeStep + 1}
            </Box>
            <Typography
                component="span"
                fontWeight={600}
                fontSize="0.9rem"
                color="primary.main"
            >
                {stepsConfigs[activeStep].label}
            </Typography>
        </Stack>
        {stepsConfigs.map((step, index) => {
            return (
                <StepContent
                    {...step}
                    hidden={activeStep !== index}
                    key={`stepper-content-${step.label}`}
                    disableButtons={true}
                />
            );
        })}
        <Box paddingBottom={"calc(env(safe-area-inset-bottom, 0px) + 60px)"}/>
        <MuiMobileStepper
            sx={{
                paddingBottom: "calc(env(safe-area-inset-bottom, 0px) + 8px)",
                boxShadow: 10
            }}
            variant="dots"
            steps={stepsConfigs.length}
            position="bottom"
            activeStep={activeStep}
            nextButton={
                stepConfig.NextButton
                    ? <Button
                        color="inherit"
                        {...stepConfig.NextButton.props}
                        sx={{
                            justifyContent: "flex-end",
                            flex: 1
                        }}
                    >
                        {stepConfig.NextButton.props.name ? stepConfig.NextButton.props.name : t("button.next")}
                        <KeyboardArrowRight/>
                    </Button>
                    : <Button sx={{visibility: "hidden", flex: 1}} size="large">
                        {t("button.next")}
                        <KeyboardArrowRight/>
                    </Button>
            }
            backButton={
                stepConfig.BackButton
                    ? <Button
                        color="inherit"
                        {...stepConfig.BackButton.props}
                        sx={{
                            justifyContent: "flex-start",
                            flex: 1
                        }}
                    >
                        <KeyboardArrowLeft/>
                        {stepConfig.BackButton.props.name ? stepConfig.BackButton.props.name : t("button.back")}
                    </Button>
                    : <Button sx={{visibility: "hidden", flex: 1}} size="large">
                        {t("button.back")}
                        <KeyboardArrowLeft/>
                    </Button>
            }
        />
    </Box>;
}

const StepperContext = React.createContext({
    getData: () => {
    },
    updateData: (data) => {
    },
    goNext: () => {
    },
    goBack: () => {
    },
    goTo: (step) => {
    },
    activeStep: 0
});

export function useStepper() {
    return React.useContext(StepperContext);
}
