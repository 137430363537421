import {OutlinedInput} from "@mui/material";
import {useTranslation} from 'react-i18next';
import isEqual from "react-fast-compare";
import React from "react";

export default function DefaultColumnFilter({column: {filterValue, setFilter, Header, preFilteredRows}}) {
    const [currentValue, setCurrentValue] = React.useState(undefined);

    const {t} = useTranslation();

    React.useEffect(() => {
        let timeoutTimout = 0;

        if(preFilteredRows.length > 8000){
            timeoutTimout = 500;
        }
        else if(preFilteredRows.length > 5000){
            timeoutTimout = 400;
        }
        else if(preFilteredRows.length > 2000) {
            timeoutTimout = 200;
        }
        else if(preFilteredRows.length > 500) {
            timeoutTimout = 100;
        }

        let timeout = setTimeout(() => {
            if (!isEqual(currentValue, filterValue)) {
                console.log("filter")
                setFilter(currentValue);
            }
        }, timeoutTimout);
        return () => clearTimeout(timeout);
    }, [currentValue]);

    return (
        <OutlinedInput
            placeholder={t("commonComponents.filter.search")}
            inputProps={{
                "aria-label": `${t("commonComponents.filter.search")}: ${Header}`
            }}
            sx={{
                width: "100%",
                fontSize: "0.9rem"
            }}
            size="small"
            value={currentValue || ""}
            onChange={(e) => {
                setCurrentValue(e.target.value || undefined);
            }}
            onClick={(e) => {
                e.stopPropagation();
            }}
        />
    );
}
