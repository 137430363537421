import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useLayoutNavigation} from "../LayoutNavigation";
import {Settings} from "@mui/icons-material";
import {useLocation} from "react-router-dom";

export default function Header() {
    const {t, i18n} = useTranslation();
    const location = useLocation();

    const {toggleMobileMenu, selectedItem, toggleSettingsMenu} = useLayoutNavigation();

    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar
                position="fixed"
                
            >
                <Box height="env(safe-area-inset-top)" width="100%" bgcolor="transparent"/>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label={"Menu"}
                        edge="start"
                        onClick={toggleMobileMenu}
                        sx={{mr: 2, display: {md: "none"}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h5" noWrap component="div">
                        {selectedItem
                            ? selectedItem.name
                            : (i18n.exists(`componentsNames.${location.pathname.substring(1)}`)
                                ? t(`componentsNames.${location.pathname.substring(1)}`)
                                : "")
                        }
                    </Typography>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: {xs: "flex", md: "none"}}}>
                        <IconButton
                            size="large"
                            aria-label={t("common.settings")}
                            aria-controls="settings"
                            aria-haspopup={true}
                            onClick={toggleSettingsMenu}
                            color="inherit"
                        >
                            <Settings/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
