import {Dialog, List, Menu, Table} from "@beesset/ui-components";
import {
    Cancel,
    CheckCircle,
    ChevronLeft,
    DirectionsBus,
    Key,
    MoreVert,
    Payment,
    Person,
    PictureAsPdf,
    Receipt,
    Replay, Rule,
    ShoppingCart,
    Today
} from "@mui/icons-material";
import {Box, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {DateUtils} from "@beesset/common-utils";
import {
    EnumActionType,
    mapValidityStatusColor,
    PriceDescriptorCell,
    ProductDescriptorCell
} from "../TransactionsHistory";
import React from "react";
import {UPORUtils} from "@beesset/upor-client-module-cmn";

const NativeTransactionDetails = ({
                                      translations,
                                      transaction,
                                      onClose,
                                      showInvoiceDialog,
                                      showRefundDialog,
                                      showComplaintDialog,
                                      downloadConfirmation
                                  }) => {
    const [additionMenuAnchorEl, setAdditionMenuAnchorEl] = React.useState(null);

    const {descriptors, actions} = React.useMemo(() => {
        let result = [{
            name: translations.mapMediumType(transaction.mediumType),
            value: transaction.mediumName,
            Icon: <Person/>
        }];

        if (!UPORUtils.isNativePlatform()) {
            result.push({
                name: translations.channel,
                value: transaction.channel,
                Icon: <ShoppingCart/>
            });
        }

        result.push({
            name: translations.transactionDate,
            value: DateUtils.formatters.FULLWithSeconds(transaction.transactionDate),
            Icon: <Today/>
        }, {
            name: translations.id,
            value: transaction.transactionId,
            Icon: <Key/>
        });

        if (transaction.visibleVehicleNumber) {
            result.push({
                name: translations.vehicleNumber,
                value: transaction.vehicleNumber,
                Icon: <DirectionsBus/>
            });
        }

        if (transaction.item) {
            let productItemProps = {
                primaryTypographyProps: {
                    fontSize: "0.83rem"
                },
                secondaryTypographyProps: {
                    fontSize: "0.83rem"
                }
            }
            result.push({
                Component: <React.Fragment>
                    <ListItemIcon>
                        <Receipt/>
                    </ListItemIcon>
                    <Box>
                        <ListItemText
                            {...productItemProps}
                            primary={translations.product}
                            secondary={`${transaction.item.count > 1 ? `${transaction.item.count} × ` : ""}${transaction.item.productName}, ${transaction.item.profileName}`}
                        />
                        <ListItemText
                            {...productItemProps}
                            sx={{
                                m: 0
                            }}
                            primary={translations.startDate}
                            secondary={DateUtils.formatters.FULLWithSeconds(transaction.item.activationDate)}
                        />
                        <ListItemText
                            {...productItemProps}
                            primary={translations.endDate}
                            secondary={DateUtils.formatters.FULLWithSeconds(transaction.item.expirationDate)}
                        />
                        {transaction.item.validityStatus && <Typography fontSize="0.75rem"
                                                                        color={mapValidityStatusColor(transaction.item.validityStatus)}>
                            {translations.mapValidityStatus(transaction.item.validityStatus)}
                        </Typography>}
                    </Box>
                </React.Fragment>
            });

            result.push({
                name: translations.price,
                value: `${transaction.price.toFixed(2)} ${transaction.currencyCode}`,
                Icon: <Payment/>
            });
        } else if (transaction.items) {
            const tabelConfig = {
                columns: [{
                    Header: translations.product,
                    accessor: props => `${props.profileName} ${props.productName}`,
                    Cell: ({row}) => {
                        return <ProductDescriptorCell {...row.original}/>
                    }
                }, {
                    Header: translations.price,
                    accessor: props => props.price.toFixed(2),
                    Cell: ({value}) => {
                        return <PriceDescriptorCell price={value} currencyCode={transaction.currencyCode}/>;
                    }
                }, {
                    Header: translations.startDate,
                    accessor: 'activationDate',
                    Cell: ({value}) => {
                        return <Typography sx={{fontSize: "0.85rem"}}>
                            {DateUtils.formatters.DD_MONTH_YYYY_HHMM(value)}
                        </Typography>
                    },
                    type: "datetime"
                }, {
                    Header: translations.endDate,
                    accessor: 'expirationDate',
                    Cell: ({value}) => {
                        return <Typography sx={{fontSize: "0.85rem"}}>
                            {DateUtils.formatters.DD_MONTH_YYYY_HHMM(value)}
                        </Typography>
                    },
                    type: "datetime"
                }],
                size: "small",
                pageSize: 5,
                pagination: true
            };

            result.push({
                Component: <Box
                    key={"all-transaction-products-list"}
                    sx={{
                        width: "100%",
                        maxWidth: "1200px"
                    }}>
                    <Table {...tabelConfig} data={transaction.items}/>
                </Box>
            })
        }

        result.push({
            isDivider: {
                sx: {
                    m: 2
                }
            }
        });

        if (transaction.paidOn != null) {
            result.push({
                name: translations.mapStatusLabel(transaction.status),
                value: DateUtils.formatters.FULLWithSeconds(transaction.paidOn),
                Icon: <CheckCircle/>
            });
        }

        if (transaction.cancelledOn != null) {
            result.push({
                name: translations.mapStatusLabel(transaction.status),
                value: DateUtils.formatters.FULLWithSeconds(transaction.cancelledOn),
                Icon: <Cancel/>
            });
        }

        if (transaction.refundedOn != null) {
            result.push({
                name: translations.mapStatusLabel(transaction.status),
                value: DateUtils.formatters.FULLWithSeconds(transaction.refundedOn),
                Icon: <Replay/>
            });
        }

        let actions = [];

        if ((transaction['availableActions'] || []).length > 0) {
            if (transaction['availableActions'].indexOf(EnumActionType.INVOICE_REQUEST) >= 0) {
                actions.push({
                    name: transaction.invoice ? translations.getInvoiceCorrection : translations.getInvoice,
                    onSelect: () => {
                        setAdditionMenuAnchorEl(null);
                        showInvoiceDialog(transaction);
                    },
                    Icon: <Receipt/>
                });
            }

            if (transaction['availableActions'].indexOf(EnumActionType.COMPLAINT_REQUEST) >= 0) {
                actions.push({
                    name: translations.getComplaint,
                    onSelect: () => {
                        setAdditionMenuAnchorEl(null);
                        showComplaintDialog(transaction);
                    },
                    Icon: <Rule/>
                });
            }

            if (transaction['availableActions'].indexOf(EnumActionType.REFUND) >= 0) {
                actions.push({
                    name: translations.refund,
                    onSelect: () => {
                        setAdditionMenuAnchorEl(null);
                        showRefundDialog(transaction);
                    },
                    Icon: <Replay/>
                });
            }

            if (transaction['availableActions'].indexOf(EnumActionType.CONFIRMATION) >= 0) {
                actions.push({
                    name: transaction.mediumType === 'PHONE_QR' ? translations.qrCodeConfirmation : translations.paymentCardConfirmation,
                    onSelect: () => {
                        setAdditionMenuAnchorEl(null);
                        downloadConfirmation(transaction);
                    },
                    Icon: <PictureAsPdf/>
                });
            }
        }

        return {
            descriptors: result,
            actions
        };
    }, [translations, transaction]);

    return <Dialog
        title={translations.transaction}
        open={true}
        actions={[{
            name: translations.close,
            onClick: onClose,
            fullScreenProps: {
                Icon: <ChevronLeft/>,
                align: "left",
            },
        }, {
            name: "Więcej",
            onClick: (event) => {
                setAdditionMenuAnchorEl(event.currentTarget);
            },
            show: actions.length > 0,
            fullScreenProps: {
                Icon: <MoreVert/>,
                align: "right",
            },
        }]}
    >
        {actions.length > 0 && <Menu
            anchorEl={additionMenuAnchorEl}
            onClose={() => setAdditionMenuAnchorEl(null)}
            items={actions}
            id={"actions-menu"}
            anchorOrigin={{
                vertical: "center",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
        />}
        <List
            descriptors={descriptors}
        />
    </Dialog>
}

export default NativeTransactionDetails;