import TextField from "@mui/material/TextField";
import * as React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {DateTimePicker} from "@mui/x-date-pickers";

const DateTimeField = (props) => {
    const {control, validationMessages} = useFormContext();

    const {
        name,
        label,
        required,
        readOnly,
        maxDateTime,
        minDateTime,
        sx = {
            marginTop: "10px"
        },
        ...restProps
    } = props;

    let muiInputConfig = {
        label: label
    };

    let validation = {};
    if (required) {
        muiInputConfig["required"] = true;
        validation.required = validationMessages.required;
    }

    validation.validate = (value) => {
        if (value && value === "Invalid date") {
            return validationMessages.invalidDate;
        }
    };

    let format = (event) => {
        if (event.isValid()) {
            let date = event.clone();
            return date.format("YYYY-MM-DDTHH:mm:ss");
        }
        return "Invalid date";
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={validation}
            defaultValue={null}
            render={({
                         field: {onChange, ref, value, ...fieldRest},
                         fieldState: {invalid, error},
                     }) => {
                return (
                    <DateTimePicker
                        value={value || null}
                        {...fieldRest}
                        onChange={(event) => {
                            onChange(event ? format(event) : null);
                        }}
                        inputRef={ref}
                        inputFormat={"DD/MM/YYYY HH:mm:ss"}
                        readOnly={readOnly}
                        minDateTime={minDateTime}
                        maxDateTime={maxDateTime}
                        clearable
                        disableHighlightToday={true}
                        {...restProps}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...{
                                        ...params,
                                        InputProps: {
                                            readOnly: true,
                                            ...params.InputProps,
                                            sx: {
                                                ...params.InputProps.sx,
                                                "& .MuiInputBase-input": {
                                                    caretColor: 'transparent'
                                                }
                                            }

                                        }
                                    }}
                                    sx={sx}
                                    {...muiInputConfig}
                                    error={invalid}
                                    helperText={error ? error.message : null}
                                />
                            );
                        }}
                    />
                );
            }}
        />
    );
};

export {
    DateTimeField
};
