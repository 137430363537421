import MuiTextField from "@mui/material/TextField";
import React from "react";
import {Controller, useFormContext} from "react-hook-form";

Number.prototype.getPrecision = function () {
    let s = this + "",
        d = s.indexOf('.') + 1;

    return !d ? 0 : s.length - d;
};

const TextField = ({
                       parseError,
                       name,
                       value,
                       validation = {},
                       helperText,
                       label,
                       required,
                       maxLength,
                       minLength,
                       min,
                       max,
                       readOnly,
                       disabled,
                       type,
                       inputProps = {},
                       isStacked,
                       sx = {},
                       autoComplete,
                       ...restProps
                   }) => {
    const {control, validationMessages, getValues} = useFormContext();

    const {finalInputConfig, finalValidation, finalSx} = React.useMemo(() => {
        let config = {
            finalInputConfig: {
                label: label,
                type: type === "float" ? "number" : (type || "text"),
                inputProps: {
                    ...inputProps
                },
                fullWidth: true,
                autoComplete: autoComplete,
                ...restProps,
            },
            finalValidation: {
                ...validation
            },
            finalSx: {
                marginTop: "10px",
                ...sx
            }
        }

        if (disabled) {
            config.finalInputConfig["disabled"] = true;
        }

        if (required) {
            config.finalInputConfig["required"] = true;
            config.finalValidation.required = validationMessages.required;
        }

        if (readOnly) {
            config.finalInputConfig.inputProps["readOnly"] = true;
        }
        if (minLength) {
            config.finalInputConfig.inputProps["minLength"] = minLength;
            config.finalValidation.minLength = {
                value: minLength,
                message: validationMessages.minLength(minLength),
            };
        }
        if (maxLength) {
            config.finalInputConfig.inputProps["maxLength"] = maxLength;
            config.finalValidation.maxLength = {
                value: maxLength,
                message: validationMessages.maxLength(maxLength),
            };
        }

        if (type === "number" || type === "float") {
            if (type === "float") {
                config.finalInputConfig.inputProps["step"] = "0.01";
            }
            if (min != undefined) {
                config.finalInputConfig.inputProps["min"] = min;
                config.finalValidation.min = {
                    value: min,
                    message: validationMessages.min(min),
                };
            }
            if (max != undefined) {
                config.finalInputConfig.inputProps["max"] = max;
                config.finalValidation.max = {
                    value: max,
                    message: validationMessages.max(max),
                };
            }
        }
        if (type === "email") {
            config.finalValidation.pattern = {
                value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: validationMessages['invalidEmail'],
            };
        }
        if (validation.validate) {
            let validate = validation.validate;
            config.finalValidation.validate = (value) => {
                return validate(value, getValues);
            };
        }

        if (isStacked) {
            config.finalSx['margin'] = "0px";
        }

        return config;
    }, [label, type, inputProps, restProps, disabled, validation, required, validationMessages, readOnly, minLength, maxLength, min, max, isStacked, sx, autoComplete]);

    return (
        <Controller
            control={control}
            name={name}
            rules={finalValidation}
            defaultValue={value || ""}
            render={({
                         field: {ref, onChange, value, ...fieldRest},
                         fieldState: {invalid, error},
                     }) => {
                return (
                    <MuiTextField
                        {...fieldRest}
                        {...finalInputConfig}
                        value={value != null && value != undefined ? value : ""}
                        onChange={(e) => {
                            if (type === "number" || type === "float") {
                                if (isNaN(e.target.valueAsNumber)) {
                                    onChange("");
                                    return;
                                } else if (type === "float" && e.target.valueAsNumber.getPrecision() > 2) {
                                    onChange(e.target.valueAsNumber.toFixed(2));
                                    return;
                                }
                                onChange(e.target.valueAsNumber);
                            } else {
                                onChange(e.target.value);
                            }

                            if(restProps && restProps.onChange){
                                restProps.onChange(e.target.value);
                            }
                        }}
                        error={invalid}
                        sx={finalSx}
                        helperText={
                            error
                                ? typeof parseError === "function"
                                    ? parseError(error)
                                    : error.message
                                : helperText
                        }
                    />
                );
            }}
        />
    );
};

export default TextField;
