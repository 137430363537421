import {Dialog, hideLoader, showLoader, Snackbar, useStepper} from "@beesset/ui-components";
import {useTariffs} from "../TariffsProvider";
import {CommonStep, DESCRIPTOR_TYPES} from "./CommonStep";
import {AttachMoney, DateRange, DirectionsBus, Receipt, Send} from '@mui/icons-material';
import React from "react";
import {Button, Collapse, ListItemButton, ListItemIcon, ListItemText, Slide, Stack, Typography} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import {DateUtils, generateId} from "@beesset/common-utils";
import {
    InvoiceApplicationComponent,
    RegulationCheck,
    UPORClientRest,
    UPORUtils,
    useConfig
} from "@beesset/upor-client-module-cmn";
import {Browser} from "@capacitor/browser";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const InvoiceCheck = ({innerRef, translations}) => {
    const [error, setError] = React.useState(false);

    const validateFnHolder = React.useRef();
    const checkboxDomRef = React.useRef();

    const [checked, setChecked] = React.useState(null);

    React.useEffect(() => {
        if (checked === true || checked === false) {
            setError(false);
        }
    }, [checked]);

    function handleChange(type) {
        if (type === 1) {
            if (checked === null || checked === false) {
                setChecked(true);
            } else {
                setChecked(null);
            }
        } else {
            if (checked === null || checked === true) {
                setChecked(false);
            } else {
                setChecked(null);
            }
        }
    }

    innerRef.current = function (onSuccess) {
        if (checked === null) {
            setError(true);
            checkboxDomRef.current['scrollIntoView']({behavior: "smooth", block: "center", inline: "nearest"});
            Snackbar.warning(translations.invoiceRequest);
        } else if (checked === true) {
            validateFnHolder.current({
                onSuccess: onSuccess
            });
        } else {
            onSuccess({});
        }
    }

    return <Stack spacing={3}>
        <Stack direction={{sm: 'column', md: 'row'}}>
            <ListItemButton disableGutters dense role={undefined} onClick={() => {
                handleChange(2);
            }}>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked != null && checked === false}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-label': translations.invoiceRequestNo}}
                        sx={{color: error && !checked ? "error.main" : null, ml: "8px", p: 1, pl: 0}}
                        ref={checkboxDomRef}
                    />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        variant: "caption",
                        color: error && !checked ? "error" : null
                    }}
                    primary={translations.invoiceRequestNo}
                />
            </ListItemButton>
            <ListItemButton disableGutters dense role={undefined} onClick={() => {
                handleChange(1);
            }}>
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        checked={checked != null && checked === true}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{'aria-label': translations.invoiceRequestYes}}
                        sx={{color: error && !checked ? "error.main" : null, ml: "8px", p: 1, pl: 0}}
                    />
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        variant: "caption",
                        color: error && !checked ? "error" : null
                    }}
                    primary={translations.invoiceRequestYes}
                />
            </ListItemButton>
        </Stack>
        <Collapse in={Boolean(checked)}>
            <InvoiceApplicationComponent
                ref={validateFnHolder}
                visibility={{
                    regulations: false,
                    button: false
                }}
                transactionRequired={false}
            />
        </Collapse>
    </Stack>;
}

const Step5_SummaryAndPayment = ({translations}) => {
    const {getLanguage} = useConfig();
    const running = React.useRef(false);
    const [showExceptionDialog, setShowExceptionDialog] = React.useState(null);
    const formId = React.useRef(generateId(10));
    const regulationCheckRef = React.useRef();
    const regulationCheck2Ref = React.useRef();
    const invoiceCheckRef = React.useRef();

    const {findProduct, resolveMediumIcon, resolvePrice, createTransactionRequest} = useTariffs();
    const {getData, updateData, goTo, goBack} = useStepper();

    function handlePayment(request) {
        if (running.current) {
            return;
        }

        running.current = true;

        showLoader();
        UPORClientRest.createTransaction({
            handlers: {
                success: (response) => {
                    if (UPORUtils.isNativePlatform()) {
                        Browser.open({url: response.redirect})
                            .catch(e => {
                            })
                            .finally(() => {
                                setTimeout(() => {
                                    running.current = false;
                                    const data = getData();
                                    updateData({
                                        medium: data.medium
                                    });
                                    goTo(0);
                                    hideLoader();
                                }, 1000);
                            });
                    } else {
                        running.current = false;
                        window.location.href = response.redirect;
                    }
                },
                failure: (response) => {
                    hideLoader();
                    let exceptionId = response.exceptionId;

                    if (exceptionId === "TRANSACTION_ID_IS_EXPIRED" || exceptionId === 'TRANSACTION_ALREADY_EXIST') {
                        setShowExceptionDialog(exceptionId);
                    } else {
                        Snackbar.error(response);
                    }

                    running.current = false;
                }
            },
            request
        });
    }

    const data = getData();
    const product = findProduct(data);
    const language = getLanguage();
    const isNative = UPORUtils.isNativePlatform();
    const descriptors = React.useMemo(() => {
        if (!product) {
            return [];
        }

        let selectionDescriptors = [
            {
                type: DESCRIPTOR_TYPES.HEADER,
                name: translations.paymentSummary,
            },
            {
                name: `${translations.mediumSelection} - ${translations.mediumTypes(data.medium.type)}`,
                value: data.medium.name,
                Icon: resolveMediumIcon(data.medium.type),
            },

        ];

        let ticketDescription = [
            <span
                key={"ticket-description"}>{`${data.quantity > 1 ? `${data.quantity} × ` : ""}${product.profileName}, ${product.productName}`}</span>];
        if (product && product.attributes) {
            let key = `Alert_${language}`;
            if (!product.attributes[key]) {
                key = `Alert_pl`;
            }
            if (product.attributes[key]) {
                ticketDescription.push(<br key={"ticket-description-breakLine"}/>);
                ticketDescription.push(<Typography key={"ticket-description-alert"} component={"span"} color="error"
                                                   variant="caption">
                    {product.attributes[key]}
                </Typography>);
            }
        }

        selectionDescriptors.push({
            name: translations.ticket,
            value: ticketDescription,
            Icon: Receipt
        });

        if (product['customActivationDateRequired'] || (product.attributes && product.attributes[`TicketPeriodDescription_${language}`])) {
            selectionDescriptors.push({
                TextProps: {
                    secondaryTypographyProps: {
                        fontSize: "0.85rem"
                    }
                },
                name: translations.configurationPeriod,
                value: [
                    <span key={"summary-startDate"}>
                        {`${translations.startDate}: ${DateUtils.formatters.FULLWithSeconds(DateUtils.getDate(data.startDate))}`}
                    </span>,
                    <br key={"summary-period-breakLine"}/>,
                    <span key={"summary-endDate"}>
                        {`${translations.endDate}: ${DateUtils.formatters.FULLWithSeconds(DateUtils.getDate(data.endDate))}`}
                    </span>
                ],
                Icon: DateRange
            });
        }

        if (product['vehicleNumberRequired']) {
            selectionDescriptors.push({
                name: translations.vehicleNumber,
                value: data['vehicleNumber'],
                Icon: DirectionsBus
            })
        }

        selectionDescriptors.push({
            name: translations.price,
            value: resolvePrice(product, data.quantity),
            Icon: AttachMoney
        });

        let result = [{
            type: DESCRIPTOR_TYPES.SUBLIST,
            descriptors: selectionDescriptors
        }, {
            type: DESCRIPTOR_TYPES.SUBLIST,
            descriptors: [{
                type: DESCRIPTOR_TYPES.HEADER,
                name: translations.paymentRegulationCheck,
            }, {
                type: DESCRIPTOR_TYPES.COMPONENT,
                Component: <Stack direction="column" overflow="hidden">
                    <RegulationCheck
                        ref={regulationCheckRef}
                        description={translations.regulation}
                        warning={translations.regulationWarning}
                        key={"purchase-summary-regulation-1"}
                    />
                    <RegulationCheck
                        ref={regulationCheck2Ref}
                        description={translations.paymentRegulation(data.medium.type)}
                        warning={translations.regulationWarning}
                        key={"purchase-summary-regulation-2"}
                    />
                </Stack>
            }]
        }];

        if (isNative) {
            result.push({
                type: DESCRIPTOR_TYPES.SUBLIST,
                descriptors: [{
                    type: DESCRIPTOR_TYPES.COMPONENT,
                    Component: <Typography variant="caption">{translations.paymentHiddenInvoiceDesc}</Typography>
                }]
            });
        } else {
            result.push({
                type: DESCRIPTOR_TYPES.SUBLIST,
                descriptors: [{
                    type: DESCRIPTOR_TYPES.COMPONENT,
                    Component: <InvoiceCheck innerRef={invoiceCheckRef} translations={translations}/>
                }]
            });
        }

        return result
            .map((o, index) => (
                index < result.length - 1 ? [o, {
                    type: DESCRIPTOR_TYPES.DIVIDER
                }] : o
            )).reduce((a, b) => a.concat(b), []);
    }, [data, product, language, isNative, translations]);

    return <React.Fragment>
        <CommonStep
            title={translations.paymentDesc}
            descriptors={[
                ...descriptors, {
                    type: DESCRIPTOR_TYPES.COMPONENT,
                    Component: <div style={{width: "100%"}}>
                        <Button
                            variant="contained"
                            size="large"
                            type="submit"
                            form={formId.current}
                            endIcon={<Send/>}
                            onClick={
                                () => {
                                    regulationCheckRef.current.check(() => {
                                        regulationCheck2Ref.current.check(() => {
                                            if (invoiceCheckRef.current) {
                                                invoiceCheckRef.current((bean) => {
                                                    let request = createTransactionRequest(data);
                                                    request['invoice'] = bean.invoice;
                                                    request['transactionInit'] = data.transactionInit;
                                                    handlePayment(request);
                                                });
                                            } else {
                                                let request = createTransactionRequest(data);
                                                request['transactionInit'] = data.transactionInit;
                                                handlePayment(request);
                                            }
                                        });
                                    });
                                }
                            }
                            sx={{float: "right", mt: 3, width: {xs: "100%", sm: "auto"}}}
                        >{translations.paymentConfirm}</Button>
                    </div>
                }]}
        />
        <Dialog
            TransitionComponent={Transition}
            open={showExceptionDialog !== null}
            disableFullScreen
            description={translations.mapException(showExceptionDialog)}
            actions={[{
                name: translations.buttonClose,
                onClick: () => {
                    updateData({
                        transactionInit: undefined
                    });
                    goBack();
                }
            }]}
        />
    </React.Fragment>
}

export default Step5_SummaryAndPayment;