import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import React from "react";
import {I18nextProvider, initReactI18next} from "react-i18next";
import commonResources from "./locate/resources";
import "moment/locale/pl";
import "moment/locale/de";
import "moment/locale/uk";
import moment from "moment";

function merge(resources) {
    if (resources) {
        let result = {};
        [...Object.keys(resources)].forEach(
            (language) => {
                result[language] = {
                    translations: {
                        ...(commonResources[language] &&
                        commonResources[language].translations
                            ? commonResources[language].translations
                            : {}),
                        ...(resources[language] && resources[language].translations
                            ? resources[language].translations
                            : {}),
                    },
                };
            }
        );
        return result;
    }
    return commonResources;
}

function initGlobalTranslator(resources, localStorageName, mergeCommonResources = true) {
    let rss = mergeCommonResources ? merge(resources) : resources;
    
    void i18n.use(LanguageDetector)
        .use(initReactI18next)
        .init({
            resources: rss,
            detection: {
                lookupLocalStorage: localStorageName,
                caches: ['localStorage'],
                convertDetectedLanguage: (lng) => {
                    let language = lng;
                    if (language.includes("-")) {
                        language = language.split("-")[0];
                    }
                    if (Object.keys(i18n.services.resourceStore.data).indexOf(language) < 0) {
                        language = "pl";
                    }
                    return language;
                }
            },
            debug: false,
            ns: ["translations"],
            defaultNS: "translations",
            keySeparator: ".",
            interpolation: {
                escapeValue: false,
                formatSeparator: ",",
            },
            react: {
                wait: true,
                bindI18n: "languageChanged loaded",
                bindStore: "added removed",
                nsMode: "default",
            }
        });

    i18n.services.formatter.add('currencyString', (value, lng, options) => {
        const formatter = new Intl.NumberFormat(lng, {
            currency: options.currencyCode,
            style: options.currencyCode ? "currency" : undefined
        });
        let result = formatter.format(value);
        if (options['showSign'] && value > 0) {
            return "+" + result;
        }
        return result;
    });

    i18n
        .on('languageChanged', (lang) => {
            moment.locale(lang);
            document.documentElement.lang = lang;
        });

    void i18n.changeLanguage();
}

const GlobalTranslator = ({children}) => {
    return <I18nextProvider i18n={i18n}>
        {children}
    </I18nextProvider>;
};

const changeLanguage = (newLanguage) => {
    if (
        i18n.language !== newLanguage &&
        Object.keys(i18n.services.resourceStore.data).indexOf(newLanguage) >= 0
    ) {
        void i18n.changeLanguage(newLanguage);
    }
};

const getLanguages = () => {
    let mappedDescriptions = {
        pl: "Polski",
        en: "English",
        de: "Deutsch",
        uk: "Yкраїнська"
    };
    return Object.keys(i18n.services.resourceStore.data).map(key => {
        return {
            id: key,
            description: mappedDescriptions[key] || key
        }
    });
};

const getLanguage = () => {
    return i18n.language;
}

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, {[key]: {}});
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {[key]: source[key]});
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export {GlobalTranslator, changeLanguage, getLanguages, getLanguage, initGlobalTranslator, mergeDeep};
