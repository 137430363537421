import download from "downloadjs";

import PDF from "./e-Sklep_BB_instrukcja.pdf";

const Page2 = () => {
    fetch(PDF)
        .then(resp => resp.blob())
        .then(blob => download(blob, "e-Sklep_BB_instrukcja.pdf"));
}

export default Page2;