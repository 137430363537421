import {matchSorter} from 'match-sorter';
import {DateUtils} from "@beesset/common-utils";

function greaterThanFilterFn(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}
// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
greaterThanFilterFn.autoRemove = (val) => typeof val !== "number";

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, {
    keys: [(row) => row.values[id]]
  });
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function fuzzyTextStartsWithFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, {
    keys: [(row) => row.values[id]],
    threshold: matchSorter.rankings.WORD_STARTS_WITH
  });
}
// Let the table remove the filter if the string is empty
fuzzyTextStartsWithFilterFn.autoRemove = (val) => !val;

const customFilterTypes = {
  fuzzyText: fuzzyTextFilterFn,
  fuzzyTextStartsWith: fuzzyTextStartsWithFilterFn,
  greaterThan: greaterThanFilterFn,
  text: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
  textContains: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
          ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
          : true;
    });
  },
  multiAttributesSelect: (rows, id, filterValue = []) => {
    if(!filterValue || filterValue.length === 0) {
      return [...rows];
    }

    let filtersByAttribute = {};
    filterValue.forEach((o) => {
      if(!filtersByAttribute[o.label]) {
        filtersByAttribute[o.label] = [];
      }
      filtersByAttribute[o.label].push(o);
    });

    let finalFilters = Object.values(filtersByAttribute).filter((o) => o.length === 1).map((o) => o[0]);
    if(finalFilters.length === 0) {
      return [...rows];
    }

    return rows.filter((row) => {
      let count = 0;
      finalFilters.forEach((el) => {
        if((el.value && row.original[el.accessor]) || (!el.value && !row.original[el.accessor])) {
          count++;
        }
      });
      return count === finalFilters.length;
    });
  },
  multiSelect: (rows, id, filterValue) => {
    if(!filterValue || filterValue.length === 0) {
      return [...rows];
    }
    let filterValuesByKey = {};
    filterValue.forEach((el) => {
      filterValuesByKey[el.label] = true;
    });
    return rows.filter((row) => {
      return filterValuesByKey[row.values[id]];
    });
  },
  select: (rows, id, filterValue) => {
    if(!filterValue || filterValue.length === 0) {
      return [...rows];
    }
    return rows.filter((row) => {
      return row.values[id] === filterValue.label;
    });
  },
  boolean: (rows, id, filterValue) => {
    if(filterValue === 0) {
      return [...rows];
    }
    const toCompate = filterValue == 1?true:false;
    return rows.filter((row) => {
      return toCompate === row.values[id];
    });
  },
  time: (rows, id, filterValue) => {
    if(filterValue && filterValue.value && filterValue.comparisonType) {
      let compFn;
      switch (filterValue.comparisonType) {
        case "less": {
          compFn = (a, b) => a < b;
          break;
        }
        case "lessEqual": {
          compFn = (a, b) => a <= b;
          break;
        }
        case "equal": {
          compFn = (a, b) => a === b;
          break;
        }
        case "moreEqual": {
          compFn = (a, b) => a >= b;
          break;
        }
        case "more": {
          compFn = (a, b) => a > b;
          break;
        }
      }

      if(compFn) {
        const date = DateUtils.getDate(filterValue.value);
        const minutes = date.hour() * 60 + date.minute();
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue && compFn(rowValue, minutes);
        });
      }
    }
    return [...rows];
  },
  date: (rows, id, filterValue) => {
    if(filterValue && filterValue.value && filterValue.comparisonType) {
      let compFn;
      switch (filterValue.comparisonType) {
        case "less": {
          compFn = (a, b) => a.isBefore(b);
          break;
        }
        case "lessEqual": {
          compFn = (a, b) => a.isBefore(b) || a.isSame(b);
          break;
        }
        case "equal": {
          compFn = (a, b) => a.isSame(b);
          break;
        }
        case "moreEqual": {
          compFn = (a, b) => a.isAfter(b) || a.isSame(b);
          break;
        }
        case "more": {
          compFn = (a, b) => a.isAfter(b);
          break;
        }
      }

      if(compFn) {
        const date = DateUtils.getDate(filterValue.value);
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined && compFn(DateUtils.getDate(rowValue), date);
        });
      }
    }
    return [...rows];
  }
};

const FilterTypeEnum = {
  FUZZY_TEXT: "fuzzyText",
  GREATHER_THAN: "greaterThan",
  DATE: "date",
  TIME: "time",
  TEXT: "text",
  EQUALS: 'equals',
  BETWEEN: 'between',
  INCLUDES: 'includes'
}

export { customFilterTypes, FilterTypeEnum}
