import download from "downloadjs";

import PDF from "./regulamin_05_2023.pdf";

const Page1 = () => {
    fetch(PDF)
        .then(resp => resp.blob())
        .then(blob => download(blob, "Regulamin_sprzedaży_w_kasownikach_mobilnych"));
}

export default Page1;