import {useTranslation} from "react-i18next";
import {Box, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./menu.css";
import React from "react";
import {NavLink} from "react-router-dom";


const Menu = ({items = [], i18nPrefix, menuClassPrefix}) => {
    const {t} = useTranslation();

    return <Box
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
    >
        <Box
            width="100%"
            maxWidth={{
                xs: 320,
                sm: 340,
                md: 600,
                lg: 900,
                xl: 1040,
            }}
        >
            <Grid container spacing={{xs: 2, md: 3, lg: 4}} justifyContent={items.length <= 2?"center":"flex-start"}>
                {items
                    .map((item, index) => (
                        <Grid xs={6} sm={6} md={4} lg={3} key={index}>
                            <Box className={"menu-item"}
                                 component={NavLink}
                                 onClick={(e) => {
                                     if(item.onClick) {
                                         item.onClick();
                                         e.preventDefault();
                                         e.stopPropagation();
                                         return false;
                                     }
                                 }}
                                 to={item.id}
                                 aria-label={item.title}
                            >
                                <div className={`menu-item-image ${menuClassPrefix}${item.id}`}/>
                                <Typography
                                    variant="body1"
                                    bgcolor="primary.main"
                                    textAlign="center"
                                    color="white"
                                    p={1}
                                    flex={1}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    fontWeight="bold"
                                >
                                    {t(`${i18nPrefix}${item.id}`)}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
            </Grid>
        </Box>
    </Box>
}

export default Menu;