import React from "react";
import {DateUtils, useDataFetcher} from "@beesset/common-utils";
import {MEDIUM_TYPE, sortProducts, UPORClientRest, useConfig} from "@beesset/upor-client-module-cmn";
import {CreditCard, LocationCity, PhoneAndroid, QrCode} from '@mui/icons-material';
import {Box, Fade, Skeleton} from "@mui/material";

const Profiles = {
    NORMAL: "NORMAL",
    REDUCTION: "REDUCTION"
}

const PeriodTypes = {
    MONTHS: "MONTHS",
    N_MONTHS: "N_MONTHS",
    DAYS: "DAYS",
    N_DAYS: "N_DAYS",
    DAYS_WEEKDAYS: "DAYS_WEEKDAYS",
    MONTHS_WEEKDAYS: "MONTHS_WEEKDAYS",
    YEAR: "N_YEAR",
    N_YEARS: "N_YEARS",
    SINGLE_PASS: "SINGLE_PASS"
}

const TariffsProvider = ({children}) => {
    const {getLanguage} = useConfig();
    const [loading, setLoading] = React.useState(false);
    const loadingTimeoutRef = React.useRef(null);

    const {data: tariff} = useDataFetcher({
        fetch: (props) => {
            if (loading === false && loadingTimeoutRef.current == null) {
                loadingTimeoutRef.current = setTimeout(() => {
                    setLoading(true);
                }, 500);
            }
            UPORClientRest.getTariff(props);
        },
        convert: response => response['tariff'],
        showLoading: false,
        request: {
            language: getLanguage()
        },
        callback: () => {
            if (loadingTimeoutRef.current != null) {
                clearTimeout(loadingTimeoutRef.current);
                loadingTimeoutRef.current = null;
            }
            setTimeout(() => {
                setLoading(false);
            }, [200]);
        }
    });

    const {
        getProfiles,
        getCategories,
        findProduct,
        resolveMediumIcon,
        resolvePrice,
        createTransactionRequest,
        initialized
    } = React.useMemo(() => {
        if (!tariff) {
            return {
                initialized: false
            };
        }

        let profiles = {};
        let products = [];

        sortProducts(tariff['products'])
            .forEach((product) => {
                products.push(product);

                let profile = profiles[product['profileLoid']];
                if (!profile) {
                    profile = {
                        profileLoid: product['profileLoid'],
                        profileName: product['profileName']
                    };
                    profiles[profile.profileLoid] = profile;
                }
            });

        let profilesArray = Object.values(profiles).map(profile => {
            return {
                profileLoid: profile.profileLoid,
                profileName: profile.profileName
            }
        })

        function resolvePrice(product, quantity) {
            let c = product['currencyList'][0];

            if (quantity > 1) {
                return `${quantity} × ${c.price.toFixed(2)} ${c['currencyCode']} = ${(c.price * quantity).toFixed(2)} ${c['currencyCode']}`
            }

            return `${c.price.toFixed(2)} ${c['currencyCode']}`
        }

        function numOr0(num) {
            return isNaN(num) ? 0 : num;
        }

        function capitalizeFirstLetter(str) {
            return str[0].toUpperCase() + str.slice(1).toLowerCase();
        }

        function getCategories(selectedProfile) {
            let filteredProducts = products.filter(({profileLoid}) => selectedProfile === profileLoid);
            let groups = {};
            let language = getLanguage();
            filteredProducts.forEach((product) => {
                let productCategories = product["categories"]
                    .sort((a, b) => numOr0(a.position) - numOr0(b.position))
                    .map(o => o.name)
                    .join(", ");

                let productGroup = groups[productCategories];
                if (!productGroup) {
                    productGroup = {
                        productGroupName: capitalizeFirstLetter(`${productCategories}${product.productGroup ? `, ${product.productGroup}` : ""}`),
                        productsConfigurations: {},
                    }
                    groups[productCategories] = productGroup;
                }

                let productConfiguration = productGroup.productsConfigurations[product['productName']];
                if (!productConfiguration) {
                    productConfiguration = {
                        productLoid: product['loid'],
                        productConfigurationName: product['productName'],
                        productConfigurationDescription: `${product['profileName']} - ${resolvePrice(product)}`,
                        productTimeUnitType: product['productTimeUnitType'],
                        productTimeUnitTypeValue: product['productTimeUnitTypeValue'],
                        productCategory: product["categories"]
                            .map(o => o.loid)
                            .sort((a, b) => a - b)
                            .join(","),
                        currencyList: [product['currencyList'][0]]
                    }

                    if (product && product.attributes) {
                        let key = `Alert_${language}`;
                        if (!product.attributes[key]) {
                            key = `Alert_pl`;
                        }
                        productConfiguration.specialInfo = product.attributes[key];
                    }

                    productGroup.productsConfigurations[productConfiguration.productConfigurationName] = productConfiguration;
                }
            });


            return Object.values(groups)
                .map((group) => {
                    return {
                        productGroupName: group.productGroupName,
                        productsConfigurations: Object.values(group.productsConfigurations)
                    }
                })
        }

        function findProduct({productLoid}) {
            return products.find((product) => {
                return product.loid === productLoid;
            });
        }

        function resolveMediumIcon(type) {
            switch (type) {
                case MEDIUM_TYPE.PAYMENT_CARD:
                    return CreditCard;
                case MEDIUM_TYPE.PHONE_QR:
                    return QrCode;
                case MEDIUM_TYPE.CITY_CARD:
                    return LocationCity;
                case MEDIUM_TYPE.VIRTUAL_CITY_CARD:
                    return PhoneAndroid;
                default:
                    return null;
            }
        }

        function createTransactionRequest(data) {
            const product = findProduct(data);

            let request = {
                medium: data.medium,
                product: product,
                language: getLanguage()
            };
            if (product['customActivationDateRequired']) {
                request.activationDate = DateUtils.getDate(data.startDate).format("YYYY-MM-DDTHH:mm:ss");
            }
            if (product['vehicleNumberRequired']) {
                request.vehicleNumber = data.vehicleNumber;
            }
            if (product['maximumQuantityInCheckout'] > 1) {
                request.quantity = data.quantity;
            }

            return request;
        }

        return {
            getProfiles: () => profilesArray,
            getCategories,
            findProduct,
            resolveMediumIcon,
            resolvePrice,
            createTransactionRequest,
            initialized: true
        }
    }, [tariff]);

    return <TariffsContext.Provider value={{
        Profiles,
        PeriodTypes,
        getProfiles,
        getCategories,
        findProduct,
        resolvePrice,
        createTransactionRequest,
        resolveMediumIcon
    }}>
        {loading && <LoadingHtml/>}
        {(initialized && !loading) && children}
    </TariffsContext.Provider>
}

const LoadingHtml = () => {
    return <Fade in={true}>
        <Box flexDirection="column" width={"100%"} flex={1} display="flex" padding={{
            xs: 2,
            md: 4,
            xl: 6
        }}>
            <Skeleton sx={{flex: "2", transform: "scale(1, 0.90)"}} animation="wave"/>
            <Skeleton sx={{flex: "20", transform: "scale(1, 0.90)"}} animation={false}/>
            <Skeleton sx={{flex: "1", transform: "scale(1, 0.90)"}} animation="wave"/>
        </Box>
    </Fade>
}

const TariffsContext = React.createContext({
    Profiles,
    PeriodTypes,
    getProfiles: () => [],
    getCategories: (profileLoid) => [],
    findProduct: ({profileLoid, productTimeUnitType, productTimeUnitTypeValue}) => {
    },
    createTransactionRequest: (data) => {
    },
    resolvePrice: () => null,
    resolveMediumIcon: () => null
});

export function useTariffs() {
    return React.useContext(TariffsContext);
}

export default TariffsProvider;