import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

const FormContainer = ({
                           defaultValues = {},
                           onSuccess = () => {
                           },
                           onSuccessEvent = (e, finalObject) => {
                           },
                           FormProps,
                           children,
                           style = {},
                           id,
                           innerRef,
                           onChange,
                           shouldUnregister = false
                       }) => {
    const methods = useForm({defaultValues, shouldUnregister});
    const {handleSubmit, reset, watch} = methods;

    const {t} = useTranslation();

    React.useEffect(() => {
        if (innerRef) {
            innerRef.current = {...methods};
        }
    }, [methods]);

    React.useEffect(() => {
        if (onChange) {
            const subscription = watch((value) => {
                onChange(value);
            });
            return () => {
                subscription.unsubscribe();
            };
        }
    }, [watch, onChange]);
    
    const validationMessages = React.useMemo(() => {
        return {
            required: t("validation.required"),
            invalidDate: t("validation.invalidDate"),
            invalidEmail: t("validation.invalidEmail"),
            minLength: (minLength) => t("validation.minLength", {minLength}),
            maxLength: (maxLength) => t("validation.maxLength", {maxLength}),
            min: (min) => t("validation.min", {min}),
            max: (max) => t("validation.max", {max}),
            maxFilesSize: (size) => t("validation.maxFilesSize", {size}),
            wrongFileFormat: (allowedExtensions) => t("validation.wrongFileFormat", {allowedExtensions})
        };
    }, [t]);

    return (
        <FormProvider {...methods} validationMessages={validationMessages}>
            <form
                id={id}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "800px",
                    ...style,
                }}
                onReset={reset}
                onSubmit={handleSubmit((object, e) => {
                    let finalObject = {};
                    Object.keys(object).forEach((key) => {
                        if (object[key] === "") {
                            finalObject[key] = null;
                        } else {
                            finalObject[key] = object[key];
                        }
                    });
                    onSuccessEvent(e, finalObject);
                    onSuccess(finalObject);
                })}
                noValidate
                autoComplete="off"
                {...FormProps}
            >
                {children}
            </form>
        </FormProvider>
    );
};

export default React.memo(
    React.forwardRef((props, ref) => {
        return <FormContainer {...props} innerRef={ref}/>;
    })
);
