import {Checkbox, FormControlLabel} from "@mui/material";
import React from "react";
import {Controller, useFormContext} from "react-hook-form";

const CheckboxField = ({name, value, label = "", sx, readOnly = false, size, ...props}) => {
    const {control} = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value || false}
            render={({field: {onChange, value}}) => (
                <FormControlLabel
                    sx={{
                        ...sx,
                        mozUserSelect: "none",
                        webkitUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none",
                    }}
                    label={label}
                    {...props}
                    control={<Checkbox
                        size={size}
                        onChange={readOnly ? null : onChange}
                        checked={value}
                        disableRipple={readOnly}
                    />}
                />
            )}
        />
    );
};

export default CheckboxField;
