import {Link, Typography} from "@mui/material";
import {NavLink} from "react-router-dom";
import {PatternFormat} from "react-number-format";
import React from "react";

const TextLink = ({navigateTo, onClick, children}) => {
    return <Typography
        sx={{
            cursor: "help",
            fontWeight: "bold",
            textDecoration: "none",
            color: "primary.light"
        }}
        variant="caption"
        component={navigateTo ? NavLink : Link}
        onClick={onClick}
        to={navigateTo}
    >
        {children}
    </Typography>
}

const PhoneNumberPattern = React.forwardRef((props, ref) => {
    const {onChange, ...other} = props;

    return <PatternFormat
        {...other}
        format="### ### ###"
        allowEmptyFormatting={false}
        getInputRef={ref}
        onValueChange={(values) => {
            onChange({
                target: {
                    value: values.formattedValue
                }
            });
        }}
    />
});

export function sortProducts(products) {
    function timeUnitOrder(unit) {
        if (unit) {
            let u = unit.toUpperCase();
            if (u.includes("SINGLE_PASS")) {
                return 0;
            }
            if (u.includes("MIN")) {
                return 1;
            }
            if (u.includes("HOUR")) {
                return 2;
            }
            if (u.includes("MONTH")) {
                return 4;
            }
            if (u.includes("DAY")) {
                return 3;
            }
        }
        return 5;
    }

    function numOr0(num) {
        return isNaN(num) ? 0 : num;
    }

    return products.sort((a, b) => {

        let categoryA = a["categories"]
            .map(o => isNaN(o.position) ? 0 : o.position)
            .reduce((a, b) => numOr0(a.position) + numOr0(b.position));
        let categoryB = b["categories"]
            .map(o => isNaN(o.position) ? 0 : o.position)
            .reduce((a, b) => numOr0(a.position) + numOr0(b.position))

        let comp = categoryA - categoryB;
        if (comp === 0) {
            comp = timeUnitOrder(a.productTimeUnitType) - timeUnitOrder(b.productTimeUnitType);
        }
        if (comp === 0) {
            comp = a.productTimeUnitTypeValue - b.productTimeUnitTypeValue;
        }
        if (comp === 0) {
            comp = a['currencyList'][0].price - b['currencyList'][0].price;
        }

        return comp;
    });
}


export {TextLink, PhoneNumberPattern};

export * from "./formFields";
export {TemplateContainer} from "./templates/TemplateContainer";
export {
    Regulations,
    PrivacyPolicy,
    DeclarationOfAccessibility
} from "./templates/DefinedTemplates";
export * from "./signInUp";
export {Articles} from "./articles/Articles";
export {Tariff} from "./tariff/Tariff";
export * from "./applications";
export * from "./settings";
export {TransactionsHistoryComponent} from "./history/TransactionsHistory";
export {PurchaseComponent, PurchaseSteps} from "./purchase/Purchase";
export {VirtualCityCardComponent} from "./virtualCityCard/VirtualCityCard";
export {EuropeanFundsLogo} from "./europeanFunds/EuropeanFundsLogo";