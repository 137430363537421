import {getLanguage, useDataFetcher} from "@beesset/common-utils";
import {UPORClientRest} from "@beesset/upor-client-module-cmn";
import {useExtendedTableFilters} from "@beesset/ui-components";
import React from 'react';
import {Box, Fade, Skeleton, Stack, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import FilterFields from "./FilterFields";
import RefundDialog from "./RefundDialog";
import InvoiceDialog from "./InvoiceDialog";
import AfterPurchaseDialog from "./AfterPurchaseDialog";
import {useTheme} from "@mui/system";
import WebTransactionsTable from "./web/WebTransactionsTable";
import NativeTransactionsTable from "./native/NativeTransactionsTable";
import ComplaintDialog from "./ComplaintDialog";

const EnumActionType = {
    INVOICE_REQUEST: "INVOICE_REQUEST",
    REFUND: "REFUND",
    CONFIRMATION: "CONFIRMATION",
    COMPLAINT_REQUEST: "COMPLAINT_REQUEST",
}

const ProductDescriptorCell = ({count, productName, profileName, validityStatus, translations}) => {
    return <Stack>
        <Typography sx={{fontSize: "0.875rem"}}>
            {`${count > 1 ? `${count} × ` : ""}${productName}`}
        </Typography>
        <Typography sx={{color: "text.secondary", fontSize: "0.75rem"}}>
            {profileName}
        </Typography>
        {validityStatus && <Typography fontSize="0.7rem" color={mapValidityStatusColor(validityStatus)}>
            {translations.mapValidityStatus(validityStatus)}
        </Typography>}
    </Stack>;
}

const PriceDescriptorCell = ({price, currencyCode}) => {
    return <Stack>
        <Typography sx={{fontSize: "0.875rem"}}>
            {price}
        </Typography>
        <Typography sx={{color: "text.secondary", fontSize: "0.75rem"}}>
            {currencyCode}
        </Typography>
    </Stack>;
}

const LoadingHtml = () => {
    return <Fade in={true}>
        <Box flexDirection="column" width={"100%"} flex={1} display="flex" padding={{
            xs: 2,
            md: 4,
            xl: 6
        }}>
            <Skeleton sx={{flex: "2", transform: "scale(1, 0.90)"}} animation="wave"/>
            <Skeleton sx={{flex: "20", transform: "scale(1, 0.90)"}} animation={false}/>
            <Skeleton sx={{flex: "1", transform: "scale(1, 0.90)"}} animation="wave"/>
        </Box>
    </Fade>
}

const mapValidityStatusColor = (status) => {
    switch (status) {
        case "ACTIVE":
            return "success.light";
        case "INACTIVE":
            return "warning.light";
        case "EXPIRED":
            return "error.light";
    }
};

const mapStatusColor = (status) => {
    switch (status) {
        case "TR_FINISHED":
            return "success";
        case "TR_REFUND_FINISHED":
        case "TR_REFUND_CONFIRMED":
            return "warning";
        case "TR_CREATED":
        case "TR_PAYMENT_IN_PROGRESS":
        case "TR_REFUND_IN_PROGRESS":
        case "TR_PAYMENT_CONFIRMED":
            return "info";
        case "TR_CANCELED":
        case "TR_UNCLEAR":
            return "error";
    }
};

const TransactionsHistoryComponent = ({AfterPurchaseComponent}) => {
    const theme = useTheme();
    const nativeView = useMediaQuery(theme.breakpoints.down("md"));
    const [loading, setLoading] = React.useState(null);
    const [invoiceApplicationProps, setInvoiceApplicationProps] = React.useState(null);
    const [refundRequest, setRefundRequest] = React.useState(null);
    const [complaintApplicationProps, setComplaintApplicationProps] = React.useState(null);
    const [afterPurchaseProps, setAfterPurchaseProps] = React.useState(null);

    const {t} = useTranslation();

    const {filterValue, FiltersButton, FiltersPopover} = useExtendedTableFilters({
        defaultValues: {
            startDate: null,
            endDate: null,
            medium: null,
            channel: null
        },
        FilterFieldsComponent: FilterFields
    });

    const {data: transactions, refresh} = useDataFetcher({
        fetch: UPORClientRest.getTransactions,
        showLoading: loading === false,
        convert: (response) => response['transactions'],
        callback: () => {
            setInvoiceApplicationProps(null);
            setRefundRequest(null);
            setComplaintApplicationProps(null);
        },
        request: {
            startDate: filterValue.startDate,
            endDate: filterValue.endDate,
            mediums: filterValue.medium ? [filterValue.medium] : [],
            channel: filterValue.channel,
            language: getLanguage()
        }
    });

    React.useEffect(() => {
        if (loading == null) {
            if (!transactions) {
                const timeout = setTimeout(() => {
                    if (!transactions) {
                        setLoading(true);
                    }
                }, 300);

                return () => {
                    clearTimeout(timeout);
                }
            } else {
                setLoading(false);
            }
        } else if (loading) {
            if (transactions) {
                setTimeout(() => {
                    setLoading(false);
                }, 500)
            }
        }
    }, [loading, transactions]);

    const translations = React.useMemo(() => {
        return {
            id: t("components.history.transactionId"),
            medium: t("components.history.medium"),
            channel: t("components.history.channel"),
            transactionDate: t("components.history.transactionDate"),
            paidOn: t("components.history.paidOn"),
            cancelledOn: t("components.history.cancelledOn"),
            refundedOn: t("components.history.refundedOn"),
            status: t("components.history.status"),
            product: t("components.history.product"),
            price: t("common.price"),
            period: t("common.period"),
            mapStatusLabel: (status) => t(`components.history.statuses.${status}`),
            mapMediumType: (mediumType) => t(`common.mediumTypes.${mediumType}`),
            close: t("button.close"),
            send: t("button.send"),
            invoice: t("components.application.invoice.one"),
            invoiceCorrection: t("components.application.invoiceCorrection.one"),
            getInvoice: t("components.application.invoice.get"),
            getInvoiceCorrection: t("components.application.invoiceCorrection.get"),
            getComplaint: t("components.application.complaint.get"),
            complaint: t("components.application.complaint.one"),
            refund: t("components.history.refund"),
            refundTitle: t("components.history.refundTitle"),
            refundDescription: t("components.history.refundDescription"),
            refundSuccess: t("components.history.refundSuccess"),
            paymentCardConfirmation: t("components.history.paymentCardConfirmation"),
            qrCodeConfirmation: t("components.history.qrCodeConfirmation"),
            confirmation: t("components.history.confirmation"),
            startDate: t("common.startDate"),
            endDate: t("common.endDate"),
            manyProducts: t("components.history.manyProducts"),
            vehicleNumber: t("components.application.other.vehicleNumber"),
            transaction: t("components.application.invoice.transaction"),
            mapValidityStatus: (validityStatus) => t(`components.history.validityStatuses.${validityStatus}`),
        };
    }, [t]);

    function showInvoiceDialog(transaction) {
        let props = {
            transaction: transaction
        };
        if (transaction.invoice) {
            props['invoice'] = transaction.invoice;
            props['correction'] = true;
        }
        setInvoiceApplicationProps(props);
    }

    function showComplaintDialog(transaction) {
        setComplaintApplicationProps({
            transaction: transaction
        });
    }

    function showRefundDialog(transaction) {
        setRefundRequest({
            transactionId: transaction.loid
        });
    }

    function downloadConfirmation(transaction) {
        UPORClientRest.getTransactionConfirmation({
            request: {
                transactionId: transaction.loid
            }
        });
    }

    function showAfterPurchaseMessage(purchaseId) {
        let found = transactions.find((el) => {
            return el.transactionId === purchaseId;
        });
        if (found) {
            setAfterPurchaseProps(found)
        }
    }

    const props = {
        downloadConfirmation,
        showInvoiceDialog,
        showRefundDialog,
        showAfterPurchaseMessage,
        showComplaintDialog,
        transactions,
        translations,
        refresh,
        FiltersButton
    };

    return <React.Fragment>
        {loading === true && <LoadingHtml/>}
        {loading === false && transactions && <Box bgcolor={"background.paper"} pl={2} pr={2} pb={1} pt={1} m={{
            xs: 2,
            sm: 3,
            md: 4,
        }}>
            {FiltersPopover}
            {nativeView
                ? <NativeTransactionsTable {...props}/>
                : <WebTransactionsTable {...props}/>}
            <RefundDialog
                translations={translations}
                refundRequest={refundRequest}
                onClose={() => setRefundRequest(null)}
                onSuccess={refresh}
            />
            <InvoiceDialog
                translations={translations}
                invoiceApplicationProps={invoiceApplicationProps}
                onClose={() => setInvoiceApplicationProps(null)}
                onSuccess={refresh}
            />
            <ComplaintDialog
                translations={translations}
                applicationProps={complaintApplicationProps}
                onClose={() => setComplaintApplicationProps(null)}
                onSuccess={refresh}
            />
            <AfterPurchaseDialog
                AfterPurchaseComponent={AfterPurchaseComponent}
                translations={translations}
                afterPurchaseProps={afterPurchaseProps}
                onClose={() => setAfterPurchaseProps(null)}
            />
        </Box>}
    </React.Fragment>
}

export {
    PriceDescriptorCell,
    ProductDescriptorCell,
    TransactionsHistoryComponent,
    EnumActionType,
    mapStatusColor,
    mapValidityStatusColor
};