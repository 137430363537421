import {
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    Stack,
    Typography
} from "@mui/material";
import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import isEqual from "react-fast-compare";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";
import {Clear} from "@mui/icons-material";

const SelectField = ({
                         validation = {},
                         name,
                         value,
                         label,
                         required,
                         parseError,
                         helperText,
                         hideIfOneOption = false,
                         getOptionLabel,
                         groupBy,
                         getOptionId,
                         renderOption,
                         sx,
                         isStacked,
                         disableClearButton = false,
                         actionOnChange,
                         optionsProvider: {options = [], fetchOptions, convertOptions, fetchOptionsRequest},
                        size
                     }) => {
    const {t} = useTranslation();

    const {control, validationMessages, getValues, setValue, watch} = useFormContext();
    const [open, setOpen] = React.useState(false);

    const [data, setData] = React.useState({
        version: getValues()[name] ? 2 : 1,
        options: fetchOptions ? [] : options,
        request: fetchOptionsRequest,
        isLoaded: !fetchOptions
    });
    const loading = (open || data.version > 1 || hideIfOneOption) && data.isLoaded === false;
    const currentValue = watch(name);

    if (required) {
        validation.required = validationMessages.required;
    }

    const mapOptionId = (option) => {
        if (!option) {
            return null;
        }
        return "" + getOptionId(option);
    }

    const mapOption = (option) => {
        if (!option) {
            return null;
        }
        return (data.options || []).find((o) => option === mapOptionId(o));
    }

    React.useEffect(() => {
        if (currentValue && data.options && data.isLoaded) {
            if (hideIfOneOption && data.options.length === 1) {
                 setValue(name, data.options[0]);
            }
            else {
                if (data.options.findIndex((el) => {
                    return getOptionId(el) === getOptionId(currentValue)
                }) < 0) {
                    setValue(name,null);
                }
            }
        }
    }, [currentValue, data.options]);

    React.useEffect(() => {
        if (!isEqual(fetchOptionsRequest, data.request)) {
            setData({
                ...data,
                version: data.version + 1,
                request: fetchOptionsRequest,
                isLoaded: false,
            });
        }
    }, [fetchOptionsRequest]);

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        fetchOptions({
            handlers: {
                success: (response) => {
                    if (active) {
                        setData({
                            options: convertOptions ? convertOptions(response) : response,
                            isLoaded: true,
                            request: data.request,
                            version: data.version + 1
                        });
                    }
                },
                failure: () => {
                    if (active) {
                        setData({
                            options: [],
                            isLoaded: true,
                            request: data.request,
                            version: data.version + 1
                        });
                    }
                },
            },
            request: data.request
        });

        return () => {
            active = false;
        };
    }, [loading]);


    function generateItems({options, name}) {
        return options.map((option, index) => {
            let props = {
                key: `${name}-form-field-label-${index}`,
                value: mapOptionId(option)
            };
            return renderOption ? renderOption(props, option) : <MenuItem {...props}>
                <Typography>
                    {getOptionLabel(option)}
                </Typography>
            </MenuItem>
        })
    }

    function generateGroupedItems({options}) {
        let groups = {};

        options.forEach(option => {
            let key = groupBy(option);
            let group = groups[key];
            if (group == null) {
                group = [];
                groups[key] = group;
            }
            group.push(option);
        });

        let items = [];
        Object.keys(groups).forEach((groupKey) => {
            items.push(<ListSubheader color="primary" key={`select-field-group-${groupKey}`}>{groupKey}</ListSubheader>);
            items.push(...generateItems({
                name: `${name}-${groupKey}`,
                options: groups[groupKey],
                emptyOption: false
            }));
        })
        return items;
    }

    if (!sx) {
        sx = {
            marginTop: "10px"
        };
    }
    if(hideIfOneOption) {
        if(!data.isLoaded || (currentValue && data.options.length === 1)) {
            sx['display'] = "none";
        }
    }
    if (isStacked) {
        sx['width'] = "100%";
        sx['margin'] = "0px";
    }

    return (
        <Controller
            control={control}
            name={name}
            rules={validation}
            defaultValue={value || null}
            render={({
                         field: {onChange, value},
                         fieldState: {invalid, error},
                     }) => {
                return <FormControl
                    required={required}
                    error={error !== undefined}
                    sx={sx}
                    size={size}
                >
                    <InputLabel
                        id={`${name}-form-field-label`}
                        htmlFor={`${name}-form-field`}
                    >
                        {label}
                    </InputLabel>
                    <Select
                        label={label}
                        endAdornment={disableClearButton === false
                            ? <IconButton
                                size="small"
                                aria-label={t("button.clear")}
                                sx={{display: value ? "" : "none", mr: 1.5}}
                                onClick={() => {
                                    setValue(name, null);
                                }}
                            >
                                <Clear/>
                            </IconButton>
                            : null}
                        onChange={(event) => {
                            if (data.isLoaded) {
                                onChange(mapOption(event.target.value));
                                if (actionOnChange) {
                                    actionOnChange(mapOption(event.target.value));
                                }
                            }
                        }}
                        value={mapOptionId(value) || ''}
                        open={open}
                        onOpen={() => {
                            setOpen(true);
                        }}
                        onClose={() => {
                            setOpen(false);
                        }}
                        renderValue={(selected) => {
                            if (!data.isLoaded || selected === '' || !mapOption(selected)) {
                                return '';
                            }
                            return getOptionLabel(mapOption(selected));
                        }}
                        MenuProps={{
                            PaperProps: {sx: {maxHeight: "calc(50% - 20px)"}}
                        }}
                        labelId={`${name}-form-field-label`}
                        inputProps={{
                            id: `${name}-form-field`
                        }}
                    >
                        {
                            data.isLoaded && data.options.length > 0 && groupBy &&
                            generateGroupedItems({options: data.options})
                        }
                        {
                            data.isLoaded && data.options.length > 0 && !groupBy &&
                            generateItems({
                                options: data.options,
                                name
                            })
                        }
                        {(!data.isLoaded || data.options.length === 0) && <MenuItem
                            selected={false}
                            key={`${name}-loading-or-no-options-label`}
                            disabled
                            value={null}
                        >
                            <LoadingOrNoOptions data={data}/>
                        </MenuItem>}
                    </Select>
                    {(helperText || error) && <FormHelperText>{
                        error
                            ? typeof parseError === "function"
                                ? parseError(error)
                                : error.message
                            : helperText
                    }</FormHelperText>}
                </FormControl>
            }}
        />
    );
};

const LoadingOrNoOptions = ({data}) => {
    const {t} = useTranslation();

    return <React.Fragment>
        {data.isLoaded && t("commonComponents.select.noOptions")}
        {!data.isLoaded && <Stack direction={"row"} justifyContent="space-between" width="100%">
            {t("commonComponents.select.loading")}
            <CircularProgress color="inherit" size={20}/>
        </Stack>}
    </React.Fragment>
}

export default SelectField;
