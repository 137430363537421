import React from "react";
import {FormContainer, TextField} from "@beesset/ui-components";
import {Editor as Tiny} from '@tinymce/tinymce-react';
import {Fab} from "@mui/material";
import {Save} from "@mui/icons-material";
import './OverrideStyles.css';

const standaloneEditorContent = (content, css) => {
    return `<html lang="pl"><head><title></title><style>${css}</style></head><body class="beesset_template">${content}</body></html>`
}

const HTMLEditorPrv
    = ({
           innerRef,
           title,
           showTitle = true,
           tokenFormat,
           content = "",
           contentCss = "",
           tokens = [],
           imageUploadUrl = "",
           documentBaseUrl = "",
           onSave
       }) => {

    const editorRef = React.useRef({});
    const formRef = React.useRef({});
    const [css, setCss] = React.useState(null);

    React.useEffect(() => {
        if (contentCss && contentCss !== "") {
            fetch(contentCss)
                .then(response => response.text())
                .then(css => setCss(css));
        } else {
            setCss("");
        }
    }, [contentCss]);


    const prepareRequest = () => {
        console.log(formRef.current.trigger());

        let result = {
            content: editorRef.current['getContent'](),
            standaloneContent: standaloneEditorContent(editorRef.current['getContent'](), css)
        }
        if (showTitle) {
            result.title = formRef.current['getValues']()["title"];
        }
        return result;
    }

    if (innerRef) {
        innerRef.current = prepareRequest;
    }

    return css && <FormContainer
        ref={formRef}
        onSuccess={(bean) => {
            if (onSave) {
                onSave(prepareRequest());
            }
        }}
        style={{maxWidth: "unset", height: "100%"}}
    >
        {showTitle && <TextField
            label="Nazwa"
            name="title"
            sx={{mb: "10px", mt: "5px"}}
            value={title}
        />}
        <Tiny
            initialValue={content}
            init={{
                init_instance_callback: (editor) => {
                    editorRef.current = editor;
                },
                language: "pl",
                selector: 'textarea',
                paste_as_text: true,
                body_class: "beesset_template",
                plugins: 'preview importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                editimage_cors_hosts: ['picsum.photos'],
                menubar: 'file edit view insert format tools table help',
                toolbar: 'undo redo | fullscreen preview code tokens | bold italic underline strikethrough forecolor backcolor removeformat | blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | pagebreak | charmap emoticons | insertfile image media link anchor codesample | ltr rtl',
                toolbar_sticky: true,
                image_advtab: true,
                content_css: contentCss,
                importcss_append: true,
                images_upload_credentials: true,
                images_upload_url: imageUploadUrl,
                document_base_url: documentBaseUrl,
                resize: false,
                height: "100%",
                image_caption: true,
                noneditable_class: 'mceNonEditable',
                noneditable_regexp: /\$\{[^\}]+\}/g,
                toolbar_mode: 'sliding',
                setup: (editor) => {
                    editor.ui.registry.addIcon('token', '<svg enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="<a href="http://www.w3.org/2000/svg">http://www.w3.org/2000/svg</a>"><path d="m0 0h24v24h-24z" fill="none"/><path d="m21 12-4.37 6.16c-.37.52-.98.84-1.63.84h-3v-2h3l3.55-5-3.55-5h-10v3h-2v-3c0-1.1.9-2 2-2h10c.65 0 1.26.31 1.63.84zm-11 3h-3v-3h-2v3h-3v2h3v3h2v-3h3z"/></svg>');
                    editor.ui.registry.addMenuButton("tokens", {
                        icon: "token",
                        tooltip: "Token",
                        fetch: (callback) => {
                            callback(tokens.map(({name, value}) => {
                                return {
                                    type: "menuitem",
                                    text: name,
                                    onAction: () => {
                                        editor.insertContent(tokenFormat ? tokenFormat(value) : `\${${value}}`);
                                    }
                                }
                            }));
                        }
                    });
                }
            }}
        />
        {onSave && <Fab
            sx={{
                position: 'absolute',
                bottom: 80,
                right: {
                    xs: 30,
                    sm: 50
                },
            }}
            type="submit"
            color="secondary"
        >
            <Save/>
        </Fab>}
    </FormContainer>
};

const HTMLEditor = React.forwardRef(
    ({
         title,
         showTitle = true,
         tokenFormat,
         contentCss = "",
         content = "",
         tokens = [],
         imageUploadUrl = "",
         documentBaseUrl = "",
         onSave
     }, ref) => {
        return <HTMLEditorPrv
            title={title}
            showTitle={showTitle}
            tokenFormat={tokenFormat}
            contentCss={contentCss}
            content={content}
            tokens={tokens}
            imageUploadUrl={imageUploadUrl}
            documentBaseUrl={documentBaseUrl}
            onSave={onSave}
            innerRef={ref}
        />;
    }
);

export default HTMLEditor;