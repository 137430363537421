export default function findRecursively({items, recursiveName, decider}) {
  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    if (decider(item)) {
      return item;
    }
    if(Array.isArray(recursiveName)) {
      for(let j = 0; j < recursiveName.length; j++) {
        if (item[recursiveName[j]]) {
          let found = findRecursively({
            items: item[recursiveName[j]],
            recursiveName: recursiveName,
            decider
          });
          if (found) {
            return found;
          }
        }
      }
    }
    else {
      if (item[recursiveName]) {
        let found = findRecursively({
          items: item[recursiveName],
          recursiveName,
          decider
        });
        if (found) {
          return found;
        }
      }
    }
  }
}
