import {Divider, Input, ListItemText, MenuItem, OutlinedInput, Select, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";

export default function BooleanColumnFilter({
                                                column: {filterValue, setFilter}
                                            }) {
    const {t} = useTranslation();

    return (
        <Select
            defaultValue={0}
            value={filterValue || 0}
            onChange={(e) => {
                setFilter(e.target.value || 0);
            }}
            fullWidth
            displayEmpty
            renderValue={(value) => {
                if (value === 0) {
                    return <em style={{color: "#aaa"}}>{t("commonComponents.filter.select")}</em>;
                } else if (value === 1) {
                    return t("commonComponents.filter.boolean.true");
                } else {
                    return t("commonComponents.filter.boolean.false");
                }
            }}
            input={<OutlinedInput size="small" sx={{fontSize: "0.9rem"}}/>}
        >
            <MenuItem value={0}>
                <em style={{fontSize: "0.8rem"}}>{t("commonComponents.filter.boolean.none")}</em>
            </MenuItem>
            <Divider light />
            <MenuItem value={1}>
                <Typography sx={{fontSize: "0.8rem"}}>
                    {t("commonComponents.filter.boolean.true")}
                </Typography>
            </MenuItem>
            <MenuItem value={2}>
                <Typography sx={{fontSize: "0.8rem"}}>
                    {t("commonComponents.filter.boolean.false")}
                </Typography>
            </MenuItem>
        </Select>
    );
}
