import Toolbar from "@mui/material/Toolbar";
import React from "react";
import {useConfig} from "@beesset/upor-client-module-cmn";

const SidebarHeader = React.memo(() => {
    const {
        LogoComponent
    } = useConfig();
    return (
        <Toolbar sx={{pl: {xs: 2}, pr: {xs: 2}}}>
            {LogoComponent && <LogoComponent height="100px"/>}
        </Toolbar>
    );
});

export default SidebarHeader;
