import React from "react";
import {OutlinedInput, Stack} from "@mui/material";
import {useTranslation} from "react-i18next";
import isEqual from "react-fast-compare";

const NumberRangeColumnFilter = ({column: {filterValue = [], preFilteredRows, setFilter, id}}) => {
    const [currentValue, setCurrentValue] = React.useState([]);

    const {t} = useTranslation();

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    React.useEffect(() => {
        let timeoutTimout = 0;

        if(preFilteredRows.length > 8000){
            timeoutTimout = 500;
        }
        else if(preFilteredRows.length > 5000){
            timeoutTimout = 400;
        }
        else if(preFilteredRows.length > 2000) {
            timeoutTimout = 200;
        }
        else if(preFilteredRows.length > 500) {
            timeoutTimout = 100;
        }

        let timeout = setTimeout(() => {
            let finalFilter = [
                currentValue[0] && !isNaN(currentValue[0]) ? parseFloat(currentValue[0]) : undefined,
                currentValue[1] && !isNaN(currentValue[1]) ? parseFloat(currentValue[1]) : undefined
            ];

            if (!isEqual(finalFilter, filterValue)) {
                setFilter([
                    ...finalFilter
                ]);
            }
        }, timeoutTimout);
        return () => clearTimeout(timeout);
    }, [currentValue]);


    return (
        <Stack
            direction="row"
            spacing={1}
        >
            <OutlinedInput
                placeholder={`Min (${min})`}
                type="number"
                inputProps={{
                    "aria-label": `Min (${min})`,
                }}
                sx={{
                    width: "100%",
                    fontSize: "0.9rem",
                    minWidth: "70px"
                }}
                size="small"
                value={currentValue[0] !== undefined ? currentValue[0] : ""}
                onChange={(e) => {
                    setCurrentValue(old => [e.target.value, old[1]])
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            />
            <OutlinedInput
                placeholder={`Max (${max})`}
                type="number"
                inputProps={{
                    "aria-label": `Max (${max})`,
                }}
                sx={{
                    width: "100%",
                    fontSize: "0.9rem",
                    minWidth: "70px"
                }}
                size="small"
                value={currentValue[1] !== undefined ? currentValue[1] : ""}
                onChange={(e) => {
                    setCurrentValue(old => [old[0], e.target.value])
                }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            />
        </Stack>
    );
}

export default NumberRangeColumnFilter;
