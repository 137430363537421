import {Snackbar} from "@beesset/ui-components";
import {UPORUtils} from "@beesset/upor-client-module-cmn";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {FileOpener} from "@capacitor-community/file-opener";
import {CapacitorHttp} from "@capacitor/core";
import download from "downloadjs";

let portalApiDomain = "";

export function setPortalApiDomain(domain) {
    portalApiDomain = domain;
}

function getRootUrl() {
    if (UPORUtils.isDevelopmentMode()) {
        // return "http://192.168.0.109:8080";
        // return "http://192.168.1.109:8080";
        // return "https://damian-home-office.beesset.com:8443";
        // return "https://test-cc-grudziadz.beesset.com";
        // return "https://test-cc-bb.beesset.com";
        // return "https://damian.beesset.com:8443";
        return "http://localhost:8080";
    }

    if (UPORUtils.isNativePlatform()) {
        return portalApiDomain;
    }

    return window.location.origin;
}

function getApiUrl(uri) {
    if (UPORUtils.isNativePlatform()) {
        return `${getRootUrl()}/uporClient/apis/native/${uri}`;
    }
    return `${getRootUrl()}/uporClient/apis/web/${uri}`;
}

function commonParseResponse(response) {
    if (response.headers.get('x-redirection-url')) {
        window.location.replace(response.headers.get('x-redirection-url'));
        return;
    }
    if (response.headers.get('X-Redirection-Url')) {
        window.location.replace(response.headers.get('X-Redirection-Url'));
        return;
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        if (!response.ok) {
            return response.json().then((exception) => {
                let error = new Error("Application error occured.");
                error.exception = exception;
                throw error;
            });
        }
        return response.json();
    } else if (contentType && contentType.indexOf("application/zip") !== -1) {
        return response.blob();
    } else {
        if (!response.ok) {
            throw new Error("Application error occured.");
        }

        return response.text().then((text) => {
            return text;
        });
    }
}

function invoke({
                    uri,
                    url,
                    timeout = 20000,
                    options = {},
                    handlers: {success, failure, both} = {},
                    showSnackbar = true
                }) {
    const controller = new AbortController();
    options.signal = controller.signal;

    if (UPORUtils.isNativePlatform() || UPORUtils.isDevelopmentMode()) {
        options.credentials = "include";
    } else {
        if (window["xCSRFToken"] && options.method === "POST") {
            if (!options.headers) {
                options.headers = {};
            }
            options.headers['X-CSRF-Token'] = window["xCSRFToken"];
        }
    }

    let createdTimeout = setTimeout(() => controller.abort(), timeout);
    fetch(url || getApiUrl(uri), options)
        .then(commonParseResponse)
        .then((response) => {
            if (success) {
                try {
                    success(response);
                } catch (error) {
                    console.error(error);
                }
            }
        })
        .catch((error) => {
            let exception = error.exception;
            if (showSnackbar) {
                Snackbar.error(exception);
            }

            if (failure) {
                try {
                    failure(exception);
                } catch (error) {
                    console.error(error);
                }
            }
        })
        .finally(() => {
            if (both) {
                try {
                    both();
                } catch (error) {
                    console.error(error);
                }
            }
            clearTimeout(createdTimeout);
        });
}

function invokeGet({
                       uri,
                       url,
                       request,
                       timeout,
                       handlers: {success, failure, both} = {},
                       showSnackbar = true,
                       options
                   }) {
    if (request && Object.keys(request).length > 0) {
        if (uri) {
            uri = uri + "?" + new URLSearchParams(request);
        } else {
            url = url + "?" + new URLSearchParams(request);
        }

    }
    invoke({
        uri,
        url,
        options: {
            method: "GET",
            ...options
        },
        timeout,
        handlers: {
            success,
            failure,
            both,
        },
        showSnackbar
    });
}

function invokePost({uri, url, request, timeout, handlers: {success, failure, both} = {}, showSnackbar = true}) {
    invoke({
        uri,
        url,
        options: {
            method: "POST",
            // data: request,
            body: request
                ? JSON.stringify(request).replace(/"\s+|\s+"/g, '"')
                : undefined,
            headers: {'Content-Type': 'application/json'}
        },
        timeout,
        handlers: {
            success,
            failure,
            both,
        },
        showSnackbar
    });
}

function invokePostFormData({
                                uri,
                                url,
                                formData,
                                timeout,
                                handlers: {success, failure, both} = {},
                                showSnackbar = true
                            }) {
    let request = formData.get("request");
    if (request) {
        formData.set("request", encodeURI(request));
    }

    invoke({
        uri,
        url,
        options: {
            method: "POST",
            body: formData
            // headers: {'Content-Type': 'multipart/form-data'},
            // method: "POST",
            // body: formData,
            // dataType: "file"
        },
        timeout,
        handlers: {
            success,
            failure,
            both,
        },
        showSnackbar
    });
}

function downloadFile({uri, request}) {
    invokeGet({
        uri,
        request,
        handlers: {
            success: (response) => {
                if (UPORUtils.isNativePlatform() && !UPORUtils.isDevelopmentMode()) {
                    Filesystem.writeFile({
                        path: `${response.fileName}`,
                        data: response.data,
                        directory: Directory.Documents,
                        recursive: true
                    })
                        .then(() => {
                            return Filesystem.getUri({
                                directory: Directory.Documents,
                                path: `${response.fileName}`,
                            });
                        })
                        .then((getUriResult) => {
                            const path = getUriResult.uri;
                            return FileOpener.open({
                                filePath: path
                            });
                        });
                }
                else {
                    download(response.data, response.fileName);
                }
            }
        }
    })

    // let options = {};
    //
    // const controller = new AbortController();
    // options.signal = controller.signal;
    // let createdTimeout = setTimeout(() => controller.abort(), 20000);
    // let fileName;
    //
    // if (UPORUtils.isNativePlatform() && !UPORUtils.isDevelopmentMode()) {
    //     options.credentials = "include";
    //
    //     let fileName;
    //     CapacitorHttp.get({
    //         url: getApiUrl(uri),
    //         params: request,
    //         responseType: "blob",
    //         webFetchExtra: options
    //     })
    //         .then(response => {
    //             fileName = response.headers['Content-Disposition'].split('filename=')[1];
    //
    //             return Filesystem.writeFile({
    //                 path: `${fileName}`,
    //                 data: response.data,
    //                 directory: Directory.Documents,
    //                 recursive: true
    //             });
    //         })
    //         .then(() => {
    //             return Filesystem.getUri({
    //                 directory: Directory.Documents,
    //                 path: `${fileName}`,
    //             });
    //         })
    //         .then((getUriResult) => {
    //             const path = getUriResult.uri;
    //             return FileOpener.open({
    //                 filePath: path
    //             });
    //         })
    //         .catch((error) => {
    //             let exception = error.exception;
    //             Snackbar.error(exception);
    //         })
    //         .finally(() => {
    //             clearTimeout(createdTimeout);
    //         });
    // } else {
    //     fetch(getApiUrl(uri) + "?" + new URLSearchParams(request), options)
    //         .then(response => {
    //             fileName = response.headers.get('Content-Disposition').split('filename=')[1];
    //             return response.blob();
    //         })
    //         .then((blob) => {
    //             download(blob, decodeURIComponent(fileName));
    //         })
    //         .catch((error) => {
    //             let exception = error.exception;
    //             Snackbar.error(exception);
    //         })
    //         .finally(() => {
    //             clearTimeout(createdTimeout);
    //         });
    // }
}

export default {
    invokeGet,
    invokePost,
    invoke,
    getRootUrl,
    invokePostFormData,
    downloadFile
}




