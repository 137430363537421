import {useAccess} from "@beesset/common-utils";
import Box from "@mui/material/Box";
import MuiTab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";

const TabPanel = React.memo(
    ({children, id, ariaLabelledby, visible, keepMounted}) => {
        return (
            <Box
                sx={{height: "100%", overflow: "auto"}}
                role="tabpanel"
                hidden={visible === false}
                id={id}
                aria-labelledby={ariaLabelledby}
            >
                {keepMounted && children}
                {!keepMounted && visible === true && children}
            </Box>
        );
    }
);

const Tabs = ({
                items = [],
                keepMounted = false,
                orientation = "horizontal",
                sx,
                selectedTab = 0,
                selectedTabChanged
              }) => {
    const {hasAccess} = useAccess();
    const [value, setValue] = React.useState(selectedTab);

    React.useEffect(() => {
        console.log("tabs selectedTab changed")
        setValue(selectedTab);
    }, [selectedTabChanged]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let tabs = [];
    let tabsPanels = [];

    items
        .filter((item) => {
            return !hasAccess || hasAccess(item.accessRights);
        })
        .forEach((item, index) => {
            if (item) {
                let tabConfig = {
                    label: item.label,
                    id: `tab-${index}`,
                    "aria-controls": `tabpanel-${index}`,
                    key: `tab-${index}`,
                };
                tabs.push(<MuiTab {...tabConfig} />);
                let tabPanelConfig = {
                    keepMounted: keepMounted || item.keepMounted,
                    visible: value === index,
                    id: `tabpanel-${index}`,
                    ariaLabelledby: `tab-${index}`,
                    key: `tabpanel-${index}`,
                };
                tabsPanels.push(
                    <TabPanel {...tabPanelConfig}>{item.content}</TabPanel>
                );
            }
        });

    return <React.Fragment>
        {orientation === "horizontal" && (
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    flexGrow: 1,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    ...sx
                }}
            >
                <Box sx={{borderBottom: 1, borderColor: "divider", width: "100%"}}>
                    <MuiTabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        orientation="horizontal"
                    >
                        {tabs}
                    </MuiTabs>
                </Box>
                <Box sx={{flexGrow: 1, position: "relative", overflow: "hidden"}}>
                    {tabsPanels}
                </Box>
            </Box>
        )}
        {orientation === "vertical" && (
            <Box
                sx={{
                    height: "100%",
                    width: "100%",
                    flexGrow: 1,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "row",
                    ...sx
                }}
            >
                <Box sx={{borderRight: 1, borderColor: "divider"}}>
                    <MuiTabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        orientation="vertical"
                    >
                        {tabs}
                    </MuiTabs>
                </Box>
                <Box sx={{flexGrow: 1, position: "relative", overflow: "hidden", ml: 2, mt: 2}}>
                    {tabsPanels}
                </Box>
            </Box>
        )
        }
    </React.Fragment>
};

Tabs.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            content: PropTypes.object,
        })
    ),
};

export default Tabs;
