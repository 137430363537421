import {matchSorter} from "match-sorter";
import {DateUtils} from "@beesset/common-utils";

function greaterThanFilterFn(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

greaterThanFilterFn.autoRemove = (val) => typeof val !== "number";

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, {
        keys: [(row) => row.values[id]]
    });
}

fuzzyTextFilterFn.autoRemove = (val) => !val;

function fuzzyTextStartsWithFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, {
        keys: [(row) => row.values[id]],
        threshold: matchSorter.rankings.WORD_STARTS_WITH
    });
}

fuzzyTextStartsWithFilterFn.autoRemove = (val) => !val;

const customFilterFns = {
    fuzzyText: fuzzyTextFilterFn,
    fuzzyTextStartsWith: fuzzyTextStartsWithFilterFn,
    greaterThan: greaterThanFilterFn,
    text: (rows, id, filterValue) => {
        return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase())
                : true;
        });
    },
    textContains: (rows, id, filterValue) => {
        return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
                ? String(rowValue)
                    .toLowerCase()
                    .includes(String(filterValue).toLowerCase())
                : true;
        });
    },
    multiAttributesSelect: (row, columnId, filterValue = []) => {
        if (!filterValue || filterValue.length === 0) {
            return true;
        }

        let filtersByAttribute = {};
        filterValue.forEach((o) => {
            if (!filtersByAttribute[o.label]) {
                filtersByAttribute[o.label] = [];
            }
            filtersByAttribute[o.label].push(o);
        });

        let finalFilters = Object.values(filtersByAttribute).filter((o) => o.length === 1).map((o) => o[0]);
        if (finalFilters.length === 0) {
            return true;
        }

        let count = 0;
        finalFilters.forEach((el) => {
            let value = row.getValue(el.accessor);

            if ((el.value && value) || (!el.value && !value)) {
                count++;
            }
        });
        return count === finalFilters.length;
    },
    multiSelect: (row, columnId, filterValue) => {
        if (!filterValue || filterValue.length === 0) {
            return true;
        }
        let value = row.getValue(columnId);
        return filterValue.findIndex(o => o.label === value) >= 0;
    },
    select: (row, columnId, filterValue) => {
        if (!filterValue || filterValue.length === 0) {
            return true;
        }
        return row.getValue(columnId) === filterValue.label;
    },
    boolean: (row, columnId, filterValue) => {
        if (filterValue === 0) {
            return true;
        }
        let value = row.getValue(columnId);
        return (filterValue === 1 && value) || (filterValue === 2 && !value);
    },
    time: (rows, id, filterValue) => {
        if (filterValue && filterValue.value && filterValue.comparisonType) {
            let compFn;
            switch (filterValue.comparisonType) {
                case "less": {
                    compFn = (a, b) => a < b;
                    break;
                }
                case "lessEqual": {
                    compFn = (a, b) => a <= b;
                    break;
                }
                case "equal": {
                    compFn = (a, b) => a === b;
                    break;
                }
                case "moreEqual": {
                    compFn = (a, b) => a >= b;
                    break;
                }
                case "more": {
                    compFn = (a, b) => a > b;
                    break;
                }
            }

            if (compFn) {
                const date = DateUtils.getDate(filterValue.value);
                const minutes = date.hour() * 60 + date.minute();
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue && compFn(rowValue, minutes);
                });
            }
        }
        return [...rows];
    },
    date: (row, columnId, filterValue) => {
        if (filterValue && filterValue.value && filterValue.comparisonType) {
            let compFn;
            switch (filterValue.comparisonType) {
                case "less": {
                    compFn = (a, b) => a.isBefore(b);
                    break;
                }
                case "lessEqual": {
                    compFn = (a, b) => a.isBefore(b) || a.isSame(b);
                    break;
                }
                case "equal": {
                    compFn = (a, b) => a.isSame(b);
                    break;
                }
                case "moreEqual": {
                    compFn = (a, b) => a.isAfter(b) || a.isSame(b);
                    break;
                }
                case "more": {
                    compFn = (a, b) => a.isAfter(b);
                    break;
                }
            }

            if (compFn) {
                const date = DateUtils.getDate(filterValue.value);
                const rowValue = row.getValue(columnId)
                return rowValue !== undefined && compFn(DateUtils.getDate(rowValue), date);
            }
            return false;
        }
        return true;
    }
}

export default customFilterFns;