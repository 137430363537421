import React from "react";
import {
    Box,
    Divider,
    Grow,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Stack,
    Typography,
    useMediaQuery
} from "@mui/material";
import {KeyboardDoubleArrowRightRounded, ThumbUpAlt} from "@mui/icons-material";
import {Dialog} from "@beesset/ui-components";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";

const DESCRIPTOR_TYPES = {
    HEADER: 0,
    DIVIDER: 1,
    COMPONENT: 2,
    ITEM: 3,
    SUBLIST: 4
}

const RenderedDescriptors = ({descriptors, onSelect, rootKey}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return descriptors
        .map(({type, ...descriptor}, i) => {
            let newKey = `selectable-list-item-${rootKey}-${i}`;
            switch (type) {
                case DESCRIPTOR_TYPES.HEADER:
                    return <ListSubheader
                        key={newKey}
                        color="primary"
                        disableGutters={descriptor.disableGutters || isMobile}
                        sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            zIndex: 2
                        }}
                    >
                        {descriptor.name}
                    </ListSubheader>;
                case DESCRIPTOR_TYPES.DIVIDER:
                    return <Divider
                        key={newKey}
                        sx={{m: 1}}
                        variant="middle"
                    />;
                case DESCRIPTOR_TYPES.COMPONENT:
                    return <ListItem
                        key={newKey}
                        alignItems="flex-start"
                        disableGutters={isMobile}
                        disablePadding={descriptor.disablePadding}
                    >
                        {descriptor.Component}
                    </ListItem>
                case DESCRIPTOR_TYPES.SUBLIST:
                    return <li key={newKey}>
                        <ul>
                            <RenderedDescriptors {...descriptor} onSelect={onSelect} rootKey={`${rootKey}-${i}`}/>
                        </ul>
                    </li>
                default:
                    if (onSelect) {
                        return <CommonItem
                            key={newKey}
                            descriptor={descriptor}
                            onSelect={onSelect}
                            isMobile={isMobile}
                        />
                    } else {
                        return <ListItem key={newKey} dense disableGutters={isMobile} alignItems="flex-start">
                            {descriptor.Icon && <ListItemIcon>
                                {<descriptor.Icon/>}
                            </ListItemIcon>}
                            <ListItemText
                                primary={descriptor.name}
                                secondary={descriptor.value}
                                {...descriptor.TextProps}
                            />
                        </ListItem>;
                    }
            }
        });
}

const CommonItem = ({isMobile, descriptor, onSelect}) => {
    const [hovered, setHovered] = React.useState();

    return <ListItem
        disablePadding
        alignItems="flex-start"
        divider={descriptor.divider}
        onMouseOver={!isMobile ? () => setHovered(true) : null}
        onMouseOut={!isMobile ? () => setHovered(false) : null}
    >
        <ListItemButton
            onClick={() => {
                descriptor.onClick ? descriptor.onClick() : onSelect(descriptor);
            }}
            dense
            disableGutters={isMobile}
        >
            {descriptor.Icon && <ListItemIcon>
                {<descriptor.Icon/>}
            </ListItemIcon>}
            {descriptor.Text && <descriptor.Text/>}
            {descriptor.name && <Stack>
                <ListItemText
                    primary={descriptor.name}
                    secondary={descriptor.value}
                >

                </ListItemText>
                {descriptor.specialInfo &&
                    descriptor.specialInfo
                        .split('\\n')
                        .map((it, i) => {
                            return <Typography variant="caption"
                                               color="error"
                                               key={'x' + i}>{it}
                            </Typography>
                        })
                }
            </Stack>}
            {!isMobile && !descriptor.onClick && <React.Fragment>
                <Box width="200px" flexShrink={0}/>
                <SlidingArrowRight key="go-next-arrow" hovered={hovered} name={descriptor.nextStepInfo}/>
            </React.Fragment>}
        </ListItemButton>
    </ListItem>;
}

const SlidingArrowRight = ({hovered}) => {
    const {t} = useTranslation();

    return <Grow in={hovered} timeout={500}>
        <Stack
            sx={{
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
                color: "info.main",
                position: "absolute",
                right: "20px",
                width: "100%"
            }}
            direction="row"
            spacing={2}
        >
            <Typography fontSize="0.85rem" fontWeight={500}>
                {t("button.goNext")}
            </Typography>
            <KeyboardDoubleArrowRightRounded/>
        </Stack>
    </Grow>
}

const SelectableList = ({descriptors, onSelect}) => {
    const [selection, setSelection] = React.useState(null);

    function handleSelect(props) {
        if (onSelect) {
            if (props.specialInfo) {
                setSelection(props);
            } else {
                onSelect(props);
            }
        }
    }

    return <React.Fragment>
        <List
            sx={{
                width: '100%',
                position: 'relative',
                overflow: 'auto',
                backgroundColor: 'background.paper',
                paddingRight: 2,
                paddingLeft: 2,
                '& ul': {
                    padding: 0,
                    overflowX: "hidden"
                },
                '& p': {whiteSpace: "pre-line"},
                borderRadius: 5
            }}
            subheader={<li/>}
        >
            <RenderedDescriptors descriptors={descriptors} onSelect={onSelect ? handleSelect : null} rootKey={"0"}/>
        </List>
        {selection != null && <ConfirmBox
            description={selection.specialInfo}
            onClose={() => setSelection(null)}
            onConfirm={() => onSelect(selection)}
        />}
    </React.Fragment>;
}

const ConfirmBox = ({description, onClose, onConfirm}) => {
    const {t} = useTranslation();

    return <Dialog
        disableFullScreen
        open={true}
        title={t("common.warning")}
        actions={[{
            name: t("button.cancel"),
            color: "error",
            onClick: onClose
        }, {
            name: t("button.confirm"),
            color: "info",
            onClick: onConfirm,
            endIcon: <ThumbUpAlt/>
        }]}
    >
        {<Stack spacing={2}>
            {description
                .split('\\n')
                .map((it, i) => {
                    return <Typography color={"text.secondary"} variant={"body1"}
                                       key={'x' + i}>{it}</Typography>
                })}
        </Stack>}
    </Dialog>
}

const CommonStep = ({title, ...props}) => {
    return <React.Fragment>
        <Typography color={"text.secondary"} mt={2} mb={2}>
            {title}
        </Typography>
        <SelectableList {...props}/>
    </React.Fragment>
}

export {CommonStep, DESCRIPTOR_TYPES};