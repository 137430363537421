import {Box} from '@mui/material';
import React from "react";
import {useLayoutNavigation} from "../LayoutNavigation";
import {DesktopDrawer, MobileDrawer} from "./MainDrawer";
import SettingsDrawer from "./SettingsDrawer";


const Sidebar = React.memo((props) => {
    const {drawerWidth} = useLayoutNavigation();

    return (
        <Box
            component="nav"
            sx={{width: {md: drawerWidth, xs: 0}, float: "left"}}
            aria-label="Menu"
        >
            <MobileDrawer/>
            <DesktopDrawer/>
            <SettingsDrawer/>
        </Box>
    );
});

export default Sidebar;
