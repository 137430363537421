import React from "react";
import LayoutNavigation from "./navigation/LayoutNavigation";
import {RefinedLoader} from "@beesset/ui-components";
import LayoutContent from "./content/LayoutContent";
import {useTranslation} from "react-i18next";
import {
    ApplicationsComponent,
    Articles,
    DeclarationOfAccessibility,
    EuropeanFundsLogo,
    MEDIUM_TYPE,
    NotificationsHandlerComponent,
    PasswordRecovery,
    PrivacyPolicy,
    PurchaseComponent,
    Regulations,
    SettingsComponent,
    SignInForm,
    SignInUp,
    SignUpForm,
    StartupPathListenerComponent,
    Tariff,
    TransactionsHistoryComponent,
    UPORClientRest,
    UPORUtils,
    useConfig,
    VirtualCityCardComponent
} from "@beesset/upor-client-module-cmn";
import {HashRouter as Router, useLocation} from "react-router-dom";
import {
    AccessibilityNew,
    AccountCircle,
    FactCheck,
    FormatListBulleted,
    HistoryEdu,
    Logout,
    Newspaper,
    PhoneAndroid,
    Sell,
    Settings,
    ShoppingCart
} from "@mui/icons-material";
import {SessionValidator} from "@beesset/common-module";
import {NativeAppEventsListener} from "../handlers/NativeAppHandler";

const LayoutComponentId = {
    PURCHASE: "purchase",
    PURCHASE_HISTORY: "purchaseHistory",
    SETTINGS: "settings",
    APPLICATIONS: "applications",
    REGULATIONS: "regulations",
    REGULATIONS_REGULATION: "regulations-regulation",
    REGULATIONS_PRIVACY_POLICY: "regulations-privacyPolicy",
    LOGOUT: "logout",
    SIGN_IN_UP: "auth",
    SIGN_IN: "sign-in",
    SIGN_UP: "sign-up",
    PASSWORD_RECOVERY: "password-recovery",
    ARTICLES: "articles",
    TARIFF: "tariff",
    DECLARATION_OF_ACCESSIBILITY: "declarationOfAccessibility",
    VIRTUAL_CITY_CARD: "virtualCityCard"
}

function createComponentConfig(id) {
    switch (id) {
        case LayoutComponentId.PURCHASE: {
            return {
                id: "purchase",
                Icon: ShoppingCart,
                onInitSelected: !UPORUtils.isNativePlatform(),
                link: "/purchase",
                component: PurchaseComponent,
            };
        }
        case LayoutComponentId.PURCHASE_HISTORY: {
            return {
                id: "purchaseHistory",
                Icon: FormatListBulleted,
                link: "/purchase-history",
                component: TransactionsHistoryComponent,
                withPaddings: false,
            };
        }
        case LayoutComponentId.SETTINGS: {
            return {
                id: "settings",
                Icon: Settings,
                link: "/settings",
                component: SettingsComponent
            };
        }
        case LayoutComponentId.APPLICATIONS: {
            return {
                id: "applications",
                Icon: HistoryEdu,
                link: "/applications",
                component: ApplicationsComponent
            }
        }
        case LayoutComponentId.REGULATIONS_REGULATION: {
            return {
                component: Regulations,
                id: "regulations-regulation"
            }
        }
        case LayoutComponentId.REGULATIONS_PRIVACY_POLICY: {
            return {
                link: "privacyPolicy",
                component: PrivacyPolicy,
                id: "regulations-privacyPolicy"
            }
        }
        case LayoutComponentId.REGULATIONS: {
            return {
                id: "regulations",
                link: "/regulations",
                isTabs: true,
                Icon: FactCheck,
                subRoutes: [
                    LayoutComponentId.REGULATIONS_REGULATION,
                    LayoutComponentId.REGULATIONS_PRIVACY_POLICY
                ]
            }
        }
        case LayoutComponentId.LOGOUT: {
            return {
                id: "logout",
                onClick: () => {
                    UPORClientRest.logout();
                },
                Icon: Logout
            }
        }
        case LayoutComponentId.SIGN_IN: {
            return {
                component: SignInForm,
                id: "sign-in"
            }
        }
        case LayoutComponentId.SIGN_UP: {
            return {
                link: "sign-up",
                id: "sign-up",
                component: SignUpForm,
            }
        }
        case LayoutComponentId.PASSWORD_RECOVERY: {
            return {
                link: "password-recovery",
                id: "password-recovery",
                component: PasswordRecovery,
            }
        }
        case LayoutComponentId.SIGN_IN_UP: {
            return {
                id: "auth",
                Icon: AccountCircle,
                link: "/auth",
                onInitSelected: true,
                withPaddings: false,
                component: SignInUp,
                subRoutes: [
                    LayoutComponentId.SIGN_IN,
                    LayoutComponentId.SIGN_UP,
                    LayoutComponentId.PASSWORD_RECOVERY
                ]
            }
        }
        case LayoutComponentId.ARTICLES: {
            return {
                id: "articles",
                Icon: Newspaper,
                link: "/articles",
                component: Articles
            }
        }
        case LayoutComponentId.TARIFF: {
            return {
                id: "tariff",
                Icon: Sell,
                link: "/tariff",
                component: Tariff
            }
        }
        case LayoutComponentId.DECLARATION_OF_ACCESSIBILITY: {
            return {
                id: "declarationOfAccessibility",
                Icon: AccessibilityNew,
                link: "/declaration-of-accessibility",
                component: DeclarationOfAccessibility
            }
        }
        case LayoutComponentId.VIRTUAL_CITY_CARD: {
            return {
                id: "virtualCityCard",
                Icon: PhoneAndroid,
                link: "/virtualCityCard",
                component: VirtualCityCardComponent,
                onInitSelected: true
            }
        }
    }
}

const ScrollToTop = () => {
    const {pathname} = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function LayoutPrv({LayoutProps}) {
    const {initialized, protectedResources, isMediumTypeAvailable} = useConfig();
    const {t} = useTranslation();

    const config = React.useMemo(() => {
        if (initialized) {
            let moduleItems = [];

            let itemsIds = [];
            if (protectedResources) {
                let virtualCardView = UPORUtils.isNativePlatform() && isMediumTypeAvailable(MEDIUM_TYPE.VIRTUAL_CITY_CARD);
                if (virtualCardView) {
                    itemsIds.push(LayoutComponentId.VIRTUAL_CITY_CARD);
                }
                itemsIds.push(
                    LayoutComponentId.PURCHASE,
                    LayoutComponentId.PURCHASE_HISTORY
                );
                if (!virtualCardView) {
                    itemsIds.push(LayoutComponentId.SETTINGS);
                }
                itemsIds.push(
                    LayoutComponentId.ARTICLES,
                    LayoutComponentId.APPLICATIONS,
                    LayoutComponentId.REGULATIONS,
                    LayoutComponentId.LOGOUT
                );
            } else {
                itemsIds.push(
                    LayoutComponentId.SIGN_IN_UP,
                    LayoutComponentId.ARTICLES,
                    LayoutComponentId.TARIFF,
                    LayoutComponentId.APPLICATIONS,
                    LayoutComponentId.REGULATIONS,
                    LayoutComponentId.DECLARATION_OF_ACCESSIBILITY
                );
            }

            let componentDefaultProps = LayoutProps.componentDefaultProps || {};

            let componentProps = {};
            if (LayoutProps.EuropeanFundsComponent) {
                componentProps[LayoutComponentId.SIGN_IN] = {
                    Header: EuropeanFundsLogo
                }
            }

            itemsIds.forEach((id) => {
                let item = {
                    ...createComponentConfig(id),
                    ...componentDefaultProps[id],
                    ...componentProps[id]
                };
                if (componentProps[id]) {
                    item.componentProps = {
                        ...item.componentProps,
                        ...componentProps[id]
                    };
                }

                if (item.subRoutes) {
                    item.subRoutes = item
                        .subRoutes
                        .map((subRouteId) => {
                            let subItem = {
                                ...createComponentConfig(subRouteId),
                                ...componentDefaultProps[subRouteId]
                            };

                            if (componentProps[subRouteId]) {
                                subItem.componentProps = {
                                    ...subItem.componentProps,
                                    ...componentProps[subRouteId]
                                };
                            }
                            return subItem;
                        })
                        .filter(o => !o.disabled);
                }
                moduleItems.push(item);
            });

            moduleItems = moduleItems.filter(o => !o.disabled);

            let components = LayoutProps.components || [];
            components.forEach(({order, ...props}) => {
                order >= 1 && moduleItems.splice(order - 1, 0, props);
            });


            if (LayoutProps.EuropeanFundsComponent) {
                moduleItems.push({
                    id: "europeanFunds",
                    path: "/europeanFunds",
                    component: LayoutProps.EuropeanFundsComponent.content
                });
            }

            let result = {
                moduleItems: moduleItems.map((item) => {
                    return {
                        ...item,
                        name: t(`componentsNames.${item.id}`),
                        subRoutes: item.subRoutes ? item.subRoutes.map(subRoute => {
                            return {
                                ...subRoute,
                                name: t(`componentsNames.${subRoute.id}`)
                            }
                        }) : undefined
                    }
                }),
                drawerWidth: 260,
            };

            if (protectedResources) {
                result.StartupPathListenerComponent = StartupPathListenerComponent;
                result.NotificationsHandlerComponent = NotificationsHandlerComponent;
            }

            if (LayoutProps.EuropeanFundsComponent) {
                result.MenuFooter = () => <EuropeanFundsLogo height="40px" paddingLeft={1}/>
            }

            return result;
        }
    }, [initialized, protectedResources, LayoutProps, t]);

    return config ? <React.Fragment>
        <Router>
            {UPORUtils.isNativePlatform() && <NativeAppEventsListener/>}
            <ScrollToTop/>
            <LayoutNavigation {...config}/>
            <LayoutContent {...config} />
        </Router>
        <RefinedLoader/>
        <SessionValidator validateSession={UPORClientRest.validateSession}/>
        {config.NotificationsHandlerComponent && <NotificationsHandlerComponent/>}
    </React.Fragment> : null;
}

const Layout = ({LayoutProps}) => {
    return <LayoutPrv LayoutProps={LayoutProps}/>;
}

export {
    Layout,
    LayoutComponentId
};