import {useTranslation} from "react-i18next";
import {useFormContext} from "react-hook-form";
import React from "react";
import {TextField} from "@beesset/ui-components";
import {IconButton, InputAdornment} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";


const DoublePassword = () => {
    const {t} = useTranslation();

    const {
        getValues,
        trigger,
        formState: {errors},
    } = useFormContext();

    const [showPassword, setShowPassword] = React.useState(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = React.useState(false);

    return <React.Fragment>
        <TextField
            isStacked
            name="password"
            label={t("common.password")}
            required
            type={showPassword ? "text" : "password"}
            placeholder={t("common.passwordPlaceholder")}
            autoComplete="new-password"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={t(showPassword ? "button.hidePassword" : "button.showPassword")}
                            onClick={() => setShowPassword((old) => !old)}
                            onMouseDown={(event) => event.preventDefault()}
                        >
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            validation={{
                validate: (value) => {
                    let bean = getValues();

                    let valid = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(value);
                    if (!valid) {
                        return t("exception.INVALID_PASSWORD_FORMAT");
                    }

                    if (value && bean.repeatedPassword && value.length !== 0 && bean.repeatedPassword.length !== 0 && value != bean.repeatedPassword) {
                        if (!errors.repeatedPassword) {
                            setTimeout(() => {
                                trigger("repeatedPassword");
                            }, 10);
                        }
                        return t("exception.PASSWORD_NOT_MATCH");
                    } else {
                        if (errors.repeatedPassword) {
                            setTimeout(() => {
                                trigger("repeatedPassword");
                            }, 10);
                        }
                    }
                },
            }}
        />
        <TextField
            name="repeatedPassword"
            sx={{marginTop: "unset"}}
            label={t("components.doublePassword.repeatedPassword")}
            required
            type={showPasswordRepeat ? "text" : "password"}
            placeholder={t("components.doublePassword.repeatedPasswordPlaceholder")}
            autoComplete="new-password"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={t(showPasswordRepeat ? "button.hidePassword" : "button.showPassword")}
                            onClick={() => setShowPasswordRepeat((old) => !old)}
                            onMouseDown={(event) => event.preventDefault()}
                        >
                            {showPasswordRepeat ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            validation={{
                validate: (value) => {
                    let bean = getValues();

                    let valid = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(value);
                    if (!valid) {
                        return t("exception.INVALID_PASSWORD_FORMAT");
                    }

                    if (value && bean.password && value.length !== 0 && bean.password.length !== 0 && value != bean.password) {
                        if (!errors.password) {
                            setTimeout(() => {
                                trigger("password");
                            }, 10);
                        }
                        return t("exception.PASSWORD_NOT_MATCH");
                    } else {
                        if (errors.password) {
                            setTimeout(() => {
                                trigger("password");
                            }, 10);
                        }
                    }
                },
            }}
        />
    </React.Fragment>
}

export {
    DoublePassword
};