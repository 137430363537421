const Page3 = () => {
    return <iframe
        width="853"
        height="480"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
            border: "none"
        }}
        src="https://www.youtube.com/embed/RL0mALJyJ1c"/>
}
export default Page3;