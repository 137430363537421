import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {IconButton, Input, InputAdornment, TextField} from "@mui/material";
import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import {useTranslation} from "react-i18next";

const FileUploadField = ({
                             parseError,
                             name,
                             value,
                             validation = {},
                             helperText,
                             multiple = false,
                             maxSize,
                             allowedExtensions,
                             actionOnChange,
                             label,
                             required,
                             isStacked,
                             sx
                         }) => {
        const {t} = useTranslation();

        const {control, validationMessages} = useFormContext();


        const {finalInputConfig, finalValidation, finalSx} = React.useMemo(() => {
            let config = {
                finalInputConfig: {
                    label: label,
                    autoComplete: "off",
                    readOnly: true,
                    fullWidth: true
                },
                finalValidation: {
                    validate: (value) => {
                        if (value && value === "Invalid date") {
                            return validationMessages.invalidDate;
                        }
                    },
                    ...validation
                },
                finalSx: {
                    marginTop: "10px",
                    ...sx
                }
            }

            if (required) {
                config.finalInputConfig["required"] = true;
                config.finalValidation.required = validationMessages.required;
            }

            if (isStacked) {
                config.finalSx['margin'] = "0px";
            }

            if (maxSize || allowedExtensions) {
                config.finalValidation.validate = (value) => {

                    function hasExtension(file) {
                        let fileName = file.name;
                        return (new RegExp('(' + allowedExtensions.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
                    }

                    if (value) {
                        if (allowedExtensions) {
                            if (multiple) {
                                for (let i = 0; i < value.length; i++) {
                                    let file = value.item(i)
                                    if (!hasExtension(file)) {
                                        return validationMessages.wrongFileFormat(allowedExtensions.join(", "));
                                    }
                                }
                            } else {
                                if (!hasExtension(value)) {
                                    return validationMessages.wrongFileFormat(allowedExtensions.join(", "));
                                }
                            }
                        }

                        let size = 0;
                        if (multiple) {
                            for (let i = 0; i < value.length; i++) {
                                let file = value.item(i)
                                size += file.size;
                            }
                        } else {
                            size = value.size;
                        }

                        if (size / 1024 / 1024 > maxSize) {
                            return validationMessages.maxFilesSize(maxSize);
                        }
                    }

                }
            }

            return config;
        }, [label, required, validationMessages, isStacked, validation, sx, maxSize, allowedExtensions, multiple]);


        return (
            <Controller
                control={control}
                name={name}
                rules={finalValidation}
                defaultValue={value || ""}
                render={({
                             field: {ref, onChange, value, ...fieldRest},
                             fieldState: {invalid, error},
                         }) => (
                    <TextField
                        {...fieldRest}
                        {...finalInputConfig}
                        value={multiple ?
                            (value && value.length > 0) ? (value.length > 1 ? t("commonComponents.filesPicker.files", {count: value.length}) : value[0].name) : "" :
                            value ? value.name : ""}
                        InputProps={{
                            sx: {pr: "unset"},
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                >
                                    <label htmlFor="contained-button-file">
                                        <Input
                                            ref={ref}
                                            accept="image/*"
                                            id="contained-button-file"
                                            onChange={(e) => {
                                                if (e.target.files && e.target.files.length > 0) {
                                                    if (multiple) {
                                                        onChange(e.target.files);
                                                    } else {
                                                        onChange(e.target.files[0]);
                                                        if (actionOnChange) {
                                                            actionOnChange(e.target.files[0]);
                                                        }
                                                    }
                                                } else {
                                                    onChange(null);
                                                }
                                            }}
                                            inputProps={{
                                                multiple: true
                                            }}
                                            type="file"
                                            style={{display: "none"}}
                                        />
                                        <IconButton
                                            aria-label={t(multiple ? "commonComponents.filesPicker.selectFiles" : "commonComponents.filesPicker.selectFile")}
                                            variant="outlined" component="span">
                                            <CloudUploadIcon/>
                                        </IconButton>
                                    </label>
                                </InputAdornment>
                            ),
                        }}
                        error={invalid}
                        sx={finalSx}
                        helperText={
                            error
                                ? typeof parseError === "function"
                                    ? parseError(error)
                                    : error.message
                                : helperText
                        }
                    />
                )}
            />
        );
    }
;

export default FileUploadField;
