import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
    Avatar,
    Box,
    Button,
    CardActionArea,
    CardHeader,
    Collapse,
    Fade,
    IconButton,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack
} from '@mui/material';
import {Add, ConfirmationNumber, LightMode, MoreVert, ShoppingCart} from "@mui/icons-material";
import {
    DateField,
    MEDIUM_TYPE,
    PASSENGER_IDENTITY_TYPE,
    PeselOrDateOfBirthField,
    UPORClientRest,
    UPORUtils,
    useConfig
} from "@beesset/upor-client-module-cmn";
import {FormContainer, hideLoader, List, ResponsiveFieldsGroup, showLoader, TextField} from "@beesset/ui-components";
import {DateUtils, generateId} from "@beesset/common-utils";
import {useTranslation} from "react-i18next";
import "./virtualCityCard.css";
import VirtualCardDetails from "./VirtualCardDetails";
import {useNavigate} from "react-router-dom";
import {ScreenBrightness} from '@capacitor-community/screen-brightness';
import {Capacitor} from "@capacitor/core";
import {styled} from "@mui/material/styles";
import {patternFormatter} from 'react-number-format';

const AddCard = () => {
    const {refresh} = useConfig();
    const formId = React.useRef(generateId(10));
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const {isPassengerIdentityType} = useConfig();

    return <Stack flex={1} alignItems="center" justifyContent={open ? "flex-start" : "center"}>
        {!open && <CardActionArea
            sx={{
                width: "100%",
                height: "100%",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
            onClick={() => setOpen(true)}
        >
            <Stack alignItems="center" maxWidth="70%">
                <Add sx={{width: "8rem", height: "8rem"}} color="action"/>
                <Typography color="text.secondary" textAlign="center">
                    {t("components.virtualCityCard.addInfoPart1")} <Box component="span"
                                                                        fontWeight={600}>{t("components.virtualCityCard.addInfoPart2")}</Box>
                </Typography>
            </Stack>
        </CardActionArea>}
        {open && <Fade in={open}>
            <Box maxWidth={600} width={"100%"} bgcolor="background.paper" p={3} borderRadius={3}>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Typography variant="body1" color="text.secondary" fontWeight={500}>
                            {t("components.virtualCityCard.createTitle")}
                        </Typography>
                        <Stack spacing={0.5}>
                            <Typography variant="body2">
                                {t("components.virtualCityCard.createDescription")}
                            </Typography>
                            <Typography variant="body2">
                                {t("components.virtualCityCard.createDescription2")}
                            </Typography>
                        </Stack>
                    </Stack>
                    <FormContainer
                        style={{marginTop: "unset"}}
                        id={formId.current}
                        onSuccess={(bean) => {
                            showLoader();
                            UPORClientRest.assignMedium({
                                handlers: {
                                    success: refresh,
                                    both: () => {
                                        hideLoader();
                                    }
                                },
                                request: {
                                    medium: {
                                        ...bean,
                                        mediumType: MEDIUM_TYPE.VIRTUAL_CITY_CARD
                                    }
                                }
                            });
                        }}
                    >
                        <ResponsiveFieldsGroup isMainContainer minWidth="unset">
                            <TextField label={t("common.firstName")} name="firstName" required isStacked/>
                            <TextField label={t("common.lastName")} name="lastName" required/>
                            {isPassengerIdentityType(PASSENGER_IDENTITY_TYPE.DATE_OF_BIRTH) && <DateField
                                label={t("common.dateOfBirth")}
                                name="dateOfBirth"
                                required
                                openTo="year"
                                views={["year", "month", "day"]}
                                minDate={DateUtils.getNow().startOf("day").subtract(120, "year")}
                                maxDate={DateUtils.getNow().startOf("day")}
                                disableHighlightToday
                            />}
                            {isPassengerIdentityType(PASSENGER_IDENTITY_TYPE.PESEL) && <PeselOrDateOfBirthField/>}
                        </ResponsiveFieldsGroup>
                    </FormContainer>
                    <Button
                        variant="contained"
                        size="large"
                        type="submit"
                        form={formId.current}
                    >
                        {t("button.save")}
                    </Button>
                </Stack>
            </Box>
        </Fade>}
    </Stack>
}

const StyledCard = styled(Card)(({theme}) =>
    theme.unstable_sx({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.mode === "light" ? "primary.dark" : "background.paper",
        color: theme.palette.mode === "light" ? "primary.contrastText" : "text.primary"
    })
);

const VirtualCityCard = ({card}) => {
    const [brightnessOn, setBrightnessOn] = React.useState(false);
    const {getLanguage, appIsRunning, networkConnection} = useConfig();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [showDetails, setShowDetails] = React.useState(false);
    const [data, setData] = React.useState(null);
    const {LogoComponent} = useConfig();

    const language = getLanguage();
    React.useEffect(() => {
        if (!appIsRunning || !networkConnection) {
            return;
        }

        let active = true;
        let timeout = null;

        function run() {
            UPORClientRest.getTransactions({
                handlers: {
                    success: (response) => {
                        if (active) {
                            setData(response['transactions']);
                        }
                    },
                    both: () => {
                        if (active) {
                            timeout = setTimeout(() => {
                                run();
                            }, 10000);
                        }
                    }
                },
                request: {
                    onlyActive: true,
                    language: language
                },
                showSnackbar: false
            });
        }

        run();

        return () => {
            active = false;
            clearInterval(timeout);
        }
    }, [language, appIsRunning, networkConnection]);

    React.useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            let brightness = null;

            ScreenBrightness
                .getBrightness()
                .then((o) => {
                    brightness = o.brightness;
                    if (o.brightness === 1.0) {
                        setBrightnessOn(true);
                    }
                })
                .catch(() => {
                });

            return () => {
                if (brightness !== null && brightness !== undefined) {
                    void ScreenBrightness.setBrightness({
                        brightness: brightness
                    });
                }
            };
        }
    }, []);

    return <React.Fragment>
        <StyledCard variant="outlined">
            <CardHeader
                avatar={
                    <Avatar
                        sx={{bgcolor: "secondary.main"}}
                        aria-label="virtual-city-card-profile"
                    >
                        {card.firstName[0]}
                    </Avatar>
                }
                action={
                    <IconButton
                        onClick={() => {
                            setShowDetails(true);
                        }}
                        aria-label="settings"
                        color="inherit"
                    >
                        <MoreVert/>
                    </IconButton>
                }
                disableTypography={true}
                title={<Stack>
                    <Typography lineHeight={1.4}
                                fontSize={"0.9rem"}>{`${card.firstName} ${card.lastName}`}</Typography>
                    <Typography lineHeight={1.3} fontSize={"0.8rem"}>
                        {card.pesel ? `${t("common.pesel")}: ${card.pesel}` : `${t("common.dateOfBirth")} ${DateUtils.formatters.DDMONTHYY(card.dateOfBirth)}`}
                    </Typography>
                    <Typography lineHeight={1} fontSize={"0.8rem"}>
                        {t("common.profile")}: <Box component="span" fontWeight="bold">{card.profiles[0].name}</Box>
                    </Typography>
                </Stack>}
            />
            {data !== null && <Fade in={true}>
                <CardContent sx={{display: "flex", flexDirection: "column", flex: 1}}>
                    <Stack flex={10} spacing={2} justifyContent="center" alignItems="center">
                        <Stack component={Paper} bgcolor="common.white" alignItems="center" pt={2} pb={1} pl={4} pr={4}
                               spacing={1}>
                            <CardMedia
                                component="img"
                                sx={{
                                    maxWidth: 140,
                                    maxHeight: 140,
                                    width: "100%"
                                }}
                                image={card.qrCode}
                            />
                            <Typography variant="caption" color="common.black">
                                {patternFormatter(card.mediumId, {format: "## ### ### ### ### ###"})}
                            </Typography>
                        </Stack>
                        {<Collapse
                            timeout={200}
                            in={UPORUtils.isNativePlatform() && !brightnessOn}
                        >
                            <Button
                                size="small"
                                color="inherit"
                                endIcon={<LightMode/>}
                                onClick={() => {
                                    ScreenBrightness
                                        .setBrightness({
                                            brightness: 1.0
                                        })
                                        .then(() => setBrightnessOn(true));
                                }}
                            >
                                {t("components.virtualCityCard.screenBrightnessButton")}
                            </Button>
                        </Collapse>}
                    </Stack>
                    <Stack m={1} flex={10} justifyContent="flex-start" alignItems="center" minHeight="120px">
                        <Box width="100%" maxWidth="350px" className="grow" paddingTop={0.7}>
                            <LogoComponent height="100px" marginRight={0}/>
                        </Box>
                    </Stack>
                    <Box>
                        {data.length === 0 &&
                            <Button
                                color="inherit"
                                fullWidth
                                size="large"
                                endIcon={<ShoppingCart/>}
                                onClick={() => {
                                    navigate("/purchase");
                                }}
                            >
                                {t("components.virtualCityCard.buyTicket")}
                            </Button>}
                        {data.length > 0 && <React.Fragment>
                            <Typography gutterBottom variant="h5" component="div">
                                {t("components.virtualCityCard.activeTickets")}
                            </Typography>
                            <List
                                sx={{
                                    color: "inherit"
                                }}
                                descriptors={data.map(o => {
                                    let description = [];
                                    description.push(
                                        <span key={"profile-title"}>{`${t("common.profile")}: `}</span>,
                                        <span key={"profile-description"}>{o.item.profileName}</span>
                                    );

                                    if (o.item.activationDate) {
                                        description.push(<br key={"activationDate-breakLine"}/>);
                                        description.push(
                                            <span key={"activationDate-title"}>{`${t("common.startDate")}: `}</span>,
                                            <span
                                                key={"activationDate-description"}>{DateUtils.formatters.DDMMYYYY_HHMMSS(o.item.activationDate)}</span>
                                        );
                                    }
                                    if (o.item.expirationDate) {
                                        description.push(<br key={"expirationDate-breakLine"}/>);
                                        description.push(
                                            <span key={"expirationDate-title"}>{`${t("common.endDate")}: `}</span>,
                                            <span
                                                key={"expirationDate-description"}>{DateUtils.formatters.DDMMYYYY_HHMMSS(o.item.expirationDate)}</span>
                                        );
                                    }
                                    if (o.vehicleNumber) {
                                        description.push(<br key={"vehicleNumber-breakLine"}/>);
                                        description.push(
                                            <span
                                                key={"vehicleNumber-title"}>{`${t("components.application.other.vehicleNumber")}: `}</span>,
                                            <span key={"vehicleNumber-description"}>{o.vehicleNumber}</span>
                                        );
                                    }

                                    return {
                                        Component: <ListItemButton
                                            disableGutters
                                            onClick={() => {
                                                navigate(`/purchase-history?transactionId=${o.transactionId}`);
                                            }}
                                        >
                                            <ListItemIcon>
                                                <ConfirmationNumber sx={{color: "secondary.light"}}/>
                                            </ListItemIcon>
                                            <ListItemText
                                                sx={{
                                                    whiteSpace: "pre-line"
                                                }}
                                                primaryTypographyProps={{
                                                    fontSize: "0.85rem",
                                                    color: "secondary.light"
                                                }}
                                                secondaryTypographyProps={{
                                                    fontSize: "0.72rem",
                                                    lineHeight: 1.2,
                                                    color: "inherit"
                                                }}
                                                primary={`${o.item.count > 1 ? `${o.item.count} × ` : ""}${o.item.productName}`}
                                                secondary={description}
                                            />
                                        </ListItemButton>
                                    }
                                })}
                            />
                        </React.Fragment>}
                    </Box>
                </CardContent>
            </Fade>}
        </StyledCard>
        <VirtualCardDetails open={showDetails} onClose={() => setShowDetails(false)} card={card}/>
    </React.Fragment>
}


const VirtualCityCardComponent = () => {
    const {config} = useConfig();

    return config['mediums'].length === 0
        ? <AddCard/>
        : <VirtualCityCard card={config['mediums'][0]}/>
}

export {
    VirtualCityCardComponent
};