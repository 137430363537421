import {DateField} from "./DateField";
import {DateUtils} from "@beesset/common-utils";
import {Stack} from "@mui/material";
import React from "react";
import {useFormContext} from "react-hook-form";

const DatePeriodField = ({startDateName, startDateLabel, endDateName, endDateLabel, required = false}) => {
    const {
        watch,
        getValues
    } = useFormContext();

    const [bean, setBean] = React.useState(getValues());

    React.useEffect(() => {
        const subscription = watch((value) => {
            setBean(value);
        });
        return () => {
            subscription['unsubscribe']();
        };
    }, [watch]);

    return <Stack
        direction={{
            xs: "column",
            sm: "row",
        }}
        spacing={2}
        alignItems="baseline"
        marginTop="10px"
    >
        <DateField
            name={startDateName}
            label={startDateLabel}
            sx={{marginTop: "unset", width: "100%"}}
            required={required}
        />
        <DateField
            name={endDateName}
            label={endDateLabel}
            minDate={
                bean[startDateName] ? DateUtils.getDate(bean[startDateName]) : undefined
            }
            sx={{marginTop: "unset", width: "100%"}}
            required={required}
        />
    </Stack>
}

export {DatePeriodField};