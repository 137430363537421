import {getLanguage, useDataFetcher} from "@beesset/common-utils";
import {sortProducts, UPORClientRest} from "@beesset/upor-client-module-cmn";
import {useTranslation} from "react-i18next";
import {Table} from "@beesset/ui-components";
import React from "react";
import {Box, Stack, Typography} from "@mui/material";

const TariffPrv = ({data, translations}) => {
    const tableConfig = React.useMemo(() => {
        return {
            columns: [
                {
                    Header: translations.profile,
                    accessor: props => `${props.productName} ${props.profileName}`,
                    Cell: (props) => {
                        return (<Stack>
                            <Typography sx={{fontSize: {xs: "0.775rem", sm: "0.875rem"}}}>
                                {props.row.original.productName}
                            </Typography>
                            <Typography sx={{color: "text.secondary", fontSize: {xs: "0.65rem", sm: "0.75rem"}}}>
                                {props.row.original.profileName}
                            </Typography>
                        </Stack>);
                    },
                    headerStyle: {
                        width: 250
                    }
                },
                {
                    Header: translations.productName,
                    accessor: props => `${props.productCategory} ${props.productGroup}`,
                    Cell: (props) => {
                        return (<Stack>
                            <Typography sx={{fontSize: {xs: "0.775rem", sm: "0.875rem"}}}>
                                {props.row.original.productCategory}
                            </Typography>
                            <Typography sx={{color: "text.secondary", fontSize: {xs: "0.65rem", sm: "0.75rem"}}}>
                                {props.row.original.productGroup}
                            </Typography>
                        </Stack>);
                    },
                    headerStyle: {
                        width: 170
                    }
                },
                {
                    Header: translations.price,
                    accessor: props => {
                        let currency = props['currencyList'][0];
                        return currency.price.toFixed(2);
                    },
                    Cell: ({value, row}) => {
                        let currency = row.original['currencyList'][0];
                        return (<Stack>
                            <Typography sx={{fontSize: {xs: "0.775rem", sm: "0.875rem"}}}>
                                {value}
                            </Typography>
                            <Typography sx={{color: "text.secondary", fontSize: {xs: "0.65rem", sm: "0.75rem"}}}>
                                {currency['currencyCode']}
                            </Typography>
                        </Stack>);
                    },
                    headerStyle: {
                        width: 80
                    }
                }],
            sorting: true,
            columnFiltering: false,
            globalFiltering: true
        }
    }, [translations]);

    return <Box bgcolor={"background.paper"}>
        <Table {...tableConfig} data={data}/>
    </Box>
}


const Tariff = () => {
    const {t} = useTranslation();

    const {data: tariff} = useDataFetcher({
        fetch: UPORClientRest.getTariff,
        convert: response => {
            if (response['tariff']) {
                return sortProducts(response['tariff']['products']);
            }
            return null;
        },
        request: {
            language: getLanguage()
        }
    });

    const translations = React.useMemo(() => {
        return {
            productName: t("components.purchase.categorySelection"),
            profile: t("components.purchase.profileSelection"),
            price: t("common.price"),
        }
    }, [t]);

    return tariff && <TariffPrv data={tariff} translations={translations}/>
}

export {
    Tariff
};