import {Dialog} from "@beesset/ui-components";
import {Close} from "@mui/icons-material";
import "./afterPurchase.css";
import {useTranslation} from "react-i18next";
import {Slide, Stack, Typography} from "@mui/material";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const AfterPurchaseDialog = ({translations, afterPurchaseProps, onClose, AfterPurchaseComponent}) => {
    const [info, setInfo] = React.useState(null);

    React.useEffect(() => {
        if (afterPurchaseProps !== null && afterPurchaseProps !== undefined) {
            setInfo({
                status: afterPurchaseProps.status
            });
        } else {
            if (info != null) {
                setTimeout(() => {
                    setInfo(null);
                }, 300);
            }
        }
    }, [afterPurchaseProps]);

    return <Dialog
        TransitionComponent={Transition}
        disableMaxWidth
        disableFullScreen
        open={afterPurchaseProps !== null && afterPurchaseProps !== undefined}
        actions={[{
            name: translations.close,
            color: "inherit",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }]}
    >
        {info !== null && <React.Fragment>
            {(info.status === 'TR_FINISHED' || info.status === 'TR_PAYMENT_CONFIRMED')
                ? <AfterPurchaseComponent/>
                : <UnknownStatusInfo/>}
        </React.Fragment>}
    </Dialog>
}

const UnknownStatusInfo = () => {
    const {t} = useTranslation();

    return <Stack direction="column" spacing={1}>
        <Typography>
            {t("components.history.purchaseUnknownStatus.part1")}
        </Typography>
        <Typography variant="body2">
            {t("components.history.purchaseUnknownStatus.part2")}
        </Typography>
    </Stack>
}

export default AfterPurchaseDialog;