import {Box, Paper, Stack, Tab, Tabs, useMediaQuery} from "@mui/material";
import React from "react";
import "./ValidatorsPage3.css";
import image1 from "./image1.png";
import image2 from "./image2.png";
import image3 from "./image3.png";
import image4 from "./image4.png";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";

const Page3 = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return  <Box position="relative" width="100%" alignSelf="flex-start">
        <AllTabs handleChange={handleChange} value={value}/>
        <Stack justifyContent="center" width="100%" alignItems="center">
            <TabContent src={image1} isActive={value===0}/>
            <TabContent src={image2} isActive={value===1}/>
            <TabContent src={image3} isActive={value===2}/>
            <TabContent src={image4} isActive={value===3}/>
        </Stack>
    </Box>
}

const AllTabs = ({handleChange, value}) => {
    const {t} = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const scrollable = useMediaQuery(theme.breakpoints.down("lg"));

    return <Box
        position={isMobile?"initial":"sticky"}
        width="100%"
        top={0}
        boxShadow="0 5px 5px rgba(182, 182, 182, 0.15);"
        alignSelf="flex-start"
        mb={2}
    >
        <Tabs
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
            centered={!scrollable}
            sx={{bgcolor: "background.paper", pb: isMobile?0:1, pt: isMobile?0:1}}
            variant={scrollable?"scrollable":"auto"}
            scrollButtons={true}
            allowScrollButtonsMobile
        >
            <Tab value={0} label={t("components.help.validators.3.1")}/>
            <Tab value={1} label={t("components.help.validators.3.2")}/>
            <Tab value={2} label={t("components.help.validators.3.3")}/>
            <Tab value={3} label={t("components.help.validators.3.4")}/>
        </Tabs>
    </Box>
}

const TabContent = ({src, isActive}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return  <Paper sx={{p: isMobile?0.3:3, display: isActive?"block":"none"}}>
        <img width="100%" style={{maxWidth: "800px"}} src={src}/>
    </Paper>
}

export default Page3;