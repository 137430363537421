import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import {
    Divider,
    List as MuiList,
    ListItem as MuiListItem,
    ListItemIcon as MuiListItemIcon,
    ListItemText as MuiListItemText,
    ListSubheader as MuiListSubheader
} from "@mui/material";
import React from "react";

const wrapValue = (value) => {
    if (value === undefined || value.length === 0) {
        return "—";
    } else if (typeof value == "boolean") {
        if (value) {
            return (
                <ToggleOnIcon color="info" fontSize="large" sx={{display: "block"}}/>
            );
        } else {
            return (
                <ToggleOffIcon
                    color="disabled"
                    fontSize="large"
                    sx={{display: "block"}}
                />
            );
        }
    } else {
        return value;
    }
};

const DenseTextProps = {
    primaryTypographyProps: {
        fontSize: "0.8rem",
        noWrap: true
    },
    secondaryTypographyProps: {
        fontSize: "0.8rem",
        noWrap: true
    }
}

const ListItem = ({name, value, Icon, TextProps = {}, dense = false, onClick, sx}) => {
    return (
        <MuiListItem disablePadding alignItems="flex-start">
            {Icon && <MuiListItemIcon>{Icon}</MuiListItemIcon>}
            <MuiListItemText
                // inset={Icon ? false : true}
                primary={name}
                secondary={wrapValue(value)}
                onClick={onClick}
                sx={sx}
                {...(dense
                    ? {
                        ...DenseTextProps,
                        ...TextProps
                    }
                    : TextProps)}
            ></MuiListItemText>
        </MuiListItem>
    );
};

export default function List({sx, descriptors, dense = false}) {
    return (
        <MuiList dense sx={sx}>
            {descriptors
                .filter(({show}) => show === undefined || show === null || show === true)
                .map((descriptor, index) => {
                    if (!descriptor) {
                        return null;
                    }
                    if (descriptor.isDivider) {
                        return <Divider key={`preview-list-item-${index}`} component="li" sx={{mt: 6, mb: 3}}
                                        variant="middle" {...descriptor.isDivider}/>;
                    }
                    if (descriptor.Component) {
                        return (
                            <MuiListItem
                                disablePadding
                                alignItems="flex-start"
                                key={`preview-list-item-${index}`}
                            >
                                {descriptor.Component}
                            </MuiListItem>
                        );
                    }
                    if (descriptor.isHeader) {
                        return (
                            <MuiListSubheader
                                disableGutters
                                color="primary"
                                disableSticky={descriptor.disableSticky === undefined ? true : descriptor.disableSticky}
                                key={`preview-list-item-${index}`}
                                sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    zIndex: 3,
                                    ...descriptor.sx
                                }}
                            >
                                {descriptor.name}
                            </MuiListSubheader>
                        );
                    }
                    return <ListItem {...descriptor} key={`preview-list-item-${index}`} dense={dense}/>;
                })}
        </MuiList>
    );
}
