import InputBase from "@mui/material/InputBase";
import {Clear, Search} from "@mui/icons-material";
import PropTypes from "prop-types";
import React from "react";
import {IconButton, InputAdornment, styled} from "@mui/material";
import {useTranslation} from "react-i18next";
import Slide from '@mui/material/Slide';

const SearchRoot = styled("div")(({theme}) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "inherit",
    marginRight: 0,
    marginLeft: theme.spacing(2),
    width: 250,
    opacity: 0.65,
    "&:hover, :focus, :active": {
        opacity: 0.9
    },
    [theme.breakpoints.down("md")]: {
        width: 200,
    },
}));

const SearchInput = styled(InputBase)(({theme}) => ({
    "&.MuiInputBase-root": {
        color: "inherit",
        padding: "5px 10px",
        "& .MuiInputBase-input": {
            transition: theme.transitions.create("width"),
            width: "100%",
        },
        "&:hover, :focus, :active": {
            borderBottom: `1px solid ${theme.palette.text.secondary}`
        }
    },
}));

const GlobalFilter = ({
                          globalFilter,
                          setGlobalFilter,
                      }) => {
    const {t} = useTranslation();
    const containerRef = React.useRef();

    return (
        <SearchRoot>
            <SearchInput
                value={globalFilter || ""}
                onChange={(e) => {
                    setGlobalFilter(e.target.value || undefined);
                }}
                placeholder={t("commonComponents.filter.search")}
                inputProps={{
                    "aria-label": t("commonComponents.filter.search")
                }}
                startAdornment={<InputAdornment position="start">
                    <Search/>
                </InputAdornment>}
                endAdornment={<InputAdornment position="end">
                    <IconButton
                        aria-label={t("button.delete")}
                        edge="end"
                        disableRipple
                        ref={containerRef}
                        onClick={(e) => {
                            setGlobalFilter(undefined);
                        }
                        }
                    >
                        <Slide in={globalFilter && globalFilter.length > 0} orientation="horizontal"
                               direction={"right"} container={containerRef.current} timeout={100}>
                            <Clear/>
                        </Slide>
                    </IconButton>
                </InputAdornment>}
            />
        </SearchRoot>
    );
};

GlobalFilter.propTypes = {
    preGlobalFilteredRows: PropTypes.array.isRequired,
    globalFilter: PropTypes.string,
    setGlobalFilter: PropTypes.func.isRequired,
};

export default GlobalFilter;
