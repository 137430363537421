import {TemplateContainer, UPORClientRest} from "@beesset/upor-client-module-cmn";
import React from 'react';

const DeclarationOfAccessibility = () => {
    return <TemplateContainer fetch={UPORClientRest.declarationOfAccessibility}/>
}

const PrivacyPolicy = () => {
    return <TemplateContainer fetch={UPORClientRest.privacyPolicy}/>
}

const Regulations = () => {
    return <TemplateContainer fetch={UPORClientRest.regulation}/>
}

export {
    DeclarationOfAccessibility,
    PrivacyPolicy,
    Regulations
};