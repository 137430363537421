import {Close} from "@mui/icons-material";
import {Dialog, hideLoader, showLoader, Snackbar} from "@beesset/ui-components";
import {UPORClientRest} from "@beesset/upor-client-module-cmn";

const RefundDialog = ({translations, refundRequest, onClose, onSuccess}) => {
    return <Dialog
        title={translations.refundTitle}
        description={translations.refundDescription}
        open={refundRequest != null}
        disableFullScreen={true}
        actions={[{
            name: translations.close,
            color: "inherit",
            onClick: onClose,
            fullScreenProps: {
                Icon: <Close/>,
                align: "left",
            },
        }, {
            name: translations.send,
            onClick: () => {
                showLoader();
                UPORClientRest.createRefund({
                    handlers: {
                        success: () => {
                            onSuccess();
                            Snackbar.success(translations.refundSuccess);
                        },
                        failure: () => {
                            hideLoader();
                        }
                    },
                    request: refundRequest
                });
            },
            fullScreenProps: {
                align: "right",
            },
        }]}
    />
}
export default RefundDialog;