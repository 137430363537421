import {useTranslation} from "react-i18next";
import React from "react";
import {FormContainer, hideLoader, showLoader, Snackbar, TextField} from "@beesset/ui-components";
import {Button, Fade, Link, Stack, Typography} from "@mui/material";
import {
    DoublePassword,
    PhoneNumberPattern,
    RegulationCheck,
    RegulationCheckDialog,
    TextLink,
    UPORClientRest
} from "@beesset/upor-client-module-cmn";
import {NavLink, useNavigate} from "react-router-dom";
import {getLanguage, useDataFetcher} from "@beesset/common-utils";
import HtmlReactParser from "html-react-parser";

const SignUpForm = ({
                        showPhoneField = false,
                        description = "components.signUp.description",
                        RegulationCheckComponent
                    }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [showPrivacyPolicy, setShowPrivacyPolicy] = React.useState(false);
    const regulationCheckPrivacyPolicy = React.useRef();
    const regulationCheck = React.useRef();

    const onSignUp = (formData) => {
        let register = () => {
            showLoader();
            UPORClientRest.register({
                handlers: {
                    success: (response) => {
                        Snackbar.info(t("components.signUp.success"));
                        navigate("/auth", {replace: true});
                    },
                    both: () => {
                        hideLoader();
                    }
                },
                request: formData
            });
        }

        regulationCheckPrivacyPolicy.current.check(() => {
            if (RegulationCheckComponent) {
                regulationCheck.current.check(register);
            } else {
                register();
            }
        });
    };

    return <React.Fragment>
        <Link component={NavLink} to={"/auth"}>
            {t("button.back")}
        </Link>
        <FormContainer onSuccess={onSignUp} style={{width: "100%"}}>
            <Fade in={true}>
                <Stack spacing={5}>
                    <Stack spacing={1}>
                        <Typography variant="h6">
                            {t("components.signUp.name")}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            {t(description)}
                        </Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <TextField
                            sx={{marginTop: "unset"}}
                            name="email"
                            label={t("common.email")}
                            type="email"
                            required
                            autoComplete="off"
                            placeholder={t("common.emailPlaceholder")}
                        />
                        {showPhoneField && <TextField
                            label={t("common.phoneNumber")}
                            name={"phoneNumber"}
                            InputProps={{
                                inputComponent: PhoneNumberPattern
                            }}
                            inputProps={{
                                inputMode: "numeric"
                            }}
                        />}
                        <DoublePassword/>
                        <RegulationCheck
                            description={<div style={{display: "inline-block"}}>
                                <Typography variant="caption">
                                    {` ${t("components.signUp.regulationPrivacyPolicy.part1")}`}
                                </Typography>
                                <TextLink onClick={(e) => {
                                    setShowPrivacyPolicy(true)
                                    e.preventDefault();
                                    e.stopPropagation();
                                    return false;
                                }}>
                                    {t("components.signUp.regulationPrivacyPolicy.part2")}
                                </TextLink>
                            </div>}
                            warning={t("components.application.regulationWarning")}
                            ref={regulationCheckPrivacyPolicy}
                        />
                        {RegulationCheckComponent && <RegulationCheckComponent ref={regulationCheck}/>}
                    </Stack>
                    <Button
                        type="submit"
                        variant="contained"
                    >
                        {t("components.signUp.enter")}
                    </Button>
                </Stack>
            </Fade>
        </FormContainer>
        <PrivacyPolicyDialog
            open={showPrivacyPolicy}
            onClose={() => setShowPrivacyPolicy(false)}
            onAccept={() => {
                regulationCheckPrivacyPolicy.current.mark();
                setShowPrivacyPolicy(false);
            }}
        />
    </React.Fragment>
}

const PrivacyPolicyDialog = ({open, onClose, onAccept}) => {
    const {
        data
    } = useDataFetcher({
        fetch: UPORClientRest.privacyPolicy,
        request: {
            language: getLanguage()
        },
        convert: (response) => response['contents'],
        showLoading: false
    })

    return <RegulationCheckDialog
        open={open && data !== null}
        onClose={onClose}
        onAccept={onAccept}
    >
        <div className="beesset_template">
            {data !== null && data.length >= 1 && HtmlReactParser(data[0].content)}
        </div>
    </RegulationCheckDialog>
}

export {
    SignUpForm
};