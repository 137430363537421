import moment from 'moment';

function getDiffDuration({fromDate, toDate}) {
    let a = fromDate != null ? moment(fromDate) : moment();
    let b = toDate != null ? moment(toDate) : moment();
    const diff = b.diff(a);
    return moment.duration(diff);
}

const DateUtils = {
    formatters: {
        YYYMMDDHHMMSS: (date) => {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
        DD_MONTH_YYYY_HHMMSS_WITH_NULL: (date) => {
            return date ? moment(date).format('LLL:ss') : "—";
        },
        YYYYMMDD: (date) => {
            return moment(date).format('YYYY-MM-DD');
        },
        DDMMYYYY: (date) => {
            return moment(date).format('L');
        },
        DDMMYYYY2: (date) => {
            return moment(date).format('DD-MM-YYYY');
        },
        DDMMYYYY_HHMMSS: (date) => {
            return moment(date).format('L HH:mm:ss');
        },
        DDMONTHYY: (date) => {
            return moment(date).format('LL');
        },
        DAY_DD_MONTH_YYYY: (date) => {
            return moment(date).format('dddd, LL');
        },
        DD_MONTH_YYYY_HHMM: (date) => {
            return moment(date).format('LLL');
        },
        DD_MONTH_YYYY_HHMMSS: (date) => {
            return moment(date).format('LLL:ss');
        },
        FULL: (date) => {
            return moment(date).format('LLLL');
        },
        FULLWithSeconds: (date) => {
            return moment(date).format('LLLL:ss');
        },
        MMSS: (seconds) => {
            let m = seconds % 60;
            let h = (seconds - m) / 60;
            return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`
        },
        HHMM: (minutes) => {
            let m = minutes % 60;
            let h = (minutes - m) / 60;
            return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}`
        },
        HH_MM: (date) => {
            return moment(date).format('HH:mm');
        },
        HH_MM_SS: (date) => {
            return moment(date).format('HH:mm:ss');
        },
        ISOfromDate: (date) => {
            return moment(date).format('YYYY-MM-DDTHH:mm');
        },
        ISO_withSeconds: (date) => {
            return moment(date).format('YYYY-MM-DDTHH:mm:ss');
        },
        ISO: (date, time) => {
            return DateUtils.formatters.YYYYMMDD(date)+"T"+DateUtils.formatters.HH_MM(time);
        }
    },
    isToday: (date) => {
        return moment().isSame(moment(date), 'day');
    },   
    isAfterToday: (date) => {
        return moment(date).isAfter(moment(), 'day');
    },
    isAfterNow: (date) => {
        return moment(date).isAfter(moment(), 'second');
    },
    isBefore: ({dateA, dateB, granularity = "day"}) => {
        return moment(dateA).isBefore(moment(dateB), granularity);
    },
    isAfter: ({dateA, dateB, granularity = "day"}) => {
        return moment(dateA).isAfter(moment(dateB), granularity);
    },
    isBeforeToday: (date) => {
        return moment(date).isBefore(moment(), 'day');
    },
    isBeforeNow: (date) => {
        return moment(date).isBefore(moment(), 'second');
    },
    getDiffMilis: ({fromDate, toDate}) => {
        return getDiffDuration({fromDate, toDate}).asMilliseconds();
    },
    getDiffMinutes: ({fromDate, toDate}) => {
        return Math.round(getDiffDuration({fromDate, toDate}).asMinutes());
    },
    getDiffSeconds: ({fromDate, toDate}) => {
        return Math.round(getDiffDuration({fromDate, toDate}).asSeconds());
    },
    getNow: () => {
        return moment();
    },
    getToday: () => {
        return moment().startOf('day')
    },
    getDate: (date) => {
        return moment(date)
    },
    getTodayStr: () => {
        return moment().format("YYYY-MM-DD");
    },
    minusHours: (value, date) => {
        if (date) {
            return date.subtract(value, 'hours')
        } else {
            return DateUtils.getNow().subtract(value, 'hours')
        }
    },
    minus: (value, adjuster, date) => {

        if (date) {
            return date.subtract(value, adjuster);
        } else {
            return DateUtils.getNow().subtract(value, adjuster)
        }
    },
    plus: (value, adjuster, date) => {
        if (date) {
            return date.add(value, adjuster)
        } else {
            return DateUtils.getNow().add(value, adjuster)
        }
    },
    getStartOfMonth: (date) => {
        return date.startOf('month');
    },
    getStartOfDay: (date) => {
        return date.startOf('day');
    },
    isDate: (date) => {
        if (date === undefined) {
            return false;
        }
        return moment(date).isValid();
    }

}

export default DateUtils;