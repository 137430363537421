import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";

export const RadioField = ({name, control, value, isStacked, options, sx, row = false, size}) => {
    const finalSx = React.useMemo(() => {
        let finalSx = {
            marginTop: "10px",
            width: "100%",
            ...sx
        };

        if (isStacked) {
            finalSx['width'] = "100%";
            finalSx['margin'] = "0px";
            finalSx['marginLeft'] = 1.5;
        }

        return finalSx;
    }, [isStacked, sx]);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value}
            render={({field: {onChange, value, ...rest}}) => (
                <RadioGroup
                    {...rest}
                    row={row}
                    onChange={(e, value) => {
                        onChange(value);
                    }}
                    value={value != null && value != undefined ? value : ""}
                    sx={finalSx}
                >
                    {options.map((singleOption, index) => (
                        <FormControlLabel
                            value={singleOption.value}
                            label={singleOption.label}
                            control={<Radio size={size}/>}
                            key={`radio-control-${index}`}
                        />
                    ))}
                </RadioGroup>
            )}
        />
    );
};

export default RadioField;
