import {styled} from "@mui/material/styles";
import {Stack} from "@mui/material";
import MZK from "./MZK.svg";
import KMBB from "./KMBB.svg";
import MZK_white from "./MZK_WHITE.svg";
import KMBB_white from "./KMBB_WHITE.svg";
import {useTheme} from "@mui/system";

const Image = styled('div')({
    height: "100%",
    display: "block",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center"
});

const Logo = ({width, src, sx = {}}) => {
    return <Image sx={{
        width,
        backgroundImage: `url(${src})`,
        ...sx
    }}/>
}

const LogoComponent = ({height = "170px"}) => {
    const theme = useTheme();

    return <Stack
        direction="row"
        justifyContent="center"
        maxWidth="350px"
        width="100%"
        height={height}
    >
        <Logo width={"58%"} src={theme.palette.mode === "light" ? MZK : MZK_white} sx={{mr: 2}}/>
        <Logo width={"34%"} src={theme.palette.mode === "light" ? KMBB : KMBB_white} sx={{mr: 3}}/>
    </Stack>
}

export {
    LogoComponent
};