import {useAppConfig} from "@beesset/common-module";
import {changeLanguage, getLanguages} from "@beesset/common-utils";
import {Menu} from "@beesset/ui-components";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ModuleIcon from "@mui/icons-material/GridViewRounded";
import LanguageIcon from "@mui/icons-material/Language";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import {Tooltip, useMediaQuery} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useLayoutNavigation} from "../LayoutNavigation";
import Clock from "./Clock";
import DevicesIcon from '@mui/icons-material/Devices';
import ActiveSessions from "./ActiveSessions";
import {useLayout} from "../../Layout";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {styled} from "@mui/material/styles";
import {UPMRest} from "@beesset/rest";

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open, drawerwidth}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerwidth}px)`,
        marginLeft: `${drawerwidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


export default function Header() {
    const {config} = useAppConfig();
    const {t} = useTranslation();
    const {selectedItem} = useLayoutNavigation();
    const {toggleMenu, desktopOpen, drawerWidth} = useLayout();

    const isMobile = useMediaQuery(theme => {
        return theme.breakpoints.down("sm");
    });

    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState(null);
    const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = React.useState(null);
    const [moduleMenuAnchorEl, setModuleMenuAnchorEl] = React.useState(null);
    const [showActiveSessions, setShowActiveSessions] = React.useState(false);

    const profileMenuConfig = React.useMemo(() => {
        let result = {
            handleProfileMenuOpen: (event) => {
                setProfileMenuAnchorEl(event.currentTarget);
            },
            handleProfileMenuClose: () => {
                setProfileMenuAnchorEl(null);
                mobileMenuConfig.handleMobileMenuClose();
            },
            props: {
                id: "profile-menu",
                items: []
            }
        }

        if (!config.standaloneModuleProps) {
            result.props.items.push({
                name: t("profile"),
                Icon: <AccountCircle fontSize="small"/>,
                closeOnSelect: true,
                onSelect: () => {
                    window.location = `/upm#/userInfo`;
                },
            });
            result.props.items.push({
                name: t("activeSessions"),
                Icon: <DevicesIcon fontSize="small"/>,
                closeOnSelect: true,
                onSelect: () => {
                    setShowActiveSessions(true);
                },
            });
        }

        if (result.props.items.length > 0) {
            result.props.items.push({
                isDivider: true,
            });
        }

        result.props.items.push({
            name: t("button.logout"),
            Icon: <Logout fontSize="small"/>,
            onSelect: () => {
                if (config.standaloneModuleProps) {
                    config.standaloneModuleProps.logout();
                } else {
                    UPMRest.logoutUser();
                }
            },
            closeOnSelect: true,
        });

        return result;
    }, [t, config.standaloneModuleProps]);

    const languageMenuConfig = React.useMemo(() => {
        if (getLanguages().length > 1) {
            return {
                handleLanguageMenuOpen: (event) => {
                    setLanguageMenuAnchorEl(event.currentTarget);
                },
                handleLanguageMenuClose: () => {
                    setLanguageMenuAnchorEl(null);
                    mobileMenuConfig.handleMobileMenuClose();
                },
                props: {
                    id: "language-menu",
                    items: getLanguages().map((language) => {
                        return {
                            name: language.description || language.id,
                            onSelect: () => {
                                changeLanguage(language.id);
                            },
                            closeOnSelect: true,
                        };
                    }),
                },
            };
        }
    }, []);

    const moduleMenuConfig = React.useMemo(() => {
        let items = [];
        config.userInfo.userModules.forEach((module) => {
            let Icon = config.modulesIcons[module.name]

            if (module.name != config.moduleId) {
                items.push({
                    name: t(`module.${module.name}`),
                    onSelect: () => {
                        window.location = `/${module.name}`;
                    },
                    Icon: Icon ? <Icon fontSize="small"/> : undefined,
                    closeOnSelect: true,
                });
            }
        });

        if (items.length > 0) {
            return {
                handleModuleMenuOpen: (event) => {
                    setModuleMenuAnchorEl(event.currentTarget);
                },
                handleModuleMenuClose: () => {
                    setModuleMenuAnchorEl(null);
                    mobileMenuConfig.handleMobileMenuClose();
                },
                props: {
                    id: "module-menu",
                    items: items,
                },
            };
        }
    }, [t]);

    const mobileMenuConfig = React.useMemo(() => {
        let items = [];

        if (languageMenuConfig) {
            items.push({
                name: t("language"),
                props: {
                    "aria-label": t("language"),
                    "aria-controls": languageMenuConfig.props.id,
                    "aria-haspopup": "true",
                    color: "inherit",
                },
                onSelect: languageMenuConfig.handleLanguageMenuOpen,
                Icon: <LanguageIcon fontSize="medium"/>,
            });
        }

        items.push({
            name: t("account"),
            props: {
                "aria-label": t("account"),
                "aria-controls": profileMenuConfig.props.id,
                "aria-haspopup": "true",
                color: "inherit",
            },
            onSelect: profileMenuConfig.handleProfileMenuOpen,
            Icon: <AccountCircle fontSize="medium"/>,
        });

        if (moduleMenuConfig) {
            items.splice(1, 0, {
                name: t("module.menu"),
                props: {
                    "aria-label": t("module.menu"),
                    "aria-controls": moduleMenuConfig.props.id,
                    "aria-haspopup": "true",
                    color: "inherit",
                },
                onSelect: moduleMenuConfig.handleModuleMenuOpen,
                Icon: <ModuleIcon fontSize="medium"/>,
            });
        }

        return {
            handleMobileMenuOpen: (event) => {
                setMobileMenuAnchorEl(event.currentTarget);
            },
            handleMobileMenuClose: () => {
                setMobileMenuAnchorEl(null);
            },
            props: {
                id: "mobile-menu",
                items: items,
            },
        };
    }, [t]);

    return <React.Fragment>
        <AppBar
            position="fixed"
            open={desktopOpen}
            drawerwidth={drawerWidth}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleMenu}
                    sx={{mr: 2}}
                >
                    {isMobile || !desktopOpen ? <MenuIcon/> : <MenuOpenIcon/>}
                </IconButton>
                <Typography variant="h5" noWrap component="div">
                    {selectedItem && selectedItem.parentPath}
                </Typography>
                <Box sx={{flexGrow: 1}}/>

                <Box sx={{display: {xs: "none", md: "flex"}, alignItems: "center"}}>
                    <Clock/>
                    {languageMenuConfig && <Tooltip title={t("language")}>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label={t("language")}
                            aria-controls={languageMenuConfig.props.id}
                            aria-haspopup="true"
                            onClick={languageMenuConfig.handleLanguageMenuOpen}
                            color="inherit"
                        >
                            <LanguageIcon/>
                        </IconButton>
                    </Tooltip>}
                    {moduleMenuConfig && <Tooltip title={t("module.menu")}>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label={t("module.menu")}
                            aria-controls={moduleMenuConfig.props.id}
                            aria-haspopup="true"
                            onClick={moduleMenuConfig.handleModuleMenuOpen}
                            color="inherit"
                        >
                            <ModuleIcon/>
                        </IconButton>
                    </Tooltip>
                    }
                    <Tooltip title={t("profile")}>
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label={t("account")}
                            aria-controls={profileMenuConfig.props.id}
                            aria-haspopup="true"
                            onClick={profileMenuConfig.handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box sx={{display: {xs: "flex", md: "none"}}}>
                    <IconButton
                        size="large"
                        aria-label={t("showMore")}
                        aria-controls={mobileMenuConfig.props.id}
                        aria-haspopup="true"
                        onClick={mobileMenuConfig.handleMobileMenuOpen}
                        color="inherit"
                    >
                        <MoreIcon/>
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
        <Menu
            anchorEl={mobileMenuAnchorEl}
            onClose={mobileMenuConfig.handleMobileMenuClose}
            {...mobileMenuConfig.props}
        />
        {languageMenuConfig && <Menu
            anchorEl={languageMenuAnchorEl}
            onClose={languageMenuConfig.handleLanguageMenuClose}
            {...languageMenuConfig.props}
        />}
        {moduleMenuConfig && <Menu
            anchorEl={moduleMenuAnchorEl}
            onClose={moduleMenuConfig.handleModuleMenuClose}
            {...moduleMenuConfig.props}
        />}
        <Menu
            anchorEl={profileMenuAnchorEl}
            onClose={profileMenuConfig.handleProfileMenuClose}
            {...profileMenuConfig.props}
        />
        {showActiveSessions && <ActiveSessions
            onClose={() => setShowActiveSessions(false)}
        />}
    </React.Fragment>;
}
