import {useStepper} from "@beesset/ui-components";
import {useTariffs} from "../TariffsProvider";
import {CommonStep, DESCRIPTOR_TYPES} from "./CommonStep";
import React from "react";

const Step1_ProfileSelection = ({translations, nextStepOnStartup}) => {
    const {getProfiles} = useTariffs();
    const {goNext, updateData} = useStepper();

    React.useEffect(() => {
        if (nextStepOnStartup) {
            goNext();
        }
    }, []);

    function onSelect(item) {
        updateData({
            profileLoid: item.profileLoid,
            transactionInit: null
        });
        goNext();
    }

    return <CommonStep
        title={translations.profileSelectionDesc}
        onSelect={onSelect}
        descriptors={[{
            type: DESCRIPTOR_TYPES.HEADER,
            name: translations.profileSelection
        }, ...getProfiles().map((profile) => {
            return {
                name: profile.profileName,
                profileLoid: profile.profileLoid
            }
        })]}
    />
}

export default Step1_ProfileSelection;